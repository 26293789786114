import isObject from 'lodash/isObject';
import InstagramSvg from '@/assets/img/channels/instagram.svg';
import FacebookSvg from '@/assets/img/channels/facebook-light.svg';
import MetaSvg from '@/assets/img/channels/meta.svg';
import PinterestSvg from '@/assets/img/channels/pinterest.svg';
import TikTokSvg from '@/assets/img/channels/tiktok.svg';
import TwitterSvg from '@/assets/img/channels/twitter.svg';
import GaSvg from '@/assets/img/channels/ga.svg';
import YoutubeSvg from '@/assets/img/channels/youtube.svg';
import LinkedInSvg from '@/assets/img/channels/linkedin.svg';
import InstagramNoAccessImg from '@/assets/img/instagram-no-access.png';
import FacebookNoAccessImg from '@/assets/img/facebook-no-access.png';
import TikTokNoAccessImg from '@/assets/img/tiktok-no-access.png';
import PinterestNoAccessImg from '@/assets/img/pinterest-no-access.png';
import TwitterNoAccessImg from '@/assets/img/twitter-no-access.png';
import YoutubeNoAccessImg from '@/assets/img/youtube-no-access.png';
import LinkedinNoAccessImg from '@/assets/img/linkedin-no-access.png';
import { BRAND, USER } from '@/models/auth/permissions.enum';

export const PLATFORM_CONNECTION_STATUS = {
  CONNECTED: 'connected',
  INVALID: 'invalid',
};

export const BRAND_PLATFORM_PERMISSIONS = Object.freeze({
  FACEBOOK: [BRAND.FACEBOOK.CAN_ACCESS_FB_INSIGHTS],
  FACEBOOK_COMPETITIVE: [BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS],
  INSTAGRAM: [BRAND.INSTAGRAM.CAN_ACCESS_INSTAGRAM],
  INSTAGRAM_BENCHMARKS: [BRAND.DASHBOARD.CAN_ACCESS_INDUSTRY_BENCHMARKS],
  INSTAGRAM_COMPETITIVE: [BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS],
  INSTAGRAM_STORIES: [BRAND.INSTAGRAM.CAN_ACCESS_INSTAGRAM],
  INSTAGRAM_UGC: [BRAND.INSTAGRAM.CAN_ACCESS_COMMUNITY_UGC],
  LINKEDIN: [BRAND.LINKEDIN.CAN_ACCESS_LINKEDIN_INSIGHTS],
  META_ADS: [BRAND.ADS.CAN_ACCESS_ADS],
  PINTEREST: [BRAND.PINTEREST.CAN_ACCESS_PINT],
  TIKTOK: [BRAND.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS],
  TIKTOK_ADS: [BRAND.ADS.CAN_ACCESS_TIKTOK_ADS],
  TWITTER: [BRAND.TWITTER.CAN_ACCESS_TWITTER_INSIGHTS],
  TWITTER_COMPETITIVE: [BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS],
  YOUTUBE: [BRAND.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS],
  YOUTUBE_COMPETITIVE: [BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS],
});

export const USER_PLATFORM_PERMISSIONS = Object.freeze({
  FACEBOOK: [USER.FACEBOOK.CAN_ACCESS_FB_INSIGHTS],
  FACEBOOK_COMPETITIVE: [USER.COMPETITIVE.CAN_ACCESS_COMPETITORS],
  INSTAGRAM: [USER.INSTAGRAM.CAN_ACCESS_INSTAGRAM],
  INSTAGRAM_COMPETITIVE: [USER.COMPETITIVE.CAN_ACCESS_COMPETITORS],
  INSTAGRAM_STORIES: [USER.INSTAGRAM.CAN_ACCESS_STORIES],
  INSTAGRAM_UGC: [USER.INSTAGRAM.CAN_ACCESS_COMMUNITY_UGC],
  LINKEDIN: [USER.LINKEDIN.CAN_ACCESS_LINKEDIN_INSIGHTS],
  META_ADS: [USER.ADS.CAN_ACCESS_ADS],
  PINTEREST: [USER.PINTEREST.CAN_ACCESS_PINT],
  TIKTOK: [USER.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS],
  TIKTOK_ADS: [USER.ADS.CAN_ACCESS_TIKTOK_ADS],
  TWITTER: [USER.TWITTER.CAN_ACCESS_TWITTER_INSIGHTS],
  TWITTER_COMPETITIVE: [USER.COMPETITIVE.CAN_ACCESS_COMPETITORS],
  YOUTUBE: [USER.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS],
  YOUTUBE_COMPETITIVE: [USER.COMPETITIVE.CAN_ACCESS_COMPETITORS],
});

export const PLATFORM_CONNECTION = {
  FACEBOOK: {
    value: 'facebook',
    sandbox: 'facebook_sandbox',
    name: 'Instagram',
    img: InstagramSvg,
    SCOPES: {
      INSTAGRAM_CONTENT_PUBLISH: {
        value: 'instagram_content_publish',
      },
      INSTAGRAM_MANAGE_COMMENTS: {
        value: 'instagram_manage_comments',
      },
      INSTAGRAM_MANAGE_MESSAGES: {
        value: 'instagram_manage_messages',
      },
    },
  },
  FACEBOOK_PAGE: {
    value: 'facebook_page',
    sandbox: 'facebook_page_sandbox',
    name: 'Facebook',
    img: FacebookSvg,
  },
  FACEBOOK_ANALYTICS: {
    value: 'facebook_analytics',
    sandbox: 'facebook_analytics_sandbox',
    name: 'Facebook',
    img: FacebookSvg,
  },
  // TODO sc-106472: Remove this legacy copy
  FACEBOOK_ADS: {
    value: 'facebook_ads',
    name: 'Facebook Ads',
    img: FacebookSvg,
  },
  // TODO sc-106472: Remove _new and make this the default
  FACEBOOK_ADS_NEW: {
    value: 'facebook_ads',
    name: 'Meta Ads',
    img: MetaSvg,
    iconName: 'meta',
    connectDisabledTooltip:
      'You need the “Manage Meta Ads” permission in order to connect this account.',
    disconnectDisabledTooltip:
      'You need the “Manage Meta Ads” permission in order to disconnect this account.',
  },
  INSTAGRAM_PAGE: {
    value: 'instagram_page',
    sandbox: 'instagram_page_sandbox',
    name: 'Instagram',
    img: InstagramSvg,
  },
  INSTAGRAM_INFLUENCER: {
    value: 'instagram_influencer',
  },
  PINTEREST: {
    value: 'pinterest',
    name: 'Pinterest',
    img: PinterestSvg,
  },
  PINTEREST_V5: {
    value: 'pinterest_v5',
    name: 'Pinterest',
    img: PinterestSvg,
  },
  TIKTOK: {
    value: 'tiktok',
    name: 'TikTok',
    img: TikTokSvg,
    SCOPES: {
      VIDEO_PUBLISH: {
        value: 'video.publish',
      },
    },
  },
  TIKTOK_CREATOR: {
    value: 'tiktok_creator',
    name: 'TikTok Creator',
    img: TikTokSvg,
  },
  TIKTOK_ADS: {
    value: 'tiktok_ads',
    name: 'TikTok Ads',
    img: TikTokSvg,
    connectDisabledTooltip:
      'You need the “Can Manage TikTok Ads” permission in order to connect this account.',
    disconnectDisabledTooltip:
      'You need the “Can Manage TikTok Ads” permission in order to disconnect this account.',
  },
  TIKTOK_CREATOR_MARKETPLACE: {
    value: 'tiktok_creator_marketplace',
    name: 'TikTok Creator Marketplace',
    img: TikTokSvg,
    connectDisabledTooltip:
      'You need the “Can Manage TikTok Creator Marketplace” permission in order to connect this account.',
    disconnectDisabledTooltip:
      'You need the “Can Manage TikTok Creator Marketplace” permission in order to disconnect this account.',
  },
  TWITTER: {
    value: 'twitter',
    name: 'X',
    img: TwitterSvg,
    SCOPES: {
      ADS_API_ACCESS: {
        value: 'ads_api_access',
      },
    },
  },
  YOUTUBE: {
    value: 'youtube',
    name: 'YouTube',
    img: YoutubeSvg,
  },
  GOOGLE_ANALYTICS: {
    value: 'ga',
    name: 'Google Analytics',
    img: GaSvg,
  },
  LINKEDIN: {
    value: 'linkedin',
    name: 'LinkedIn',
    img: LinkedInSvg,
  },
};

export const PLATFORM_CONNECTION_NAMES = {
  FACEBOOK: 'facebook_analytics',
  FACEBOOK_COMPETITIVE: 'facebook_analytics',
  INSTAGRAM: 'facebook',
  INSTAGRAM_STORY: 'facebook',
  INSTAGRAM_STORIES: 'facebook',
  INSTAGRAM_UGC: 'facebook',
  INSTAGRAM_COMPETITIVE: 'facebook',
  INSTAGRAM_BENCHMARKS: 'facebook',
  META_ADS: 'facebook_ads',
  YOUTUBE_COMPETITIVE: 'youtube',
};

export function extractPlatformConnectionType(type, useSandbox) {
  let platformType = type?.value ?? type;

  if (platformType === 'google_analytics') {
    platformType = 'ga';
  }

  if (useSandbox) {
    const sandboxPlatformType = Object.values(PLATFORM_CONNECTION).find(
      (connection) => connection.value === platformType,
    )?.sandbox;
    return sandboxPlatformType ?? platformType;
  }

  return platformType;
}

export function isSamePlatformConnection(left, right, useSandbox) {
  const leftType = extractPlatformConnectionType(left, useSandbox);
  const rightType = extractPlatformConnectionType(right, useSandbox);
  return leftType === rightType;
}

export function determinePlatformConnectionType(type) {
  if (isObject(type)) {
    return type.value;
  }

  let result;
  if (type.includes('sandbox')) {
    result =
      Object.values(PLATFORM_CONNECTION).find((enumValue) => enumValue.sandbox === type)?.sandbox ??
      type;
  } else {
    result =
      Object.values(PLATFORM_CONNECTION).find((enumValue) => enumValue.value === type)?.value ??
      type;
  }
  return result;
}

export function extractPlatformConnectionScope(scope) {
  return scope?.value ?? scope;
}

export const PLATFORM_NO_CONNECTION_DATA = {
  INSTAGRAM: {
    name: 'Instagram',
    message: 'Essential Instagram metrics at your fingertips.',
    image: InstagramNoAccessImg,
  },
  FACEBOOK: {
    name: 'Facebook',
    message: 'Understand and connect with your fans with the right insights.',
    image: FacebookNoAccessImg,
  },
  LINKEDIN: {
    name: 'LinkedIn',
    message: 'Gain rich insight and analytics on metrics and trends.',
    image: LinkedinNoAccessImg,
  },
  TIKTOK: {
    name: 'TikTok',
    message: 'Understand how your entertaining content is performing.',
    image: TikTokNoAccessImg,
  },
  PINTEREST: {
    name: 'Pinterest',
    message: 'Make decisions based on insights that will inspire your audience.',
    image: PinterestNoAccessImg,
  },
  X: {
    name: 'X',
    message: 'Stay up to date with real-time data.',
    image: TwitterNoAccessImg,
  },
  YOUTUBE: {
    name: 'YouTube',
    message: 'Essential video-first metrics at your fingertips.',
    image: YoutubeNoAccessImg,
  },
};
