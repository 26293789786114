import { defineStore } from 'pinia';
import { computed } from 'vue';
import { BRAND } from '@/models/auth/permissions.enum';
import { METRICS } from '@/models/dashboards/metrics';
import { useIdentityStore } from '@/stores/identity';
import { useFlagStore } from '@/stores/flag';
import { COMPETITIVE_AVERAGE_KEY } from '@/app/dashboards/constants';
import { getAdsPlatformController } from '@/app/dashboards/utils/getAdsPlatformController';
import { getMetricDetails } from '@/utils/metrics';

export const useDashboardPermissionsStore = defineStore('dashboardPermissions', () => {
  const identityStore = useIdentityStore();
  const flagStore = useFlagStore();

  const hasHideCompetitiveReportsFlag = computed(
    () => flagStore.ready && flagStore.flags.hideCompetitiveReports,
  );

  function canUserAccessDashboards(brand) {
    const brandLabel = brand.label;
    return identityStore.identity?.permissions?.dashboard?.[brandLabel]?.can_access_dashboards;
  }

  function canUserAccessCompetitors(brand) {
    const brandLabel = brand?.label;
    return identityStore.identity?.permissions?.competitive?.[brandLabel]?.can_access_competitors;
  }

  const canAccessDashboards = computed(() => {
    return identityStore.allUserBrands.some(
      (brand) =>
        identityStore.guard(BRAND.DASHBOARD.CAN_ACCESS_DASHBOARDS, brand) ||
        canUserAccessDashboards(brand) ||
        false,
    );
  });

  const canAccessIndustryBenchmarks = computed(() => {
    return identityStore.allUserBrands.some(
      (brand) =>
        identityStore.guard(BRAND.DASHBOARD.CAN_ACCESS_INDUSTRY_BENCHMARKS, brand) || false,
    );
  });

  function canAccessMetrics(channel, metric, metricTypeReportKey, brands = null) {
    let metricPermissions;
    const { displayName, permissions } = getMetricDetails(metric, [channel], metricTypeReportKey);
    if (displayName) {
      metricPermissions = permissions;
    } else {
      metricPermissions = METRICS?.[channel]?.[metric]?.permissions;
    }
    return identityStore.checkIfBrandPermissionsExist(metricPermissions, brands);
  }

  function canAccessCompetitor(competitor) {
    if (
      hasHideCompetitiveReportsFlag.value &&
      competitor?.isCompetitor &&
      competitor?.id !== COMPETITIVE_AVERAGE_KEY
    ) {
      const brandDetails = competitor.brandDetails ?? [];
      return !!brandDetails.some(
        (brand) =>
          canUserAccessCompetitors(identityStore.identityBrandsById[brand.brandId]) ||
          identityStore.guard(
            BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS,
            identityStore.identityBrandsById[brand.brandId],
          ),
      );
    }
    return true;
  }

  function userOrBrandCanAccessCompetitor(brand) {
    return (
      !hasHideCompetitiveReportsFlag.value ||
      identityStore.guard(BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS, brand) ||
      canUserAccessCompetitors(brand)
    );
  }

  function userOrBrandCanAccessDashboards(brand) {
    return (
      !hasHideCompetitiveReportsFlag.value ||
      identityStore.guard(BRAND.DASHBOARD.CAN_ACCESS_DASHBOARDS, brand) ||
      canUserAccessDashboards(brand)
    );
  }

  function brandCanAccessBenchmark(brand) {
    return (
      !hasHideCompetitiveReportsFlag.value ||
      identityStore.guard(BRAND.DASHBOARD.CAN_ACCESS_INDUSTRY_BENCHMARKS, brand)
    );
  }

  function canAccessContentTags(selectedBrandsOrCompetitors) {
    return identityStore.checkIfBrandPermissionsExist(
      [BRAND.TAGS.CAN_ACCESS_CONTENT_TAGS],
      selectedBrandsOrCompetitors,
    );
  }

  function brandCanAccessAds(brand, channel) {
    const adsPlatformController = getAdsPlatformController(channel);
    return adsPlatformController?.brandHasPermission(brand) ?? false;
  }

  function userCanAccessAds(brand, channel) {
    const adsPlatformController = getAdsPlatformController(channel);
    return adsPlatformController?.userHasPermission(brand) ?? false;
  }

  function canAccessAdsForBrand(brand, channel) {
    return brandCanAccessAds(brand, channel) && userCanAccessAds(brand, channel);
  }

  return {
    canUserAccessDashboards,
    canUserAccessCompetitors,
    canAccessMetrics,
    canAccessDashboards,
    canAccessIndustryBenchmarks,
    canAccessCompetitor,
    userOrBrandCanAccessCompetitor,
    brandCanAccessBenchmark,
    userOrBrandCanAccessDashboards,
    canAccessContentTags,
    canAccessAdsForBrand,
    brandCanAccessAds,
    userCanAccessAds,
  };
});
