<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { Avatar, Button } from '@dashhudson/dashing-ui';
import PrimaryNavigationMenu from '@/components/layout/navigation/PrimaryNavigationMenu.vue';
import { getImageSource } from '@/assets';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';
import SecondaryNavigationPanel from '@/components/layout/navigation/SecondaryNavigationPanel.vue';
import NotificationsPanel from '@/components/layout/navigation/NotificationsPanel.vue';
import UtilityPanel from '@/components/layout/navigation/UtilityPanel.vue';
import { trackNavigation, trackSecondaryNavigationInteractions } from '@/utils/analytics/tracking';

const props = defineProps({
  hideToggleButton: {
    type: Boolean,
    default: false,
  },
});

const { toggleNavigationPanel, expanded, hidden } = useSecondaryNavigationPanel();

const route = useRoute();
const secondaryNavHidden = computed(() => {
  return hidden.value;
});

const hideSecondaryNavigation = computed(() => {
  return route?.meta?.hideSecondaryNav || secondaryNavHidden.value;
});

const isExpanded = computed(() => {
  return expanded.value;
});

const buttonPosition = computed(() => {
  return {
    right: !isExpanded.value ? '0' : '-15.5rem',
  };
});

function handleSecondaryNavigationToggleClick() {
  toggleNavigationPanel();
  trackSecondaryNavigationInteractions(!isExpanded.value ? 'closed' : 'opened');
}
</script>

<template>
  <header
    class="fixed bottom-0 left-0 top-0 z-[var(--z-index-nav)] grid h-full w-[var(--primary-navigation-width)] items-start bg-white"
  >
    <nav class="grid h-full w-full bg-white py-6">
      <div class="z-10 flex h-full flex-col items-center justify-between">
        <div>
          <router-link
            to="/"
            class="dash-rounded-sm mx-1 mb-9 block h-8 w-8 flex-col items-center focus:outline-[color:--action-500]"
            @click="trackNavigation('Landing Page')"
          >
            <span class="visually-hidden"> Go to the Dash Hudson home page </span>
            <Avatar :image="getImageSource('logos/dh-logo.svg')" size="md" class="bg-white" />
          </router-link>
          <PrimaryNavigationMenu />
        </div>
        <div class="grid place-items-center gap-3">
          <NotificationsPanel />
          <UtilityPanel />
        </div>
      </div>
      <Button
        v-show="!hideSecondaryNavigation && !props.hideToggleButton"
        icon="layout-left"
        size="xs"
        aria-label="toggle"
        class="dash-transition-md absolute z-20 translate-y-1"
        :class="{ 'translate-x-2/4': !isExpanded }"
        severity="tertiary"
        :style="buttonPosition"
        @click="handleSecondaryNavigationToggleClick"
      />
      <SecondaryNavigationPanel
        v-show="!hideSecondaryNavigation"
        class="-z-10"
        :width="isExpanded ? 276 : 0"
      />
    </nav>
  </header>
</template>
