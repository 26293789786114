<template>
  <div
    ref="media"
    class="media-item"
    @mouseover="mediaHovered = true"
    @mouseleave="mediaHovered = false"
    @click="handleMediaItemClick"
  >
    <div
      class="media-mask"
      :class="{
        'media-mask--top': mediaMaskTop,
        'media-mask--bottom': mediaMaskBottom,
        'media-mask--none': mediaMaskNone,
      }"
    />

    <div :class="['media-container', aspectRatio]">
      <div class="aspect-ratio-container">
        <!-- Image or video player -->
        <template v-if="isImage">
          <div
            :style="{ 'background-image': `url(${mediaUrl})` }"
            :class="{ cover }"
            class="media"
          ></div>
        </template>
        <template v-else>
          <video
            id="video"
            ref="video"
            :src="mediaUrl"
            :class="{ cover }"
            :poster="thumbnailUrl"
            preload="Metadata"
            class="media"
            loop
            @canplay="updateDuration"
          />
        </template>

        <div class="media-badges">
          <Icon class="media-source-badge" :name="iconName" xlarge />
        </div>

        <!-- Video icon -->
        <div class="media">
          <div v-if="isVideo" class="video-duration">
            <Icon
              v-if="duration"
              :color="iconColor"
              :name="videoPlaying ? 'pause' : 'play'"
              height="15"
              width="15"
              class="video-button"
            />
            <span class="video-duration-text">{{ videoDuration }}</span>
          </div>
        </div>

        <!-- Play/pause controls -->
        <div
          :class="['image-hover-view', { visible: mediaHovered }]"
          :style="{ cursor: isVideo ? 'pointer' : 'default' }"
        >
          <Icon
            v-if="isVideo"
            :color="iconColor"
            class="video-button"
            :name="videoPlaying ? 'pause' : 'play'"
          />
          <Button
            v-if="showDownloadButton"
            light
            class="download-button"
            :href="mediaUrl"
            @click.prevent.stop="downloadMedia(mediaUrl)"
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import isEqual from 'lodash/isEqual';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import downloadMediaMixin from '@/mixins/downloadMediaMixin';
import enumTypes from '@/app/library/constants';
import { colours } from '@/ux/colours';
import { formatVideoDuration, formatVideoDurationUnit } from '@/utils/formatters';
import { CHANNELS } from '@/models/dashboards/channels.enum';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaItem',
  components: { Icon, Button },
  mixins: [downloadMediaMixin],
  props: {
    carousel: { type: Boolean, default: false },
    mediaType: { type: String, default: null },
    mediaUrl: { type: String, default: null },
    cover: { type: Boolean, default: false },
    thumbnailUrl: { type: String, default: null },
    sourceType: { type: String, default: null },
    duration: { type: Number, default: null },
    aspectRatio: { type: String, default: 'square' },
  },
  data() {
    return {
      iconColor: colours.BASIC.WHITE,
      videoPlaying: false,
      mediaHovered: false,
      loadedVideoDuration: null,
    };
  },
  computed: {
    videoDuration() {
      return this.loadedVideoDuration
        ? formatVideoDurationUnit(this.loadedVideoDuration, enumTypes.SECONDS)
        : formatVideoDuration(this.duration, this.sourceType);
    },
    isUploadedMedia() {
      return this.sourceType === enumTypes.UPLOADED;
    },
    isInstagramUGC() {
      return (
        this.sourceType === enumTypes.INSTAGRAM_UGC ||
        this.sourceType === enumTypes.INSTAGRAM_STORY_UGC ||
        this.sourceType === enumTypes.INSTAGRAM_UGC_IGTV
      );
    },
    iconName() {
      return CHANNELS[this.sourceType]?.channelIcon || '';
    },
    mediaMaskTop() {
      return !this.videoDuration && !this.carousel && !this.isUploadedMedia && !this.isInstagramUGC;
    },
    mediaMaskBottom() {
      return (
        (this.videoDuration && !this.carousel && this.isUploadedMedia) ||
        (this.isInstagramUGC && !this.carousel && this.videoDuration)
      );
    },
    mediaMaskNone() {
      return (
        (!this.videoDuration && !this.carousel && this.isUploadedMedia) ||
        (this.isInstagramUGC && !this.videoDuration)
      );
    },
    isImage() {
      return this.mediaType === 'IMAGE';
    },
    isVideo() {
      return this.mediaType === 'VIDEO';
    },
    showDownloadButton() {
      return this.isImage || this.isVideo;
    },
  },
  watch: {
    mediaUrl(to, from) {
      if (!isEqual(to, from)) {
        this.videoPlaying = false;
      }
    },
  },
  beforeUnmount() {
    if (this.isVideo && this.$refs?.video) {
      const { video } = this.$refs;
      video.pause();
      video.removeAttribute('src');
      video.load();
    }
  },
  methods: {
    handleMediaItemClick() {
      if (this.isVideo) {
        if (this.videoPlaying) {
          this.videoPlaying = false;
          this.$refs.video.pause();
        } else {
          this.videoPlaying = true;
          this.$refs.video.play();
        }
      }
    },
    updateDuration() {
      this.loadedVideoDuration = this.$refs.video.duration;
    },
  },
});
export default comp;
</script>
<style lang="postcss" scoped>
.media-item {
  position: relative;
  height: fit-content;
  min-height: 18rem;

  img,
  video {
    height: auto;
    width: 100%;
    max-width: 100%;
    border-radius: var(--round-corner-small);
    position: relative;
    cursor: pointer;
  }

  .image-hover-view,
  .media-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: var(--round-corner-small);
  }

  .image-hover-view {
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: var(--transition-all);
    z-index: var(--z-index-raised);
    background: rgb(0 0 0 / 30%);

    .video-button {
      margin-bottom: var(--space-32);
      transition: var(--transition-all);

      &:hover {
        transform: var(--hover-move);
      }
    }

    button,
    a {
      width: 15.4rem;
      margin: var(--space-8) 0;
    }

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  .media-mask {
    background: var(--tile-bg-gradient);
    height: 100%;
    z-index: 2;

    &.media-mask--bottom {
      background: var(--tile-bg-gradient-bottom);
      bottom: 0;
      height: 50%;
      top: inherit;
    }

    &.media-mask--top {
      background: var(--tile-bg-gradient-top);
      height: 50%;
    }

    &.media-mask--none {
      background: transparent;
    }
  }

  .video-duration {
    position: absolute;
    right: var(--space-20);
    bottom: var(--space-28);
    z-index: 3;

    .icon {
      position: relative;
      top: 3px;
    }

    .video-duration-text {
      padding-left: var(--space-4);
      color: var(--white);
      font-size: var(--x14);
      font-weight: var(--font-medium);
      line-height: var(--space-18);
    }
  }

  .media-badges {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    padding: 0.5rem 0.5rem 0.5rem 0;
    margin-left: 2rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    color: white;

    & > * {
      margin: 0 0 var(--space-8) var(--space-6);
    }

    .media-source-badge {
      fill: white;
    }
  }

  .aspect-ratio-container {
    width: 100%;
    height: 100%;
  }

  .square {
    aspect-ratio: 1 / 1;
  }

  .portrait {
    aspect-ratio: 9 / 16;
  }

  .media-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--background-500);
    border-radius: var(--round-corner-small);
    overflow: hidden;
    height: 100%;

    .media {
      height: 100%;
      width: 100%;
      object-fit: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-top: 0;

      &.cover {
        object-fit: cover;
        background-size: cover;
      }
    }
  }
}
</style>
