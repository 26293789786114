import { computed, unref } from 'vue';
import get from 'lodash/get';
import { camelCase } from 'lodash/string';
import isNil from 'lodash/isNil';
import { logger } from '@/utils/logger';
import { useMetricsStore } from '@/stores/metrics';
import {
  AGGREGATION_CURRENT_GRAPH_STATS,
  AGGREGATION_NO_GRAPH_STATS,
  AGGREGATION_ONLY_AVG_GRAPH_STATS,
  AGGREGATION_TYPES,
  DATA_TYPE_FORMATS,
  DATA_TYPES,
} from '@/models/dashboards/metrics.constants';
import { METRIC_TYPE_REPORT_KEYS } from '@/app/dashboards/utils/reports.enum';
import { toolTips } from '@/config';
import { GRAPH_STATS } from '@/models/dashboards/graph-stats.enum';
import { MULTI_CHANNEL_METRICS } from '@/models/dashboards/multi-channel-metrics.enum';
import { formatValueByFormatType } from '@/utils/formatters';
import { MEDIA_TYPES } from '@/models/dashboards/media-types.enum';
import { DATA_TYPE_TIME_UNITS } from '@/app/dashboards/constants';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { useDashboardReportsStore } from '@/stores/dashboards-reports';

export function useMetricDetails({ metric, channels, metricReportTypeKey } = {}) {
  const metricsStore = useMetricsStore();
  const dashboardReportsStore = useDashboardReportsStore();
  const channel = unref(channels)?.[0];

  // Internal set up. Do not return these properties and functions
  const isContentReportMetric = computed(() => {
    return unref(metricReportTypeKey) === METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT;
  });

  const isGraphReportMetric = computed(() => {
    return unref(metricReportTypeKey) === METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT;
  });

  const isMultiChannelMetric = computed(() => {
    return unref(channels)?.length > 1;
  });

  const targetMetricDetails = computed(() => {
    if (isMultiChannelMetric.value) return MULTI_CHANNEL_METRICS[unref(metric)]; // TODO: Update
    return metricsStore.getTargetMetricDetails(channel, unref(metricReportTypeKey), unref(metric));
  });

  // Properties and functions for ALL report types
  const allowContentTags = computed(() => {
    return targetMetricDetails.value?.allow_content_tags;
  });

  const displayName = computed(() => {
    if (isMultiChannelMetric.value) return targetMetricDetails.value.text;
    return targetMetricDetails.value?.display_name;
  });

  const formats = computed(() => {
    const dataType = targetMetricDetails.value?.data_type;
    return DATA_TYPE_FORMATS?.[dataType];
  });

  function convertMetricToRequiredUnit(dataType, value) {
    // seconds is the preferred unit to be displayed
    if (dataType === DATA_TYPE_TIME_UNITS.MILLISECONDS) {
      return Math.floor(value / 1000);
    }
    return value;
  }

  function formatMetricValue(value, formatType, unit = null) {
    const emptyValue = '-';
    if (isNil(value)) return emptyValue;
    const format = formats.value?.[formatType];
    const convertedValue = convertMetricToRequiredUnit(targetMetricDetails.value?.data_type, value);

    let formattedValue = value;
    if (format) {
      formattedValue = formatValueByFormatType(convertedValue, format, emptyValue);
    } else {
      logger.error(`formatMetricValue: missing format for '${formatType}'`);
    }
    if (unit) formattedValue = `${formattedValue} ${unit}`;
    return formattedValue;
  }

  const isNegativeMetric = computed(() => {
    return targetMetricDetails.value?.is_negative_metric;
  });

  const isMonetaryMetric = computed(() => {
    return targetMetricDetails.value?.data_type === DATA_TYPES.MONETARY;
  });

  const lockedMediaBreakdown = computed(() => {
    return targetMetricDetails.value?.locked_media_breakdown;
  });

  const supportsMediaBreakdown = computed(() => {
    return targetMetricDetails.value?.supports_media_breakdown;
  });

  const mediaTypeLabel = computed(() => {
    if (!supportsMediaBreakdown.value) return null;
    if (!targetMetricDetails.value?.media_type_label) return MEDIA_TYPES.ALL_MEDIA.text;
    return targetMetricDetails.value?.media_type_label;
  });

  const parentMetric = computed(() => {
    return targetMetricDetails.value?.parent_metric ?? unref(metric);
  });

  const permissions = computed(() => {
    return targetMetricDetails.value?.permissions;
  });

  const requiredFeatureFlag = computed(() => {
    if (isMultiChannelMetric.value && unref(channels).includes(CHANNELS.YOUTUBE.value)) {
      const ytKey =
        dashboardReportsStore.comparableMetrics?.[unref(metric)]?.[CHANNELS.YOUTUBE.value];
      const ytDetails = metricsStore.getTargetMetricDetails(
        CHANNELS.YOUTUBE.value,
        unref(metricReportTypeKey),
        ytKey,
      );
      return ytDetails?.required_feature_flag;
    }
    return targetMetricDetails.value?.required_feature_flag;
  });

  const tooltip = computed(() => {
    if (isMultiChannelMetric.value) {
      const multiChannelTooltips = {
        ...toolTips.multiChannelDashboard,
        ...toolTips.sentimentDashboard,
      };
      return get(multiChannelTooltips, camelCase(unref(metric)), '');
    }
    return targetMetricDetails.value?.description;
  });

  // Properties and functions for CONTENT report types
  const sourceDataName = computed(() => {
    if (!isContentReportMetric.value) return null;
    return targetMetricDetails.value?.source_data_name;
  });

  // Properties and functions for GRAPH report types
  const aggregationType = computed(() => {
    let aggregation = targetMetricDetails.value?.aggregation;
    if (aggregation === AGGREGATION_TYPES.CUSTOM)
      aggregation = targetMetricDetails.value?.aggregation_type;
    return aggregation;
  });

  const graphBeginAtZero = computed(() => {
    if (!isGraphReportMetric.value) return null;
    return (
      aggregationType.value !== AGGREGATION_TYPES.LATEST &&
      aggregationType.value !== AGGREGATION_TYPES.LATEST_VALID
    );
  });

  const graphPrecision = computed(() => {
    if (!isGraphReportMetric.value) return null;
    const dataType = targetMetricDetails.value?.data_type;
    if (dataType === DATA_TYPES.PERCENTAGE) return 4;
    return 0;
  });

  const graphStats = computed(() => {
    if (!isGraphReportMetric.value) return null;
    if (AGGREGATION_NO_GRAPH_STATS.includes(aggregationType.value)) return [];
    if (AGGREGATION_ONLY_AVG_GRAPH_STATS.includes(aggregationType.value))
      return [GRAPH_STATS.MEAN_BY_DAY.value];
    if (AGGREGATION_CURRENT_GRAPH_STATS.includes(aggregationType.value))
      return [GRAPH_STATS.CURRENT.value, GRAPH_STATS.MEAN_BY_DAY.value];
    return [GRAPH_STATS.SUM.value, GRAPH_STATS.MEAN_BY_DAY.value];
  });

  return {
    allowContentTags,
    displayName,
    formatMetricValue,
    graphBeginAtZero,
    graphPrecision,
    graphStats,
    isNegativeMetric,
    isMonetaryMetric,
    lockedMediaBreakdown,
    mediaTypeLabel,
    parentMetric,
    permissions,
    requiredFeatureFlag,
    sourceDataName,
    supportsMediaBreakdown,
    tooltip,
  };
}
