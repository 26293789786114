<script setup>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { FilterSidePanel, useFilterSidePanel } from '@dashhudson/dashing-ui';
import { useFlagStore } from '@/stores/flag';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';
import PrimaryNavigation from '@/components/layout/navigation/PrimaryNavigation.vue';
import TrialBanner from '@/components/InAppTrials/TrialBanner.vue';

const route = useRoute();

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  showNav: {
    type: Boolean,
    default: true,
  },
});

const flagStore = useFlagStore();

const { visible: filterVisible } = useFilterSidePanel();
const { hidden, expanded } = useSecondaryNavigationPanel();

const filterSidePanelTransitionClasses = ref('-z-10 hidden');
const contentAreaTransitionClasses = ref('');

const secondaryNavHidden = computed(() => {
  return hidden.value;
});
const secondaryNavExpanded = computed(() => {
  return expanded.value && !route?.meta?.hideSecondaryNav;
});

const useReportLayout = computed(() => {
  return route?.meta?.report;
});

const hidePrimaryNav = computed(() => {
  return route?.meta?.hidePrimaryNav;
});

const backgroundClass = computed(
  () =>
    `before:fixed before:bottom-0 before:left-[var(--primary-navigation-width)] before:right-0 before:top-0 before:-z-10 before:h-screen before:w-[calc(100%-var(--primary-navigation-width))] before:rounded-tl-[2.5rem] ${!useReportLayout.value ? 'before:bg-[#f9fbff] ' : ''}before:transition-[all_0.3s_cubic-bezier(0.35,0,0.3,1.1)] before:content-['']`,
);

const secondaryNavigationVisible = computed(() => {
  return !secondaryNavHidden.value && !useReportLayout.value && secondaryNavExpanded.value;
});

function handleContentTransitionStart($event) {
  const { propertyName, target } = $event;
  if (propertyName === 'transform' && target.id === 'mainContentArea') {
    filterSidePanelTransitionClasses.value = '-z-10 block';
    contentAreaTransitionClasses.value = 'z-10 dash-bg-background-page';
  }
}

function handleContentTransitionEnd($event) {
  const { propertyName, target } = $event;
  if (propertyName === 'transform' && target.id === 'mainContentArea') {
    if (filterVisible.value) {
      filterSidePanelTransitionClasses.value = 'block';
      contentAreaTransitionClasses.value = 'z-10 dash-bg-background-page';
    } else {
      filterSidePanelTransitionClasses.value = '-z-10 hidden';
      contentAreaTransitionClasses.value = '';
    }
  }
}

const primaryNavigationTranslateX = computed(() => {
  if (filterVisible.value) {
    if (secondaryNavigationVisible.value) {
      return '-translate-x-[var(--combined-navigation-width)]';
    }
    return '-translate-x-[var(--primary-navigation-width)]';
  }
  return '';
});

const mainContentAreaTranslateX = computed(() => {
  if (filterVisible.value) {
    return '-translate-x-[var(--combined-navigation-width)]';
  }
  return '';
});

const mainContentPadding = computed(() => {
  if (useReportLayout.value || hidePrimaryNav.value) {
    return 'pl-0';
  }
  if (filterVisible.value) {
    return 'pl-[var(--combined-navigation-width)]';
  }
  if (secondaryNavigationVisible.value && !filterVisible.value) {
    return 'pl-[var(--primary-navigation-width)] xl:pl-[var(--combined-navigation-width)]';
  }
  return 'pl-[var(--primary-navigation-width)]';
});

const innerPadding = computed(() => {
  if (!useReportLayout.value && !secondaryNavHidden.value && !hidePrimaryNav.value) {
    return 'px-10';
  }
  return null;
});

watch(
  () => filterVisible.value,
  (visible) => {
    if (visible) {
      document.body.dataset.filterSidePanelOpen = '';
    } else {
      delete document.body.dataset.filterSidePanelOpen;
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="relative" :class="backgroundClass">
    <PrimaryNavigation
      v-if="props.showNav"
      :hide-toggle-button="filterVisible"
      class="dash-transition-md"
      :class="primaryNavigationTranslateX"
    />
    <section
      id="mainContentArea"
      class="main-content-transition relative min-h-screen w-full rounded-tl-[2.5rem]"
      :class="[
        filterVisible
          ? 'main-content-transition-open dash-elevation-2xl'
          : 'main-content-transition-close',
        contentAreaTransitionClasses,
        mainContentAreaTranslateX,
        mainContentPadding,
      ]"
      @transitionstart="handleContentTransitionStart"
      @transitionend="handleContentTransitionEnd"
    >
      <div :class="innerPadding">
        <slot />
      </div>
    </section>
    <template v-if="flagStore.flags.saveJackie">
      <FilterSidePanel ref="filterSidePanel" :class="filterSidePanelTransitionClasses" />
    </template>
    <TrialBanner />
  </div>
</template>

<style lang="postcss" scoped>
.main-content-transition-open {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.35, 0, 0.3, 1.1);
  transition-property: transform, padding-left;
}

.main-content-transition-close {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 0.35, 1.1, 0.3);
  transition-property: transform, padding-left;
}
</style>
