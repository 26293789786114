export const CHANNELS = Object.freeze({
  INSTAGRAM: 'INSTAGRAM',
  INSTAGRAM_UGC: 'INSTAGRAM_UGC',
  FACEBOOK: 'FACEBOOK',
  X: 'X',
  PINTEREST: 'PINTEREST',
  YOUTUBE: 'YOUTUBE',
  LINKEDIN: 'LINKEDIN',
  TIKTOK: 'TIKTOK',
});

export const OWNED_CHANNELS = [
  CHANNELS.INSTAGRAM,
  CHANNELS.FACEBOOK,
  CHANNELS.X,
  CHANNELS.PINTEREST,
  CHANNELS.YOUTUBE,
  CHANNELS.LINKEDIN,
  CHANNELS.TIKTOK,
];

export const UGC_CHANNELS = [CHANNELS.INSTAGRAM_UGC];

export const CHANNEL_ICON_NAME = Object.freeze({
  [CHANNELS.INSTAGRAM]: 'instagram',
  [CHANNELS.INSTAGRAM_UGC]: 'instagram',
  [CHANNELS.FACEBOOK]: 'facebook',
  [CHANNELS.X]: 'twitter',
  [CHANNELS.PINTEREST]: 'pinterest',
  [CHANNELS.YOUTUBE]: 'youtube',
  [CHANNELS.LINKEDIN]: 'linkedin',
  [CHANNELS.TIKTOK]: 'tiktok',
});

export const ACTION_TYPE = Object.freeze({
  CONTENT_TAGS: 'contentTags',
  GALLERIES: 'galleries',
  CAMPAIGNS: 'campaigns',
  BOARDS: 'boards',
});

export const FILTER_TYPE = Object.freeze({
  KEYWORD: 'keyword',
  DATE_PUBLISHED: 'date_published',
});

export const FILTER_OPERATOR = Object.freeze({
  OR: 'OR',
  AND: 'AND',
});

export const RULES_BUILDER_CHIP_TYPE = Object.freeze({
  FILTER: 'FILTER',
  ACTION: 'ACTION',
});

export const noBrandAccessTooltip = 'You don’t have access to some brands in this automation.';
export const noEditContentAutomationPermissionsTooltip =
  'You don’t have access to edit content automations. Please contact your plan administrator to enable this permission.';

export const applyToExistingPostSuccessMessage =
  'Your automation has been saved. It may take a few moments to apply it to all posts.';
export const disableContentAutomationSuccessMessage = 'Your automation has been paused.';
export const disableContentAutomationFailedMessage =
  'Failed to pause the automation. Please try again.';
export const runContentAutomationFailedMessage = 'Failed to run the automation. Please try again.';

export const deleteAutomationConfirmationTitle = 'Delete Content Automation?';
export const deleteAutomationConfirmationMessage =
  'This will permanently remove this content automation. Any content previously impacted by this automation will remain unchanged.';
export const deleteConfirmationAlias = 'Delete';
export const deleteAutomationUnauthorizedError = (contentAutomationName) =>
  `You do not have permission to delete content automation ${contentAutomationName}.`;
export const deleteAutomationNotFoundError = (contentAutomationName) =>
  `Content automation ${contentAutomationName} was not found.`;
export const deleteAutomationUnknownError = (contentAutomationName) =>
  `Unable to delete content automation ${contentAutomationName} at this time, please try again later.`;
export const deleteAutomationSuccessMessage = (contentAutomationName) =>
  `Content automation ${contentAutomationName} successfully deleted.`;

export const contentAutomationListContainsErrorMessage =
  'One or more of your automations below has an error and is not running. Edit or delete this automation to resolve the issue.';

export const contentAutomationListItemContainsErrorMessage = 'This automation has errors';

export const contentAutomationActionErrorMessages = Object.freeze({
  [ACTION_TYPE.CONTENT_TAGS]:
    'This Content Tag was deleted or is not associated with any of the brands you have selected for this automation',
  [ACTION_TYPE.BOARDS]:
    'This Board was deleted or is not associated with any of the brands you have selected for this automation',
  [ACTION_TYPE.GALLERIES]:
    'This Gallery was deleted or is not associated with any of the brands you have selected for this automation',
  [ACTION_TYPE.CAMPAIGNS]:
    'This Campaign was deleted or is not associated with any of the brands you have selected for this automation',
});

export const deleteFilterGroupConfirmationTitle = 'Delete Container?';
export const deleteFilterGroupConfirmationMessage =
  'Are you sure you want to delete this container? You can’t undo this action.';
export const deleteFilterGroupConfirmationAlias = 'Delete Container';

export const CONTENT_AUTOMATION_WIZARD_NEXT_BUTTON_TOOLTIPS = [
  'You must specify which brands will have their content monitored',
  'You must specify which social channels will have their content monitored',
  'You must specify a condition and a valid action',
  'You must give a name to your automation',
];
