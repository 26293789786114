import { defineAsyncComponent } from 'vue';
import {
  SOCIAL_LISTENING_REPORT_SECTIONS,
  SOCIAL_LISTENING_ROUTE_NAMES,
} from '@/app/socialListening/constants';
import PublicMediaPopup from '@/components/common/MediaPopupV2/PublicMediaPopup.vue';
import LDRouteGuard from '@/components/core/LDRouteGuard';

const App = () => import('@/app/socialListening/App.vue');
const NoOrgAccess = () => import('@/app/socialListening/pages/NoOrgAccess.vue');
const NoUserAccess = () => import('@/app/socialListening/pages/NoUserAccess.vue');
const Trends = defineAsyncComponent(() => import('@/app/socialListening/pages/Trends.vue'));
const Overview = defineAsyncComponent(() => import('@/app/socialListening/pages/Overview.vue'));
const QuickSearch = defineAsyncComponent(
  () => import('@/app/socialListening/pages/QuickSearch.vue'),
);
const Topics = () => import('@/app/socialListening/pages/ListeningTopic.vue');
const TopPosts = () => import('@/app/socialListening/pages/TopPostsPage.vue');
const VisualTrends = defineAsyncComponent(
  () => import('@/app/socialListening/components/trends/VisualTrendPopup.vue'),
);
const WebResults = () => import('@/app/socialListening/pages/WebResults.vue');
const TopicAlertPopup = defineAsyncComponent(
  () => import('@/app/socialListening/components/topic/alerts/TopicAlertPopup.vue'),
);

const PAGE_LEVEL_TOP = 'Social Listening';

export default {
  name: 'socialListening',
  path: 'listening',
  component: App,
  meta: {
    requiresAuth: true,
    analytics: `${PAGE_LEVEL_TOP} - Root`,
  },
  children: [
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.OVERVIEW,
      path: 'overview',
      component: LDRouteGuard,
      props: {
        requiredFeatureFlag: 'socialListeningOverviewRevamp',
        to: { name: SOCIAL_LISTENING_ROUTE_NAMES.TRENDS },
        component: Overview,
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.QUICK_SEARCH,
      path: 'quick-search',
      component: LDRouteGuard,
      props: {
        requiredFeatureFlag: 'socialListeningOverviewRevamp',
        to: { name: SOCIAL_LISTENING_ROUTE_NAMES.TRENDS },
        component: QuickSearch,
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Quick Search`,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.QUICK_SEARCH_TOP_POSTS,
      path: 'quick-search/top-posts',
      component: TopPosts,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Quick Search Top Posts`,
        scrollToTop: true,
        section: SOCIAL_LISTENING_REPORT_SECTIONS.TOP_PERFORMING_POST,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.TRENDS,
      path: 'trends',
      component: Trends,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Trends`,
        section: SOCIAL_LISTENING_REPORT_SECTIONS.SOCIAL_TRENDS,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.NO_ORG_ACCESS,
      path: 'upgrade',
      component: NoOrgAccess,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Organization No Access`,
        noAccessRoute: true,
        hideSecondaryNav: true,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.NO_USER_ACCESS,
      path: 'no-access',
      component: NoUserAccess,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - User No Access`,
        noAccessRoute: true,
        hideSecondaryNav: true,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.NO_TOPIC,
      path: 'topics',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Topics Root`,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.TOPICS,
      path: 'topics/:id',
      component: Topics,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Topics`,
        isTopicRoute: true,
        section: SOCIAL_LISTENING_REPORT_SECTIONS.TOPIC,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.TOPICS_TOP_POSTS,
      path: 'topics/:id/top-posts',
      component: TopPosts,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Topic Top Posts`,
        isTopicRoute: true,
        scrollToTop: true,
        section: SOCIAL_LISTENING_REPORT_SECTIONS.TOP_PERFORMING_POST,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.WEB_RESULTS,
      path: 'topics/:id/web-results',
      component: WebResults,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Web Results`,
        scrollToTop: true,
        section: SOCIAL_LISTENING_REPORT_SECTIONS.WEB_RESULTS,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.TOP_POSTS,
      path: 'top-posts',
      component: TopPosts,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Top Posts`,
        scrollToTop: true,
        section: SOCIAL_LISTENING_REPORT_SECTIONS.TOP_PERFORMING_POST,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.VISUAL_TRENDS,
      path: 'visual-trends/:id',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Visual Trends`,
        modal: {
          component: VisualTrends,
        },
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.PUBLIC_MEDIA,
      path: 'public-media/:channel/:id',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - View Public Media`,
        modal: {
          component: PublicMediaPopup,
        },
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.MANAGE_USAGE,
      path: 'manage-usage',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Manage Usage`,
      },
    },
    {
      name: SOCIAL_LISTENING_ROUTE_NAMES.TOPIC_MONITOR,
      path: 'topics/:id/monitor',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Topic Alert`,
        modal: {
          component: TopicAlertPopup,
        },
      },
    },
  ],
};
