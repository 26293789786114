import isEqual from 'lodash/isEqual';
import { defineStore } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import {
  CONTEXT_PERIOD_MODES,
  isValidContextPeriodMode,
  isValidReportingPeriodMode,
  REPORTING_PERIOD_MODES,
  updateDateRange,
} from '@/components/core/comparison-date-picker/constants';
import { getCurrentDate } from '@/utils';
import { DashboardAPI } from '@/apis';
import * as TikTokAPI from '@/apis/tiktok';
import merge from 'lodash/merge';
import { refreshAbortController, handleCancelError } from '@/apis/axios.utils';

export function formatSummaryMetrics(response) {
  const result = {};
  result.total_followers = response?.metrics?.TOTAL_FOLLOWERS.TIKTOK;
  result.net_new_followers = response?.metrics?.NET_NEW_FOLLOWERS.TIKTOK;
  result.video_views = response?.metrics?.VIDEO_VIEWS.TIKTOK;
  result.engagements = response?.metrics?.ENGAGEMENTS.TIKTOK;
  result.likes = response?.metrics?.ORGANIC_LIKES.TIKTOK;
  result.comments = response?.metrics?.ORGANIC_COMMENTS.TIKTOK;
  result.shares = response?.metrics?.SHARES.TIKTOK;
  result.profile_views = response?.metrics?.PROFILE_VIEWS.TIKTOK;
  result.videos_published = response?.metrics?.NUMBER_OF_POSTS.TIKTOK;
  Object.keys(result).forEach((key) => {
    if (result[key] && result[key].value == null) {
      result[key].value = '-';
    }
  });
  return result;
}

const REPORT_RANGE_DEFAULT = [getCurrentDate(7), getCurrentDate(1)];
const CONTEXT_RANGE_DEFAULT = [getCurrentDate(14), getCurrentDate(8)];

export const useTiktokStore = defineStore('tiktok', {
  resetOnBrandChange: true,
  state: () => ({
    reportDateRange: cloneDeep(REPORT_RANGE_DEFAULT),
    contextDateRange: cloneDeep(CONTEXT_RANGE_DEFAULT),
    trendingVideoList: [],
    topVideoList: [],
    reportingPeriodMode: null,
    contextPeriodMode: null,
    tiktokGalleriesParams: null,
    followersDemographics: null,
    videoViews: null,
    adAccounts: {},
    adCampaigns: {},
    adAccountsFromApi: [],
    accountMetrics: {
      summary_metrics: {},
      timeseries_metrics: {},
      averageEntertainmentScore: null,
    },
    topKeywords: [],
    ttcmAccountsFromApi: [],
    creatorByHandle: null,
    trendingHashtags: [],
    trendingHashtagsActiveIndex: null,
    hashtagDetails: [],
    feedPosts: [],

    pending: {
      trendingVideoList: false,
      topVideoList: false,
      followersDemographics: false,
      accountMetrics: {
        summary_metrics: false,
        timeseries_metrics: false,
        averageEntertainmentScore: false,
      },
      videoViews: false,
      adAccounts: false,
      adCampaigns: false,
      adAccountsFromApi: false,
      topKeywords: false,
      ttcmAccountsFromApi: false,
      trendingHashtags: false,
      hashtagDetails: false,
      creatorByHandle: false,
      feedPosts: false,
    },

    error: {
      creatorByHandle: null,
    },

    paging: {
      feedPostsNextUrl: null,
    },

    abortControllers: {
      getFeedPosts: new AbortController(),
    },
  }),
  getters: {
    adAccountsList(state) {
      const clonedAdAccounts = cloneDeep(state.adAccounts);
      const uniqAdAccounts = Object.values(
        Object.entries(clonedAdAccounts).reduce((acc, [brandId, adAccounts]) => {
          const brandIdAsInt = parseInt(brandId, 10);
          adAccounts?.forEach((adAccount) => {
            if (!acc[adAccount.sourceAdvertiserId]) {
              acc[adAccount.sourceAdvertiserId] = adAccount;
              acc[adAccount.sourceAdvertiserId].brandIds = [brandIdAsInt];
            } else if (!acc[adAccount.sourceAdvertiserId].brandIds.includes(brandIdAsInt)) {
              acc[adAccount.sourceAdvertiserId].brandIds.push(brandIdAsInt);
            }
          });
          return acc;
        }, {}),
      );
      return uniqAdAccounts.sort((adAccountA, adAccountB) => {
        return adAccountA.name.localeCompare(adAccountB.name);
      });
    },
    tiktokAdsAccountToCurrency() {
      const mapping = {};
      this.adAccountsList.forEach((account) => {
        mapping[account.sourceAdvertiserId] = account.currency;
      });
      return mapping;
    },
  },
  actions: {
    updateReportDate({ reportDateRange }) {
      updateDateRange(this.$state, 'reportDateRange', reportDateRange, REPORT_RANGE_DEFAULT);
    },
    updateContextDate({ contextDateRange }) {
      updateDateRange(this.$state, 'contextDateRange', contextDateRange, CONTEXT_RANGE_DEFAULT);
    },
    updateReportingPeriodMode(mode) {
      let valueToBeUsed = null;
      if (isValidReportingPeriodMode(mode)) {
        valueToBeUsed = mode;
      } else {
        valueToBeUsed = REPORTING_PERIOD_MODES.LAST_7_DAYS.value;
      }

      this.reportingPeriodMode = valueToBeUsed;
    },
    updateContextPeriodMode(mode) {
      let valueToBeUsed = null;
      if (isValidContextPeriodMode(mode)) {
        valueToBeUsed = mode;
      } else {
        valueToBeUsed = CONTEXT_PERIOD_MODES.PREVIOUS_7_DAYS.value;
      }

      this.contextPeriodMode = valueToBeUsed;
    },
    initDateRanges({
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
      reportingPeriodMode,
      contextPeriodMode,
    }) {
      this.updateReportDate({ reportDateRange: [startDate, endDate] });
      this.updateContextDate({ contextDateRange: [contextStartDate, contextEndDate] });
      this.updateReportingPeriodMode(reportingPeriodMode);
      this.updateContextPeriodMode(contextPeriodMode);
    },
    updateDateRanges({ reportDateRange, contextDateRange }) {
      const updateReportDateRange =
        !!reportDateRange && !isEqual(reportDateRange, this.reportDateRange);
      const updateContextDateRange =
        !!contextDateRange && !isEqual(contextDateRange, this.contextDateRange);

      if (updateReportDateRange) {
        this.updateReportDate({ reportDateRange });
      }

      if (updateContextDateRange) {
        this.updateContextDate({ contextDateRange });
      }
    },
    setTikTokGalleriesParams({ tiktokGalleriesParams }) {
      this.tiktokGalleriesParams = tiktokGalleriesParams;
    },
    clearTikTokGalleriesParams() {
      this.tiktokGalleriesParams = null;
    },
    async getTopLineMetrics({ brandIds, startDate, endDate, contextStartDate, contextEndDate }) {
      this.pending.accountMetrics.summary_metrics = true;
      try {
        const response = await DashboardAPI.getTopLineStatsForChannel({
          brandIds,
          requirePosts: true,
          startDate,
          endDate,
          contextStartDate,
          contextEndDate,
          channels: 'TIKTOK',
          metrics:
            'NET_NEW_FOLLOWERS,VIDEO_VIEWS,ENGAGEMENTS,ORGANIC_LIKES,ORGANIC_COMMENTS,SHARES,PROFILE_VIEWS,TOTAL_FOLLOWERS,NUMBER_OF_POSTS',
        });
        this.accountMetrics.summary_metrics = formatSummaryMetrics(response.data.data[brandIds]);
      } catch (error) {
        if (error?.response?.status === 404) {
          this.accountMetrics.summary_metrics = {};
        } else {
          throw error;
        }
      } finally {
        this.pending.accountMetrics.summary_metrics = false;
      }
      return this.accountMetrics.summary_metrics;
    },
    async getFollowersDemographics({ brandId, date }) {
      this.pending.followersDemographics = true;
      try {
        const response = await TikTokAPI.getFollowersDemographics({
          brandId,
          date,
        });
        this.followersDemographics = response.data.data;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.followersDemographics = null;
        } else {
          throw error;
        }
      } finally {
        this.pending.followersDemographics = false;
      }
    },
    async getGraphStats({
      brandIds,
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
      timeScale,
    }) {
      this.pending.accountMetrics.timeseries_metrics = true;
      try {
        const response = await DashboardAPI.getGraphStats({
          brandIds,
          startDate,
          endDate,
          contextStartDate,
          contextEndDate,
          timeScale,
          channels: 'TIKTOK',
          metrics: 'NET_NEW_FOLLOWERS,TOTAL_FOLLOWERS,VIDEO_VIEWS,ENGAGEMENTS',
        });
        this.accountMetrics.timeseries_metrics = response.data?.data?.[brandIds]?.TIKTOK;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.accountMetrics.timeseries_metrics = {};
        } else {
          throw error;
        }
      } finally {
        this.pending.accountMetrics.timeseries_metrics = false;
      }
      return this.accountMetrics.timeseries_metrics;
    },
    async getAvgEntertainmentScore({ brandIds, startDate, endDate }) {
      this.pending.accountMetrics.averageEntertainmentScore = true;
      try {
        const response = await DashboardAPI.getGenericReportData({
          brandIds,
          startDate,
          endDate,
          contextStartDate: startDate,
          contextEndDate: endDate,
          channels: 'TIKTOK',
          metrics: 'AVG_ENTERTAINMENT_SCORE',
          reportType: 'SINGLE_METRIC',
        });
        this.accountMetrics.averageEntertainmentScore =
          response.data?.data?.[brandIds]?.metrics?.AVG_ENTERTAINMENT_SCORE?.TIKTOK?.value;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.accountMetrics.averageEntertainmentScore = null;
        } else {
          throw error;
        }
      } finally {
        this.pending.accountMetrics.averageEntertainmentScore = false;
      }
    },
    async getTrendingVideoList({ brandId, startDate, endDate, limit = 8 }) {
      this.pending.trendingVideoList = true;
      const response = await TikTokAPI.getTrendingVideoList({
        brandId,
        startDate,
        endDate,
        limit,
      });
      this.trendingVideoList = response.data.data;
      this.pending.trendingVideoList = false;
    },
    async getTopVideoList({ brandId, startDate, endDate, limit = 8 }) {
      this.pending.topVideoList = true;
      const response = await TikTokAPI.getTrendingVideoList({
        brandId,
        startDate,
        endDate,
        limit,
      });
      this.topVideoList = response.data.data;
      this.pending.topVideoList = false;
    },
    async getVideoViews({ brandId, videoId, startDate, endDate, timeScale }) {
      this.pending.videoViews = true;
      try {
        const response = await TikTokAPI.getVideoViews({
          brandId,
          videoId,
          startDate,
          endDate,
          timeScale,
        });
        this.videoViews = response.data.data;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.videoViews = {};
        } else {
          throw error;
        }
      } finally {
        this.pending.videoViews = false;
      }
    },
    async getTopKeywords({ brandId, includeKeywords, includeHashtags }) {
      this.pending.topKeywords = true;
      try {
        const response = await TikTokAPI.getTopKeywords({
          brandId,
          includeKeywords,
          includeHashtags,
        });
        this.topKeywords = response.data.data;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.topKeywords = [];
        } else {
          throw error;
        }
      } finally {
        this.pending.topKeywords = false;
      }
    },
    async fetchAdAccountsFromApi({ brandId, connectTiktokAdsToken }) {
      this.pending.adAccountsFromApi = true;
      try {
        const response = await TikTokAPI.getAdAccounts({
          brandId,
          fetchApi: true,
          connectTiktokAdsToken,
        });
        const data = response?.data?.data || [];
        this.adAccountsFromApi = data;
      } finally {
        this.pending.adAccountsFromApi = false;
      }
    },
    async updateAdAccount({ brandId, sourceAdvertiserId, isMonitored, connectTiktokAdsToken }) {
      return TikTokAPI.updateAdAccount({
        brandId,
        sourceAdvertiserId,
        isMonitored,
        connectTiktokAdsToken,
      });
    },
    async getAdAccounts({ brandIds }) {
      const brandIdsToRequest = (brandIds ?? []).filter((brandId) => !this.adAccounts[brandId]);
      if (brandIdsToRequest.length === 0) return this.adAccounts;

      this.pending.adAccounts = true;
      try {
        const { data } = await TikTokAPI.getAdAccountsAllBrands({ brandIds: brandIdsToRequest });
        this.adAccounts = merge(this.adAccounts, data.data) ?? {};
      } finally {
        this.pending.adAccounts = false;
      }
      return this.adAccounts;
    },
    async getAdCampaigns({ brandIds, sourceAdAccountIds, sourceCampaignIds }) {
      const outstandingSourceCampaignIds = sourceCampaignIds?.filter(
        (sourceCampaignId) => !this.adCampaigns[sourceCampaignId],
      );
      if (
        (brandIds?.length > 0 && sourceAdAccountIds?.length > 0) ||
        outstandingSourceCampaignIds?.length > 0
      ) {
        this.pending.adCampaigns = true;
        try {
          const response = await TikTokAPI.getAdCampaignsAllBrands({
            brandIds,
            sourceAdAccountIds,
            sourceCampaignIds: outstandingSourceCampaignIds,
          });
          const payload = response?.data;
          if (payload) {
            const newCampaignMap = payload.data.reduce((acc, campaign) => {
              acc[campaign.sourceCampaignId] = campaign;
              return acc;
            }, {});
            this.adCampaigns = {
              ...this.adCampaigns,
              ...newCampaignMap,
            };
          }
        } finally {
          this.pending.adCampaigns = false;
        }
      }
    },

    async getBrandTTCMAccounts({ brandId }) {
      this.pending.ttcmAccountsFromApi = true;
      try {
        const response = await TikTokAPI.getBrandTTCMAccounts({ brandId, fetchApi: true });
        const data = response?.data?.data || [];
        this.ttcmAccountsFromApi = data;
      } finally {
        this.pending.ttcmAccountsFromApi = false;
      }
    },

    async updateCreatorMarketplaceAccount({ brandId, sourceTtcmAccountId, isMonitored }) {
      return TikTokAPI.updateCreatorMarketplaceAccount({
        brandId,
        sourceTtcmAccountId,
        isMonitored,
      });
    },

    async getCreatorByHandle({ handle }) {
      // Reset the error value before fetching.
      this.error.creatorByHandle = null;
      this.pending.creatorByHandle = true;
      try {
        const response = await TikTokAPI.getCreatorByHandle({ handle });
        const data = response?.data?.data || {};
        this.creatorByHandle = {
          handle: data?.handleName,
          avatar: data?.profileImage,
          bio: data?.bio,
          followers: data?.followersCount,
          source: 'TIKTOK',
          totalLikes: data?.likesCount,
          numberOfPosts: data?.videosCount,
          internalCreatorId: data?.internalCreatorId,
          ttcmStatus: data?.ttcmStatus,
        };
      } catch (err) {
        this.error.creatorByHandle = err.response;
        if (err.response?.status !== 404) {
          throw err;
        }
      } finally {
        this.pending.creatorByHandle = false;
      }
    },

    clearCreatorByHandle() {
      this.error.creatorByHandle = null;
      this.creatorByHandle = null;
    },
    async getTrendingHashtags({ categoryName, countryCode, dateRange }) {
      this.pending.trendingHashtags = true;
      try {
        const res = await TikTokAPI.getTrendingHashtags({
          categoryName,
          countryCode,
          dateRange,
        });
        this.trendingHashtags = res?.data?.list;
      } finally {
        this.pending.trendingHashtags = false;
      }
    },

    async getHashtagDetails({ hashtagId, countryCode, dateRange }) {
      this.pending.hashtagDetails = true;
      try {
        const res = await TikTokAPI.getHashtagDetails({
          hashtagId,
          countryCode,
          dateRange,
        });
        this.hashtagDetails = res?.data;
      } finally {
        this.pending.hashtagDetails = false;
      }
    },
    setTrendingHashtagsActiveIndex(index) {
      this.trendingHashtagsActiveIndex = index;
    },

    async getFeedPosts({ creatorId, limit }) {
      this.pending.feedPosts = true;
      try {
        const signal = refreshAbortController(this.abortControllers.getFeedPosts);

        const response = await TikTokAPI.getFeedPosts(
          {
            creatorId,
            limit,
            url: this.paging.feedPostsNextUrl,
          },
          { signal },
        );

        const payload = response.status === 200 ? response.data : response;

        this.feedPosts = [...this.feedPosts, ...payload.data];
        this.paging.feedPostsNextUrl = response.data?.paging?.next;
      } catch (error) {
        if (error?.response?.status !== 404) handleCancelError(error);
        this.feedPosts = [];
      } finally {
        this.pending.feedPosts = false;
      }
    },
    clearFeedPosts() {
      this.pending.feedPosts = false;
      this.paging.feedPostsNextUrl = null;
      this.feedPosts = [];
    },
  },
});
