import { computed } from 'vue';
import { useFlagStore } from '@/stores/flag';
import { useIdentityStore } from '@/stores/identity';
import { BRAND, ORGANIZATION_USER } from '@/models/auth/permissions.enum';

export function useContentAutomationPermissions() {
  const identityStore = useIdentityStore();
  const flagStore = useFlagStore();

  const canAccessContentAutomations = computed(() => {
    return (
      identityStore.checkIfBrandPermissionsExist(
        [BRAND.SETTINGS.CAN_ACCESS_CONTENT_AUTOMATION],
        [identityStore.currentBrand],
      ) &&
      flagStore.ready &&
      (flagStore.flags?.contentAutomationGalleries ||
        flagStore.flags?.contentAutomationContentTags ||
        flagStore.flags?.contentAutomationCampaigns ||
        flagStore.flags?.contentAutomationBoards)
    );
  });

  const canManageContentAutomations = computed(() => {
    return identityStore.guard(
      ORGANIZATION_USER.SETTINGS.CAN_MANAGE_CONTENT_AUTOMATIONS,
      identityStore.currentBrand,
    );
  });

  return {
    canAccessContentAutomations,
    canManageContentAutomations,
  };
}
