import {
  groupValidationMap,
  mediaValidationMapV,
  validationTypes,
  groupValidationTypes,
  ERROR_LEVEL,
  REEL_CUSTOM_COVER,
  validationMessageType,
  PLATFORM_VALIDATES_SN,
  aspectRatioTolerance,
} from '@/app/scheduler/constants/mediaValidationConsts';
import {
  getValidationErrorMessage,
  getMediaDataForValidation,
  MediaPropertyRequiredError,
} from '@/app/scheduler/utils/mediaValidationFormatters';
import { UPLOAD_STATUS } from '@/config';
import { mediaTypes } from '@/app/library/constants';
import { PLATFORMS } from '@/app/scheduler/constants';

function _mediaGroupHasUniformTypes(mediaArray) {
  const typesPresent = new Set(mediaArray.map((m) => m.type));
  return typesPresent.size === 1;
}

function _roundRatio(ratio) {
  return Math.round((ratio + Number.EPSILON) * 100) / 100;
}

function _mediaGroupHasUniformAspectRatios(mediaArray) {
  const aspectRatioArray = mediaArray.map((m) => getMediaDataForValidation(m).aspectRatio);
  const referenceRatio = aspectRatioArray[0];
  const offset = referenceRatio * aspectRatioTolerance;

  // Apply rounding & offset to ratio check to account for inconsistencies with cropper tool
  return aspectRatioArray.every((r) => {
    const curRatio = _roundRatio(Number(r));
    return Math.abs(curRatio - referenceRatio) <= offset;
  });
}

export function validateMediaGroup(mediaArray, platform, autoPublish, postType = 'default') {
  const validations = groupValidationMap[platform][postType];
  const validationKeys = Object.keys(validations);
  const { MAX_COUNT, MATCH_ASPECT_RATIO, MATCH_MEDIA_TYPE, MAX_IMAGE_COUNT, MAX_VIDEO_COUNT } =
    groupValidationTypes;

  const checkMediaCount = validationKeys.includes(MAX_COUNT) && validations[MAX_COUNT];
  const checkImageCount = validationKeys.includes(MAX_IMAGE_COUNT) && validations[MAX_IMAGE_COUNT];
  const checkVideoCount = validationKeys.includes(MAX_VIDEO_COUNT) && validations[MAX_VIDEO_COUNT];
  const checkAspectRatio =
    validationKeys.includes(MATCH_ASPECT_RATIO) && validations[MATCH_ASPECT_RATIO];
  const checkMediaType = validationKeys.includes(MATCH_MEDIA_TYPE) && validations[MATCH_MEDIA_TYPE];
  // This check is bypassed for send notification stories as brands utilize this feature
  // as a workaround for not having a bulk story editor
  const mediaCountBypass = postType === 'STORY' && !autoPublish;

  if (checkMediaCount && !mediaCountBypass) {
    const invalidMediaCount = mediaArray.length > validations[MAX_COUNT];
    if (invalidMediaCount)
      return {
        level: ERROR_LEVEL.ERROR,
        message: getValidationErrorMessage({
          messageType: validationMessageType.GROUP_ERROR,
          validationType: MAX_COUNT,
          upperBound: validations[MAX_COUNT],
        }),
      };
  }

  if (checkImageCount || checkVideoCount) {
    const numOfImage = mediaArray.filter((item) => item.type === mediaTypes.IMAGE).length;
    const numOfVideo = mediaArray.length - numOfImage;
    const invalidImageCount = numOfImage > validations[MAX_IMAGE_COUNT];
    const invalidVideoCount = numOfVideo > validations[MAX_VIDEO_COUNT];

    if (invalidImageCount || invalidVideoCount) {
      return {
        level: ERROR_LEVEL.ERROR,
        message: getValidationErrorMessage({
          messageType: validationMessageType.GROUP_ERROR,
          validationType: numOfVideo ? MAX_VIDEO_COUNT : MAX_IMAGE_COUNT,
          upperBound: numOfVideo ? validations[MAX_VIDEO_COUNT] : validations[MAX_IMAGE_COUNT],
        }),
      };
    }
  }

  if (checkAspectRatio) {
    let invalidGroupRatios;
    try {
      invalidGroupRatios = !_mediaGroupHasUniformAspectRatios(mediaArray) && autoPublish;
    } catch (e) {
      if (e instanceof MediaPropertyRequiredError)
        return {
          level: ERROR_LEVEL.ERROR,
          message: e.message,
        };
      throw e;
    }
    if (invalidGroupRatios)
      return {
        level: ERROR_LEVEL.ERROR,
        message: getValidationErrorMessage({
          messageType: validationMessageType.GROUP_ERROR,
          validationType: MATCH_ASPECT_RATIO,
        }),
      };
  }

  if (checkMediaType) {
    const hasUniformTypes = _mediaGroupHasUniformTypes(mediaArray);
    if (!hasUniformTypes)
      return {
        level: ERROR_LEVEL.ERROR,
        message: getValidationErrorMessage({
          messageType: validationMessageType.GROUP_ERROR,
          validationType: MATCH_MEDIA_TYPE,
        }),
      };
  }
  return null;
}

export function validateMediaSpecs(media, platform, autoPublish, postType = 'default') {
  if (!media || (media.uploadStatus && media.uploadStatus !== UPLOAD_STATUS.SUCCESS)) {
    return null;
  }

  const allowedMediaTypes = mediaValidationMapV[platform][postType].allowTypes;
  if (!allowedMediaTypes.includes(media.type)) {
    return {
      level: ERROR_LEVEL.ERROR,
      message: getValidationErrorMessage({
        messageType: validationMessageType.ERROR,
        validationType: validationTypes.ALLOW_TYPES,
        allowType: allowedMediaTypes,
      }),
    };
  }

  const validationSpecs = mediaValidationMapV[platform][postType][media.type];
  let mediaData;
  try {
    mediaData = getMediaDataForValidation(media);
  } catch (e) {
    if (e instanceof MediaPropertyRequiredError)
      return {
        level: ERROR_LEVEL.ERROR,
        message: e.message,
      };
    throw e;
  }

  let hardError = null;
  let softError = null;

  Object.keys(validationSpecs).forEach((vKey) => {
    // TODO: Back populating frame_rate to existing videos
    // Temporary skipping frame rate check for old media which doesn't have frameRate field set
    if (vKey === 'frameRate' && !mediaData[vKey]) {
      return;
    }

    const tolerancePercentage = validationSpecs[vKey]?.tolerance || 0;
    const hardUpperBound = validationSpecs[vKey].max * (1 + tolerancePercentage);
    const hardLowerBound = validationSpecs[vKey].min * (1 - tolerancePercentage);
    const softUpperBound = validationSpecs[vKey].maxSoft * (1 + tolerancePercentage);
    const softLowerBound = validationSpecs[vKey].minSoft * (1 - tolerancePercentage);

    // Any negative value for the following represents an out of bounds value in mediaData
    const hardCompareUpper = hardUpperBound - mediaData[vKey];
    const hardCompareLower = mediaData[vKey] - hardLowerBound;
    const softCompareUpper = softUpperBound - mediaData[vKey];
    const softCompareLower = mediaData[vKey] - softLowerBound;
    if ((hardCompareLower < 0 || hardCompareUpper < 0) && autoPublish) {
      hardError = {
        level: ERROR_LEVEL.ERROR,
        message: getValidationErrorMessage({
          messageType: validationMessageType.ERROR,
          validationType: vKey,
          upperBound: validationSpecs[vKey]?.max,
          lowerBound: validationSpecs[vKey]?.min,
        }),
      };
    }

    if (softCompareLower < 0 || softCompareUpper < 0) {
      softError = {
        level: ERROR_LEVEL.WARN,
        message: getValidationErrorMessage({
          messageType: validationMessageType.WARN,
          validationType: vKey,
          upperBound: validationSpecs[vKey]?.maxSoft,
          lowerBound: validationSpecs[vKey]?.minSoft,
          mediaType: mediaData?.type,
        }),
      };
    }
  });
  return hardError || softError || null;
}

export function validatePostMedia(mediaArray, platform, autoPublish, postType = 'default') {
  if (!PLATFORM_VALIDATES_SN[platform] && !autoPublish) {
    return null;
  }
  const hasMultipleMedia = mediaArray.length > 1;

  if (hasMultipleMedia) {
    const mediaGroupError = validateMediaGroup(mediaArray, platform, autoPublish, postType);
    if (mediaGroupError) return mediaGroupError;
  }

  const errorList = [];
  const warningList = [];
  mediaArray.forEach((mediaItem) => {
    const currError = validateMediaSpecs(mediaItem, platform, autoPublish, postType);
    errorList.push(currError?.level === ERROR_LEVEL.ERROR ? currError : null);
    warningList.push(currError?.level === ERROR_LEVEL.WARN ? currError : null);
  });

  const foundErrors = errorList.filter((error) => error);
  const foundWarnings = warningList.filter((warning) => warning);

  // Facebook and Twitter uses an array of errors with index matched with mediaList
  if (platform === PLATFORMS.FACEBOOK) {
    return (foundErrors.length && errorList) || (foundWarnings.length && warningList) || [];
  }
  return foundErrors.shift() || foundWarnings.shift() || null;
}

export function validateCustomCoverImage(media, autoPublish, platform) {
  const coverValidation = validateMediaSpecs(media, platform, autoPublish, REEL_CUSTOM_COVER);

  if (coverValidation) {
    coverValidation.message = coverValidation.message.replace(
      'Selected media',
      'Selected cover image media',
    );
  }

  return coverValidation;
}
