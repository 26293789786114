import { ref } from 'vue';
import { defineStore } from 'pinia';
import startCase from 'lodash/startCase';
import dayjs from 'dayjs';
import { LibraryAPI } from '@/apis';
import { localizeCurrency } from '@/utils/formatters';
import enumTypes, { linkedinMediaTypes } from '@/app/library/constants';
import { useAuthStore } from '@/stores/auth';
import { axios as instagramAxios } from '@/apis/instagram';
import { stringifyQuery } from '@/utils/query';
import { axios } from '@/apis/library';
import { DEMO_VIDEO_SOURCE_ID, DEMO_YOUTUBE_VIDEO_URL } from '@/app/youtube/constants';
import { constants, mediaSourceType } from '@/config';
import { useFlagStore } from '@/stores/flag';
import axiosForExternalCall from 'axios';
import { externalAxios } from '@/apis/external-apis';

export const formatYouTubeAudienceObject = (sourceData) => {
  const directOrUnknownTrafficSourceTypes = ['UNKNOWNMOBILEORDIRECT', 'NOLINKOTHER'];
  // ignoring these attributes as they describe values relating to external sources,
  // and we use the data within external_traffic_sources to display
  // external source information.
  const propertiesToIgnore = ['EXTURL', 'NOLINKEMBEDDED'];
  const {
    externalTrafficSources,
    trafficSourceTotals,
    topSearchTerms,
    countryDemographics,
    ageGenderDemographics,
  } = sourceData;

  // Recreate original formatting of certain metrics
  const formattedCountryDemographics = {};
  Object.entries(countryDemographics || {}).forEach(([countryCode, value]) => {
    formattedCountryDemographics[countryCode.toUpperCase()] = value;
  });
  const formattedTrafficSourceTotals = {};
  Object.entries(trafficSourceTotals || {}).forEach(([trafficSource, value]) => {
    formattedTrafficSourceTotals[trafficSource.toUpperCase()] = value;
  });

  let audience = {
    externalTrafficSources,
    topSearchTerms,
    countryDemographics: formattedCountryDemographics,
  };

  if (formattedTrafficSourceTotals) {
    const withinYouTube = {};
    const directOrUnknown = {};

    Object.keys(formattedTrafficSourceTotals).forEach((key) => {
      if (propertiesToIgnore.includes(key)) return;

      if (directOrUnknownTrafficSourceTypes.includes(key)) {
        directOrUnknown[key] = formattedTrafficSourceTotals[key];
      } else {
        withinYouTube[key] = formattedTrafficSourceTotals[key];
      }
    });

    audience = {
      ...audience,
      withinYouTube,
      directOrUnknown,
    };
  }

  if (ageGenderDemographics) {
    const formattedStats = {};
    Object.keys(ageGenderDemographics).forEach((key) => {
      // keys are of the form: age<lowerBound>-<upperBound>_<gender> ex: age13-17_male
      const [ageGroup, gender] = key.split('_');

      if (!formattedStats[ageGroup]) {
        formattedStats[ageGroup] = {};
      }

      formattedStats[ageGroup][gender] = ageGenderDemographics[key];
    });
    audience = {
      ...audience,
      ageGenderDemographics: formattedStats,
    };
  }
  return audience;
};

function parseUser(sourceData) {
  let userName = null;
  if (sourceData.instagramUser) {
    userName = sourceData.instagramUser.handle;
  }
  return { userName };
}

export const formatYouTubeMediaDetail = (isOwned, sourceData) => {
  const flagStore = useFlagStore();
  const youtubeBaseUrl = 'https://www.youtube.com';

  // Demo brands will have videos with sourceVideoIds like "test_video_id_<brand_id>_<random_string>"
  // we want to show a demo video for these videos
  let { youtubeLink: postUrl, sourceVideoId } = sourceData;
  if (sourceVideoId?.startsWith('test_video_')) {
    sourceVideoId = DEMO_VIDEO_SOURCE_ID;
    postUrl = DEMO_YOUTUBE_VIDEO_URL;
  }

  const youtubeMediaDetail = {
    userName: sourceData.accountDetails?.title,
    avatarUrl: sourceData.accountDetails?.thumbnailUrl,
    postTitle: sourceData.title,
    postMessage: sourceData.description,
    datePosted: sourceData.sourcePublishedAt,
    platformHomePageUrl: `${youtubeBaseUrl}/channel/${sourceData.accountDetails?.sourceChannelId}`,
    sourceVideoId,
    postUrl,
  };

  if (isOwned) {
    youtubeMediaDetail.insights = {
      videoViews: sourceData.views,
      avgViewDuration: sourceData.avgViewDuration,
      avgViewPercentage: sourceData.avgViewPercentage,
      watchTime: sourceData.estSecondsWatched,
      subscribers: sourceData.subscribers,
      ...(flagStore.flags?.youtubeCompliance ? { totalEngagements: sourceData.engagements } : {}),
      likes: sourceData.likes,
      dislikes: sourceData.dislikes,
      comments: sourceData.comments,
      shares: sourceData.shares,
      addedToPlaylist: sourceData.videosAddedToPlaylist,
    };
    youtubeMediaDetail.audience = formatYouTubeAudienceObject(sourceData);
  } else {
    youtubeMediaDetail.insights = {
      videoViews: sourceData.views,
      likes: sourceData.likes,
      comments: sourceData.comments,
    };
  }

  return youtubeMediaDetail;
};

function positiveNumber(value) {
  return value > 0 ? value : 0;
}

export function formatInstagramData(data) {
  let { boosted_data: rawBoostedData } = data;
  rawBoostedData = rawBoostedData ?? {};
  const likeCountPaid = positiveNumber(rawBoostedData.like_count_paid);
  const commentsCountPaid = positiveNumber(rawBoostedData.comments_count_paid);
  const impressionsPaid = positiveNumber(rawBoostedData.impressions_paid);
  const videoViewsPaid = positiveNumber(rawBoostedData.video_views);
  const amountSpentPaid = positiveNumber(rawBoostedData.amount_spent);
  const clicksPaid = positiveNumber(rawBoostedData.clicks);
  const costPerThruplayPaid = positiveNumber(rawBoostedData.cost_per_thruplay);
  const cpcPaid = positiveNumber(rawBoostedData.cpc);
  const cpmPaid = positiveNumber(rawBoostedData.cpm);
  const ctrPaid = positiveNumber(rawBoostedData.ctr);
  const reachPaid = positiveNumber(rawBoostedData.reach);
  const reactionsPaid = positiveNumber(rawBoostedData.reactions);
  const sharesPaid = positiveNumber(rawBoostedData.shares);
  const thruplaysPaid = positiveNumber(rawBoostedData.thruplays);
  const videoPlays25Paid = positiveNumber(rawBoostedData.video_plays_25);
  const videoPlays50Paid = positiveNumber(rawBoostedData.video_plays_50);
  const videoPlays75Paid = positiveNumber(rawBoostedData.video_plays_75);
  const videoPlays100Paid = positiveNumber(rawBoostedData.video_plays_100);
  const totalEngagements = positiveNumber(data.sum_total_engagement);
  const totalEngagementsOrganic = positiveNumber(data.total_engagement);
  const totalEngagementsPaid = positiveNumber(data.paid_total_engagement);
  const engagementRatePaid = positiveNumber(data.paid_total_engagement_rate);
  const frequencyPaid = positiveNumber(rawBoostedData.frequency ?? impressionsPaid / reachPaid);
  const shares = positiveNumber(data.shares);

  const { profile_activity: profileActivity } = data;
  // Empty profileActivity means unavailable but some older posts created before this metric was introduced
  // will also have it empty even though it is supported
  const defaultProfileActivityValue = Object.keys(profileActivity).length ? 0 : null;
  const boostedData = {};
  if (Object.keys(rawBoostedData ?? {}).length > 0) {
    Object.assign(boostedData, {
      totalEngagementsOrganic,
      totalEngagementsPaid,
      sharesTotal: shares + sharesPaid,
      videoViewsOrganic: data.video_views,
      reachTotal: (data.reach ?? 0) + reachPaid,
      likesOrganic: data.like_count,
      commentsOrganic: data.comments_count,
      impressionsOrganic: data.impressions,
      amountSpentPaid,
      clicksPaid,
      likeCountPaid,
      commentsCountPaid,
      impressionsPaid,
      videoViewsPaid,
      costPerThruplayPaid,
      cpcPaid,
      cpmPaid,
      ctrPaid,
      engagementRatePaid,
      reachPaid,
      reactionsPaid,
      sharesPaid,
      thruplaysPaid,
      videoPlays25Paid,
      videoPlays50Paid,
      videoPlays75Paid,
      videoPlays100Paid,
      videoPlaysTotalPaid:
        videoPlays25Paid + videoPlays50Paid + videoPlays75Paid + videoPlays100Paid,
      frequencyPaid,
    });
  }
  const relationshipData = {};

  if ((data.is_mentioned || data.is_tagged) && data?.relationship_post) {
    relationshipData.followersGained = data.relationship_post.followers_gained;
    relationshipData.emv = data.relationship_post.emv;
  }
  return {
    effectiveness: data.effectiveness,
    engagementRate: data.engagement,
    engagementRateImpressions: data.engagement_rate_impressions,
    impressions: impressionsPaid + data.impressions,
    reach: data.reach,
    totalEngagements,
    likes: likeCountPaid + data.like_count,
    comments: commentsCountPaid + data.comments_count,
    is_commented: data.is_commented,
    saved: data.saved,
    shares,
    follows: data.follows,
    profileVisits: data.profile_visits,
    profileClicks: profileActivity.total ?? defaultProfileActivityValue,
    bioLinkClicks: profileActivity.bio_link_clicked ?? defaultProfileActivityValue,
    calls: profileActivity.call ?? defaultProfileActivityValue,
    directions: profileActivity.direction ?? defaultProfileActivityValue,
    emails: profileActivity.email ?? defaultProfileActivityValue,
    other: profileActivity.other ?? defaultProfileActivityValue,
    text: profileActivity.text ?? defaultProfileActivityValue,
    videoViews: data.total_video_views,
    showBusinessInsights: data.show_business_insights,
    ...boostedData,
    ...relationshipData,
  };
}

function formatInstagramMediaDetail(data) {
  const { boostedData: rawBoostedData } = data.sourceData;
  const boostedData = {};
  if (Object.keys(rawBoostedData ?? {}).length > 0) {
    boostedData.commentsOrganic = positiveNumber(data.sourceData.commentsCount);
    boostedData.commentsCountPaid = positiveNumber(rawBoostedData.commentsCountPaid);
    boostedData.comments = positiveNumber(rawBoostedData.commentsTotal);
    boostedData.impressionsPaid = positiveNumber(rawBoostedData.impressionsPaid);
    boostedData.impressions = positiveNumber(data.sourceData.totalImpressions);
    boostedData.impressionsOrganic = positiveNumber(data.sourceData.impressions);
    boostedData.videoViewsOrganic = positiveNumber(data.sourceData.videoViews);
    boostedData.videoViewsPaid = positiveNumber(rawBoostedData.videoViews);
    boostedData.videoViewsTotal = positiveNumber(data.sourceData.totalVideoViews);
    boostedData.amountSpentPaid = positiveNumber(rawBoostedData.amountSpent);
    boostedData.clicksPaid = positiveNumber(rawBoostedData.clicks);
    boostedData.costPerThruplayPaid = positiveNumber(rawBoostedData.costPerThruplay);
    boostedData.cpcPaid = positiveNumber(rawBoostedData.cpc);
    boostedData.cpmPaid = positiveNumber(rawBoostedData.cpm);
    boostedData.ctrPaid = positiveNumber(rawBoostedData.ctr);
    boostedData.totalEngagements = positiveNumber(data.sourceData.sumTotalEngagement);
    boostedData.totalEngagementsOrganic = positiveNumber(data.sourceData.totalEngagement);
    boostedData.totalEngagementsPaid = positiveNumber(data.sourceData.paidTotalEngagement);
    boostedData.paidTotalEngagements = positiveNumber(data.sourceData.paidTotalEngagement);
    boostedData.engagementRatePaid = positiveNumber(data.sourceData.paidTotalEngagementRate);
    boostedData.engagementRate = positiveNumber(data.sourceData.engagement);
    boostedData.engagementRateImpressions = positiveNumber(
      data.sourceData.engagementRateImpressions,
    );
    boostedData.reachPaid = positiveNumber(rawBoostedData.reach);
    boostedData.reachTotal = positiveNumber(data.sourceData.totalReach);
    boostedData.reactionsPaid = positiveNumber(rawBoostedData.reactions);
    boostedData.sharesTotal = positiveNumber(data.sourceData.totalShares);
    boostedData.sharesOrganic = positiveNumber(data.sourceData.shares);
    boostedData.sharesPaid = positiveNumber(rawBoostedData.shares);
    boostedData.thruplaysPaid = positiveNumber(rawBoostedData.thruplays);
    boostedData.videoPlays25Paid = positiveNumber(rawBoostedData.videoPlays25);
    boostedData.videoPlays50Paid = positiveNumber(rawBoostedData.videoPlays50);
    boostedData.videoPlays75Paid = positiveNumber(rawBoostedData.videoPlays75);
    boostedData.videoPlays100Paid = positiveNumber(rawBoostedData.videoPlays100);
    boostedData.reach = positiveNumber(data.sourceData.reach);
    boostedData.effectiveness = positiveNumber(data.sourceData.effectiveness);
    boostedData.likes = positiveNumber(rawBoostedData.likesTotal);
    boostedData.likeCountPaid = positiveNumber(rawBoostedData.likeCountPaid);
    boostedData.likesOrganic = positiveNumber(data.sourceData.likeCount);
    boostedData.saved = positiveNumber(data.sourceData.saved);
    boostedData.frequencyPaid = positiveNumber(rawBoostedData.frequency);
    boostedData.startTimeForAd = rawBoostedData.startTimeForAd;
    boostedData.replays = positiveNumber(data.sourceData.replays);
    boostedData.totalPlays = positiveNumber(data.sourceData.totalPlays);
    boostedData.avgTimeViewedSec = positiveNumber(data.sourceData.avgTimeViewedSec);
    boostedData.totalTimeViewedSec = positiveNumber(data.sourceData.totalTimeViewedSec);
  }
  const contentRightsRequested = data.sourceData.rightsRequestMedia !== null;
  const contentRightsRequestType = data.sourceData.rightsRequestMedia
    ? data.sourceData.rightsRequestMedia.type
    : null;
  return {
    userName: data.sourceData ? parseUser(data.sourceData).userName : null,
    datePosted: data.sourceData.timestamp,
    postUrl: data.sourceData.url,
    thumbnailUrl: data.sourceData.media?.[0]?.urls?.ratio,
    insights: null,
    likeshopClicks: data.likeshopClicks,
    // special fields
    postCaption: data.sourceData.caption,
    atMentions: data.sourceData.photoTags,
    isRiq: data.sourceData.isRiq,
    relationship: data.sourceData.relationship,
    contentRightsRequested,
    contentRightsRequestType,
    rightsRequestMedia: data.sourceData.rightsRequestMedia,
    type: data.sourceData.type,
    isReel: data.sourceData.type?.toLowerCase() === 'reel',
    hasCopyrightIssue: data.sourceData.hasCopyrightIssue,
    // carousel field
    media: data.sourceData?.media?.map((m) => ({ ...m, postSourceId: data.sourceId })),
    boostedData,
  };
}

function formatPinterestMediaDetail(data) {
  let insightList;
  let allInsights = {};
  const pin = data.sourceData;
  if (Object.keys(pin).length > 0) {
    allInsights.allTime = {};
    Object.keys(pin.insights).forEach((key) => {
      const interval = pin.insights[key];
      const isAllTime = key === 'allTime';

      const engagementRate = interval.engagement;
      const videoViews = isAllTime ? pin.totalVideoViews : interval.videoViews;
      const averageWatchTime = isAllTime ? pin.averageVideoWatchTime : interval.videoAverageTime;
      const linkClicks = isAllTime
        ? pin.totalClicks
        : interval.outboundClick || interval.clickthrough;
      const saves = isAllTime ? pin.totalSaves : interval.save;
      const impressions = isAllTime ? pin.totalImpressions : interval.impression;
      const closeups = isAllTime ? pin.totalCloseups : interval.pinClick || interval.closeup;
      const engagements = isAllTime ? pin.engagements : interval.engagements;
      const lastUpdated = interval.lastUpdated;

      insightList = {
        engagementRate,
        engagements,
        videoViews,
        averageWatchTime,
        linkClicks,
        saves,
        impressions,
        closeups,
        lastUpdated,
      };
      allInsights = { ...allInsights, ...{ [key]: insightList } };
    });
    allInsights.allTime.engagementRate = pin.engagement;
  }
  return {
    userName: pin.pinner ? pin.pinner.username : null,
    avatarUrl: pin.pinner?.avatarUrl || pin.pinner?.imageUrl,
    postUrl: pin.link,
    datePosted: pin.pinCreatedAt,
    insights: allInsights,
    // special fields
    linkToSite: pin.sourceUrl,
    title: pin.title,
    boardName: pin.pinBoardName,
    description: pin.note && pin.note.trim() !== '' ? pin.note : '-',
    pinterest_video_id: pin.pinterestVideoId,
    is_video: pin.isVideo,
    pinBoardPrivacy: pin.pinBoardPrivacy,
    video_status: pin.videoStatus,
  };
}

function formatUploadMediaDetail(data) {
  return {
    firstName: data.sourceData.firstName,
    lastName: data.sourceData.lastName,
    thumbnailUrl: data.urls.ratio,
    // make source data nested inside uploadSourceData instead of mixin at same level
    // in case data with same field name that come from different sources override each other.
    uploadSourceData: {
      ...data.sourceData,
      uploadTime: data.sourceCreatedAt,
    },
  };
}

function formatInstagramStoryMediaDetail(data) {
  const instagramSourceData = {
    userName: data.sourceData ? parseUser(data.sourceData).userName : null,
    datePosted: data.sourceData.date,
    postUrl: data.sourceData.url,
    insights: {
      reach: data.sourceData.reach,
      impressions: data.sourceData.views,
      completionRate: data.sourceData.completionRate,
      exits: data.sourceData.exits,
      exitRate: data.sourceData.exitRate,
      tapsForward: data.sourceData.tapsForward,
      tapsBack: data.sourceData.tapsBack,
      replies: data.sourceData.replies,
    },
  };
  if (data.sourceData.shortUrl) {
    if (typeof data.sourceData.shortUrl === 'string') {
      instagramSourceData.shortURLData = {
        swipeUpLink: data.sourceData.shortUrl,
      };
    } else {
      instagramSourceData.shortURLData = {
        swipeUps: data.sourceData.shortUrl.totalClicks,
        swipeUpLink: data.sourceData.shortUrl.url,
        trackableLink: data.meta.storyLink,
      };
    }
  }
  return instagramSourceData;
}

function formatFacebookAdsMediaDetail(data, brand, adId) {
  let adsSourceData = {
    userName: null,
    adsManagerUrl: null,
    datePosted: data.source_created_at,
    caption: '',
    // Ids of all media in the same ad.
    mediaIds: [],
    adEffectiveStatus: 'INACTIVE',
    title: null,
    link: null,
  };
  let adsInfo = {};
  let insights = {};
  const publishersInsights = {};
  if (data.sourceData.length > 0) {
    let firstAd = {};
    if (adId) {
      if (data.sourceData.length === 1) {
        [firstAd] = data.sourceData;
      } else {
        firstAd = data.sourceData.filter((item) => {
          return item.fbAdId === adId.toString();
        });
        [firstAd] = firstAd;
      }
    } else {
      firstAd = data.sourceData.reduce((prev, curr) => {
        const timeDiff = dayjs(curr.adSetStartTime).diff(dayjs(prev.adSetStartTime));
        if (timeDiff < 0) {
          return prev;
        }
        if (timeDiff === 0) {
          return (prev.reach || 0) < (curr.reach || 0) ? curr : prev;
        }
        return curr;
      });
    }
    let endDate;
    if (!firstAd.adSetEndTime) {
      endDate = firstAd.adSetEffectiveStatus === 'ACTIVE' ? 'In Progress' : '-';
    } else {
      endDate = dayjs(firstAd.adSetEndTime).format('MMM DD, YYYY');
    }

    adsInfo = {
      startDate: dayjs(firstAd.adSetStartTime).format('MMM DD, YYYY'),
      endDate,
      distributionChannels: firstAd.adSetPublisherPlatforms
        ? firstAd.adSetPublisherPlatforms.map((platform) => startCase(platform)).join(', ')
        : '-',
      adCreatedTime: dayjs(firstAd.adCreatedTime).format('MMM DD, YYYY'),
      adName: firstAd.name,
      adSet: firstAd.adSetName,
      campaignName: firstAd.campaignName,
      objective: firstAd.campaignObjective,
      audienceGenders: firstAd.audienceGenders,
      audienceLocations: firstAd.audienceLocations,
      audienceAgeMax: firstAd.audienceAgeMax,
      audienceAgeMin: firstAd.audienceAgeMin,
      adSetEndTime: firstAd.adSetEndTime,
      campaignStopTime: firstAd.campaignStopTime,
      id: firstAd.id,
      adIsDynamic: firstAd.creativeIsDynamic,
      adDynamicMediaCount: firstAd.creativeDynamicMediaCount,
      adIsBoosted: firstAd.isBoosted,
    };
    if (firstAd.publisherInsights) {
      Object.keys(firstAd.publisherInsights).forEach((key) => {
        let sourceInsights = {};
        const interval = firstAd.publisherInsights[key];
        sourceInsights = {
          roas: interval.purchaseRoas,
          reach: interval.reach,
          impressions: interval.impressions,
          ctr: interval.ctrLinkClicks,
          cpc: interval.cpcLinkClicks
            ? localizeCurrency(interval.cpcLinkClicks, firstAd.accountCurrency)
            : '',
          linkClicks: interval.linkClicks,
          spend: interval.spend,
        };
        if (firstAd.creative_type === 'VIDEO') {
          sourceInsights.videoViews = interval.videoViews ? interval.videoViews : 0;
        }
        publishersInsights[key] = sourceInsights;
      });
    }
    insights = {
      roas: firstAd.purchaseRoas,
      reach: firstAd.reach,
      impressions: firstAd.impressions,
      ctr: firstAd.ctrLinkClicks,
      cpc: localizeCurrency(firstAd.cpcLinkClicks, firstAd.accountCurrency),
      linkClicks: firstAd.linkClicks,
      spend: firstAd.spend,
    };
    if (firstAd.creative_type === 'VIDEO') {
      insights.videoViews = firstAd.videoViews ? firstAd.videoViews : 0;
    }

    adsSourceData = {
      ...adsSourceData,
      publishersInsights,
      userName: firstAd.campaignName,
      adsManagerUrl: firstAd.fbAdsManagerUrl,
      caption: firstAd.creativeBody,
      // brand media ids is formatted like ['<brand_id>:<media_id>', ....]
      mediaIds: firstAd.brandMediaIds
        .filter((bm) => Number(bm.split(':')[0]) === brand.id)
        .map((bm) => bm.split(':')[1]),
      adEffectiveStatus: firstAd.effectiveStatus,
      adSetEffectiveStatus: firstAd.adSetEffectiveStatus,
      campaignEffectiveStatus: firstAd.campaignEffectiveStatus,
      title: firstAd.creativeTitle,
      link: firstAd.creativeLink,
      dateRange:
        firstAd.insightsStartDate && firstAd.insightsEndDate
          ? [
              dayjs(firstAd.insightsStartDate).format('YYYY-MM-DD'),
              dayjs(firstAd.insightsEndDate).format('YYYY-MM-DD'),
            ]
          : null,
    };
  }
  return {
    ...adsSourceData,
    adsInfo,
    insights,
  };
}

function extractFacebookPromotedAndOrganicMetrics(sourceData) {
  return {
    promotedAmountSpent: sourceData?.amountSpend,
    // promotedClicks missing

    promotedAndOrganicComments: sourceData?.paidAndOrganicComments,
    organicComments: sourceData?.organicComments,
    promotedComments: sourceData?.paidComments,

    promotedCostPerThruPlay: sourceData?.costPerThruPlay,
    promotedCpc: sourceData?.cpc,
    promotedCpm: sourceData?.cpm,
    promotedCtr: sourceData?.ctr,

    organicEffectiveness: sourceData?.organicEffectiveness,

    promotedAndOrganicEngagements: sourceData?.paidAndOrganicEngagements,
    organicEngagements: sourceData?.organicEngagements,
    promotedEngagements: sourceData?.paidEngagements,

    organicEngagementRate: sourceData?.organicEngagementRate,
    promotedEngagementRate: sourceData?.paidEngagementRate,

    promotedFrequency: sourceData?.frequency,

    promotedAndOrganicImpressions: sourceData?.paidAndOrganicImpressions,
    organicImpressions: sourceData?.organicImpressions,
    promotedImpressions: sourceData?.paidImpressions,

    promotedLinkClicks: sourceData?.paidLinkClicks,
    // promotedPageLikes missing

    promotedAndOrganicReach: sourceData?.paidAndOrganicReach,
    organicReach: sourceData?.organicReach,
    promotedReach: sourceData?.paidReach,

    promotedAndOrganicReactions: sourceData?.paidAndOrganicReactions,
    organicReactions: sourceData?.organicReactions,
    promotedReactions: sourceData?.paidReactions,

    promotedAndOrganicShares: sourceData?.paidAndOrganicShares,
    organicShares: sourceData?.organicShares,
    promotedShares: sourceData?.paidShares,

    promotedThruPlays: sourceData?.thruplays,

    promotedAndOrganicVideoCompleteViews: sourceData?.paidAndOrganicVideoCompleteViews,
    organicVideoCompleteViews: sourceData?.organicVideoCompleteViews,
    promotedVideoCompleteViews: sourceData?.paidVideoCompleteViews,

    promotedVideoPlay25: sourceData?.videoPlay25,
    promotedVideoPlay50: sourceData?.videoPlay50,
    promotedVideoPlay75: sourceData?.videoPlay75,

    promotedAndOrganicVideoViews: sourceData?.paidAndOrganicVideoViews,
    organicVideoViews: sourceData?.organicVideoViews,
    promotedVideoViews: sourceData?.paidVideoViews,
  };
}

function formatFacebookMediaDetail(data, brand, mediaDetail, adId) {
  if (mediaDetail.sourceType === 'FACEBOOK_ADS') {
    return formatFacebookAdsMediaDetail(data, brand, adId);
  }
  if (enumTypes.FACEBOOK_SOURCE_LIST.includes(mediaDetail.sourceType)) {
    let insights = {};
    let reelInsights = {};
    const isReel = data.sourceData.type?.toLowerCase() === 'reel';
    const isBoosted = data.sourceData.isBoosted;

    if (isReel) {
      reelInsights = {
        plays: data.sourceData.reel.blueReelsPlayCount,
        replays: data.sourceData.reel.fbReelsReplayCount,
        totalPlays: data.sourceData.reel.fbReelsTotalPlays,
        uniqueImpressions: data.sourceData.reel.postImpressionsUnique,
        avgTimeWatched: data.sourceData.reel.postVideoAvgTimeWatched, // in milliseconds
        totalTimeWatched: data.sourceData.reel.postVideoViewTime, // in milliseconds
        follows: data.sourceData.reel.postVideoFollowers,
        percentageViewed: data.sourceData.reel.postVideoRetentionGraph,
        effectivenessReels: data.sourceData.reel.effectiveness,
      };
    }

    if ('reactions' in data.sourceData) {
      insights = {
        reactions: data.sourceData.reactions,
        comments: data.sourceData.comments,
        shares: data.sourceData.shares,
        engagementRate: data.sourceData.engagementRate,
        totalEngagements: data.sourceData.totalEngagements,
        effectiveness: data.sourceData.effectiveness,
        impressions: data.sourceData.impressions,
        reach: data.sourceData.reach,
        linkClicks: data.sourceData.linkClicks,
        videoViews: data.sourceData.videoViews,
        videoCompleteViews: data.sourceData.videoCompleteViews,
        otherClicks: data.sourceData.otherClicks,
        photoViewClicks: data.sourceData.photoViewClicks,
        postClicks: data.sourceData.postClicks,
      };
    }
    if (enumTypes.FACEBOOK_COMPETITIVE_SOURCE_LIST.includes(mediaDetail.sourceType)) {
      insights = {
        engagementRate: data.sourceData.engagementRate,
        totalComments: data.sourceData.totalComments,
        totalEngagements: data.sourceData.totalEngagements,
        totalLikes: data.sourceData.totalLikes,
        totalReactions: data.sourceData.totalReactions,
        totalShares: data.sourceData.totalShares,
      };
    }
    const carouselUrls = data.sourceData.carouselMediaList;
    let media;
    if (carouselUrls) {
      const carouselMedia = carouselUrls.map((carouselUrl) => ({
        originalUrl: carouselUrl,
        urls: {
          full: carouselUrl,
          ratio: carouselUrl,
        },
        mediaType: 'IMAGE',
        postSourceId: data.sourceId,
      }));
      media = [mediaDetail, ...carouselMedia];
    }

    const flagStore = useFlagStore();
    const promotedAndOrganicInsights =
      isBoosted && flagStore?.ready && flagStore?.flags?.metaPromotedPostsInsights
        ? extractFacebookPromotedAndOrganicMetrics(data.sourceData)
        : {};

    return {
      avatarUrl: data.sourceData.pageDetails && data.sourceData.pageDetails.avatar,
      userName: data.sourceData.pageDetails && data.sourceData.pageDetails.name,
      fbPageId: data.sourceData.pageDetails && data.sourceData.pageDetails.fbPageId,
      datePosted: data.sourceData.fbPostCreatedAt,
      sourcePostType: data.sourceData.postType?.toUpperCase(),
      postUrl: data.sourceData.url,
      title: data.sourceData.title,
      message: data.sourceData.message,
      isReel,
      isBoosted,
      // carousel field
      media,
      insights: { ...insights, ...promotedAndOrganicInsights },
      reelInsights,
    };
  }
  return {};
}

function formatTwitterMediaDetail(data, mediaDetail) {
  // check for carousel
  /* eslint-disable camelcase */
  const {
    engagements,
    engagementsTotal,
    engagementsOrganic,
    engagementsPromoted,
    engagementRate,
    engagementRateTotal,
    engagementRateOrganic,
    engagementRatePromoted,
    follows,
    followsTotal,
    followsOrganic,
    followsPromoted,
    impressions,
    impressionsTotal,
    impressionsOrganic,
    impressionsPromoted,
    likes,
    likesTotal,
    likesOrganic,
    likesPromoted,
    replies,
    repliesTotal,
    repliesOrganic,
    repliesPromoted,
    retweets,
    retweetsTotal,
    retweetsOrganic,
    retweetsPromoted,
    totalPublicEngagements,
    totalRetweets,
    quoteTweets,
    urlClicks,
    urlClicksTotal,
    urlClicksOrganic,
    urlClicksPromoted,
    userProfileClicks,
    userProfileClicksTotal,
    userProfileClicksOrganic,
    userProfileClicksPromoted,
    videoViews,
    videoViewsTotal,
    videoViewsOrganic,
    videoViewsPromoted,
    inReplyToUserId,
    inReplyToStatusId,
    retweetedStatusId,
    quotedStatusId,
  } = data.sourceData;
  const insights = {
    engagements,
    engagementsTotal,
    engagementsOrganic,
    engagementsPromoted,
    engagementRate,
    engagementRateTotal,
    engagementRateOrganic,
    engagementRatePromoted,
    follows,
    followsTotal,
    followsOrganic,
    followsPromoted,
    impressions,
    impressionsTotal,
    impressionsOrganic,
    impressionsPromoted,
    likes,
    likesTotal,
    likesOrganic,
    likesPromoted,
    replies,
    repliesTotal,
    repliesOrganic,
    repliesPromoted,
    retweets,
    retweetsTotal,
    retweetsOrganic,
    retweetsPromoted,
    totalPublicEngagements,
    totalRetweets,
    quoteTweets,
    urlClicks,
    urlClicksTotal,
    urlClicksOrganic,
    urlClicksPromoted,
    userProfileClicks,
    userProfileClicksTotal,
    userProfileClicksOrganic,
    userProfileClicksPromoted,
    videoViews,
    videoViewsTotal,
    videoViewsOrganic,
    videoViewsPromoted,
    inReplyToUserId,
    inReplyToStatusId,
    retweetedStatusId,
    quotedStatusId,
  };
  /* eslint-enable */
  const carouselUrls = data.sourceData.additionalMediaUrls;
  let media;
  if (carouselUrls) {
    const carouselMedia = carouselUrls.map((carouselUrl) => ({
      originalUrl: carouselUrl,
      urls: {
        full: carouselUrl,
        ratio: carouselUrl,
      },
      mediaType: 'IMAGE',
      postSourceId: data.sourceId,
    }));
    media = [mediaDetail, ...carouselMedia];
  }
  return {
    avatarUrl: data.sourceData.accountDetails?.twitterAvatar,
    datePosted: data.sourceData.tweetCreatedAt,
    postUrl: data.sourceData.permalinkUrl,
    tweetStatus: data.sourceData.text,
    userName: data.sourceData.accountDetails?.handle,
    isPromoted: data.sourceData.isPromoted,
    insights,
    media,
  };
}

function formatTikTokMediaDetail(data) {
  if (data.sourceData) {
    const {
      accountDetails,
      creatorHandleName,
      audienceLocations,
      views,
      totalEngagements,
      likes,
      comments,
      shares,
      shareUrl,
      reach,
      totalTimeWatched,
      averageTimeWatched,
      averageCompletionRate,
      fullVideoWatchedRate,
      videoCreatedAt,
      embedUrl,
      caption,
      duration,
      impressionsForYouRate,
      impressionsFollowRate,
      impressionsHashtagRate,
      impressionsSoundRate,
      impressionsPersonalProfileRate,
      impressionsSearchRate,
      engagementRate,
      entertainmentScore,
      lastMetricsUpdatedAt,
      videoLink,
    } = data.sourceData;
    const insights = {
      entertainmentScore,
      videoViews: views,
      reach,
      engagementRate,
      retentionRate: averageCompletionRate,
      totalEngagements,
      likes,
      comments,
      shares,
      videoDuration: duration,
      totalTimeWatched,
      avgTimeWatched: averageTimeWatched,
      completionRate: fullVideoWatchedRate,
      forYouPageViews: impressionsForYouRate,
      followingPageViews: impressionsFollowRate,
      hashtagViews: impressionsHashtagRate,
      soundViews: impressionsSoundRate,
      profileViews: impressionsPersonalProfileRate,
      searchViews: impressionsSearchRate,
      lastMetricsUpdatedAt,
    };
    return {
      userName: accountDetails?.tiktokDisplayName || creatorHandleName,
      avatarUrl: accountDetails?.tiktokProfileImage,
      datePosted: videoCreatedAt,
      postUrl: videoLink ?? shareUrl,
      embedUrl,
      caption,
      insights,
      audienceLocations,
    };
  }
  return {};
}

function formatLinkedinMediaDetail(data) {
  const sourceData = data.sourceData;
  const accountDetails = sourceData.accountDetails;
  const multiImageData = sourceData?.multiImages?.urls ?? [];
  const media = multiImageData.map((imageData) => {
    return {
      mediaType: linkedinMediaTypes.IMAGE,
      sourceType: data.type,
      postSourceId: imageData.imageId,
      urls: {
        full: imageData.url,
      },
    };
  });
  const {
    clickThroughRate,
    clicks,
    comments,
    engagementRate,
    engagements,
    impressions,
    likes,
    shares,
    uniqueImpressions,
  } = sourceData;
  const insights = {
    clickThroughRate,
    clicks,
    comments,
    engagementRate,
    engagements,
    impressions,
    likes,
    shares,
    uniqueImpressions,
  };
  if (sourceData?.videoAnalytics) {
    insights.videoViews = sourceData.videoAnalytics.videoViews;
    insights.watchTime = sourceData.videoAnalytics.timeWatchedForViews;
  }
  return {
    caption: sourceData.caption,
    userName: accountDetails.name,
    avatarUrl: accountDetails.logo,
    platformHomePageUrl: accountDetails.accountUrl,
    postUrl: sourceData.linkedinLink,
    datePosted: sourceData.sourceCreatedAt,
    insights,
    media,
    mediaType: sourceData.type,
  };
}

export function formatChannelMediaDetail(data, brand, mediaDetail, adId) {
  switch (mediaDetail.postType) {
    case constants.INSTAGRAM:
      return formatInstagramMediaDetail(data);
    case constants.PINTEREST:
      return formatPinterestMediaDetail(data);
    case constants.UPLOAD:
      return formatUploadMediaDetail(data);
    case constants.INSTAGRAM_STORY:
      return formatInstagramStoryMediaDetail(data);
    case constants.FACEBOOK:
      return formatFacebookMediaDetail(data, brand, mediaDetail, adId);
    case constants.TWITTER:
      return formatTwitterMediaDetail(data, mediaDetail);
    case constants.TIKTOK:
      return formatTikTokMediaDetail(data);
    case constants.YOUTUBE:
      return formatYouTubeMediaDetail(
        mediaDetail.sourceType === enumTypes.YOUTUBE_OWNED,
        data.sourceData,
      );
    case constants.LINKEDIN:
      return formatLinkedinMediaDetail(data);
    default:
      return {};
  }
}

export function formatMediaDetailData(data, brand, adId) {
  // shared fields for all types of media
  // TODO(pmdarrow): This manual post-processing of the media object makes it hard to guess what
  // server field values are on the client. At most we should be camelCasing the snake_case fields,
  // and leave the data as-is. If the data coming from the server isn't the way we want it on the
  // client, we should work with the backend devs to make it correct / easier to use.
  const {
    tags,
    systemTags,
    mediaType,
    urls,
    meta,
    predictions,
    likeshopClicks,
    tiktokLikeshopClicks,
    imageSizes,
    originalHeight,
    originalWidth,
    duration,
    videoSizes,
    galleries,
    campaigns,
    captionSentiment,
    commentSentiment,
    triggeredContentAutomations,
  } = data;
  const mediaDetail = {
    _original: data,
    id: data.mediaId,
    brandId: brand.id,
    mediaGroupId: data.mediaGroup,
    tags,
    systemTags,
    // TODO: take care of it when we have video type media detail specs
    mediaType,
    media_type: mediaType,
    url: urls.original,
    urls,
    meta,
    predictions,
    // current enum for sources: PINTEREST, INSTAGRAM
    postType: data.source,
    postSourceId: data.sourceId,
    sourceType: data.type,
    likeshopClicks,
    tiktokLikeshopClicks,
    imageUrl: urls.full ? urls.full : urls.original,
    imageSizes,
    originalHeight: parseInt(originalHeight, 10),
    originalWidth: parseInt(originalWidth, 10),
    videoUrl: urls.full,
    duration,
    videoSizes,
    originalUrl: urls.original,
    galleries,
    campaigns,
    captionSentiment,
    commentSentiment,
    triggeredContentAutomations,
  };
  // workaround for issue when source data is not indexed in ES
  const sourceDataToCheck = data.sourceData || {};
  const sourceDataHasError =
    Object.keys(sourceDataToCheck).length === 1 && 'description' in sourceDataToCheck;
  if (!data?.sourceData || sourceDataHasError) {
    return mediaDetail;
  }
  return { ...mediaDetail, ...formatChannelMediaDetail(data, brand, mediaDetail, adId) };
}

async function getFacebookAdMediaData(mediaIds, brandId) {
  try {
    const promiseList = mediaIds.map((id) => {
      const adMediaUrl = `/brands/${brandId}/media/${id}`;
      return axios.get(adMediaUrl);
    });
    const dataList = await Promise.all(promiseList);
    return dataList.map((d) => d.data);
  } catch (e) {
    // If failed, treat it as a single media.
    return null;
  }
}

function getURL(source, brandId, mediaId, start, end) {
  if (source) {
    const queryString = stringifyQuery({ source_type: source });
    return `/brands/${brandId}/media/source/${mediaId}?${queryString}`;
  }
  if (start && end) {
    const queryString = stringifyQuery({
      insights_start_date: dayjs(start).format('YYYY-MM-DD'),
      insights_end_date: dayjs(end).format('YYYY-MM-DD'),
    });
    return `/brands/${brandId}/media/${mediaId}?${queryString}&allow_index=true`;
  }
  return `/brands/${brandId}/media/${mediaId}?allow_index=true`;
}

async function patchInstagramPost(url, patchJson, brandId, mediaId) {
  try {
    const summaryUrl = url.includes('?') ? `${url}&summary=true` : `${url}?summary=true`;
    await axios.get(summaryUrl);
  } catch (err) {
    await instagramAxios.patch(`/brands/${brandId}/instagram/posts/${mediaId}`, patchJson);
  }
}
async function imageToBase64(url) {
  const axiosInstance = axiosForExternalCall.create({
    headers: {
      Origin: url,
    },
  });
  const response = await axiosInstance.get(url, {
    responseType: 'blob',
    withCredentials: false,
  });
  const blob = response.data;
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      if (reader.readyState === FileReader.DONE) {
        resolve(reader.result);
      } else {
        reject(new Error('Error reading image data.'));
      }
    };
    reader.onerror = () => {
      reject(new Error('Error reading image data.'));
    };
    reader.readAsDataURL(blob);
  });
}

async function handleCorruptedCarouselChildMedia(formattedMediaDetail) {
  if (
    formattedMediaDetail?.postType === mediaSourceType.INSTAGRAM &&
    formattedMediaDetail?.media?.length > 1
  ) {
    try {
      await externalAxios.head(formattedMediaDetail.media[0].urls.full);
    } catch (e) {
      if (e.response?.status === 403) {
        formattedMediaDetail.fallbackCarouselMedia = [
          {
            mediaType: formattedMediaDetail.mediaType,
            postSourceId: formattedMediaDetail.postSourceId,
            urls: {
              full: formattedMediaDetail.urls.full,
              ratio: formattedMediaDetail.urls.ratio,
            },
          },
        ];
      }
    }
  }
}

export const useMediaDetailStore = defineStore(
  'mediaDetail',
  () => {
    const pending = ref({
      multipleMediaTagsStatus: false,
      updateMediaSentimentStatus: false,
      updateMediaNotesStatus: false,
      deleteMediaItemStatus: false,
      bulkDeleteMediaStatus: false,
    });

    const error = ref({});
    const mediaTags = ref(null);
    const imageDataDetails = ref(null);
    const mediaDetail = ref(null);
    const mediaDetailRequestStatus = ref(null);
    const showMediaPopup = ref(null);
    const imageDataMediaType = ref(null);
    const saveToFBAssetsResult = ref(null);

    async function addMediaTag({ brandId, mediaId, name }) {
      const { data } = await LibraryAPI.addMediaTag({ brandId, mediaId, name });
      mediaTags.value = data;
    }

    async function addMultipleMediaTags({ brandId, mediaIds, tags }) {
      pending.value.multipleMediaTagsStatus = true;
      try {
        return await LibraryAPI.addMultipleMediaTags({ brandId, mediaIds, tags });
      } finally {
        pending.value.multipleMediaTagsStatus = false;
      }
    }

    async function removeMediaTag({ brandId, mediaId, tagId }) {
      const { data } = await LibraryAPI.removeMediaTag({ brandId, mediaId, tagId });
      mediaTags.value = data;
    }

    async function updateMediaNotes({ brandId, mediaId, meta }) {
      pending.value.updateMediaNotesStatus = true;
      try {
        return await LibraryAPI.updateMediaMeta({ brandId, mediaId, meta });
      } finally {
        pending.value.updateMediaNotesStatus = false;
      }
    }

    async function updateMediaSentiment({ brandId, mediaId, meta }) {
      pending.value.updateMediaSentimentStatus = true;
      try {
        const resp = await LibraryAPI.updateMediaMeta({ brandId, mediaId, meta });
        this.setMediaDetail({
          mediaItem: { ...mediaDetail.value, captionSentiment: resp.data.captionSentiment },
        });
      } finally {
        pending.value.updateMediaSentimentStatus = false;
      }
    }

    async function deleteMediaItem({ brandId, mediaId }) {
      pending.value.deleteMediaItemStatus = true;
      try {
        return await LibraryAPI.deleteMediaItem({ brandId, mediaId });
      } finally {
        pending.value.deleteMediaItemStatus = false;
      }
    }

    async function bulkDeleteMedia({ brandIds, mediaIds }) {
      pending.value.bulkDeleteMediaStatus = true;
      try {
        return await LibraryAPI.bulkDeleteMedia({ brandIds, mediaIds });
      } finally {
        pending.value.bulkDeleteMediaStatus = false;
      }
    }

    async function getImageDataByMediaId({ mediaId }) {
      const { data } = await LibraryAPI.getImageDataByMediaId({ mediaId });
      imageDataDetails.value = data;
    }
    async function getImageDataByUrl({ url }) {
      const data = await imageToBase64(url);
      imageDataDetails.value = {
        imageData: data,
      };
    }

    async function getMediaDetail({
      mediaId,
      source,
      adId,
      insightsStart,
      insightsEnd,
      patchJson,
      brandId,
    }) {
      const authStore = useAuthStore();
      mediaDetail.value = null;
      mediaDetailRequestStatus.value = 'pending';
      const mediaBrand = authStore.identityBrandsById[brandId];
      const brand = mediaBrand || authStore.currentBrand;
      const url = getURL(source, brand.id, mediaId, insightsStart, insightsEnd);
      if (source?.toUpperCase() === mediaSourceType.INSTAGRAM) {
        await patchInstagramPost(url, patchJson || {}, brand.id, mediaId);
      }
      try {
        const { data } = await axios.get(url);
        const formattedMediaDetail = formatMediaDetailData(data, brand, adId);
        if (
          formattedMediaDetail.sourceType === mediaSourceType.FACEBOOK_ADS &&
          formattedMediaDetail.mediaIds.length > 1
        ) {
          formattedMediaDetail.media =
            (await getFacebookAdMediaData(formattedMediaDetail.mediaIds, brand.id)) ??
            formattedMediaDetail.media;
        }
        await handleCorruptedCarouselChildMedia(formattedMediaDetail);
        this.setMediaDetail({ mediaItem: formattedMediaDetail });
      } catch (err) {
        this.setMediaDetail({ mediaItem: null });
        if (err?.response?.status === 404) {
          this.mediaNotFound = true;
        } else {
          this.mediaNotFound = false;
          throw err;
        }
      }
    }

    function setMediaDetail({ mediaItem }) {
      mediaDetail.value = mediaItem;
      mediaDetailRequestStatus.value = null;
    }

    function clearMediaDetail() {
      mediaDetail.value = null;
      mediaDetailRequestStatus.value = null;
    }

    function openMediaPopup() {
      showMediaPopup.value = true;
    }

    function closeMediaPopup() {
      showMediaPopup.value = false;
    }

    function updateIsRIQ({ value }) {
      if (mediaDetail.value && value) {
        mediaDetail.value.isRiq = value;
      }
    }

    function setMediaDetailInsights({ insights }) {
      if (mediaDetail.value && insights) {
        mediaDetail.value.insights = insights;
      }
    }

    function setMediaDetailContentRightsRequested({ requested }) {
      if (mediaDetail.value && requested) {
        mediaDetail.value.contentRightsRequested = requested;
      }
    }

    function setImageDataMediaType({ mediaType }) {
      imageDataMediaType.value = mediaType;
    }

    return {
      pending,
      error,
      mediaTags,
      imageDataDetails,
      mediaDetail,
      mediaDetailRequestStatus,
      imageDataMediaType,
      addMediaTag,
      addMultipleMediaTags,
      removeMediaTag,
      updateMediaNotes,
      updateMediaSentiment,
      deleteMediaItem,
      bulkDeleteMedia,
      getImageDataByMediaId,
      getImageDataByUrl,
      setMediaDetail,
      showMediaPopup,
      saveToFBAssetsResult,
      clearMediaDetail,
      openMediaPopup,
      closeMediaPopup,
      updateIsRIQ,
      setMediaDetailInsights,
      setMediaDetailContentRightsRequested,
      setImageDataMediaType,
      getMediaDetail,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
