<script setup>
import { computed, useSlots } from 'vue';
import isEmpty from 'lodash/isEmpty';
import EmptyStatus from '@/components/EmptyStatus.vue';
import SkeletonLoader from '@/components/core/skeleton/SkeletonLoader.vue';

// slots
const slots = useSlots();

// props
const props = defineProps({
  /**
   * details to display when list is empty
   */
  emptyList: { type: Object, default: () => {} },
  /**
   * items to display in the list
   */
  items: { type: Array, default: () => [] },
  /**
   * Determins if the list is currently loading.
   */
  loading: { type: Boolean, default: false },
  /**
   * Flag to set alternating color for list items
   */
  alternateItemColor: { type: Boolean, default: true },
  /**
   * Flag to separate list items with a horizontal line
   */
  lineSeparate: { type: Boolean, default: false },
  /**
   * Property to be used when setting the key of the option
   */
  optionKey: { type: String, default: 'key' },
});

// computed
const isListEmpty = computed(() => {
  return isEmpty(props.items);
});
const hasAvatarContent = computed(() => {
  return !!slots['avatar-content'];
});
const hasActionContent = computed(() => {
  return !!slots['action-content'];
});

// expose for testing
defineExpose({
  isListEmpty,
  hasAvatarContent,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div>
    <SkeletonLoader :loading="loading" type="list">
      <slot v-if="isListEmpty" name="empty-status">
        <EmptyStatus
          v-if="isListEmpty && !loading"
          :title="emptyList?.title"
          :image="emptyList?.image"
          :message="emptyList?.message"
        />
      </slot>

      <div class="list-container">
        <template v-for="(item, index) in items" :key="item?.[optionKey] ?? `item-${index}`">
          <div
            class="item-details"
            :class="{ 'alternate-color': alternateItemColor, 'line-separate': lineSeparate }"
          >
            <div v-if="hasAvatarContent" class="avatar-panel">
              <slot name="avatar-content" :item="item" :index="index" />
            </div>

            <div class="main-content-panel">
              <slot name="main-content" :item="item" :index="index" />
            </div>

            <div v-if="hasActionContent" class="action-panel">
              <slot name="action-content" :item="item" :index="index" />
            </div>
          </div>
        </template>
      </div>
    </SkeletonLoader>
  </div>
</template>

<style lang="postcss" scoped>
.list-container {
  overflow: auto;
}

.item-details {
  display: flex;
  flex-grow: 1;
  border-radius: var(--space-4);
  padding: var(--space-22) 0 var(--space-20) 0;
  align-items: center;
}

.avatar-panel {
  display: flex;
  margin-left: var(--space-24);
  flex-direction: row;
}

.main-content-panel {
  margin-left: var(--space-24);
  margin-right: var(--space-24);
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.action-panel {
  margin-left: auto;
  margin-right: var(--space-24);
  display: flex;
  flex-direction: row;
}

.item-details.alternate-color:nth-child(odd) {
  background-color: var(--background-300);
}

.item-details.line-separate {
  border-radius: unset;
  border-bottom: 1px solid var(--border);
}

.item-details.line-separate:last-child {
  border-bottom: unset;
}
</style>
