<template>
  <section class="media-item" @click="handleMediaItemClick">
    <div
      class="media-mask"
      :class="{
        'media-mask--top': mediaMaskTop,
        'media-mask--bottom': mediaMaskBottom,
        'media-mask--none': mediaMaskNone,
      }"
    />
    <Media
      ref="media"
      :media="mediaItem"
      :on-video-ended="onVideoEnded"
      show-media-only
      :first-video-play="firstVideoPlay"
    />

    <!--Carousel Media Badges are at the Carousel level-->
    <MediaPopupBadges v-if="!carousel" :media-item="mediaItem" />
    <div
      :class="['image-hover-view', { visible: imageHoverViewVisible }]"
      :style="{ cursor: isVideo ? 'pointer' : 'default' }"
    >
      <div
        v-if="(isVideo && showPinterestBadge) || isFacebookCompetitiveVideo || hasIGCopyrightIssue"
        class="message-box"
        data-cy="disabledVideoOverlay"
        @click.stop
      >
        <Icon class="tooltip-icon" name="info" height="38" width="38" />
        <div>
          <p v-if="showPinterestBadge">
            The video file for this Pin is currently unavailable.
            <a @click="handleMediaItemClick">View it on Pinterest.</a>
          </p>
          <p v-else-if="isFacebookCompetitiveVideo">
            Video playbacks for Facebook Competitors are not supported in the API.
            <a @click="handleMediaItemClick">View it on Facebook.</a>
          </p>
          <p v-else-if="hasIGCopyrightIssue">
            This post has been flagged for a copyright violation by Meta. As a result, playback is
            currently unavailable. <a @click="handleMediaItemClick">View it on Instagram.</a>
          </p>
          <p></p>
        </div>
      </div>
      <Icon
        v-else-if="isVideo"
        :color="iconColor"
        :name="videoPlaying ? 'pause' : 'play'"
        height="70"
        width="70"
        class="video-button"
      />
      <Button
        v-if="showFindSimilarButton"
        light
        data-cy="findSimilarButton"
        @click.stop="findSimilar"
      >
        Find Similar
      </Button>
      <!-- TODO: Overview stats temporarily disabled until after launch -->
      <Button
        v-if="showPromote"
        :source-id="mediaItem.postSourceId"
        :disabled="carousel"
        class="js-promote-post"
        light
      >
        Send to FB Ads Manager
      </Button>
      <Button
        v-if="showDownloadButton"
        light
        class="download-button"
        :href="mediaResourceUrl"
        @click.prevent.stop="onDownloadClick"
      >
        Download
      </Button>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import Media from '@/app/scheduler/components/EditPost/MediaViewer/Media.vue';
import enumTypes from '@/app/library/constants';

import { colours } from '@/ux/colours';
import MediaPopupBadges from '@/components/MediaBadges/MediaPopupBadges.vue';
import { formatTime } from '@/utils/formatters';
import { BRAND } from '@/models/auth/permissions.enum';
import { useSearchStore } from '@/stores/search';
import { useSocketStore } from '@/stores/socket';
import { useMediaDetailStore } from '@/stores/media-detail';
import { downloadFileFromUrl } from '@/utils';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaItem',
  components: { MediaPopupBadges, Button, Icon, Media },
  props: {
    carousel: { type: Boolean, default: false },
    mediaItem: { type: Object, default: null },
    showPromote: { type: Boolean, default: false },
    imageHoverViewVisible: { type: Boolean, default: false },
  },
  data() {
    return {
      iconColor: colours.BASIC.WHITE,
      videoPlaying: false,
      firstVideoPlay: true,
    };
  },
  computed: {
    ...mapStores(
      useAuthStore,
      useSearchStore,
      useSocketStore,
      useMediaDetailStore,
      useTrackingStore,
    ),
    videoDuration() {
      if (this.isVideo && this.mediaItem.duration) {
        return formatTime(this.mediaItem.duration);
      }
      return null;
    },
    isImage() {
      return (
        (this.mediaItem.mediaType === 'IMAGE' || this.mediaItem.media_type === 'IMAGE') &&
        !this.mediaItem.is_video
      );
    },
    isVideo() {
      return (
        this.mediaItem.mediaType === 'VIDEO' ||
        this.mediaItem.media_type === 'VIDEO' ||
        this.mediaItem.is_video ||
        this.isFacebookCompetitiveVideo
      );
    },
    resourceUrl() {
      if (this.mediaItem) {
        if (this.mediaItem.originalUrl) {
          // don't use file without proper extension.
          const ext = this.mediaItem.originalUrl.split('.').pop().toLowerCase();
          const valid = /(gif|jp?g|png|mp4)$/.test(ext);
          if (valid) {
            return this.mediaItem.originalUrl;
          }
        }
        return this.mediaItem.urls?.full || '';
      }
      return '';
    },
    resourceFilename() {
      if (this.mediaItem.uploadSourceData && this.mediaItem.uploadSourceData.filename) {
        return this.mediaItem.uploadSourceData.filename;
      }
      if (this.resourceUrl.includes('fbcdn.net')) {
        // If the media url is a facebook url, we need to supply it with a filename. Here, we get
        // the original filename provided by facebook.
        // TODO: remove this case when our usage of facebook urls is removed.
        const originalFilename = this.mediaItem.urls.full.split('?')[0].split('/');
        return originalFilename[originalFilename.length - 1];
      }
      return '';
    },
    isUploadedMedia() {
      return this.mediaItem.sourceType === enumTypes.UPLOADED;
    },
    isInstagramUGC() {
      return (
        this.mediaItem.sourceType === enumTypes.INSTAGRAM_UGC ||
        this.mediaItem.sourceType === enumTypes.INSTAGRAM_STORY_UGC ||
        this.mediaItem.sourceType === enumTypes.INSTAGRAM_UGC_IGTV
      );
    },
    downloadFilename() {
      return this.resourceFilename || `download.${this.mediaResourceUrl.split('.').pop()}`;
    },
    hasIGCopyrightIssue() {
      return this.mediaItem?.postType === enumTypes.INSTAGRAM && this.mediaItem?.hasCopyrightIssue;
    },
    showPinterestBadge() {
      return this.mediaItem.sourceType === enumTypes.PINTEREST_OWNED;
    },
    hasLibraryAccess() {
      return this.authStore.guard(BRAND.LIBRARY.CAN_ACCESS_LIBRARY);
    },
    showFindSimilarButton() {
      return (
        (this.isImage || (this.isVideo && !this.showPinterestBadge)) &&
        !this.isFacebookText &&
        !this.isFacebookCompetitiveVideo &&
        this.hasLibraryAccess &&
        !this.isFacebookOtherText &&
        !this.isFacebookOtherTL
      );
    },
    showDownloadButton() {
      return (
        (!this.showPinterestBadge || this.isImage) &&
        !this.isFacebookCompetitiveVideo &&
        !this.isFacebookText &&
        !this.isFacebookOtherText &&
        !this.isFacebookOtherTL
      );
    },
    isFacebookText() {
      return this.mediaItem?.sourceType === enumTypes.FACEBOOK_TEXT;
    },
    isFacebookOtherText() {
      return this.mediaItem?.sourceType === enumTypes.FACEBOOK_OTHER_TEXT;
    },
    isFacebookOtherTL() {
      return this.mediaItem?.sourceType === enumTypes.FACEBOOK_OTHER_TL;
    },
    isFacebookCompetitiveVideo() {
      const isFacebookCompetitive = enumTypes.FACEBOOK_COMPETITIVE_SOURCE_LIST.includes(
        this.mediaItem.sourceType,
      );
      const isVideo = this.mediaItem.sourcePostType === 'VIDEO';
      return isFacebookCompetitive && isVideo;
    },
    mediaResourceUrl() {
      let mediaResourceUrl = '';
      if (
        this.isVideo &&
        this.mediaItem.originalUrl &&
        (this.mediaItem.originalUrl.includes('instagram.com') ||
          this.mediaItem.originalUrl.includes('fbcdn.net'))
      ) {
        mediaResourceUrl = this.mediaItem.videoUrl || this.mediaItem.urls.full;
      } else {
        mediaResourceUrl = this.resourceUrl;
      }
      return mediaResourceUrl;
    },
    mediaMaskTop() {
      return !this.videoDuration && !this.carousel && !this.isUploadedMedia && !this.isInstagramUGC;
    },
    mediaMaskBottom() {
      return (
        (this.videoDuration && !this.carousel && this.isUploadedMedia) ||
        (this.isInstagramUGC && !this.carousel && this.videoDuration)
      );
    },
    mediaMaskNone() {
      return (
        (!this.videoDuration && !this.carousel && this.isUploadedMedia) ||
        (this.isInstagramUGC && !this.videoDuration)
      );
    },
  },
  methods: {
    findSimilar() {
      const routeData = { name: 'library.index' };
      this.mediaDetailStore.setImageDataMediaType({
        mediaType: this.mediaItem.media_type ?? this.mediaItem.mediaType,
      });
      if (this.mediaItem.id) {
        // single media
        routeData.query = { media_id: this.mediaItem.id };
      } else {
        // carousel media
        routeData.query = { url: this.mediaItem.urls.ratio };
      }
      // use current brand's library when searching for similar media
      routeData.params = {
        brandLabel: this.authStore.currentBrand.label,
      };

      this.searchStore.setMediaSearchStatus({ status: true });
      this.$router.push(routeData);
      this.searchStore.clearMediaSearchList();
      this.trackingStore.track('Find Similar Clicked', {
        media: this.mediaItem,
        mediaType: this.mediaItem.isReel ? 'Reels Search' : `${this.mediaItem.mediaType} Search`,
        platform: this.mediaItem.postType,
        component: 'Media Popup',
      });
      this.searchStore.setCarouselState({ newCarouselState: this.carousel });
    },
    async onDownloadClick() {
      downloadFileFromUrl(this.mediaResourceUrl, this.downloadFilename);
      const properties = {
        mediaId: this.mediaItem.id,
        clickedFrom: 'Button',
        url: this.mediaResourceUrl,
        page: this.$route.meta.analytics,
      };
      this.trackingStore.track('Media Popup Download', properties);
      this.trackingStore.track('Report Downloaded', {
        ...properties,
        type: 'Media Popup Download',
      });
    },
    handleMediaItemClick() {
      if (this.isVideo) {
        if (
          this.showPinterestBadge ||
          this.isFacebookCompetitiveVideo ||
          this.hasIGCopyrightIssue
        ) {
          window.open(this.mediaItem.postUrl, '_blank');
          return;
        }
        if (this.videoPlaying) {
          this.videoPlaying = false;
          this.$refs.media.pause();
        } else {
          this.videoPlaying = true;
          if (this.firstVideoPlay) {
            this.firstVideoPlay = false;
          }
          this.$refs.media.play();
        }
      }
    },
    onVideoEnded() {
      this.videoPlaying = false;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.media-item {
  position: relative;
  height: fit-content;
  min-height: 18rem;

  img,
  video {
    height: auto;
    width: 100%;
    max-width: 100%;
    border-radius: var(--round-corner-small);
    position: relative;
    cursor: pointer;
  }

  .image-hover-view,
  .media-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: var(--round-corner-small);
  }

  .image-hover-view {
    height: 100%;
    background: rgb(0 0 0 / 30%);
    visibility: hidden;
    opacity: 0;
    transition: var(--transition-all);
    z-index: 2;

    .video-button {
      margin-bottom: var(--space-32);
      transition: var(--transition-all);

      &:hover {
        transform: var(--hover-move);
      }
    }

    button,
    a {
      width: 15.4rem;
      margin: var(--space-8) 0;
    }

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  .media-mask {
    background: var(--tile-bg-gradient);
    height: 100%;
    z-index: 2;

    &.media-mask--bottom {
      background: var(--tile-bg-gradient-bottom);
      bottom: 0;
      height: 50%;
      top: inherit;
    }

    &.media-mask--top {
      background: var(--tile-bg-gradient-top);
      height: 50%;
    }

    &.media-mask--none {
      background: transparent;
    }
  }

  .message-box {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    display: flex;
    width: 75%;
    background-color: var(--background-0);
    box-shadow: var(--shadow-1);
    border-radius: var(--round-corner-small);
    padding: var(--space-24);
    cursor: default;

    .svg-icon {
      margin-right: var(--space-14);
      padding-bottom: var(--space-14);
    }

    p {
      text-align: left;
      font-size: var(--x14);
      line-height: var(--space-20);
    }

    a {
      color: var(--action-500);
      font-weight: var(--font-medium);
      cursor: pointer;
    }
  }

  .video-duration {
    position: absolute;
    bottom: var(--space-14);
    right: var(--space-20);
    z-index: 3;

    .icon {
      position: relative;
      top: 3px;
    }

    .video-duration-text {
      padding-left: var(--space-4);
      color: var(--white);
      font-size: var(--x14);
      font-weight: var(--font-medium);
      line-height: var(--space-18);
    }
  }
}
</style>
