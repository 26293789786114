<script setup>
import { computed, ref, onMounted } from 'vue';
import dayjs from 'dayjs';
import MediaCardBase from '@/components/MediaCard/MediaCardBase.vue';
import Button from '@/components/foundation/Button.vue';
import Avatar from '@/components/foundation/Avatar.vue';
import Icon from '@/components/foundation/Icon.vue';
import { isMediaVideo } from '@/utils/media';
import { SOURCE_TO_METRIC_PROP } from '@/config';
import { colours, SOCIAL_CHANNEL } from '@/ux/colours';
import { formatVideoDuration } from '@/utils/formatters';
import LetterAvatar from '@/components/LetterAvatar.vue';
import enumtypes, { MEDIA_TYPE_OPTIONS } from '@/app/library/constants';
import { getNumbersFromString } from '@/utils/user';
import loadImage from '@/utils/image';
import { getUserTimezone } from '@/utils/timezone';
import { downloadImage } from '@/components/VisionAi/utils/tile-download';
import { IMAGE_DOWNLOAD_FILE_FORMATS } from '@/app/dashboards/constants';
import { useNotificationStore } from '@/stores/notification';

const props = defineProps({
  /**
   * Metrics to be shown by default in the stats slot, should be in the following format:
   * <code>
   * {
   *   "media_type": required,
   *   "source": required,
   *   "source_created_at": required,
   *   "source_creator_avatar": required,
   *   "source_creator_handle": required,
   *   "source_id": required,
   *   "urls": {
   *       "image_sizes": {
   *           "medium_square": required,
   *           "original_converted": required,
   *           "small": required,
   *           "small_square": required,
   *       },
   *  },
   *  "video_duration": required,
   *  },
   * </code>
   */
  media: { type: Object, default: () => {} },
  /**
   * Metrics to be shown by default in the stats slot, should be in the following format:
   * <code>
   * {
   *   key: optional,
   *   label: required,
   *   rawValue: optional,
   *   value: required,
   *   tooltip: optional,
   *   iconColor: optional
   *   highlighted: optional
   * }
   * </code>
   */
  metrics: { type: Array, default: () => [] },
  isDownloadable: { type: Boolean, default: false },
  hoverEffect: { type: Boolean, default: true },
});

const notificationStore = useNotificationStore();

const emit = defineEmits(['downloadButtonClicked']);

const mediaCardRef = ref(null);
const twitterBlue = ref(SOCIAL_CHANNEL.SC_TWITTER);
const defaultColour = ref(colours.BASIC.WHITE);
const showAvatar = ref(false);

const mediaId = computed(() => {
  return props.media?.sourceId;
});

const mediaType = computed(() => {
  return props.media?.mediaType;
});

const sourceType = computed(() => {
  return props.media?.source;
});

const sourceKey = computed(() => {
  return SOURCE_TO_METRIC_PROP[props.media?.source] || props.media?.source.toLowerCase();
});

const pictureUrl = computed(() => {
  return props.media?.urls?.imageSizes?.smallSquare?.url || props.media?.urls?.thumbs || '';
});

const timeStamp = computed(() => {
  if (!props.media?.sourceCreatedAt) {
    return null;
  }
  const timezone = getUserTimezone();
  const postDate = dayjs.utc(props.media.sourceCreatedAt).tz(timezone);
  const isCurrYear = postDate.year() === dayjs().tz(timezone).year();
  return postDate.format(`MMM D,${isCurrYear ? '' : ' YYYY'} h:mm A`);
});

const isVideo = computed(() => {
  return isMediaVideo(props.media);
});

const videoDuration = computed(() => {
  return formatVideoDuration(props.media?.videoDuration, props.media?.source);
});

const creatorHandle = computed(() => {
  return props.media?.sourceCreatorHandle;
});

const creatorAvatar = computed(() => {
  return props.media?.sourceCreatorAvatar;
});

const numberId = computed(() => {
  return getNumbersFromString(props.media?.sourceCreatorId);
});

const isTwitter = computed(() => {
  return sourceType.value === enumtypes.TWITTER;
});

const postCaption = computed(() => {
  return props.media?.caption;
});

const isTextPreview = computed(() => {
  return (
    isTwitter.value &&
    (mediaType.value === MEDIA_TYPE_OPTIONS.LINK.value ||
      mediaType.value === MEDIA_TYPE_OPTIONS.TEXT.value)
  );
});

const download = () => {
  if (mediaCardRef.value.$el) {
    emit('downloadButtonClicked');
    try {
      downloadImage(mediaCardRef.value.$el, IMAGE_DOWNLOAD_FILE_FORMATS.PNG.extension);
    } catch (err) {
      notificationStore.setToast({
        message: 'Failed to download widget image',
        type: 'error',
      });
    }
  }
};

onMounted(async () => {
  // Pre-loads avatar images
  try {
    const imageDecoded = await loadImage(creatorAvatar.value);
    if (imageDecoded) {
      showAvatar.value = true;
    }
  } catch (error) {
    showAvatar.value = false;
  }
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <MediaCardBase
    ref="mediaCardRef"
    :class="['media-card-base', { 'transform-none cursor-default': !props.hoverEffect }]"
    :media-id="mediaId"
    :picture-url="pictureUrl"
    :time-stamp="timeStamp"
    :metrics="metrics"
    disable-multi-select
    :is-downloadable="props.isDownloadable"
  >
    <template v-if="isTextPreview" #content>
      <header class="text-post-header">
        <div class="post-details">
          <Button
            v-if="props.isDownloadable"
            v-tooltip="'Download as .png'"
            class="image-download pacity-50 mr-3 hover:opacity-100"
            icon-name="download"
            icon-class="h-[1.125rem]"
            dismiss
            is-height-of-icon
            is-width-of-icon
            @click="download"
          />
          <Icon :name="sourceKey" small :color="twitterBlue" />
        </div>
        <div :class="['post-caption', { 'is-avatar-displayed': creatorAvatar }]">
          <p>{{ postCaption }}</p>
        </div>
        <Avatar
          v-if="showAvatar"
          v-bind="$attrs"
          class="creator-avatar"
          medium
          :img-src="creatorAvatar"
        />
        <LetterAvatar
          v-if="!showAvatar"
          class="creator-avatar"
          :name="creatorHandle"
          :account-id="numberId"
        />
      </header>
    </template>

    <template v-else #avatar>
      <Avatar
        v-if="showAvatar"
        v-bind="$attrs"
        class="creator-avatar"
        medium
        :img-src="creatorAvatar"
      />
      <LetterAvatar
        v-if="!showAvatar"
        class="creator-avatar"
        :name="creatorHandle"
        :account-id="numberId"
      />
    </template>
    <template #top-badges>
      <Button
        v-if="props.isDownloadable"
        v-tooltip="'Download as .png'"
        class="image-download mr-3"
        icon-name="download"
        icon-color="var(--white)"
        icon-class="h-[1.125rem]"
        dismiss
        is-height-of-icon
        is-width-of-icon
        @click="download"
      />
      <Icon :name="sourceKey" small :color="defaultColour" />
    </template>
    <template v-if="isVideo" #bottom-badges>
      <span class="video-duration-text">{{ videoDuration }}</span>
    </template>
    <template #overlay>
      <div v-if="$slots['visual-filter']" class="visual-filter">
        <slot name="visual-filter" />
      </div>
    </template>
  </MediaCardBase>
</template>

<style lang="postcss" scoped>
.text-post-header {
  background-color: var(--background-0);
  border-radius: var(--round-corner) var(--round-corner) 0 0;
  border-bottom: 1px solid var(--border);
  line-height: normal;
  display: flex;
  flex-direction: column;
  padding: var(--space-16) var(--space-12);
  position: relative;
  min-height: 9rem;
  flex-grow: 1;
  justify-content: center;

  .post-details {
    position: absolute;
    width: 100%;
    z-index: 4;
    top: 0;
    right: 0;
    padding: var(--space-8);
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  .post-caption {
    display: flex;
    align-items: center;
    height: 100%;

    &.is-avatar-displayed {
      max-height: 100%; /* cut too long text */
      height: 100%;
    }

    p {
      display: flex;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      overflow-wrap: break-word;
      vertical-align: middle;
    }
  }
}

.display-creator-handle {
  line-height: var(--x15);
  margin-bottom: 3%;
}

.align-right {
  margin-right: calc(-1 * var(--space-16));
}

.video-duration-text {
  color: var(--white);
  font-size: var(--x14);
  font-weight: var(--font-medium);
  line-height: var(--space-16);
}

.video-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.creator-avatar {
  border: 2px solid var(--white);
  bottom: -0.85rem;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  box-shadow: var(--shadow-1);
  z-index: var(--z-index-sticky);
}

.tweet-text {
  min-height: 282px;
}

.dh-letter-avatar {
  margin: 0;
}

.media-card-base {
  .visual-filter {
    border-top-left-radius: var(--round-corner);
    border-top-right-radius: var(--round-corner);
    border: var(--space-4) solid transparent;
    position: absolute;
    inset: 0;
    background: rgb(0 0 0 / 0%);
    opacity: 0;
    visibility: hidden;
    transition: var(--transition-all);

    :deep(.visual-filter-media-tile) {
      height: 100%;
    }
  }

  &:hover {
    .visual-filter {
      background: rgb(0 0 0 / 40%);
      opacity: 1;
      visibility: visible;
      border: none;
    }
  }
}
</style>
