<template>
  <Popup :close="close" type="xlarge" close-on-route-change data-cy="media-popup">
    <div v-if="showPrevArrow" class="nav-arrow prev-arrow" @click="previousArrowClicked">
      <!-- TODO: Use <Icon/> for this buttons; get SVG version of arrow -->
      <img ref="prevArrow" :src="IMG.arrowLeft" />
    </div>

    <div v-if="showNextArrow" class="nav-arrow next-arrow" @click="nextArrowClicked">
      <!-- TODO: Use <Icon/> for this buttons; get SVG version of arrow -->
      <img ref="nextArrow" :src="IMG.arrowRight" />
    </div>
    <main>
      <div v-if="mediaItem" class="media-main" data-cy="media-popup-content">
        <div class="left-container">
          <div
            class="media-item-container"
            @mouseover="mediaIsHovered = true"
            @mouseleave="mediaIsHovered = false"
          >
            <MediaPopupBadges
              v-if="isIgtv"
              class="media-badges-wrapper"
              igtv
              :media-item="mediaItem.media[0]"
            />
            <!-- Carousel media popup -->
            <MediaPopupBadges
              v-if="isCarousel"
              class="media-badges-wrapper"
              carousel
              :media-item="carouselMediaItems[0]"
            />
            <Carousel
              v-if="isCarousel"
              ref="carousel"
              :per-page="1"
              :pagination-color="paginationColor"
              :pagination-active-color="paginationActiveColor"
              :navigate-to="carouselPage"
              :navigation-enabled="true"
              :navigation-next-label="''"
              :navigation-prev-label="''"
              class="media-carousel"
            >
              <Slide
                v-for="(media, index) in carouselMediaItems"
                :key="index"
                :title="`Media ${index + 1}`"
              >
                <MediaItem
                  :id="`media-${index}`"
                  :media-item="media"
                  :show-promote="showPromote"
                  :image-hover-view-visible="mediaIsHovered"
                  carousel
                  @close="close"
                />
              </Slide>
            </Carousel>

            <TextPreviewMediaView v-else-if="isTextPreview" :media="mediaItem" />

            <LinkedinEmbed v-else-if="isLinkedinText" :media-item="mediaItem" />

            <YouTubeEmbed
              v-else-if="isYouTube"
              class="youtube-media-view"
              :media-item="mediaItem"
            />

            <TikTokEmbed v-else-if="isTikTokVideo" :media-item="mediaItem" />

            <!-- Single media popup -->
            <MediaItem
              v-else
              id="media-0"
              ref="mediaItem"
              :media-item="mediaItem"
              :show-promote="showPromote"
              :image-hover-view-visible="mediaIsHovered"
              @close="close"
            />
          </div>
          <section v-if="showTextPlaceHolder" class="info-text">
            <Icon :name="'info'" xsmall class="info-icon" />
            This Facebook post only included a caption, with no associated image, video, or link
            preview.
          </section>
          <section v-else-if="showLinkPlaceHolder" class="info-text">
            <Icon :name="'info'" xsmall class="info-icon" />
            The link preview image associated with this Facebook post is unavailable.
          </section>
          <Predictions v-if="showPredictions" :predictions="mediaItem.predictions" />
          <FBAdsDynamicInfo
            v-if="mediaItem.sourceType === enumTypes.FACEBOOK_ADS && mediaItem.adsInfo.adIsDynamic"
            :media-item="mediaItem"
          />

          <section v-if="showSimilarMedia" class="similar-media">
            <label>Similar Media</label>
            <CircularLoader v-if="searchStore.pending.similarMediaList" />
            <MediaLayout
              v-else
              :items="searchStore.formattedSimilarMediaList"
              :row-count="1"
              :row-height="160"
              :drag-select="{ enabled: false }"
            >
              <template #default="{ item, width, height }">
                <MediaTile
                  tile-type="similarMedia"
                  :item="item"
                  :width="width"
                  :height="height"
                  :show-duration="false"
                />
              </template>
            </MediaLayout>
          </section>
          <section v-if="isTikTokOwned" class="tiktok-video-views-graph">
            <MetricsChart
              :tooltip="tiktokVideoViewsToolTip"
              :metric-options="metricOptions"
              :loading="tiktokStore.pending.videoViews"
              chart-id="tiktok-video-views"
              :force-integer="true"
              large-size-chart
              title="Video Views"
            >
              <template #topRight>
                <div class="filter-group">
                  <div class="sort-select">
                    <Select
                      dropdown-label="Reporting Period"
                      :options="dateOptions"
                      :default-option="1"
                      placeholder="Last 7 Days"
                      :date-range-value="dateRange"
                      type="calendar"
                      filter-group
                      class="date-filter"
                      @selected="setDateRange"
                    />
                  </div>

                  <div class="sort-select">
                    <div>
                      <Select
                        dropdown-label="View Graph"
                        :model-value="graphScale"
                        :options="scaleOptions"
                        :default-option="0"
                        data-cy="GraphScaleSelect"
                        @selected="updateGraphScale"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <LineChart
                :chart-data="tikTokVideoViews"
                :tooltip-data-array="videoViewTooltips"
                :loading="tiktokStore.pending.videoViews"
                chart-id="tiktok-video-views"
                :force-integer="true"
                large-size-chart
                style="min-height: 200px"
              />
            </MetricsChart>
          </section>
        </div>

        <div class="right-container">
          <Banner v-if="skewedIGStoryReach.show" alert-type="information" class="mb-8">
            {{ skewedIGStoryReach.copy }}
          </Banner>
          <MediaActionButtons
            :media-item="mediaItem"
            :show-add-to-gallery-control="!isHashtagMonitor"
            @pause-video="pauseVideo"
            @button-clicked="sendMixpanelInteractionEvent"
          />
          <Banner
            v-if="metricWarningMessage"
            class="metric-warning-message mb-8"
            alert-type="danger"
          >
            {{ metricWarningMessage }}
          </Banner>
          <!-- uploaded media has its own way to show uploader info -->
          <UserSection
            v-if="showUserSection"
            :media-item="mediaItem"
            class="user-info"
            :allow-expanding-message="!isYouTube"
          />
          <MediaPopupSentiment
            v-if="showOverallSentiment && showSentiment"
            :media-item="mediaItem"
            @sentiment-changed="handleSentimentChanged"
          />
          <UploadInformation v-if="mediaItem.postType === enumTypes.UPLOAD" />
          <IGInsights
            v-if="mediaItem.postType === enumTypes.INSTAGRAM && !mediaItem.isReel"
            :media-item="mediaItem"
            :is-hashtag-monitor="isHashtagMonitor"
            class="insights-dropdown"
            @update-popup-status="updatePopupStatus"
          />
          <ReelsInsights
            v-if="mediaItem.postType === enumTypes.INSTAGRAM && mediaItem.isReel"
            :media-item="mediaItem"
            class="insights-dropdown"
          />
          <PinterestInsights
            v-if="mediaItem.postType === enumTypes.PINTEREST"
            :media-item="mediaItem"
            class="insights-dropdown"
          />
          <TikTokInsights
            v-if="isTikTok"
            :media-item="mediaItem"
            class="insights-dropdown"
            @toggle-insights="sendMixpanelInteractionEvent"
          />
          <TikTokAudienceSection
            v-if="isTikTokOwned"
            v-model="audienceSectionTab"
            :media-item="mediaItem"
            @tab-changed="sendMixpanelInteractionEvent"
          />
          <IGStoriesInsights
            v-if="mediaItem.postType === enumTypes.INSTAGRAM_STORY"
            :media-item="mediaItem"
            class="insights-dropdown"
          />

          <FBAdsInsights
            v-if="mediaItem.sourceType === enumTypes.FACEBOOK_ADS"
            :media-item="mediaItem"
            class="insights-dropdown"
            @date-range-updated="adStatInsightRangeUpdated"
          />

          <FacebookInsights
            v-if="enumTypes.FACEBOOK_SOURCE_LIST.includes(mediaItem.sourceType)"
            :media-item="mediaItem"
            class="insights-dropdown"
          />

          <TwitterInsights
            v-if="enumTypes.TWITTER_SOURCE_LIST.includes(mediaItem.sourceType)"
            :media-item="mediaItem"
            class="insights-dropdown"
          />

          <LikeShopInsights
            v-if="showLikeShopStats"
            :media-item="mediaItem"
            class="insights-dropdown"
          />

          <YouTubeInsights v-if="isYouTube" :media-item="mediaItem" class="insights-dropdown" />

          <LinkedinInsights v-if="isLinkedin" :media-item="mediaItem" class="insights-dropdown" />

          <YouTubeAudienceSection
            v-if="isYouTubeOwned"
            v-model="audienceSectionTab"
            :media-item="mediaItem"
            @tab-changed="sendMixpanelInteractionEvent"
          />

          <ContentRights
            v-if="showRequestRightsButton"
            :media-item="mediaItem"
            :content-rights-handle="contentRightsHandle"
            :rights-request-media="mediaItem.rightsRequestMedia"
          />

          <div v-if="showRequestCommentsPopup" class="request-comments-popup">
            <ContentRightsCommentsPopup
              :hide-request-comments-popup="hideRequestCommentsPopup"
              :post-id="mediaItem.postSourceId"
              @update-popup-status="updatePopupStatus"
            />
          </div>

          <RequestErrorPopup
            v-if="showRequestErrorPopup"
            :media-post-url="mediaItem.postUrl"
            :hide-request-error-popup="hideRequestErrorPopup"
          />

          <RequestSuccessPopup
            v-if="showRequestSuccessPopup"
            :hide-request-success-popup="hideRequestSuccessPopup"
          />

          <CommentErrorPopup
            v-if="showCommentErrorPopup"
            :media-post-url="mediaItem.postUrl"
            :hide-comment-error-popup="hideCommentErrorPopup"
          />

          <MessagePopup
            v-if="showNotBrandPermissionPopup"
            :hide-message-popup="hideNotBrandPermissionPopup"
            :click-handle="contactClick"
            title="Unable to Comment"
            message="Looks like UGC commenting isn't included in your plan! <br/>Contact us to try it out."
            button-text="Contact Us"
          />

          <MessagePopup
            v-if="showNotUserPermissionPopup"
            :hide-message-popup="hideNotUserPermissionPopup"
            :click-handle="hideNotUserPermissionPopup"
            title="Unable to Comment"
            message="Looks like you don't have UGC commenting<br/>permissions! Contact your team to get access."
            button-text="Got it"
          />

          <template v-if="showMediaContentTags">
            <MediaContentTags
              v-if="showMediaContentTags"
              :brand-ids="[originalMediaItem.brandId]"
              :brand-media-id="originalMediaItem.id"
              :media-content-tags="originalMediaItem.contentTags"
              @update="originalMediaItem.contentTags = $event"
              @added-content-tag="onContentTagAdd($event)"
              @removed-content-tag="onContentTagRemove($event)"
            />
          </template>

          <AutomationsTriggered
            v-if="canAccessContentAutomations"
            :automations="mediaItem.triggeredContentAutomations"
            @dropdown-open="sendMixpanelInteractionEvent"
          />

          <!-- We want everyone see vision tags -->
          <VisualTags
            v-if="isAllowedSourceType"
            :custom-tags="mediaItem.tags"
            :system-tags="mediaItem.systemTags"
            @tags-changed="sendMixpanelInteractionEvent"
          />

          <MediaSources
            v-if="isAllowedSourceType"
            :sources="searchStore.mediaSourceList.data"
            @dropdown-open="sendMixpanelInteractionEvent"
          />
        </div>
      </div>
      <CircularLoader v-else-if="isPending" class="loading" />
      <PageNotFound v-else-if="mediaDetailStore.mediaNotFound" class="errors" />
      <ErrorPanel v-else class="errors" />
      <template v-if="mediaDetailStore.mediaDetail">
        <MediaLinks v-if="showMediaLinksPopup" context="mediaPopup" />
      </template>
    </main>
  </Popup>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import humps from 'humps';
import { useContentTagsStore } from '@/stores/content-tags';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { SKEWED_IG_STORY_REACH_MESSAGE, toolTips } from '@/config';
import { colours } from '@/ux/colours';
import globalModalsMixin from '@/mixins/globalModalsMixin';
import enumTypes, {
  audienceSectionTabs,
  linkedinMediaTypes,
  allowedMediaTypes,
} from '@/app/library/constants';
import { MediaPopupUserEventTracker } from '@/app/library/mixpanel';
import ContentRights from '@/app/library/components/MediaPopup/ContentRights.vue';
import RequestSuccessPopup from '@/app/instagram/components/RequestComments/RequestSuccessPopup.vue';
import RequestErrorPopup from '@/app/instagram/components/RequestComments/RequestErrorPopup.vue';
import ContentRightsCommentsPopup from '@/app/instagram/components/RequestComments/ContentRightsCommentsPopup.vue';
import Icon from '@/components/foundation/Icon.vue';
import IGInsights from '@/app/library/components/MediaPopup/IGInsights.vue';
import IGStoriesInsights from '@/app/library/components/MediaPopup/IGStoriesInsights.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';
import FBAdsInsights from '@/app/library/components/MediaPopup/FBAdsInsights.vue';
import MediaActionButtons from '@/app/library/components/MediaPopup/MediaActionButtons.vue';
import MediaItem from '@/app/library/components/MediaPopup/MediaItem.vue';
import MediaSources from '@/app/library/components/MediaPopup/MediaSources.vue';
import MessagePopup from '@/components/MessagePopup.vue';
import PinterestInsights from '@/app/library/components/MediaPopup/PinterestInsights.vue';
import Popup from '@/components/Popup.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import MediaLinks from '@/app/library/components/MediaLinks.vue';
import VisualTags from '@/app/library/components/MediaPopup/VisualTags.vue';
import MediaContentTags from '@/components/ContentTags/MediaContentTags.vue';
import UploadInformation from '@/app/library/components/MediaPopup/UploadInformation.vue';
import CommentErrorPopup from '@/app/library/components/MediaPopup/CommentErrorPopup.vue';
import AutomationsTriggered from '@/app/library/components/MediaPopup/AutomationsTriggered.vue';
import { getMediaTypeFromDetail } from '@/utils/media';
import FacebookInsights from '@/app/library/components/MediaPopup/FacebookInsights.vue';
import FBAdsDynamicInfo from '@/app/library/components/MediaPopup/FBAdsDynamicInfo.vue';
import Predictions from '@/app/library/components/MediaPopup/Predictions.vue';
import UserSection from '@/app/library/components/MediaPopup/UserSection.vue';
import TwitterInsights from '@/app/library/components/MediaPopup/TwitterInsights.vue';
import LikeShopInsights from '@/app/library/components/MediaPopup/LikeShopInsights.vue';
import TextPreviewMediaView from '@/app/library/components/MediaPopup/TextPreviewMediaView.vue';
import MediaPopupBadges from '@/components/MediaBadges/MediaPopupBadges.vue';
import MediaLayout from '@/components/core/layout/MediaLayout.vue';
import MediaTile from '@/components/MediaTile.vue';
import TikTokInsights from '@/app/library/components/MediaPopup/TikTokInsights.vue';
import MetricsChart from '@/components/Metrics/MetricsChart.vue';
import LineChart from '@/components/foundation/charts/LineChart.vue';
import Select from '@/components/Select.vue';
import TikTokAudienceSection from '@/app/tiktok/components/TikTokAudienceSection.vue';
import TikTokEmbed from '@/app/library/components/MediaPopup/TikTokEmbed.vue';
import YouTubeEmbed from '@/app/library/components/MediaPopup/YouTubeEmbed.vue';
import YouTubeInsights from '@/app/library/components/MediaPopup/YouTubeInsights.vue';
import YouTubeAudienceSection from '@/app/youtube/components/YouTubeAudienceSection.vue';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import ReelsInsights from '@/app/library/components/MediaPopup/ReelsInsights.vue';
import { usePlatformStore } from '@/stores/platform';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useMediaLinksStore } from '@/stores/media-links';
import { useSearchStore } from '@/stores/search';
import { useMediaPopupStore } from '@/stores/media-popup';
import { useTiktokStore } from '@/stores/tiktok';
import { useMediaDetailStore } from '@/stores/media-detail';
import MediaPopupSentiment from '@/app/library/components/MediaPopup/MediaPopupSentiment.vue';
import { useFlagStore } from '@/stores/flag';
import { showPredictions } from '@/utils/vision';
import { useSupportChatStore } from '@/stores/support-chat';
import { useInstagramAccountStore } from '@/stores/instagram-account';
import { usePopupGalleryStore } from '@/stores/popup-gallery';
import { mediaSupportsContentTags } from '@/components/ContentTags/content-tag-utils';

import competitiveConstants from '@/app/competitive/constants';
import { useSchedulerStore } from '@/stores/scheduler';
import ErrorPanel from '@/components/Panel/ErrorPanel.vue';
import PageNotFound from '@/components/PageNotFound.vue';
import { useTrackingStore } from '@/stores/tracking';
import {
  CONTENT_TAG_ADDED_METHOD,
  CONTENT_TAG_MEDIA_ACTIONS,
  CONTENT_TAGS_EVENT_NAMES,
} from '@/app/settings/constants';
import { tiktokUGCMetricErrors } from '@/app/tiktok/constants';
import { IMG } from '@/assets';
import LinkedinInsights from '@/app/library/components/MediaPopup/LinkedinInsights.vue';
import LinkedinEmbed from '@/app/library/components/MediaPopup/LinkedinEmbed.vue';
import { Carousel, Slide } from '@/vendor/vue-carousel';
import { useVisionAiStore } from '@/stores/vision-ai';
import { useContentAutomationPermissions } from '@/app/settings/composables/content-automation-permissions';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaPopup',
  components: {
    YouTubeAudienceSection,
    YouTubeEmbed,
    TikTokEmbed,
    LinkedinEmbed,
    Select,
    MetricsChart,
    LineChart,
    TikTokAudienceSection,
    Carousel,
    CircularLoader,
    CommentErrorPopup,
    AutomationsTriggered,
    ContentRights,
    ContentRightsCommentsPopup,
    FacebookInsights,
    FBAdsDynamicInfo,
    FBAdsInsights,
    Icon,
    IGInsights,
    IGStoriesInsights,
    Banner,
    MediaActionButtons,
    MediaPopupBadges,
    MediaItem,
    MediaLinks,
    MediaLayout,
    MediaTile,
    MediaSources,
    MessagePopup,
    PinterestInsights,
    Popup,
    Predictions,
    ReelsInsights,
    RequestErrorPopup,
    RequestSuccessPopup,
    Slide,
    VisualTags,
    MediaContentTags,
    TextPreviewMediaView,
    TwitterInsights,
    LikeShopInsights,
    LinkedinInsights,
    UploadInformation,
    UserSection,
    TikTokInsights,
    YouTubeInsights,
    MediaPopupSentiment,
    PageNotFound,
    ErrorPanel,
  },
  mixins: [globalModalsMixin],
  props: {
    showPromote: { type: Boolean, default: false },
    isHashtagMonitor: { type: Boolean, default: false },
    id: { type: [Number, String], default: null },
    brandId: { type: [Number, String], default: null },
    brandLabel: { type: String, default: null },
    adId: { type: Number, default: null },
    insightsStart: { type: String, default: null },
    insightsEnd: { type: String, default: null },
    source: { type: String, default: null },
    patchJson: { type: String, default: null },
  },
  setup() {
    const { canAccessContentAutomations } = useContentAutomationPermissions();

    return {
      canAccessContentAutomations,
    };
  },
  data() {
    return {
      mediaIsHovered: false,
      enumTypes,
      currentMediaIndex: null,
      // carousel settings
      carouselPage: 0,
      paginationColor: colours.TEXT.TEXT_SECONDARY,
      paginationActiveColor: colours.BASIC.WHITE,
      showRequestCommentsPopup: false,
      showRequestErrorPopup: false,
      showRequestSuccessPopup: false,
      showCommentErrorPopup: false,
      showNotUserPermissionPopup: false,
      showNotBrandPermissionPopup: false,
      loadedFromPreviousArrow: false,
      loadedFromNextArrow: false,
      tikTokVideoViews: {
        labels: [],
        values: [],
      },
      metricOptions: [{ label: 'Video Views', value: 'VIDEO_VIEWS' }],
      dateOptions: [
        { label: 'Last 3 Days', value: 2 },
        { label: 'Last 7 Days', value: 6 },
        { label: 'Last 4 Weeks', value: 27 },
      ],
      scaleOptions: [
        { label: 'By Day', value: enumTypes.DAILY },
        { label: 'By Month', value: enumTypes.MONTHLY },
      ],
      graphScale: enumTypes.DAILY,
      dateRange: null,
      audienceSectionTab: audienceSectionTabs.TRAFFIC_SOURCE,
      route: null,
      origin: null,
      userEventTracker: new MediaPopupUserEventTracker(),
      hasUpdatedSentiment: false,
    };
  },
  computed: {
    ...mapStores(
      useAuthStore,
      useContentTagsStore,
      useNotificationStore,
      usePlatformStore,
      useMediaLinksStore,
      useSearchStore,
      useMediaPopupStore,
      useMediaDetailStore,
      useTiktokStore,
      useFlagStore,
      useSupportChatStore,
      useInstagramAccountStore,
      usePopupGalleryStore,
      useSchedulerStore,
      useTrackingStore,
      useVisionAiStore,
    ),
    IMG() {
      return IMG;
    },
    mediaItem() {
      return this.mediaDetailStore.mediaDetail;
    },
    originalMediaItem() {
      return this.mediaDetailStore.mediaDetail?._original ?? {};
    },
    carouselMediaItems() {
      return this.mediaItem?.fallbackCarouselMedia ?? this.mediaItem?.media;
    },
    isPending() {
      return this.mediaDetailStore.mediaDetailRequestStatus === 'pending' || this.pending;
    },
    showLikeShopStats() {
      if (![enumTypes.EDITOR, enumTypes.UPLOAD].includes(this.mediaItem.postType)) {
        return false;
      }

      // the click value could be numeric if the media is in LikeShop, or null if not.
      return (
        Number.isInteger(this.mediaItem.likeshopClicks) ||
        Number.isInteger(this.mediaItem.tiktokLikeshopClicks)
      );
    },
    showPrevArrow() {
      if (this.mediaPopupStore.contextList) {
        return this.currentMediaIndex > 0 && !this.mediaLinksStore.showLinkPopup;
      }
      return false;
    },
    showNextArrow() {
      if (this.mediaPopupStore.contextList) {
        return (
          (this.currentMediaIndex < this.mediaPopupStore.contextList.length - 1 &&
            !this.mediaLinksStore.showLinkPopup &&
            this.currentMediaIndex > 0) ||
          (this.currentMediaIndex === 0 && this.mediaPopupStore.contextList.length !== 1)
        );
      }
      return false;
    },
    isCarousel() {
      return this.mediaDetailStore.mediaDetail?.media?.length > 1;
    },
    isIgtv() {
      return this.mediaSource === enumTypes.INSTAGRAM_OWNED_IGTV;
    },
    isTextPreview() {
      const textPreviewSources = [
        enumTypes.TWITTER_OWNED_LINK,
        enumTypes.TWITTER_OWNED_TEXT,
        enumTypes.TWITTER_OTHER_LINK,
        enumTypes.TWITTER_OTHER_TEXT,
      ];
      return textPreviewSources.includes(this.mediaItem.sourceType);
    },
    isTikTok() {
      return this.mediaItem?.postType === enumTypes.TIKTOK;
    },
    isTikTokVideo() {
      // checking insights.videoDuration > 0 is a hack to differentiate between image and video posts
      return (
        this.mediaItem?.postType === enumTypes.TIKTOK && this.mediaItem?.insights?.videoDuration > 0
      );
    },
    isTikTokOwned() {
      return this.mediaItem?.sourceType === enumTypes.TIKTOK_OWNED;
    },
    isTikTokUgc() {
      return this.mediaItem?.sourceType === enumTypes.TIKTOK_UGC;
    },
    isYouTube() {
      return this.mediaItem?.postType === enumTypes.YOUTUBE;
    },
    isLinkedin() {
      return this.mediaItem?.postType === enumTypes.LINKEDIN;
    },
    isLinkedinText() {
      return this.isLinkedin && this.mediaItem?.mediaType === linkedinMediaTypes.TEXT;
    },
    isYouTubeOwned() {
      return this.mediaItem?.sourceType === enumTypes.YOUTUBE_OWNED;
    },
    showUserSection() {
      return (
        (this.hasUsername || this.isTikTokUgc) &&
        this.mediaItem.sourceType !== enumTypes.UPLOADED &&
        this.mediaItem.sourceType !== enumTypes.FACEBOOK_ADS &&
        !(this.isHashtagMonitor && this.mediaItem.sourceType === enumTypes.INSTAGRAM_OTHER)
      );
    },
    showPredictions() {
      return showPredictions(this.mediaItem, this.$router);
    },
    hasUGCSentimentFlag() {
      return this.flagStore.ready && this.flagStore.flags.ugcSentiment;
    },
    showOverallSentiment() {
      const { postType, sourceType } = this.mediaItem;
      const disallowedPostTypes = [enumTypes.PINTEREST, enumTypes.LINKEDIN];
      const disallowedSourceTypes = [
        enumTypes.INSTAGRAM_STORY,
        enumTypes.UPLOADED,
        enumTypes.TIKTOK_UGC,
      ];
      const hasDisallowedPostType = disallowedPostTypes.includes(postType);
      const hasDisallowedSourceType = disallowedSourceTypes.includes(sourceType);
      const hasCompetitiveSourceType = enumTypes.COMPETITIVE_LIST.includes(sourceType);

      return (
        !hasDisallowedPostType &&
        !hasDisallowedSourceType &&
        !hasCompetitiveSourceType &&
        this.hasUGCSentimentFlag &&
        this.canAccessSentiment
      );
    },
    mediaSource() {
      const sourceType = this.mediaItem?.sourceType?.split(':');
      return sourceType ? sourceType[sourceType.length - 1] : this.mediaItem?.source;
    },
    hasUsername() {
      return (this.mediaItem.firstName && this.mediaItem.lastName) || this.mediaItem.userName;
    },
    hasVisionAccess() {
      return this.authStore.user_can('vision', 'can_access_vision');
    },
    showRequestRightsButton() {
      return (
        this.mediaItem.postType === enumTypes.INSTAGRAM &&
        this.mediaItem.sourceType !== enumTypes.INSTAGRAM_OWNED &&
        this.mediaItem.sourceType !== enumTypes.INSTAGRAM_OWNED_IGTV &&
        this.hasRequestRightsAccess
      );
    },
    canAccessSentiment() {
      return this.authStore.guard(BRAND.GENERAL.CAN_ACCESS_SENTIMENT);
    },
    hasRequestRightsAccess() {
      return (
        this.authStore.guard(BRAND.INSTAGRAM.CAN_ACCESS_RIGHTS_REQUESTS) &&
        this.authStore.guard(USER.INSTAGRAM.CAN_ACCESS_RIGHTS_REQUESTS)
      );
    },
    showSimilarMedia() {
      return (
        this.searchStore.similarMediaList.length && this.isAllowedSourceType && !this.isTikTokUgc
      );
    },
    showTextPlaceHolder() {
      return this.mediaItem?.sourceType === enumTypes.FACEBOOK_TEXT;
    },
    showLinkPlaceHolder() {
      return (
        !this.searchStore.similarMediaList.length &&
        enumTypes.FACEBOOK_LINK_LIST.includes(this.mediaItem.sourceType)
      );
    },
    /**
     * Add media types to allowed list. The previous solution with the computed `isExcludedSourceType` property,
     * caused some issues with new media types which indirectly displayed an unwanted section in the global popup.
     */
    isAllowedSourceType() {
      if (this.mediaItem.sourceType === enumTypes.LINKEDIN_OWNED) {
        const allowedLinkedinMediaTypes = [
          linkedinMediaTypes.IMAGE,
          linkedinMediaTypes.MULTI_IMAGE,
          linkedinMediaTypes.VIDEO,
        ];
        return allowedLinkedinMediaTypes.includes(this.mediaItem.mediaType);
      }
      return allowedMediaTypes.includes(this.mediaItem.sourceType);
    },
    skewedIGStoryReach() {
      // There's a known error with reach sometimes being reported as higher than impressions
      // for IG story insights by the FB API so we're going to show some custom messaging to
      // make sure the users know it's them & not us
      return {
        show:
          this.mediaItem?.sourceType === enumTypes.INSTAGRAM_STORY &&
          this.mediaItem?.insights?.reach > this.mediaItem?.insights?.impressions,
        copy: SKEWED_IG_STORY_REACH_MESSAGE,
      };
    },
    showMediaLinksPopup() {
      const isSchedulerPopupOpen = this.globalModals.some(
        (modal) => modal.component.name === 'ScheduledPostPopup',
      );
      return (
        this.mediaLinksStore.showLinkPopup &&
        !this.schedulerStore.popupType &&
        !isSchedulerPopupOpen
      );
    },
    tiktokVideoViewsToolTip() {
      return toolTips.tiktok.metrics.single_post_video_views;
    },
    videoViewTooltips() {
      const tooltipData = [];
      Object.entries(this.tikTokVideoViewObject).forEach((data) => {
        const [label, value] = data;
        tooltipData.push({
          tooltip_label:
            this.graphScale === enumTypes.DAILY
              ? dayjs(label).format('MMM DD')
              : dayjs(label).format('MMM'),
          Views: value === null ? '-' : value,
        });
      });
      return tooltipData;
    },
    tikTokVideoViewObject() {
      const videoViews = {};
      const scale = this.graphScale === enumTypes.DAILY ? 'days' : 'months';
      const format = this.graphScale === enumTypes.DAILY ? 'YYYY-MM-DD' : 'YYYY-MM';
      const startDate =
        this.graphScale === enumTypes.DAILY
          ? this.dateRange[0]
          : dayjs(this.dateRange[0]).startOf('month');
      const endDate =
        this.graphScale === enumTypes.DAILY
          ? this.dateRange[1]
          : dayjs(this.dateRange[1]).startOf('month');
      const numOfLabels = dayjs(endDate).diff(dayjs(startDate), scale) + 1;

      const labels = Array.from({ length: numOfLabels }, (value, index) => {
        return dayjs(this.dateRange[0]).add(index, scale).format(format);
      });

      labels.forEach((label) => {
        if (this.tiktokStore.videoViews?.[label] || this.tiktokStore.videoViews?.[label] >= 0) {
          videoViews[label] = this.tiktokStore.videoViews[label];
        } else {
          videoViews[label] = null;
        }
      });
      return videoViews;
    },
    routeQuery() {
      const queryParams = {
        popup_scale: this.graphScale,
        audience_section_tab: this.audienceSectionTab,
      };
      if (this.dateRange && this.dateRange.length === 2) {
        [queryParams.popup_start_date, queryParams.popup_end_date] = this.dateRange;
      }
      return queryParams;
    },
    brandIdForRequests() {
      // always use the ID of the brand that owns the media whenever possible
      return this.brandId ?? this.authStore.currentBrand.id;
    },
    showMediaContentTags() {
      return mediaSupportsContentTags(this.mediaItem);
    },
    metricWarningMessage() {
      if (this.flagStore.flags.tiktokUgc && this.isTikTokUgc) {
        const currentDate = dayjs();
        const creationDate = dayjs(this.mediaItem.datePosted);
        const lastUpdated = dayjs(this.mediaItem.insights?.lastMetricsUpdatedAt);

        if (currentDate.diff(creationDate, 'days') > 90) {
          return tiktokUGCMetricErrors.TOO_OLD;
        }
        if (currentDate.diff(lastUpdated, 'hours') > 48) {
          return tiktokUGCMetricErrors.NO_LONGER_RELEVANT;
        }
      }
      return null;
    },
    zIndex() {
      return this.visionAiStore.visionAiChatWindowOpen ? 'notification' : 'popup';
    },
    showSentiment() {
      const show = this.flagStore.ready && this.flagStore.flags.youtubeCompliance;
      return !this.isYouTubeOwned || show;
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(to) {
        if (to) {
          if (this.$route.name !== 'instagram.media') {
            this.mediaDetailStore.getMediaDetail({
              brandId: this.brandId,
              mediaId: to,
              source: this.source,
              patchJson: this.patchJson,
              adId: this.adId,
              insightsStart: this.insightsStart,
              insightsEnd: this.insightsEnd,
            });
          }
        }
        this.pending = false;
      },
    },
    'mediaDetailStore.mediaTags': function mediaTagsWatcher(to) {
      this.mediaDetailStore.mediaDetail.tags = to;
    },
    'mediaDetailStore.mediaDetail': function mediaDetailWatcher(to) {
      this.mediaLinksStore.clearMediaLinks();
      if (to) {
        if (this.isAllowedSourceType) {
          this.fetchSimilarMedia(to.id, to.mediaGroupId);
          if (to.mediaGroupId) {
            this.fetchMediaSources(to.mediaGroupId);
          }
        }
        if (this.currentMediaIndex === null && this.mediaPopupStore.contextList !== null) {
          // init once here, and then update whenever next/prev is clicked,
          // in that way, there won't be a moment that currentIndex is 0 each switch due to mediaDetail pending
          // and therefore we won't see prev button flashing all the time.

          const findIndexFunc = function findIndexFunc(item) {
            if (item.id) {
              return item.id === to.id || item.id === to.postSourceId;
            }
            return item.sourceId === to.postSourceId;
          };
          this.currentMediaIndex = this.mediaPopupStore.contextList.findIndex(findIndexFunc);
        }
        if (to.postType === enumTypes.INSTAGRAM) {
          this.getInstagramAccount();
        }
        let howPopupOpen = 'Click on Post';
        if (this.loadedFromPreviousArrow) {
          howPopupOpen = 'Left Arrow';
        } else if (this.loadedFromNextArrow) {
          howPopupOpen = 'Right Arrow';
        }

        // competitive section
        let competitivePlatform;
        if (this.$route?.meta?.socialMediaType && this.$route.name.startsWith('competitors')) {
          competitivePlatform =
            competitiveConstants.toolTips[this.$route.meta.socialMediaType].platformName;
        }

        this.userEventTracker.popupOpened(to, this.$route, howPopupOpen, {
          platform: competitivePlatform,
        });
        this.loadedFromPreviousArrow = false;
        this.loadedFromNextArrow = false;
      }
    },
    'mediaDetailStore.mediaDetailRequestStatus': function mediaDetailRequestStatusWatcher(to) {
      if (to === 'success' && !this.mediaDetailStore.mediaDetail) {
        if (this.mediaDetailStore.showMediaPopup) {
          this.mediaDetailStore.closeMediaPopup();
        }
        this.$router.replace('/not_found');
      }
    },
    'mediaLinksStore.linkUpdateStatus': function linkUpdateStatusWatcher(to) {
      if (to === 'success' && this.mediaLinksStore.linkUpdateSource !== 'schedulerPopup') {
        this.notificationStore.setToast({ message: 'Media links updated.' });
      }
    },
    'mediaDetailStore.pending.deleteMediaItemStatus': function deleteMediaItemStatusWatcher(to) {
      if (!to) {
        setTimeout(() => {
          if (this.$route.name === 'library.index') {
            this.searchStore.clearMediaSearchList();
          } else if (this.$route.name === 'library.galleries.board') {
            this.popupGalleryStore.clearGalleryMedia();
          }
          this.notificationStore.setToast({ message: this.getDeleteNotificationMessage() });
          this.close();
        }, 1000);
      }
    },
    'mediaDetailStore.error.deleteMediaItemStatus': function deleteMediaItemErrorWatcher(to) {
      if (to) {
        this.notificationStore.setToast({
          message: 'Something went wrong, please try again.',
          type: 'warning',
        });
      }
    },
    'authStore.currentBrand': {
      handler(to) {
        if (to) {
          if (this.mediaItem && this.mediaItem.postType === enumTypes.INSTAGRAM) {
            this.instagramAccountStore.clearBrandInstagramAccount();
            this.getInstagramAccount();
          }
        }
      },
    },
    mediaItem() {
      if (this.mediaItem?.postSourceId && this.isTikTok) {
        this.fetchTikTokVideoViews();
        this.updateRouteQuery();
      }
    },
    'tiktokStore.videoViews': {
      handler() {
        this.formatTikTokVideoViews();
      },
    },
    audienceSectionTab() {
      this.updateRouteQuery();
    },
  },
  beforeMount() {
    this.pending = true;
  },
  created() {
    this.loadParamsFromQuery();
    if (!this.platformStore.platformConnectionsMap) {
      this.platformStore.getPlatformConnections();
    }
  },
  mounted() {
    window.addEventListener('keydown', this.arrowKeySwitchHandler);
  },
  unmounted() {
    this.mediaDetailStore.clearMediaDetail();
    this.searchStore.clearMediaSourceList();
    window.removeEventListener('keydown', this.arrowKeySwitchHandler);
  },
  methods: {
    updateRouteQuery() {
      if (this.isTikTokOwned) {
        this.$router.replace({
          name: 'library.media',
          params: {
            brandLabel: this.brandLabel,
            id: this.id,
            brandId: this.brandId,
          },
          query: {
            replaceModal: true,
            ...this.routeQuery,
          },
        });
      }
    },
    loadParamsFromQuery() {
      const queryFilters = { ...humps.camelizeKeys(this.$route.query) };
      const { popupStartDate, popupEndDate, popupScale, audienceSectionTab, route } = queryFilters;

      if (popupScale) {
        this.graphScale = popupScale;
      }

      if (audienceSectionTab) {
        this.audienceSectionTab = audienceSectionTab;
      }

      if (route) {
        this.route = route;
      }

      // call the setDateRange method last as it calls fetchVideoViews
      if (popupStartDate && popupEndDate) {
        this.setDateRange([popupStartDate, popupEndDate]);
      } else {
        this.setDateRange([
          dayjs(new Date()).subtract(6, 'days').format('YYYY-MM-DD'),
          dayjs(new Date()).format('YYYY-MM-DD'),
        ]);
      }
    },
    close() {
      if (this.mediaDetailStore.showMediaPopup) {
        this.mediaDetailStore.closeMediaPopup();
      } else if (this.route) {
        this.$router.push({ name: this.route });
      } else if (this.hasUpdatedSentiment) {
        // This is intended to solve the issue of MediaCard sentiment not updating in real time
        // It refreshes the initial page when closing only if sentiment has been manually changed
        window.location.href = this.$router.resolve(this.$route).href;
      } else {
        this.$router.back();
      }
    },
    contentRightsHandle() {
      const platformConnections = this.platformStore.platformConnectionsMap.facebook;
      if (!platformConnections || !platformConnections[this.brandIdForRequests]) {
        this.platformStore.connect(PLATFORM_CONNECTION.FACEBOOK, {
          title: 'Connect Facebook',
          message: 'To enable content rights requests, please connect your Facebook account.',
        });
      } else {
        this.showRequestCommentsPopup = true;
      }
    },
    getDeleteNotificationMessage() {
      const deletedMediaType = getMediaTypeFromDetail(this.mediaDetailStore.mediaDetail);
      return `Your ${deletedMediaType} has been deleted.`;
    },
    updatePopupStatus(popupName, popupStatus) {
      this[popupName] = popupStatus;
    },
    hideRequestCommentsPopup() {
      this.showRequestCommentsPopup = false;
    },
    hideRequestErrorPopup() {
      this.showRequestErrorPopup = false;
    },
    hideRequestSuccessPopup() {
      this.showRequestSuccessPopup = false;
    },
    hideCommentErrorPopup() {
      this.showCommentErrorPopup = false;
    },
    hideNotUserPermissionPopup() {
      this.showNotUserPermissionPopup = false;
    },
    hideNotBrandPermissionPopup() {
      this.showNotBrandPermissionPopup = false;
    },
    contactClick() {
      this.supportChatStore.show();
      this.showNotBrandPermissionPopup = false;
    },
    getMediaDetailParams(item) {
      if (item.fb_ad_id) {
        return { mediaId: item.id, adId: item.fb_ad_id };
      }
      if (item.instagram && item.handle) {
        const patchJson = {
          media_id: item.sourceId,
          instagram_name: item.handle,
          timestamp: item.instagram.timestamp,
        };
        return {
          mediaId: item.sourceId,
          source: 'INSTAGRAM',
          patchJson,
        };
      }
      if (item.id) {
        return { mediaId: item.id };
      }
      return { mediaId: item.sourceId, source: item.source };
    },
    previousArrowClicked() {
      this.loadedFromPreviousArrow = true;
      this.currentMediaIndex -= 1;

      const previousMediaItem = this.mediaPopupStore.contextList[this.currentMediaIndex];
      const id = previousMediaItem.id || previousMediaItem.sourceId;

      const previousMediaItemPatchJson = { ...this.patchJson, media_id: id };
      if (previousMediaItem[this.source]?.timestamp) {
        previousMediaItemPatchJson.timestamp = previousMediaItem[this.source].timestamp;
      }

      this.$router.push({
        name: 'library.media',
        params: {
          brandLabel: this.brandLabel,
          id,
        },
        query: {
          brandId: this.brandId,
          patchJson: previousMediaItemPatchJson,
          replaceModal: true,
          source: this.source,
        },
      });
    },
    nextArrowClicked() {
      this.loadedFromNextArrow = true;
      this.currentMediaIndex += 1;

      const nextMediaItem = this.mediaPopupStore.contextList[this.currentMediaIndex];
      const id = nextMediaItem.id || nextMediaItem.sourceId;
      const source = nextMediaItem.source;

      const nextMediaItemPatchJson = { ...this.patchJson, media_id: id };
      if (nextMediaItem[source]?.timestamp) {
        nextMediaItemPatchJson.timestamp = nextMediaItem[source].timestamp;
      }

      this.$router.push({
        name: 'library.media',
        params: {
          brandLabel: this.brandLabel,
          id: nextMediaItem.id || nextMediaItem.sourceId,
        },
        query: {
          brandId: this.brandId,
          patchJson: nextMediaItemPatchJson,
          replaceModal: true,
          source: this.source,
        },
      });
    },
    adStatInsightRangeUpdated(range) {
      const fbAdId = this.mediaItem.fb_ad_id;
      const adParams = {
        mediaId: this.mediaItem.id,
        adId: fbAdId,
        insightsStart: range && range[0],
        insightsEnd: range && range[1],
      };
      this.mediaDetailStore.getMediaDetail(adParams);
    },
    fetchSimilarMedia(mediaId, groupId) {
      let sources = [
        enumTypes.UPLOADED,
        enumTypes.INSTAGRAM_OWNED,
        enumTypes.INSTAGRAM_OWNED_IGTV,
        enumTypes.INSTAGRAM_UGC,
        enumTypes.INSTAGRAM_STORY,
        enumTypes.PINTEREST_OWNED,
        enumTypes.LINKEDIN_OWNED,
      ];
      if (this.authStore.brand_can('facebook', 'can_access_fb_insights')) {
        sources.push(enumTypes.FACEBOOK_OWNED);
      }
      sources = sources.join(',');

      const params = {
        brandId: this.brandIdForRequests,
        mediaId,
        groupId,
        sources,
        limit: 200,
      };
      this.searchStore.getSimilarMediaList(params);
    },
    fetchMediaSources(groupId) {
      const params = {
        brandId: this.brandIdForRequests,
        groupId,
      };
      this.searchStore.getMediaSourceList(params);
    },
    arrowKeySwitchHandler(e) {
      const key = e.which || e.keyCode;
      const LEFT_ARROW_KEYCODE = 37;
      const RIGHT_ARROW_KEYCODE = 39;

      // Make sure to disable the arrow key when adding a library media to scheduler.
      if (
        ['TEXTAREA', 'INPUT'].indexOf(document.activeElement.tagName) > -1 ||
        window.location.href.includes('scheduler')
      ) {
        return;
      }
      if (key === LEFT_ARROW_KEYCODE && this.showPrevArrow) {
        this.previousArrowClicked();
      } else if (key === RIGHT_ARROW_KEYCODE && this.showNextArrow) {
        this.nextArrowClicked();
      }
    },
    async getInstagramAccount() {
      if (!this.instagramAccountStore.brandInstagramAccount) {
        await this.instagramAccountStore.getBrandInstagramAccount();
      }
    },
    pauseVideo() {
      this.$refs.mediaItem.$refs.media.pause();
      this.$refs.mediaItem.videoPlaying = false;
    },
    sendMixpanelInteractionEvent(section, action, actionDetails) {
      this.userEventTracker.interaction(
        this.mediaItem,
        this.$route,
        section,
        action,
        actionDetails,
      );
    },
    handleSentimentChanged(section, action, actionDetails) {
      this.hasUpdatedSentiment = true;
      this.sendMixpanelInteractionEvent(section, action, actionDetails);
    },
    setDateRange(range) {
      this.dateRange = [range[0], range[1]];
      this.updateRouteQuery();

      if (this.mediaItem?.postSourceId) {
        this.updateGraph('Update Reporting Period');
      }
    },
    updateGraphScale(optionIndex) {
      this.graphScale = this.scaleOptions[optionIndex].value;
      this.updateRouteQuery();
      this.updateGraph('Update View Graph');
    },
    updateGraph(updateGraphAction) {
      this.fetchTikTokVideoViews();

      const dateRangeDiff = dayjs(this.dateRange[1]).diff(this.dateRange[0], 'days');
      let dateRangeText = 'Custom';
      this.dateOptions.forEach((option) => {
        if (option.value === dateRangeDiff) {
          dateRangeText = option.label;
        }
      });

      this.sendMixpanelInteractionEvent('Video Views', updateGraphAction, {
        viewGraph: this.graphScale,
        reportingPeriod: dateRangeText,
      });
    },
    fetchTikTokVideoViews() {
      this.tiktokStore.getVideoViews({
        brandId: this.brandIdForRequests,
        videoId: this.mediaItem.postSourceId,
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        timeScale: this.graphScale,
      });
    },
    formatTikTokVideoViews() {
      this.tikTokVideoViews = {
        labels: [],
        values: [],
      };

      const format = this.graphScale === enumTypes.DAILY ? 'MMM DD' : 'MMM';
      Object.entries(this.tikTokVideoViewObject).forEach((data) => {
        const [label, value] = data;
        this.tikTokVideoViews.labels.push(dayjs(label).format(format));
        this.tikTokVideoViews.values.push(value);
      });
    },
    sendContentTagMixPanelEvent(contentTag, action) {
      const brandsAssociatedToTags = {
        [contentTag.name]: this.authStore.getBrandNames(contentTag.brandIds),
      };
      this.trackingStore.track(CONTENT_TAGS_EVENT_NAMES.CONTENT_TAG_MEDIA_ACTION, {
        action,
        tagNames: [contentTag?.name],
        tagIDs: [contentTag?.id],
        tagAddedMethod: CONTENT_TAG_ADDED_METHOD.MEDIA_POP_UP,
        channels: [this.mediaItem.postType],
        totalMedia: 1,
        brandsAssociatedToTags,
      });
    },
    onContentTagAdd(contentTag) {
      this.sendContentTagMixPanelEvent(contentTag, CONTENT_TAG_MEDIA_ACTIONS.ADD);
    },
    onContentTagRemove(contentTag) {
      // content tags from /media endpoint don't contain brandIds
      const orgContentTag = this.contentTagsStore.organizationContentTags.find(
        (tag) => tag.id === contentTag.id,
      );
      contentTag.brandIds = orgContentTag.brandIds;
      this.sendContentTagMixPanelEvent(contentTag, CONTENT_TAG_MEDIA_ACTIONS.REMOVE);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.nav-arrow {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: var(--space-24);
  cursor: pointer;
}

.prev-arrow {
  position: absolute;
  left: -60px;
}

.next-arrow {
  position: absolute;
  right: -60px;
}

main {
  position: relative;
}

.media-main {
  display: flex;

  .left-container {
    width: 55%;
    border-radius: var(--round-corner) 0 0 var(--round-corner);
    text-align: center;
    background: var(--background-300);
    padding: var(--space-40);

    .info-text {
      float: left;
      font-size: var(--x12);
      margin-bottom: 30px;

      .info-icon {
        margin-right: 10px;
        margin-bottom: -3px;
      }
    }

    .similar-media {
      text-align: left;

      label {
        color: var(--text-primary);
        font-size: var(--x18);
      }
    }

    .media-badges-wrapper {
      position: relative;
      height: 0;
    }
  }

  .right-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: var(--space-40);

    :deep(.information-box) {
      margin-bottom: var(--space-16);
    }

    .metric-warning-message {
      width: 100%;
    }

    .user-info,
    .content-rights {
      width: 100%;
      background: var(--background-300);
      border-radius: var(--round-corner-small);
    }

    .user-info {
      padding: var(--space-16);
      margin: 0 auto var(--space-8);
    }

    .content-rights {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: var(--space-8) auto;
      padding: 0 var(--space-16);
    }

    .insights {
      background: var(--background-300);
      border-radius: var(--round-corner);
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: var(--space-24);
      margin: var(--space-8) auto;
      font-size: var(--x14);
      color: var(--text-primary);
    }
  }
}

.loading,
.errors {
  padding: 10rem 0;
}

.small.loading {
  padding: 0 var(--space-24);
}

:deep(.chart-title) {
  padding-bottom: 0 !important;
}

:deep(.selection-display) {
  text-align: left;
}

:deep(.chart-container) {
  background: var(--background-300);
  box-shadow: none;
}

.youtube-media-view {
  margin-bottom: var(--space-40);
}
</style>

<style lang="postcss">
.media-item-container {
  margin-bottom: var(--space-32);
}

.media-carousel {
  .VueCarousel-navigation-button.VueCarousel-navigation-next,
  .VueCarousel-navigation-button.VueCarousel-navigation-prev {
    top: 50%;
  }

  .VueCarousel-pagination {
    z-index: var(--z-index-sticky);
    position: absolute;
    bottom: var(--space-18);
    padding-left: var(--space-24);
    padding-right: var(--space-24);

    ul {
      height: 5rem;
    }

    button {
      margin-top: 0 !important;
    }

    button:focus {
      outline: none;
    }
  }
}

.filter-group {
  display: flex;
}
</style>
