import { DATA_TYPES } from '@/models/dashboards/metrics.constants';

const CHANNELS = {
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  INSTAGRAM_STORIES: 'INSTAGRAM_STORIES',
  PINTEREST: 'PINTEREST',
  TIKTOK: 'TIKTOK',
  TWITTER: 'TWITTER',
  YOUTUBE: 'YOUTUBE',
};

export const MULTI_CHANNEL_METRICS = Object.freeze({
  AVG_COMPLETION_RATE: {
    value: 'AVG_COMPLETION_RATE',
    text: 'Avg. Completion Rate',
    channels: [CHANNELS.INSTAGRAM_STORIES, CHANNELS.TIKTOK, CHANNELS.YOUTUBE],
    data_type: DATA_TYPES.PERCENTAGE,
  },
  AVG_EFFECTIVENESS: {
    value: 'AVG_EFFECTIVENESS',
    text: 'Avg. Effectiveness - Organic',
    channels: [CHANNELS.FACEBOOK, CHANNELS.INSTAGRAM],
    data_type: DATA_TYPES.PERCENTAGE,
  },
  AVG_ENGAGEMENT_RATE: {
    value: 'AVG_ENGAGEMENT_RATE',
    text: 'Avg. Engagement Rate - Organic',
    channels: [
      CHANNELS.FACEBOOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.PINTEREST,
      CHANNELS.TIKTOK,
      CHANNELS.TIKTOK,
    ],
    data_type: DATA_TYPES.PERCENTAGE,
  },
  AVG_ENGAGEMENT_RATE_ALL_POSTS: {
    value: 'AVG_ENGAGEMENT_RATE_ALL_POSTS',
    text: 'Avg. Engagement Rate - All Posts',
    channels: [CHANNELS.PINTEREST, CHANNELS.TWITTER],
    data_type: DATA_TYPES.PERCENTAGE,
  },
  AVG_ENTERTAINMENT_SCORE: {
    value: 'AVG_ENTERTAINMENT_SCORE',
    text: 'Avg. Entertainment Score',
    channels: [CHANNELS.INSTAGRAM, CHANNELS.TIKTOK],
    data_type: DATA_TYPES.FLOAT,
  },
  AVG_REACH: {
    value: 'AVG_REACH',
    text: 'Avg. Reach',
    channels: [CHANNELS.FACEBOOK, CHANNELS.INSTAGRAM, CHANNELS.INSTAGRAM_STORIES, CHANNELS.TIKTOK],
    data_type: DATA_TYPES.INTEGER,
  },
  COMMENT_SENTIMENT_NEGATIVE_RATE: {
    value: 'COMMENT_SENTIMENT_NEGATIVE_RATE',
    text: 'Post Sentiment - Negative',
    channels: [
      CHANNELS.FACEBOOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.TIKTOK,
      CHANNELS.TWITTER,
      CHANNELS.YOUTUBE,
    ],
    data_type: DATA_TYPES.PERCENTAGE,
  },
  COMMENT_SENTIMENT_NEUTRAL_RATE: {
    value: 'COMMENT_SENTIMENT_NEUTRAL_RATE',
    text: 'Post Sentiment - Neutral',
    channels: [
      CHANNELS.FACEBOOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.TIKTOK,
      CHANNELS.TWITTER,
      CHANNELS.YOUTUBE,
    ],
    data_type: DATA_TYPES.PERCENTAGE,
  },
  COMMENT_SENTIMENT_POSITIVE_RATE: {
    value: 'COMMENT_SENTIMENT_POSITIVE_RATE',
    text: 'Post Sentiment - Postive',
    channels: [
      CHANNELS.FACEBOOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.TIKTOK,
      CHANNELS.TWITTER,
      CHANNELS.YOUTUBE,
    ],
    data_type: DATA_TYPES.PERCENTAGE,
  },
  FOLLOWER_GROWTH_RATE: {
    value: 'FOLLOWER_GROWTH_RATE',
    text: 'Followers - Growth Rate',
    channels: [
      CHANNELS.FACEBOOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.PINTEREST,
      CHANNELS.TIKTOK,
      CHANNELS.TWITTER,
      CHANNELS.YOUTUBE,
    ],
    data_type: DATA_TYPES.PERCENTAGE,
  },
  IMPRESSIONS: {
    value: 'IMPRESSIONS',
    text: 'Impressions',
    channels: [
      CHANNELS.FACEBOOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.INSTAGRAM_STORIES,
      CHANNELS.PINTEREST,
      CHANNELS.TWITTER,
    ],
    data_type: DATA_TYPES.INTEGER,
  },
  LINK_CLICKS: {
    value: 'LINK_CLICKS',
    text: 'Link Clicks',
    channels: [CHANNELS.FACEBOOK, CHANNELS.PINTEREST, CHANNELS.TWITTER],
    data_type: DATA_TYPES.INTEGER,
  },
  NET_NEW_FOLLOWERS: {
    value: 'NET_NEW_FOLLOWERS',
    text: 'Followers - Net New',
    channels: [
      CHANNELS.FACEBOOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.PINTEREST,
      CHANNELS.TIKTOK,
      CHANNELS.TWITTER,
      CHANNELS.YOUTUBE,
    ],
    data_type: DATA_TYPES.INTEGER,
  },
  NUMBER_OF_POSTS: {
    value: 'NUMBER_OF_POSTS',
    text: 'Number of Posts',
    channels: [
      CHANNELS.FACEBOOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.INSTAGRAM_STORIES,
      CHANNELS.PINTEREST,
      CHANNELS.TIKTOK,
      CHANNELS.TWITTER,
      CHANNELS.YOUTUBE,
    ],
    data_type: DATA_TYPES.INTEGER,
  },
  ORGANIC_COMMENTS: {
    value: 'ORGANIC_COMMENTS',
    text: 'Comments - Organic',
    channels: [CHANNELS.FACEBOOK, CHANNELS.INSTAGRAM],
    data_type: DATA_TYPES.INTEGER,
  },
  ORGANIC_VIDEO_VIEWS: {
    value: 'ORGANIC_VIDEO_VIEWS',
    text: 'Video Views - Organic',
    channels: [CHANNELS.FACEBOOK, CHANNELS.INSTAGRAM, CHANNELS.TWITTER],
    data_type: DATA_TYPES.INTEGER,
  },
  PROFILE_VIEWS: {
    value: 'PROFILE_VIEWS',
    text: 'Profile views',
    channels: [CHANNELS.FACEBOOK, CHANNELS.TIKTOK],
    data_type: DATA_TYPES.INTEGER,
  },
  REPLIES: {
    value: 'REPLIES',
    text: 'Replies',
    channels: [CHANNELS.PINTEREST, CHANNELS.TWITTER],
    data_type: DATA_TYPES.INTEGER,
  },
  SAVES: {
    value: 'SAVES',
    text: 'Saves - Organic',
    channels: [CHANNELS.INSTAGRAM, CHANNELS.PINTEREST],
    data_type: DATA_TYPES.INTEGER,
  },
  TOTAL_COMMENTS: {
    value: 'TOTAL_COMMENTS',
    text: 'Comments - Total',
    channels: [CHANNELS.INSTAGRAM, CHANNELS.TIKTOK, CHANNELS.YOUTUBE],
    data_type: DATA_TYPES.INTEGER,
  },
  TOTAL_ENGAGEMENTS: {
    value: 'TOTAL_ENGAGEMENTS',
    text: 'Total Engagements',
    channels: [CHANNELS.FACEBOOK, CHANNELS.INSTAGRAM, CHANNELS.TWITTER, CHANNELS.YOUTUBE],
    data_type: DATA_TYPES.INTEGER,
  },
  TOTAL_FOLLOWERS: {
    value: 'TOTAL_FOLLOWERS',
    text: 'Followers - Total',
    channels: [
      CHANNELS.FACEBOOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.PINTEREST,
      CHANNELS.TIKTOK,
      CHANNELS.TWITTER,
      CHANNELS.YOUTUBE,
    ],
    data_type: DATA_TYPES.INTEGER,
  },
  TOTAL_LIKES: {
    value: 'TOTAL_LIKES',
    text: 'Likes - Total',
    channels: [CHANNELS.INSTAGRAM, CHANNELS.TIKTOK, CHANNELS.YOUTUBE],
    data_type: DATA_TYPES.INTEGER,
  },
  TOTAL_SHARES: {
    value: 'TOTAL_SHARES',
    text: 'Shares - Total',
    channels: [CHANNELS.TIKTOK, CHANNELS.YOUTUBE],
    data_type: DATA_TYPES.INTEGER,
  },
  TOTAL_VIDEO_VIEWS: {
    value: 'TOTAL_VIDEO_VIEWS',
    text: 'Video Views - Total',
    channels: [CHANNELS.INSTAGRAM, CHANNELS.PINTEREST, CHANNELS.TIKTOK, CHANNELS.YOUTUBE],
    data_type: DATA_TYPES.INTEGER,
  },
});
