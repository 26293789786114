export const FILTER_OPTIONS_LIMIT = 4;

export const FETCH_CREATORS_DELAY_TIME = 700;

export const SEARCH_DEBOUNCE_TIME = 250;

export const PAGE_SIZE = 100;

export const tooltips = {
  importedCreatorEmail:
    "This email was automatically imported from the Creator's bio. You can edit it at any time.",
  tiktokAudienceRegions:
    'Displays creators with 25% or more of their audience in the selected country',
  tiktokAudienceAge:
    'Displays creators with 25% or more of their audience in the selected age range',
  tiktokAudienceGender:
    'Displays creators with 50% or more of their audience in the selected gender',
};

const discoveryInterests = [
  {
    label: 'Animals and Pets',
    value: 'Animals and Pets',
    children: [
      { label: 'Animals', value: 'Animals' },
      { label: 'Cats', value: 'Cats' },
      { label: 'Dogs', value: 'Dogs' },
      { label: 'Pets', value: 'Pets' },
    ],
  },
  {
    label: 'Arts and Music',
    value: 'Arts and Music',
    children: [
      { label: 'Acting', value: 'Acting' },
      { label: 'Art', value: 'Art' },
      { label: 'Ceramics', value: 'Ceramics' },
      { label: 'Country Music', value: 'Country Music' },
      { label: 'Dance', value: 'Dance' },
      { label: 'Hip Hop', value: 'Hip Hop' },
      { label: 'Jazz', value: 'Jazz' },
      { label: 'Music', value: 'Music' },
      { label: 'Painting', value: 'Painting' },
      { label: 'Photography', value: 'Photography' },
      { label: 'Singing', value: 'Singing' },
      { label: 'Tattoos', value: 'Tattoos' },
      { label: 'Theater', value: 'Theater' },
      { label: 'Writing', value: 'Writing' },
      { label: 'Concerts', value: 'Concerts' },
    ],
  },
  {
    label: 'Automotive',
    value: 'Automotive',
    children: [
      { label: 'Cars', value: 'Cars' },
      { label: 'Motorcycles', value: 'Motorcycles' },
    ],
  },
  {
    label: 'Beauty and Esthetics',
    value: 'Beauty and Esthetics',
    children: [
      { label: 'Beauty', value: 'Beauty' },
      { label: 'Botox', value: 'Botox' },
      { label: 'Fragrance', value: 'Fragrance' },
      { label: 'Hair', value: 'Hair' },
      { label: 'Hairstyling', value: 'Hairstyling' },
      { label: 'Makeup', value: 'Makeup' },
      { label: 'Nails', value: 'Nails' },
      { label: 'Skincare', value: 'Skincare' },
    ],
  },
  {
    label: 'Business and Entrepreneurship',
    value: 'Business and Entrepreneurship',
    children: [
      { label: 'Business', value: 'Business' },
      { label: 'Entrepreneurship', value: 'Entrepreneurship' },
      { label: 'Leadership', value: 'Leadership' },
      { label: 'Marketing', value: 'Marketing' },
      { label: 'Real Estate', value: 'Real Estate' },
      { label: 'Small Business', value: 'Small Business' },
      { label: 'Graphic Design', value: 'Graphic Design' },
    ],
  },
  {
    label: 'Clothing and Accessories',
    value: 'Clothing and Accessories',
    children: [
      { label: 'Accessories', value: 'Accessories' },
      { label: 'Activewear', value: 'Activewear' },
      { label: 'Fashion', value: 'Fashion' },
      { label: 'Jewelry', value: 'Jewelry' },
      { label: 'Lingerie', value: 'Lingerie' },
      { label: "Men's Fashion", value: "Men's Fashion" },
      { label: 'Shoes', value: 'Shoes' },
      { label: 'Streetwear', value: 'Streetwear' },
      { label: 'Sustainable Fashion', value: 'Sustainable Fashion' },
      { label: 'Swimwear', value: 'Swimwear' },
      { label: 'Watches', value: 'Watches' },
    ],
  },
  {
    label: 'Design and Home',
    value: 'Design and Home',
    children: [
      { label: 'DIY', value: 'DIY' },
      { label: 'Architecture', value: 'Architecture' },
      { label: 'Design', value: 'Design' },
      { label: 'Floral Design', value: 'Floral Design' },
      { label: 'Home Decor', value: 'Home Decor' },
      { label: 'Interior Design', value: 'Interior Design' },
      { label: 'Renovation', value: 'Renovation' },
    ],
  },
  {
    label: 'Media and Entertainment',
    value: 'Media and Entertainment',
    children: [
      { label: 'Books', value: 'Books' },
      { label: 'Comedy', value: 'Comedy' },
      { label: 'Entertainment', value: 'Entertainment' },
      { label: 'Events', value: 'Events' },
      { label: 'Festivals', value: 'Festivals' },
      { label: 'Gaming', value: 'Gaming' },
      { label: 'Movies', value: 'Movies' },
      { label: 'Podcasting', value: 'Podcasting' },
      { label: 'Reality TV', value: 'Reality TV' },
      { label: 'Toys', value: 'Toys' },
      { label: 'TV Shows', value: 'TV Shows' },
    ],
  },
  {
    label: 'Family and Relationships',
    value: 'Family and Relationships',
    children: [
      { label: 'Family', value: 'Family' },
      { label: 'Friendship', value: 'Friendship' },
      { label: 'LGBTQ+', value: 'LGBTQ+' },
      { label: 'Marriage', value: 'Marriage' },
      { label: 'Motherhood', value: 'Motherhood' },
      { label: 'Parenting', value: 'Parenting' },
      { label: 'Pregnancy', value: 'Pregnancy' },
      { label: 'Relationships', value: 'Relationships' },
      { label: 'Weddings', value: 'Weddings' },
    ],
  },

  {
    label: 'Fitness and Training',
    value: 'Fitness and Training',
    children: [
      { label: 'Barre', value: 'Barre' },
      { label: 'Bodybuilding', value: 'Bodybuilding' },
      { label: 'Crossfit', value: 'Crossfit' },
      { label: 'Fitness', value: 'Fitness' },
      { label: 'Gym', value: 'Gym' },
      { label: 'Personal Training', value: 'Personal Training' },
      { label: 'Pilates', value: 'Pilates' },
      { label: 'Running', value: 'Running' },
      { label: 'Strength Training', value: 'Strength Training' },
      { label: 'Yoga', value: 'Yoga' },
    ],
  },
  {
    label: 'Food and Beverage',
    value: 'Food and Beverage',
    children: [
      { label: 'Baking', value: 'Baking' },
      { label: 'Cocktails', value: 'Cocktails' },
      { label: 'Coffee', value: 'Coffee' },
      { label: 'Cooking', value: 'Cooking' },
      { label: 'Desserts', value: 'Desserts' },
      { label: 'Food', value: 'Food' },
      { label: 'Gluten-free', value: 'Gluten-free' },
      { label: 'Healthy Eating', value: 'Healthy Eating' },
      { label: 'Recipes', value: 'Recipes' },
      { label: 'Tea', value: 'Tea' },
      { label: 'Vegan', value: 'Vegan' },
      { label: 'Wine', value: 'Wine' },
    ],
  },
  {
    label: 'Health and Wellness',
    value: 'Health and Wellness',
    children: [
      { label: 'Body Positivity', value: 'Body Positivity' },
      { label: 'Gut Health', value: 'Gut Health' },
      { label: 'Health', value: 'Health' },
      { label: 'Holistic Health', value: 'Holistic Health' },
      { label: 'Meditation', value: 'Meditation' },
      { label: 'Nutrition', value: 'Nutrition' },
      { label: 'Self-care', value: 'Self-care' },
      { label: 'Sleep', value: 'Sleep' },
      { label: 'Wellness', value: 'Wellness' },
      { label: 'Well-being', value: 'Well-being' },
      { label: 'Yoga', value: 'Yoga' },
    ],
  },
  {
    label: 'Luxury',
    value: 'Luxury',
  },
  {
    label: 'Nature and Outdoor Activities',
    value: 'Nature and Outdoor Activities',
    children: [
      { label: 'Beaches', value: 'Beaches' },
      { label: 'Flowers', value: 'Flowers' },
      { label: 'Mountains', value: 'Mountains' },
      { label: 'Nature', value: 'Nature' },
      { label: 'Plants', value: 'Plants' },
      { label: 'Camping', value: 'Camping' },
      { label: 'Fishing', value: 'Fishing' },
      { label: 'Gardening', value: 'Gardening' },
      { label: 'Hiking', value: 'Hiking' },
      { label: 'Hunting', value: 'Hunting' },
      { label: 'Outdoors', value: 'Outdoors' },
    ],
  },
  {
    label: 'Political and Social',
    value: 'Political and Social',
    children: [
      { label: 'Activism', value: 'Activism' },
      { label: 'Charity', value: 'Charity' },
      { label: 'Culture', value: 'Culture' },
      { label: 'Education', value: 'Education' },
      { label: 'Faith', value: 'Faith' },
      { label: 'History', value: 'History' },
      { label: 'Politics', value: 'Politics' },
      { label: 'Social Justice', value: 'Social Justice' },
      { label: 'Spirituality', value: 'Spirituality' },
      { label: 'Sustainability', value: 'Sustainability' },
    ],
  },
  {
    label: 'Sports and Athletics',
    value: 'Sports and Athletics',
    children: [
      { label: 'Basketball', value: 'Basketball' },
      { label: 'Boxing', value: 'Boxing' },
      { label: 'Cycling', value: 'Cycling' },
      { label: 'Football', value: 'Football' },
      { label: 'Golf', value: 'Golf' },
      { label: 'Skateboarding', value: 'Skateboarding' },
      { label: 'Skiing', value: 'Skiing' },
      { label: 'Snowboarding', value: 'Snowboarding' },
      { label: 'Soccer', value: 'Soccer' },
      { label: 'Sports', value: 'Sports' },
      { label: 'Surfing', value: 'Surfing' },
      { label: 'Tennis', value: 'Tennis' },
      { label: 'Wrestling', value: 'Wrestling' },
    ],
  },
  {
    label: 'Technology',
    value: 'Technology',
  },
  {
    label: 'Travel',
    value: 'Travel',
  },
];

export const defaultDiscoveryRegions = [
  { label: 'United States', value: 'United States', code: 'US' },
  { label: 'Canada', value: 'Canada', code: 'CA' },
  { label: 'United Kingdom', value: 'United Kingdom', code: 'GB' },
  { label: 'Australia', value: 'Australia', code: 'AU' },
];

const discoveryRegions = [
  { label: 'Argentina', value: 'Argentina', code: 'AR' },
  { label: 'Australia', value: 'Australia', code: 'AU' },
  { label: 'Austria', value: 'Austria', code: 'AT' },
  { label: 'Belgium', value: 'Belgium', code: 'BE' },
  { label: 'Brazil', value: 'Brazil', code: 'BR' },
  { label: 'Canada', value: 'Canada', code: 'CA' },
  { label: 'Chile', value: 'Chile', code: 'CL' },
  { label: 'China', value: 'China', code: 'CN' },
  { label: 'Colombia', value: 'Colombia', code: 'CO' },
  { label: 'Costa Rica', value: 'Costa Rica', code: 'CR' },
  { label: 'Croatia', value: 'Croatia', code: 'HR' },
  { label: 'Czech Republic', value: 'Czech Republic', code: 'CZ' },
  { label: 'Denmark', value: 'Denmark', code: 'DK' },
  { label: 'Dominican Republic', value: 'Dominican Republic', code: 'DO' },
  { label: 'Finland', value: 'Finland', code: 'FI' },
  { label: 'France', value: 'France', code: 'FR' },
  { label: 'Germany', value: 'Germany', code: 'DE' },
  { label: 'Greece', value: 'Greece', code: 'GR' },
  { label: 'Hungary', value: 'Hungary', code: 'HU' },
  { label: 'India', value: 'India', code: 'IN' },
  { label: 'Indonesia', value: 'Indonesia', code: 'ID' },
  { label: 'Ireland', value: 'Ireland', code: 'IE' },
  { label: 'Italy', value: 'Italy', code: 'IT' },
  { label: 'Israel', value: 'Israel', code: 'IL' },
  { label: 'Japan', value: 'Japan', code: 'JP' },
  { label: 'Kuwait', value: 'Kuwait', code: 'KW' },
  { label: 'Lebanon', value: 'Lebanon', code: 'LB' },
  { label: 'Malaysia', value: 'Malaysia', code: 'MY' },
  { label: 'Mexico', value: 'Mexico', code: 'MX' },
  { label: 'Morocco', value: 'Morocco', code: 'MA' },
  { label: 'Netherlands', value: 'Netherlands', code: 'NL' },
  { label: 'New Zealand', value: 'New Zealand', code: 'NZ' },
  { label: 'Nigeria', value: 'Nigeria', code: 'NG' },
  { label: 'Norway', value: 'Norway', code: 'NO' },
  { label: 'Pakistan', value: 'Pakistan', code: 'PK' },
  { label: 'Philippines', value: 'Philippines', code: 'PH' },
  { label: 'Poland', value: 'Poland', code: 'PL' },
  { label: 'Portugal', value: 'Portugal', code: 'PT' },
  { label: 'Romania', value: 'Romania', code: 'RO' },
  { label: 'Russia', value: 'Russia', code: 'RU' },
  { label: 'Serbia', value: 'Serbia', code: 'RS' },
  { label: 'Saudi Arabia', value: 'Saudi Arabia', code: 'SA' },
  { label: 'Singapore', value: 'Singapore', code: 'SG' },
  { label: 'South Africa', value: 'South Africa', code: 'ZA' },
  { label: 'South Korea', value: 'South Korea', code: 'KR' },
  { label: 'Spain', value: 'Spain', code: 'ES' },
  { label: 'Sweden', value: 'Sweden', code: 'SE' },
  { label: 'Switzerland', value: 'Switzerland', code: 'CH' },
  { label: 'Taiwan', value: 'Taiwan', code: 'TW' },
  { label: 'Thailand', value: 'Thailand', code: 'TH' },
  { label: 'Turkey', value: 'Turkey', code: 'TR' },
  { label: 'Ukraine', value: 'Ukraine', code: 'UA' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates', code: 'AE' },
  { label: 'United Kingdom', value: 'United Kingdom', code: 'GB' },
  { label: 'United States', value: 'United States', code: 'US' },
  { label: 'Venezuela', value: 'Venezuela', code: 'VE' },
  { label: 'Vietnam', value: 'Vietnam', code: 'VN' },
];

export const tikTokDiscoveryEngagementRateOptions = [
  { value: '0-5', label: '0-5', minEngagementRate: 0, maxEngagementRate: 5 },
  { value: '5-10', label: '5-10', minEngagementRate: 5, maxEngagementRate: 10 },
  { value: '10-15', label: '10-15', minEngagementRate: 10, maxEngagementRate: 15 },
  { value: '15-20', label: '15-20', minEngagementRate: 15, maxEngagementRate: 20 },
  { value: '>20', label: '>20', minEngagementRate: 20, maxEngagementRate: null },
];

export const tikTokDiscoveryMobileOSOptions = [
  { value: 'android', label: 'Android' },
  { value: 'ios', label: 'iOS' },
];

export const errors = {
  invalidBirthday: 'Please enter a valid birthday',
  invalidBirthYear: 'Please enter a valid birth year',
  invalidPhoneNumber: 'Please enter a valid phone number',
  invalidEmail: 'Please enter a valid email address',
};

export const routeDetails = {
  CREATORS: {
    name: 'creators',
    path: '',
    title: 'Creators',
  },
  YOUR_CREATORS: {
    name: 'creators.your-creators',
    path: 'your-creators',
    title: 'Your Creators',
  },
  CREATOR_DISCOVERY: {
    name: 'creators.discovery',
    path: 'discovery',
    title: 'Creator Discovery',
  },
  TIKTOK_DISCOVERY: {
    name: 'creators.discovery.tiktok',
    path: 'discovery/tiktok',
    title: 'TikTok Discovery',
  },
  INSTAGRAM_DISCOVERY: {
    name: 'creators.discovery.instagram',
    path: 'discovery/instagram',
    title: 'Instagram Discovery',
  },
};

export const breadCrumbs = {
  YOUR_CREATORS: [routeDetails.CREATORS, routeDetails.YOUR_CREATORS],
  TIKTOK_DISCOVERY: [
    routeDetails.CREATORS,
    routeDetails.CREATOR_DISCOVERY,
    routeDetails.TIKTOK_DISCOVERY,
  ],
  INSTAGRAM_DISCOVERY: [
    routeDetails.CREATORS,
    routeDetails.CREATOR_DISCOVERY,
    routeDetails.INSTAGRAM_DISCOVERY,
  ],
};

export const metricDetails = {
  postCount: {
    label: 'Posts Feat. You',
    metricValue: 'postCount',
    sortValue: 'POST_COUNT',
    isRIQMetric: true,
  },
  avgEngagements: {
    label: 'Avg. Engagements',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'avgEngagements',
    sortValue: 'AVG_ENGAGEMENT',
    isRIQMetric: true,
  },
  avgEngagementRate: {
    label: 'Avg. Engagement Rate',
    formatType: 'percent',
    formatOptions: { style: 'percent', maximumFractionDigits: 2 },
    metricValue: 'avgEngagementRate',
    sortValue: 'AVG_ENGAGEMENT_RATE',
    isRIQMetric: true,
  },
  totalEngagements: {
    label: 'Total Engagements',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'totalEngagements',
    sortValue: 'TOTAL_ENGAGEMENTS',
    isRIQMetric: true,
  },
  totalEmv: {
    label: 'EMV',
    formatType: 'currency',
    formatOptions: { style: 'currency', currency: 'USD' },
    metricValue: 'totalEmv',
    sortValue: 'TOTAL_EMV',
    isRIQMetric: true,
  },
  avgReach: {
    label: 'Avg. Reach',
    disconnectedLabel: 'Avg. Estimated Reach',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'avgReach',
    sortValue: 'AVG_REACH',
    isRIQMetric: true,
  },
  avgImpressions: {
    label: 'Avg. Impressions',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'avgImpressions',
    sortValue: 'AVG_IMPRESSIONS',
    isRIQMetric: true,
  },
  avgEffectiveness: {
    label: 'Avg. Effectiveness',
    formatType: 'percent',
    formatOptions: { style: 'percent', maximumFractionDigits: 2 },
    metricValue: 'avgEffectiveness',
    sortValue: 'AVG_EFFECTIVENESS',
    isRIQMetric: true,
  },
  followers: {
    label: 'Followers',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'followers',
    sortValue: 'FOLLOWERS',
  },
  followersGained: {
    label: 'Followers Gained',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'followersGained',
    sortValue: 'FOLLOWERS_GAINED',
    isRIQMetric: true,
  },
  instagramFollowers: {
    label: 'Instagram Followers',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'followers',
    sortValue: 'INSTAGRAM_FOLLOWERS',
  },
  tiktokFollowers: {
    label: 'TikTok Followers',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'followers',
    sortValue: 'TIKTOK_FOLLOWERS',
  },
  totalImpressions: {
    label: 'Total Impressions',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'totalImpressions',
    sortValue: 'TOTAL_IMPRESSIONS',
    isRIQMetric: true,
  },
  avgCompletionRate: {
    label: 'Avg. Completion Rate',
    formatType: 'percent',
    formatOptions: { style: 'percent', maximumFractionDigits: 2 },
    metricValue: 'avgCompletionRate',
    sortValue: 'AVG_COMPLETION_RATE',
    isRIQMetric: true,
  },
  avgExitRate: {
    label: 'Avg. Exit Rate',
    formatType: 'percent',
    formatOptions: { style: 'percent', maximumFractionDigits: 2 },
    metricValue: 'avgExitRate',
    sortValue: 'AVG_EXIT_RATE',
    isRIQMetric: true,
  },
  avgVideoViews: {
    label: 'Avg. Video Views',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'avgVideoViews',
    sortValue: 'AVG_VIDEO_VIEWS',
  },
  views: {
    label: 'Total Video Views',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'views',
    sortValue: 'VIDEO_VIEWS',
  },
  // Need a time format for these two - add in GDI
  avgTimeWatched: {
    label: 'Avg. Time Watched',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'avgTimeWatched',
    sortValue: 'AVG_TIME_WATCHED',
  },
  totalTimeWatched: {
    label: 'Total Time Watched',
    formatType: 'number',
    formatOptions: { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 0 },
    metricValue: 'totalTimeWatched',
    sortValue: 'TOTAL_TIME_WATCHED',
  },
};

export const creatorCardMetrics = {
  INSTAGRAM: [
    metricDetails.postCount,
    metricDetails.avgEngagements,
    metricDetails.avgEngagementRate,
    metricDetails.totalEmv,
  ],
  INSTAGRAM_STORIES_ONLY: [
    metricDetails.postCount,
    metricDetails.avgImpressions,
    metricDetails.avgCompletionRate,
    metricDetails.totalEmv,
  ],
  TIKTOK: [
    metricDetails.postCount,
    metricDetails.avgEngagements,
    metricDetails.avgVideoViews,
    metricDetails.totalEmv,
  ],
  MULTIPLE_CHANNELS: [],
};

export const defaultTikTokRegions = [
  { label: 'United States', code: 'US', value: 'US' },
  { label: 'Canada', code: 'CA', value: 'CA' },
  { label: 'United Kingdom', code: 'GB', value: 'GB' },
  { label: 'Australia', code: 'AU', value: 'AU' },
];

const tiktokRegions = [
  { label: 'Australia', code: 'AU', value: 'AU' },
  { label: 'Brazil', code: 'BR', value: 'BR' },
  { label: 'Canada', code: 'CA', value: 'CA' },
  { label: 'China', code: 'CN', value: 'CN' },
  { label: 'Egypt', code: 'EG', value: 'EG' },
  { label: 'France', code: 'FR', value: 'FR' },
  { label: 'Germany', code: 'DE', value: 'DE' },
  { label: 'Hong Kong', code: 'HK', value: 'HK' },
  { label: 'India', code: 'IN', value: 'IN' },
  { label: 'Indonesia', code: 'ID', value: 'ID' },
  { label: 'Israel', code: 'IL', value: 'IL' },
  { label: 'Italy', code: 'IT', value: 'IT' },
  { label: 'Japan', code: 'JP', value: 'JP' },
  { label: 'South Korea', code: 'KR', value: 'KR' },
  { label: 'Malaysia', code: 'MY', value: 'MY' },
  { label: 'Philippines', code: 'PH', value: 'PH' },
  { label: 'Russia', code: 'RU', value: 'RU' },
  { label: 'Saudi Arabia', code: 'SA', value: 'SA' },
  { label: 'Singapore', code: 'SG', value: 'SG' },
  { label: 'Spain', code: 'ES', value: 'ES' },
  { label: 'Taiwan', code: 'TW', value: 'TW' },
  { label: 'Thailand', code: 'TH', value: 'TH' },
  { label: 'Turkey', code: 'TR', value: 'TR' },
  { label: 'United Arab Emirates', code: 'AE', value: 'AE' },
  { label: 'United Kingdom', code: 'GB', value: 'GB' },
  { label: 'United States', code: 'US', value: 'US' },
  { label: 'Vietnam', code: 'VN', value: 'VN' },
];

export const tiktokInterests = [
  {
    label: 'Acting & Drama',
    value: 'Acting & Drama',
  },
  {
    label: 'Animation & Cosplay',
    value: 'Animation & Cosplay',
    children: [
      { label: 'Comics, Cartoon & Anime', value: 'Comics, Cartoon & Anime' },
      { label: 'Cosplay', value: 'Cosplay' },
    ],
  },
  {
    label: 'Arts & Crafts',
    value: 'Arts & Crafts',
    children: [
      { label: 'Crafts', value: 'Crafts' },
      { label: 'Drawing & Painting', value: 'Drawing & Painting' },
      { label: 'Other Arts', value: 'Other Arts' },
    ],
  },
  {
    label: 'Auto and Vehicles',
    value: 'Auto and Vehicles',
    children: [
      { label: 'Cars & Trucks', value: 'Cars & Trucks' },
      { label: 'Special Events', value: 'Special Events' },
      { label: 'Vehicle Test & Showcase', value: 'Vehicle Test & Showcase' },
    ],
  },
  {
    label: 'Beauty & Self Care',
    value: 'Beauty & Self Care',
    children: [
      { label: 'Cosmetic Surgery', value: 'Cosmetic Surgery' },
      { label: 'Cosmetics & Makeup', value: 'Cosmetics & Makeup' },
      { label: 'Hair', value: 'Hair' },
      { label: 'Nails', value: 'Nails' },
      { label: 'Oral Care', value: 'Oral Care' },
      { label: 'Other Beauty', value: 'Other Beauty' },
      { label: 'Perfumes', value: 'Perfumes' },
      { label: 'Personal Care', value: 'Personal Care' },
      { label: 'Skincare', value: 'Skincare' },
    ],
  },
  {
    label: 'DIY & Life Hacks',
    value: 'DIY & Life Hacks',
    children: [
      { label: 'DIY', value: 'DIY' },
      { label: 'Life Hacks', value: 'Life Hacks' },
    ],
  },
  {
    label: 'Emotion',
    value: 'Emotion',
    children: [
      { label: 'Romance', value: 'Romance' },
      { label: 'Wedding', value: 'Wedding' },
    ],
  },
  {
    label: 'Entertainment & Music',
    value: 'Entertainment & Music',
    children: [
      { label: 'Entertainment News', value: 'Entertainment News' },
      { label: 'Fan Accounts', value: 'Fan Accounts' },
      { label: 'Internet Personality', value: 'Internet Personality' },
      { label: 'Movies & TV', value: 'Movies & TV' },
      { label: 'Music', value: 'Music' },
      { label: 'Other Pop Culture', value: 'Other Pop Culture' },
      { label: 'Variety Shows & Reality TV', value: 'Variety Shows & Reality TV' },
    ],
  },
  {
    label: 'Family',
    value: 'Family',
    children: [
      { label: 'Family & Relationships', value: 'Family & Relationships' },
      { label: 'Kids', value: 'Kids' },
      { label: 'Toddlers', value: 'Toddlers' },
    ],
  },
  {
    label: 'Fashion & Accessories',
    value: 'Fashion & Accessories',
    children: [
      { label: 'Accessories', value: 'Accessories' },
      { label: 'Bags', value: 'Bags' },
      { label: 'Body Art', value: 'Body Art' },
      { label: 'Clothing', value: 'Clothing' },
      { label: 'Jewelry', value: 'Jewelry' },
      { label: 'Other Fashion', value: 'Other Fashion' },
      { label: 'Shoes', value: 'Shoes' },
    ],
  },
  {
    label: 'Finance',
    value: 'Finance',
  },
  {
    label: 'Fitness & Health',
    value: 'Fitness & Health',
    children: [
      { label: 'Fitness', value: 'Fitness' },
      { label: 'Health', value: 'Health' },
      { label: 'Yoga & Meditation', value: 'Yoga & Meditation' },
    ],
  },
  {
    label: 'Food',
    value: 'Food',
    children: [
      { label: 'Drinks & Beverages', value: 'Drinks & Beverages' },
      { label: 'Food Displays', value: 'Food Displays' },
      { label: 'Food Recommendations', value: 'Food Recommendations' },
      { label: 'Mukbang & Tasting', value: 'Mukbang & Tasting' },
      { label: 'Recipes', value: 'Recipes' },
    ],
  },
  {
    label: 'Funny',
    value: 'Funny',
    children: [
      { label: 'Fail', value: 'Fail' },
      { label: 'Other Comedy', value: 'Other Comedy' },
      { label: 'Prank', value: 'Prank' },
      { label: 'Reaction Videos', value: 'Reaction Videos' },
      { label: 'Scripted Comedy', value: 'Scripted Comedy' },
    ],
  },
  {
    label: 'Games',
    value: 'Games',
    children: [
      { label: 'Action & Shooting Games', value: 'Action & Shooting Games' },
      { label: 'Adventure Games', value: 'Adventure Games' },
      { label: 'Casual & Intellectual Games', value: 'Casual & Intellectual Games' },
      { label: 'Elimination Games', value: 'Elimination Games' },
      { label: 'Large Online Games', value: 'Large Online Games' },
      { label: 'Massively Multiplayer Online Games', value: 'Massively Multiplayer Online Games' },
      { label: 'Metaverse', value: 'Metaverse' },
      { label: 'Other Games', value: 'Other Games' },
      { label: 'Other Role-Playing Games', value: 'Other Role-Playing Games' },
      { label: 'Parent Child Games', value: 'Parent Child Games' },
      { label: 'Racing & Sports Games', value: 'Racing & Sports Games' },
      { label: 'Sandbox Games', value: 'Sandbox Games' },
      { label: 'Strategy Games', value: 'Strategy Games' },
      { label: 'Video Games', value: 'Video Games' },
    ],
  },
  {
    label: 'General Education',
    value: 'General Education',
  },
  {
    label: 'Home & Garden',
    value: 'Home & Garden',
    children: [
      { label: 'Garden', value: 'Garden' },
      { label: 'Home', value: 'Home' },
    ],
  },
  {
    label: 'Life & Society',
    value: 'Life & Society',
    children: [
      { label: 'Social News & Events', value: 'Social News & Events' },
      {
        label: 'Street Interview & Social Experiment',
        value: 'Street Interview & Social Experiment',
      },
    ],
  },
  {
    label: 'Lipsync',
    value: 'Lipsync',
    children: [
      { label: 'Dialogue Lipsync', value: 'Dialogue Lipsync' },
      { label: 'Music Lipsync', value: 'Music Lipsync' },
    ],
  },
  {
    label: 'Nature',
    value: 'Nature',
    children: [{ label: 'Natural Environment', value: 'Natural Environment' }],
  },
  {
    label: 'Oddly Satisfying',
    value: 'Oddly Satisfying',
    children: [
      { label: 'Aesthetics', value: 'Aesthetics' },
      { label: 'Slime & ASMR', value: 'Slime & ASMR' },
    ],
  },
  {
    label: 'Other Transportation',
    value: 'Other Transportation',
    children: [{ label: 'Transportation Vehicles', value: 'Transportation Vehicles' }],
  },
  {
    label: 'Outdoor Activity',
    value: 'Outdoor Activity',
    children: [
      { label: 'Adventure', value: 'Adventure' },
      { label: 'Camping & Hiking', value: 'Camping & Hiking' },
      { label: 'Fishing & Hunting', value: 'Fishing & Hunting' },
    ],
  },
  {
    label: 'Pets & Animals',
    value: 'Pets & Animals',
    children: [
      { label: 'Exotic Animals & Wildlife', value: 'Exotic Animals & Wildlife' },
      { label: 'Livestock', value: 'Livestock' },
      { label: 'Other Animals', value: 'Other Animals' },
      { label: 'Pets', value: 'Pets' },
    ],
  },
  {
    label: 'Photography',
    value: 'Photography',
    children: [{ label: 'Photography & Videography', value: 'Photography & Videography' }],
  },
  {
    label: 'Production Education',
    value: 'Production Education',
    children: [{ label: 'Tutorials', value: 'Tutorials' }],
  },
  {
    label: 'Product Showcase',
    value: 'Product Showcase',
    children: [
      { label: 'Device Comparison', value: 'Device Comparison' },
      { label: 'Product Testing', value: 'Product Testing' },
      { label: 'Unboxing', value: 'Unboxing' },
    ],
  },
  {
    label: 'Sports',
    value: 'Sports',
    children: [
      { label: 'Extreme Sports', value: 'Extreme Sports' },
      { label: 'Sports & Recreation', value: 'Sports & Recreation' },
      { label: 'Sports News', value: 'Sports News' },
    ],
  },
  {
    label: 'Talent',
    value: 'Talent',
    children: [
      { label: 'Beginner Dance', value: 'Beginner Dance' },
      { label: 'Instrument', value: 'Instrument' },
      { label: 'Magic', value: 'Magic' },
      { label: 'Professional Dance', value: 'Professional Dance' },
      { label: 'Singing', value: 'Singing' },
      { label: 'Unusual Talent', value: 'Unusual Talent' },
    ],
  },
  {
    label: 'Travel',
    value: 'Travel',
    children: [
      { label: 'Adventure Travel', value: 'Adventure Travel' },
      { label: 'Destination', value: 'Destination' },
      { label: 'Global Culture', value: 'Global Culture' },
      { label: 'Hotels & Resorts', value: 'Hotels & Resorts' },
      { label: 'Luxury Travel', value: 'Luxury Travel' },
      { label: 'Travel Guide', value: 'Travel Guide' },
    ],
  },
  {
    label: 'Upskilling',
    value: 'Upskilling',
  },
  {
    label: 'Vlog',
    value: 'Vlog',
    children: [
      { label: 'Career', value: 'Career' },
      { label: 'Daily Life', value: 'Daily Life' },
      { label: 'Fortune Telling', value: 'Fortune Telling' },
      { label: 'Inspirational', value: 'Inspirational' },
      { label: 'Interviews', value: 'Interviews' },
      { label: 'School Life', value: 'School Life' },
      { label: 'Spirituality', value: 'Spirituality' },
    ],
  },
];

export const defaultTikTokLanguages = [
  { label: 'English', code: 'en', value: 'en' },
  { label: 'Spanish', code: 'es', value: 'es' },
  { label: 'Chinese (Simplified)', code: 'zh', value: 'zh' },
  { label: 'Russian', code: 'ru', value: 'ru' },
];

const tiktokLanguages = [
  { label: 'Arabic', code: 'ar', value: 'ar' },
  { label: 'Assamese', code: 'as', value: 'as' },
  { label: 'Bengali', code: 'bn', value: 'bn' },
  { label: 'Bihari', code: 'bh', value: 'bh' },
  { label: 'Burmese', code: 'my', value: 'my' },
  { label: 'Cebuano', code: 'ceb', value: 'ceb' },
  { label: 'Central Khmer', code: 'km', value: 'km' },
  { label: 'Chinese (Simplified)', code: 'zh', value: 'zh' },
  { label: 'Chinese (Traditional)', code: 'zh-Hant', value: 'zh-Hant' },
  { label: 'Czech', code: 'cs', value: 'cs' },
  { label: 'Dutch', code: 'nl', value: 'nl' },
  { label: 'English', code: 'en', value: 'en' },
  { label: 'Filipino', code: 'fil', value: 'fil' },
  { label: 'Finnish', code: 'fi', value: 'fi' },
  { label: 'French', code: 'fr', value: 'fr' },
  { label: 'German', code: 'de', value: 'de' },
  { label: 'Greek', code: 'el', value: 'el' },
  { label: 'Gujarati', code: 'gu', value: 'gu' },
  { label: 'Haryanvi', code: 'bgc', value: 'bgc' },
  { label: 'Hebrew', code: 'he', value: 'he' },
  { label: 'Hindi', code: 'hi', value: 'hi' },
  { label: 'Hungarian', code: 'hu', value: 'hu' },
  { label: 'Indonesian', code: 'id', value: 'id' },
  { label: 'Italian', code: 'it', value: 'it' },
  { label: 'Japanese', code: 'ja', value: 'ja' },
  { label: 'Javanese', code: 'jv', value: 'jv' },
  { label: 'Kannada', code: 'kn', value: 'kn' },
  { label: 'Korean', code: 'ko', value: 'ko' },
  { label: 'Malay', code: 'ms', value: 'ms' },
  { label: 'Malayalam', code: 'ml', value: 'ml' },
  { label: 'Marathi', code: 'mr', value: 'mr' },
  { label: 'Oriya', code: 'or', value: 'or' },
  { label: 'Polish', code: 'pl', value: 'pl' },
  { label: 'Portuguese', code: 'pt', value: 'pt' },
  { label: 'Punjabi', code: 'pa', value: 'pa' },
  { label: 'Rajasthani', code: 'raj', value: 'raj' },
  { label: 'Romanian', code: 'ro', value: 'ro' },
  { label: 'Russian', code: 'ru', value: 'ru' },
  { label: 'Spanish', code: 'es', value: 'es' },
  { label: 'Swedish', code: 'sv', value: 'sv' },
  { label: 'Tagalog', code: 'tl', value: 'tl' },
  { label: 'Tamil', code: 'ta', value: 'ta' },
  { label: 'Telugu', code: 'te', value: 'te' },
  { label: 'Thai', code: 'th', value: 'th' },
  { label: 'Turkish', code: 'tr', value: 'tr' },
  { label: 'Ukrainian', code: 'uk', value: 'uk' },
  { label: 'Vietnamese', code: 'vi', value: 'vi' },
];

export const tikTokDiscoveryAgeOptions = [
  { value: '', label: 'All Ages', minAge: null, maxAge: null },
  { value: '18-24', label: '18-24', minAge: 18, maxAge: 24 },
  { value: '25-34', label: '25-34', minAge: 25, maxAge: 34 },
  { value: '35-44', label: '35-44', minAge: 35, maxAge: 44 },
  { value: '45-54', label: '45-54', minAge: 45, maxAge: 54 },
  { divider: true },
  { value: '55+', label: '55+', minAge: 55, maxAge: null },
  { value: '35+', label: '35+', minAge: 35, maxAge: null },
];

export const tikTokDiscoveryGenderOptions = [
  { value: '', label: 'All Genders' },
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

export const sortDetails = {
  YOUR_CREATORS: {
    options: {
      MULTIPLE_CHANNELS: [
        { label: 'Posts Feat. You', value: 'POST_COUNT' },
        { label: 'Recently Added', value: 'ADDED_TIME' },
        { label: 'Recently Published', value: 'PUBLISH_TIME' },
        { label: 'Instagram Followers', value: 'INSTAGRAM_FOLLOWERS' },
        { label: 'TikTok Followers', value: 'TIKTOK_FOLLOWERS' },
      ],
      INSTAGRAM: [
        { label: 'Posts Feat. You', value: 'POST_COUNT' },
        { label: 'Recently Added', value: 'ADDED_TIME' },
        { label: 'Recently Published', value: 'PUBLISH_TIME' },
        { label: 'Avg. Completion Rate', value: 'AVG_COMPLETION_RATE' },
        { label: 'Avg. Effectiveness Rate', value: 'AVG_EFFECTIVENESS' },
        { label: 'Avg. Engagement Rate', value: 'AVG_ENGAGEMENT_RATE' },
        { label: 'Avg. Engagements', value: 'AVG_ENGAGEMENT' },
        { label: 'Avg. Exit Rate', value: 'AVG_EXIT_RATE' },
        { label: 'Avg. Reach', value: 'AVG_REACH' },
        { label: 'Followers', value: 'INSTAGRAM_FOLLOWERS' },
        { label: 'Followers Gained', value: 'FOLLOWERS_GAINED' },
        { label: 'Total Impressions', value: 'TOTAL_IMPRESSIONS' },
        { label: 'Total Engagements', value: 'TOTAL_ENGAGEMENTS' },
        { label: 'Total EMV', value: 'TOTAL_EMV' },
      ],
      INSTAGRAM_FEED_ONLY: [
        { label: 'Posts Feat. You', value: 'POST_COUNT' },
        { label: 'Recently Added', value: 'ADDED_TIME' },
        { label: 'Recently Published', value: 'PUBLISH_TIME' },
        { label: 'Avg. Effectiveness Rate', value: 'AVG_EFFECTIVENESS' },
        { label: 'Avg. Engagement Rate', value: 'AVG_ENGAGEMENT_RATE' },
        { label: 'Avg. Engagements', value: 'AVG_ENGAGEMENT' },
        { label: 'Avg. Estimated Reach', value: 'AVG_REACH' },
        { label: 'Followers', value: 'INSTAGRAM_FOLLOWERS' },
        { label: 'Followers Gained', value: 'FOLLOWERS_GAINED' },
        { label: 'Total Impressions', value: 'TOTAL_IMPRESSIONS' },
        { label: 'Total Engagements', value: 'TOTAL_ENGAGEMENTS' },
        { label: 'Total EMV', value: 'TOTAL_EMV' },
      ],
      INSTAGRAM_STORIES_ONLY: [
        { label: 'Posts Feat. You', value: 'POST_COUNT' },
        { label: 'Recently Added', value: 'ADDED_TIME' },
        { label: 'Recently Published', value: 'PUBLISH_TIME' },
        { label: 'Avg. Completion Rate', value: 'AVG_COMPLETION_RATE' },
        { label: 'Avg. Exit Rate', value: 'AVG_EXIT_RATE' },
        { label: 'Avg. Impressions', value: 'AVG_IMPRESSIONS' },
        { label: 'Total EMV', value: 'TOTAL_EMV' },
        { label: 'Total Impressions', value: 'TOTAL_IMPRESSIONS' },
      ],
      TIKTOK: [
        { label: 'Posts Feat. You', value: 'POST_COUNT' },
        { label: 'Recently Added', value: 'ADDED_TIME' },
        { label: 'Recently Published', value: 'PUBLISH_TIME' },
        { label: 'Avg. Engagement Rate', value: 'AVG_ENGAGEMENT_RATE' },
        { label: 'Avg. Engagements', value: 'AVG_ENGAGEMENT' },
        { label: 'Avg. Reach', value: 'AVG_REACH' },
        { label: 'Total Engagements', value: 'TOTAL_ENGAGEMENTS' },
        { label: 'Total EMV', value: 'TOTAL_EMV' },
        { label: 'Total Video Views', value: 'VIDEO_VIEWS' },
      ],
    },
    default: {
      sortOrder: 'DESC',
      selected: { label: 'Recently Published', value: 'PUBLISH_TIME' },
    },
  },
  DISCOVERY: {
    options: {
      TIKTOK: [
        { label: 'Followers', value: 'follower_count' },
        { label: 'Avg. Views', value: 'avg_views' },
        { label: 'Engagement Rate', value: 'engagement_rate' },
        { label: 'Relevance', value: 'relevance' },
      ],
      INSTAGRAM: [
        { label: 'Followers', value: 'followers' },
        { label: 'Avg. Engagements', value: 'instagram.engagements' },
        { label: 'Avg. Engagement Rate', value: 'instagram.engagement_rate' },
      ],
    },
    default: {
      TIKTOK: {
        sortOrder: 'DESC',
        selected: { label: 'Followers', value: 'follower_count' },
      },
      INSTAGRAM: {
        sortOrder: 'DESC',
        selected: { label: 'Followers', value: 'followers' },
      },
    },
  },
};

export const filterDetails = {
  YOUR_CREATORS: {
    scope: 'yourCreators',
    options: {
      restrictToPlatforms: [
        { label: 'TikTok', value: 'TIKTOK', icon: 'tiktok-color' },
        { label: 'Instagram', value: 'INSTAGRAM', icon: 'instagram-color' },
      ],
      tagFilterType: [
        {
          label: 'Match All Selected Tags',
          value: 'AND',
        },
        {
          label: 'Match Any Selected Tags',
          value: 'OR',
        },
      ],
      hasMentionedBrand: [
        { label: 'Mentioned Me', value: 'MENTIONED_ME' },
        { label: 'Has Not Mentioned Me', value: 'HAS_NOT_MENTIONED_ME' },
      ],
      connectionStatus: [
        { label: 'Not Invited', value: 'NOT_INVITED' },
        { label: 'Invite Sent', value: 'INVITATION_SENT' },
        { label: 'Connected', value: 'ACCEPTED' },
        { label: 'Disconnected', value: 'REVOKED' },
        { label: 'Expired', value: 'LAPSED' },
      ],
      instagramPostType: [
        { label: 'Feed & Reels', value: 'FEED_POSTS', icon: 'instagram-color' },
        { label: 'Stories', value: 'STORY', icon: 'instagram-story-color' },
      ],
      instagramInterests: discoveryInterests,
      instagramRegions: discoveryRegions,
      instagramIsBusiness: [
        { label: 'Professional', value: true },
        { label: 'Personal', value: false },
      ],
      tiktokTTCMStatus: [
        { label: 'Registered', value: 'IN' },
        { label: 'Not Registered', value: 'NOT_IN' },
        { label: 'Pending', value: 'INVITED' },
      ],
      tiktokRegions,
      tiktokInterests,
      tiktokAudienceAge: [
        { label: 'All Ages', value: 'ALL_AGES' },
        { label: '18-24', value: '18-24' },
        { label: '25-34', value: '25-34' },
        { label: '35+', value: '35+' },
      ],
      tiktokAudienceGender: [
        { label: 'All Genders', value: 'ALL_GENDERS' },
        { label: 'Male', value: 'MALE' },
        { label: 'Female', value: 'FEMALE' },
      ],
    },
    default: {
      restrictToPlatforms: ['TIKTOK', 'INSTAGRAM'],
      datePublished: {
        preset: 'ALL_TIME',
      },
      tagFilterType: 'OR',
      tags: [],
      hasMentionedBrand: [],
      instagramPostType: [],
      instagramConnectionStatus: [],
      instagramFollowersGreaterThan: undefined,
      instagramFollowersLessThan: undefined,
      instagramInterests: [],
      instagramRegions: [],
      instagramIsBusiness: [],
      tiktokConnectionStatus: [],
      tiktokFollowersGreaterThan: undefined,
      tiktokFollowersLessThan: undefined,
      tiktokTTCMStatus: [],
      tiktokInterests: [],
      tiktokRegions: [],
      tiktokAudienceRegions: [],
      tiktokAudienceAge: 'ALL_AGES',
      tiktokAudienceGender: 'ALL_GENDERS',
    },
  },
  TIKTOK_DISCOVERY: {
    scope: 'tikTokDiscovery',
    options: {
      age: tikTokDiscoveryAgeOptions,
      engagementRate: tikTokDiscoveryEngagementRateOptions,
      mobileOS: tikTokDiscoveryMobileOSOptions,
      genders: tikTokDiscoveryGenderOptions,
      interests: tiktokInterests,
      languages: tiktokLanguages,
      creatorCountryCode: tiktokRegions,
      audienceCountryCode: tiktokRegions,
    },
    default: {
      age: '',
      keywords: '',
      engagementRate: undefined,
      genders: '',
      interests: [],
      languages: [],
      followersGreaterThan: null,
      followersLessThan: null,
      minAvgViews: null,
      maxAvgViews: null,
      minCampaignCount: null,
      maxCampaignCount: null,
      creatorCountryCode: undefined,
      audienceCountryCode: undefined,
      mobileOS: undefined,
      responsiveness: false,
    },
  },
  INSTAGRAM_DISCOVERY: {
    scope: 'instagramDiscovery',
    options: {
      countries: discoveryRegions,
      interests: discoveryInterests,
      hasMentionedBrand: [
        { label: 'Mentioned Me', value: 'MENTIONED_ME' },
        { label: 'Has Not Mentioned Me', value: 'HAS_NOT_MENTIONED_ME' },
      ],
    },
    default: {
      followersGreaterThan: null,
      followersLessThan: null,
      interests: [],
      countries: [],
      hasMentionedBrand: [],
      hasPostedBetween: {
        preset: 'ALL_TIME',
      },
    },
  },
};

export const tiktokFilterInputLimit = {
  minFollowers: 100,
  maxFollowers: 10000000000,
  minAvgViews: 1,
  maxAvgViews: 100000000000,
  minCampaignCount: 0,
  maxCampaignCount: 100,
};

export const instagramFilterInputLimit = {
  minFollowers: 1000,
  maxFollowers: 1000000000,
};

export const inviteStatusDetails = {
  NOT_INVITED: {
    label: 'Not Invited',
    value: 'NOT_INVITED',
    severity: 'neutral',
  },
  INVITATION_SENT: {
    label: 'Invite Sent',
    value: 'INVITATION_SENT',
    severity: 'warning',
    tooltip: 'This account has not yet accepted your invitation to connect.',
  },
  ACCEPTED: {
    label: 'Connected',
    value: 'ACCEPTED',
    severity: 'positive',
    tooltip: 'This account has accepted your invite and is connected.',
  },
  LAPSED: {
    label: 'Lapsed',
    value: 'LAPSED',
    severity: 'statusNeutral',
    tooltip: 'The invite sent to this account has not been accepted in over 14 days.',
  },
  REVOKED: {
    label: 'Disconnected',
    value: 'REVOKED',
    severity: 'warning',
    tooltip:
      'This account has been disconnected. Select analytics and Stories will not import until they reconnect.',
  },
};

export const emptyStateDetails = {
  NO_SEARCH: {
    value: 'NO_SEARCH',
    icon: 'search',
    title: 'No results found',
    message:
      'You may want to try different keywords, checking for typos, or adjusting your filters',
  },
  NO_FILTER: {
    value: 'NO_FILTER',
    icon: 'user',
    title: 'No Creators Match Your Filters',
    message: 'You may want to adjust your filters to see more results',
  },
  NO_CREATORS: {
    value: 'NO_CREATORS',
    icon: 'user',
    title: 'No Creators Yet',
    message: 'Discover and add new creators that you want to work with',
  },
  NO_POSTS: {
    icon: 'message-alert',
    title: 'No Posts Found',
    message:
      "You haven't been featured by this creator account during the date range selected. Try adjusting the dates to discover more posts.",
  },
  NO_STORIES: {
    icon: 'message-alert',
    title: 'No Stories Found',
    message:
      "You haven't been featured by this creator account during the date range selected. Try adjusting the dates to discover more stories.",
  },
  NO_HANDLE: {
    icon: 'search',
    title: 'No TikTok Users Found',
    message: 'Try a different handle, checking for typos or adjusting your filters',
  },
  RATE_LIMITED: {
    icon: 'alert-triangle',
    title: 'Try Again',
    message:
      "There was a delay in retrieving creators' data, possibly due to high traffic or API issues. Please refresh the page in a few moments.",
  },
  GENERIC: {
    icon: 'alert-triangle',
    title: 'Try Again',
    message: 'Sorry, something went wrong. Please refresh the page to try again.',
  },
  NOT_IN_TTCM: {
    icon: 'message-alert',
    title: 'No Posts Found',
    message:
      'Due to data privacy/security restrictions, we can only show posts  when the creator has accepted a connection request or enrolled in TikTok Creator Marketplace.',
  },
  PRIVATE_INSTAGRAM_ACCOUNT: {
    icon: 'alert-triangle',
    title: 'Cannot Display Posts',
    message: 'This personal account is private and their feed posts cannot be imported.',
  },
};

export const locations = [
  {
    type: 'item',
    label: 'Argentina',
    value: 'Argentina',
    code: 'AR',
  },
  {
    type: 'item',
    label: 'Australia',
    value: 'Australia',
    code: 'AU',
  },
  {
    type: 'item',
    label: 'Austria',
    value: 'Austria',
    code: 'AT',
  },
  {
    type: 'item',
    label: 'Belgium',
    value: 'Belgium',
    code: 'BE',
  },
  {
    type: 'item',
    label: 'Brazil',
    value: 'Brazil',
    code: 'BR',
  },
  {
    type: 'item',
    label: 'Canada',
    value: 'Canada',
    code: 'CA',
  },
  {
    type: 'item',
    label: 'Chile',
    value: 'Chile',
    code: 'CL',
  },
  {
    type: 'item',
    label: 'China',
    value: 'China',
    code: 'CN',
  },
  {
    type: 'item',
    label: 'Colombia',
    value: 'Colombia',
    code: 'CO',
  },
  {
    type: 'item',
    label: 'Costa Rica',
    value: 'Costa Rica',
    code: 'CR',
  },
  {
    type: 'item',
    label: 'Croatia',
    value: 'Croatia',
    code: 'HR',
  },
  {
    type: 'item',
    label: 'Czech Republic',
    value: 'Czech Republic',
    code: 'CZ',
  },
  {
    type: 'item',
    label: 'Denmark',
    value: 'Denmark',
    code: 'DK',
  },
  {
    type: 'item',
    label: 'Dominican Republic',
    value: 'Dominican Republic',
    code: 'DO',
  },
  {
    type: 'item',
    label: 'Finland',
    value: 'Finland',
    code: 'FI',
  },
  {
    type: 'item',
    label: 'France',
    value: 'France',
    code: 'FR',
  },
  {
    type: 'item',
    label: 'Germany',
    value: 'Germany',
    code: 'DE',
  },
  {
    type: 'item',
    label: 'Greece',
    value: 'Greece',
    code: 'GR',
  },
  {
    type: 'item',
    label: 'Hungary',
    value: 'Hungary',
    code: 'HU',
  },
  {
    type: 'item',
    label: 'India',
    value: 'India',
    code: 'IN',
  },
  {
    type: 'item',
    label: 'Indonesia',
    value: 'Indonesia',
    code: 'ID',
  },
  {
    type: 'item',
    label: 'Ireland',
    value: 'Ireland',
    code: 'IE',
  },
  {
    type: 'item',
    label: 'Italy',
    value: 'Italy',
    code: 'IT',
  },
  {
    type: 'item',
    label: 'Israel',
    value: 'Israel',
    code: 'IL',
  },
  {
    type: 'item',
    label: 'Japan',
    value: 'Japan',
    code: 'JP',
  },
  {
    type: 'item',
    label: 'Kuwait',
    value: 'Kuwait',
    code: 'KW',
  },
  {
    type: 'item',
    label: 'Lebanon',
    value: 'Lebanon',
    code: 'LB',
  },
  {
    type: 'item',
    label: 'Malaysia',
    value: 'Malaysia',
    code: 'MY',
  },
  {
    type: 'item',
    label: 'Mexico',
    value: 'Mexico',
    code: 'MX',
  },
  {
    type: 'item',
    label: 'Morocco',
    value: 'Morocco',
    code: 'MA',
  },
  {
    type: 'item',
    label: 'Netherlands',
    value: 'Netherlands',
    code: 'NL',
  },
  {
    type: 'item',
    label: 'New Zealand',
    value: 'New Zealand',
    code: 'NZ',
  },
  {
    type: 'item',
    label: 'Nigeria',
    value: 'Nigeria',
    code: 'NG',
  },
  {
    type: 'item',
    label: 'Norway',
    value: 'Norway',
    code: 'NO',
  },
  {
    type: 'item',
    label: 'Pakistan',
    value: 'Pakistan',
    code: 'PK',
  },
  {
    type: 'item',
    label: 'Philippines',
    value: 'Philippines',
    code: 'PH',
  },
  {
    type: 'item',
    label: 'Poland',
    value: 'Poland',
    code: 'PL',
  },
  {
    type: 'item',
    label: 'Portugal',
    value: 'Portugal',
    code: 'PT',
  },
  {
    type: 'item',
    label: 'Romania',
    value: 'Romania',
    code: 'RO',
  },
  {
    type: 'item',
    label: 'Russia',
    value: 'Russia',
    code: 'RU',
  },
  {
    type: 'item',
    label: 'Serbia',
    value: 'Serbia',
    code: 'RS',
  },
  {
    type: 'item',
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
    code: 'SA',
  },
  {
    type: 'item',
    label: 'Singapore',
    value: 'Singapore',
    code: 'SG',
  },
  {
    type: 'item',
    label: 'South Africa',
    value: 'South Africa',
    code: 'ZA',
  },
  {
    type: 'item',
    label: 'South Korea',
    value: 'South Korea',
    code: 'KR',
  },
  {
    type: 'item',
    label: 'Spain',
    value: 'Spain',
    code: 'ES',
  },
  {
    type: 'item',
    label: 'Sweden',
    value: 'Sweden',
    code: 'SE',
  },
  {
    type: 'item',
    label: 'Switzerland',
    value: 'Switzerland',
    code: 'CH',
  },
  {
    type: 'item',
    label: 'Taiwan',
    value: 'Taiwan',
    code: 'TW',
  },
  {
    type: 'item',
    label: 'Thailand',
    value: 'Thailand',
    code: 'TH',
  },
  {
    type: 'item',
    label: 'Turkey',
    value: 'Turkey',
    code: 'TR',
  },
  {
    type: 'item',
    label: 'Ukraine',
    value: 'Ukraine',
    code: 'UA',
  },
  {
    type: 'item',
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
    code: 'AE',
  },
  {
    type: 'item',
    label: 'United Kingdom',
    value: 'United Kingdom',
    code: 'GB',
  },
  {
    type: 'item',
    label: 'United States',
    value: 'United States',
    code: 'US',
  },
  {
    type: 'item',
    label: 'Venezuela',
    value: 'Venezuela',
    code: 'VE',
  },
  {
    type: 'item',
    label: 'Vietnam',
    value: 'Vietnam',
    code: 'VN',
  },
];

export const months = [
  { type: 'item', label: 'January', value: 1 },
  { type: 'item', label: 'February', value: 2 },
  { type: 'item', label: 'March', value: 3 },
  { type: 'item', label: 'April', value: 4 },
  { type: 'item', label: 'May', value: 5 },
  { type: 'item', label: 'June', value: 6 },
  { type: 'item', label: 'July', value: 7 },
  { type: 'item', label: 'August', value: 8 },
  { type: 'item', label: 'September', value: 9 },
  { type: 'item', label: 'October', value: 10 },
  { type: 'item', label: 'November', value: 11 },
  { type: 'item', label: 'December', value: 12 },
];

const tooltipSuffixes = {
  AVG_ENGAGEMENT_RATE: `This is a 12 week rolling average across this creator's most recent posts.\n\nEngagement Rate = (Likes + Comments) / Followers`,
  AVG_EFFECTIVENESS: `This is a 12 week rolling average across this creator's most recent posts.\n\nEffectiveness = (Likes + Comments) / Est. Reach`,
};

export const creatorPerformanceMetricTooltips = {
  FOLLOWERS: 'The total number of followers this creator has on Instagram.',
  AVG_WEEKLY_POSTS:
    "The average number of posts published per week. This is a 12 week rolling average across this creator's most recent posts.",
  AVG_ENGAGEMENT_RATE: `The average engagement rate per post. ${tooltipSuffixes.AVG_ENGAGEMENT_RATE}`,
  AVG_ENGAGEMENTS: `The average number of engagements per post. This is a 12 week rolling average across this creator's most recent posts.\n\nEngagements = Likes + Comments`,
  AVG_EFFECTIVENESS: `The average effectiveness per post. ${tooltipSuffixes.AVG_EFFECTIVENESS}`,
  TOTAL_EMV:
    'Earned Media Value is calculated by a custom algorithm to quantify the value of earned content.',
  AVG_ESTIMATED_REACH: `The average estimated reach per post. This is a 12 week rolling average across this creator's most recent posts.`,
};

export const tikTokAccountMetricTooltips = {
  FOLLOWERS: 'The total number of followers this creator has on TikTok.',
  AVG_WEEKLY_POSTS:
    "The average number of posts published per week. This is a 12 week rolling average based on this creator's most recent posts.",
  AVG_ENGAGEMENT_RATE: `This creator's average engagement rate, based on their 30 most recent posts. \n\nEngagement Rate = (Likes + Shares + Comments) / Video Views`,
  AVG_ENGAGEMENTS: `The average number of engagements per post, based on this creator's 30 most recent posts. .\n\nEngagements = Likes + Shares + Comments`,
  AVG_VIDEO_VIEWS: 'The average number of video views per post, based on the 30 most recent posts',
  AVG_COMMENTS: 'The average number of comments per post, based on their 30 most recent posts',
};

export const tikTokAccountPerformanceMetricTooltips = {
  SIX_SECONDS_VIEW_RATE:
    "The average number of views that watch this creator's videos for at least six seconds or more",
  AVG_ENGAGEMENT_RATE:
    "This creator's average engagement rate, based on their 30 most recent posts. \n\nEngagement Rate = (Likes + Shares + Comments) / Video Views",
  TOTAL_EMV:
    "This creator's total Earned Media Value. This value is based on the creator's audience size, media type, and number of engagements.",
  AVG_COMPLETION:
    "The average number of times this creator's videos are played to completion, based on their 30 most recent posts",
  FOLLOWERS_GROWTH:
    "The total growth rate of this creator's followers compared to 30 days ago. This is unavailable for creators added within the last 30 days.",
  AVG_LIKES: 'The average number of likes per post, based on the 30 most recent posts',
  TOTAL_LIKES: 'The total number of likes this creator has received',
  AVG_SHARES: 'The average number of likes per post, based on the 30 most recent posts',
  AVG_COMMENTS: 'The average number of comments per post, based on their 30 most recent posts',
  VIDEO_COUNT: 'The total number of videos this creator has published',
};

export const tikTokCreatorMetricTooltips = {
  FOLLOWERS: 'The total number of followers this creator has on TikTok',
  AVG_ENGAGEMENT_RATE: `This creator's average engagement rate, based on their 30 most recent posts. \n\nEngagement Rate = (Likes + Shares + Comments) / Video Views`,
  AVG_ENGAGEMENTS: `The average number of engagements per post, based on this creator's 30 most recent posts. \n\nEngagements = Likes + Shares + Comments`,
  AVG_VIDEO_VIEWS: 'The average number of video views per post, based on the 30 most recent posts',
  AVG_WEEKLY_POSTS:
    "The average number of posts published per week. This is a 12 week rolling average based on this creator's most recent posts",
  EMV: "The average Earned Media Value of this creator's 30 most recent posts. This value is based on the creator's audience size, media type, and number of engagements",
};

export const tiktokPostInsightsMetricTooltips = {
  AVG_LIKES:
    'The average likes per post that has tagged or mentioned your brand, based on their 30 most recent posts featuring you',
  AVG_COMMENTS:
    'The average comments per post that has tagged or mentioned your brand, based on their 30 most recent posts featuring you',
  AVG_SHARES:
    'The average shares per post that has tagged or mentioned your brand, based on their 30 most recent posts featuring you',
  AVG_VIDEO_VIEWS:
    'The average video views per post that has tagged or mentioned your brand, based on their 30 most recent posts featuring you',
  TOTAL_EMV:
    "The total Earned Media Value across this creator's 30 most recent posts featuring you",
  AVG_ENGAGEMENT:
    "The average number of engagements per post that have tagged or mentioned your brand, based on this creator's 30 most recent posts featuring you. \n\nEngagements = Likes + Shares + Comments",
  AVG_ENGAGEMENT_RATE:
    "The average engagement rate per post that have tagged or mentioned your brand, based on this creator's 30 most recent posts featuring you. \n\nEngagement Rate = (Likes + Shares + Comments) / Video Views",
};

export const instagramPostInsightsMetricTooltips = {
  AVG_ENGAGEMENT_RATE:
    'The average engagement rate per post. This is a 12 week rolling average across this creator’s most recent posts. \n\nEngagement Rate = (Likes + Comments) / Followers',
  AVG_ENGAGEMENT_PER_POST:
    'The average sum of the likes, comments and saves on the creator’s posts over the last 12 weeks',
  TOTAL_FOLLOWERS_GAINED:
    'The number of followers this creator has gained for you through their posts',
  AVG_EFFECTIVENESS:
    'The average effectiveness per post. This is a 12 week rolling average across this creator’s most recent posts. \n\nEffectiveness = Likes + Comments / Est. Reach',
  TOTAL_EMV:
    'Earned Media Value is calculated by a custom algorithm to quantify the value of earned content',
};

export const instagramStoryInsightsMetricTooltips = {
  AVG_REACH: 'The average unique accounts reached per Story',
  AVG_IMPRESSIONS: "The average number of times this creator's Stories were displayed to users",
  AVG_EXIT_RATE:
    "The average exit rate of this creator's Stories. \n\nExit Rate = Exits / Impressions",
  AVG_COMPLETION_RATE:
    "The average completion rate of this creator's Stories. \n\nCompletion Rate = (Impression - Exits) / Impressions",
};

export const instagramPostMetrics = ['likeCount', 'commentsCount'];
export const instagramPostMetricLabels = {
  likeCount: 'Likes',
  commentsCount: 'Comments',
  impressions: 'Impressions',
};

export const instagramRelationshipPostMetrics = ['engagement', 'likeCount', 'commentsCount', 'emv'];
export const instagramRelationshipPostMetricLabels = {
  engagement: 'Engagement Rate',
  likeCount: 'Likes',
  commentsCount: 'Comments',
  emv: 'EMV',
};

export const instagramStoryRelationshipPostMetrics = [
  'completion_rate',
  'impressions',
  'reach',
  'emv',
];
export const instagramStoryRelationshipPostMetricLabels = {
  completion_rate: 'Completion Rate',
  impressions: 'Impressions',
  reach: 'Reach',
  emv: 'EMV',
};

export const tikTokAccountFeedPostMetrics = ['views', 'likes', 'comments', 'shares'];
export const tikTokAccountFeedPostMetricLabels = {
  views: 'Video Views',
  likes: 'Likes',
  comments: 'Comments',
  shares: 'Shares',
};

export const tikTokCreatorFeedPostMetrics = ['views', 'engagementCount', 'engagementRate', 'emv'];
export const tikTokCreatorFeedPostMetricLabels = {
  views: 'Video Views',
  engagementCount: 'Engagements',
  engagementRate: 'Eng. Rate',
  emv: 'EMV',
};

export const tikTokCreatorRIQMetricsConnected = [
  'views',
  'totalEngagements',
  'engagementRate',
  'emv',
];
export const tikTokCreatorRIQConnectedMetricLabels = {
  views: 'Video Views',
  totalEngagements: 'Engagements',
  engagementRate: 'Eng. Rate',
  emv: 'EMV',
};

export const tikTokCreatorRIQMetricsNotConnected = ['views', 'likes', 'comments', 'emv'];
export const tikTokCreatorRIQNotConnectedMetricLabels = {
  views: 'Video Views',
  likes: 'Likes',
  comments: 'Comments',
  emv: 'EMV',
};

export const creatorSource = {
  INSTAGRAM: 'INSTAGRAM',
  TIKTOK: 'TIKTOK',
};

export const instagramRelationshipPostListTypeOptions = {
  FEED: { value: 'IG_FEED', label: 'Entire Feed' },
  RIQ: { value: 'IG_RIQ', label: 'Featuring You' },
};

export const tikTokConnectedRIQPostListTypeOptions = {
  FEED: { value: 'TT_CREATOR_FEED', label: 'Entire Feed' },
  RIQ: { value: 'TT_CREATOR_RIQ_CONNECTED', label: 'Posts Featuring You' },
};

export const tikTokNotConnectedRIQPostListTypeOptions = {
  FEED: { value: 'TT_CREATOR_FEED', label: 'Entire Feed' },
  RIQ: { value: 'TT_CREATOR_RIQ_NOT_CONNECTED', label: 'Posts Featuring You' },
};

export const tikTokCreatorConnectedSortOptions = {
  ENGAGEMENT_RATE: {
    value: 'ENGAGEMENT_RATE',
    label: 'Engagement Rate',
    sortValue: 'TIKTOK_ENGAGEMENT_RATE',
  },
  ENGAGEMENTS: {
    value: 'ENGAGEMENTS',
    label: 'Engagements',
    sortValue: 'TIKTOK_TOTAL_ENGAGEMENTS',
  },
  VIDEO_VIEWS: {
    value: 'VIDEO_VIEWS',
    label: 'Video Views',
    sortValue: 'TIKTOK_VIEWS',
  },
  LIKES: {
    value: 'LIKES',
    label: 'Likes',
    sortValue: 'TIKTOK_LIKES',
  },
  COMMENTS: {
    value: 'COMMENTS',
    label: 'Comments',
    sortValue: 'TIKTOK_COMMENTS',
  },
  SHARES: {
    value: 'SHARES',
    label: 'Shares',
    sortValue: 'TIKTOK_SHARES',
  },
};

export const tikTokCreatorNotConnectedSortOptions = {
  VIDEO_VIEWS: {
    value: 'VIDEO_VIEWS',
    label: 'Video Views',
    sortValue: 'TIKTOK_VIEWS',
  },
  LIKES: {
    value: 'LIKES',
    label: 'Likes',
    sortValue: 'TIKTOK_LIKES',
  },
  COMMENTS: {
    value: 'COMMENTS',
    label: 'Comments',
    sortValue: 'TIKTOK_COMMENTS',
  },
  SHARES: {
    value: 'SHARES',
    label: 'Shares',
    sortValue: 'TIKTOK_SHARES',
  },
};

export const instagramRelationshipPostSortOptions = {
  COMMENTS: { value: 'COMMENTS', label: 'Comments', sortValue: 'INSTAGRAM_TOTAL_COMMENTS' },
  EFFECTIVENESS: {
    value: 'EFFECTIVENESS',
    label: 'Effectiveness',
    sortValue: 'INSTAGRAM_EFFECTIVENESS',
  },
  EMV: { value: 'EMV', label: 'EMV', sortValue: 'INSTAGRAM_EMV' },
  ENGAGEMENT_RATE: {
    value: 'ENGAGEMENT_RATE',
    label: 'Engagement Rate',
    sortValue: 'INSTAGRAM_ENGAGEMENT_RATE',
  },
  FOLLOWERS_GAINED: {
    value: 'FOLLOWERS_GAINED',
    label: 'Followers Gained',
    sortValue: 'INSTAGRAM_FOLLOWERS_GAINED',
  },
  IMPRESSIONS: {
    value: 'IMPRESSIONS',
    label: 'Impressions',
    sortValue: 'INSTAGRAM_TOTAL_IMPRESSIONS',
  },
  LIKES: { value: 'LIKES', label: 'Likes', sortValue: 'INSTAGRAM_TOTAL_LIKES' },
  REACH: { value: 'REACH', label: 'Reach', sortValue: 'INSTAGRAM_REACH' },
  RECENTLY_PUBLISHED: {
    value: 'RECENTLY_PUBLISHED',
    label: 'Recently Published',
    sortValue: 'DATE',
  },
  SAVES: { value: 'SAVES', label: 'Saves', sortValue: 'INSTAGRAM_SAVES' },
  TOTAL_ENGAGEMENT: {
    value: 'TOTAL_ENGAGEMENT',
    label: 'Total Engagement',
    sortValue: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
  },
  VIDEO_VIEWS: { value: 'VIDEO_VIEWS', label: 'Video Views', sortValue: 'INSTAGRAM_VIDEO_VIEWS' },
};
export const instagramRelationshipStorySortOptions = {
  COMPLETION_RATE: {
    value: 'COMPLETION_RATE',
    label: 'Completion Rate',
    sortValue: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
  },
  EXISTS: { value: 'EXISTS', label: 'Exists', sortValue: 'INSTAGRAM_STORY_FRAME_EXITS' },
  EXIT_RATE: {
    value: 'EXIT_RATE',
    label: 'Exit Rate',
    sortValue: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
  },
  REACH: { value: 'REACH', label: 'Reach', sortValue: 'INSTAGRAM_STORY_FRAME_REACH' },
  RECENTLY_PUBLISHED: {
    value: 'RECENTLY_PUBLISHED',
    label: 'Recently Published',
    sortValue: 'DATE',
  },
};

export const instagramRelationshipPostTypeOptions = {
  POSTS: { value: 'POSTS', label: 'Instagram Posts' },
  STORIES: { value: 'STORIES', label: 'Instagram Stories' },
};
export const creatorPostsDefaultOptions = {
  postListType: instagramRelationshipPostListTypeOptions.FEED,
  postSort: {
    sortOrder: 'DESC',
    selected: {
      label: 'Recently Published',
      value: 'RECENTLY_PUBLISHED',
    },
  },
  storySort: {
    sortOrder: 'DESC',
    selected: {
      label: 'Recently Published',
      value: 'RECENTLY_PUBLISHED',
    },
  },
  postType: instagramRelationshipPostTypeOptions.POSTS,
  dateRangeFilter: {
    preset: 'ALL_TIME',
    range: undefined,
  },
};

export const tikTokCreatorDefaultSort = {
  sortOrder: 'DESC',
  selected: {
    label: 'Video Views',
    value: 'VIDEO_VIEWS',
  },
};

export const creatorInviteButtonText = 'Invite to Connect';

export const creatorInvitationSelectData = {
  INSTAGRAM: {
    ACCEPTED: {
      label: inviteStatusDetails.ACCEPTED.label,
      value: inviteStatusDetails.ACCEPTED.value,
      tooltip: inviteStatusDetails.ACCEPTED.tooltip,
      options: [
        {
          value: 'REVOKED',
          label: 'Disconnect Account',
          icon: 'link-broken',
          class: 'dash-text-danger dash-fill-icon-danger',
        },
      ],
    },
    INVITATION_SENT: {
      label: inviteStatusDetails.INVITATION_SENT.label,
      value: inviteStatusDetails.INVITATION_SENT.value,
      tooltip: inviteStatusDetails.INVITATION_SENT.tooltip,
      options: [
        { value: 'RESEND', label: 'Resend Invite', icon: 'send' },
        { value: 'RESET', label: 'Reset Status', icon: 'refresh-cw' },
      ],
    },
    LAPSED: {
      label: inviteStatusDetails.LAPSED.label,
      value: inviteStatusDetails.LAPSED.value,
      tooltip: inviteStatusDetails.LAPSED.tooltip,
      options: [
        { value: 'RESEND', label: 'Resend Invite', icon: 'send' },
        { value: 'RESET', label: 'Reset Status', icon: 'refresh-cw' },
      ],
    },
    REVOKED: {
      label: inviteStatusDetails.REVOKED.label,
      value: inviteStatusDetails.REVOKED.value,
      tooltip: inviteStatusDetails.REVOKED.tooltip,
      options: [
        { value: 'RESEND', label: 'Resend Invite', icon: 'send' },
        { value: 'RESET', label: 'Reset Status', icon: 'refresh-cw' },
      ],
    },
    NOT_INVITED: {
      label: inviteStatusDetails.NOT_INVITED.label,
      value: inviteStatusDetails.NOT_INVITED.value,
      tooltip: inviteStatusDetails.NOT_INVITED.tooltip,
    },
    NOT_PROFESSIONAL: {
      label: 'Cannot Connect',
      value: 'NOT_PROFESSIONAL',
      tooltip: 'Only professional accounts can be connected.',
    },
  },
};

export const tikTokDiscoveryFilterPanels = {
  CREATORS: 'creators',
  CREATORS_AUDIENCE: 'creatorsAudience',
};

export const searchPlaceholders = {
  TIKTOK: {
    LANGUAGES: 'Search Language',
  },
};

export const creatorPostsTypeConfigs = {
  IG_FEED: {
    value: 'IG_FEED',
    metricKeys: instagramPostMetrics,
    metricLabels: instagramPostMetricLabels,
  },
  IG_RIQ: {
    value: 'IG_RIQ',
    metricKeys: instagramRelationshipPostMetrics,
    metricLabels: instagramRelationshipPostMetricLabels,
  },
  IG_STORY: {
    value: 'IG_STORY',
    metricKeys: instagramStoryRelationshipPostMetrics,
    metricLabels: instagramStoryRelationshipPostMetricLabels,
  },
  TT_ACCOUNT_FEED: {
    value: 'TT_ACCOUNT_FEED',
    metricKeys: tikTokAccountFeedPostMetrics,
    metricLabels: tikTokAccountFeedPostMetricLabels,
  },
  TT_CREATOR_FEED: {
    value: 'TT_CREATOR_FEED',
    metricKeys: tikTokCreatorFeedPostMetrics,
    metricLabels: tikTokCreatorFeedPostMetricLabels,
  },
  TT_CREATOR_RIQ_CONNECTED: {
    value: 'TT_CREATOR_RIQ_CONNECTED',
    metricKeys: tikTokCreatorRIQMetricsConnected,
    metricLabels: tikTokCreatorRIQConnectedMetricLabels,
  },
  TT_CREATOR_RIQ_NOT_CONNECTED: {
    value: 'TT_CREATOR_RIQ_NOT_CONNECTED',
    metricKeys: tikTokCreatorRIQMetricsNotConnected,
    metricLabels: tikTokCreatorRIQNotConnectedMetricLabels,
  },
};

export const tikTokCreatorBadges = {
  EXPERIENCED: 'tiktok-exprienced',
  PROFILE_COMPLETE: 'tiktok-profile-complete',
  RESPONSIVE: 'tiktok-responsive',
};

export const tikTokCreatorBadgeTooltips = {
  EXPERIENCED: 'Experienced',
  PROFILE_COMPLETE: 'Profile Complete',
  RESPONSIVE: 'Responsive',
};

export const instagramPostTypeForInsights = {
  FEED_POSTS: {
    label: 'Post',
    value: 'FEED_POSTS',
  },
  STORY: {
    label: 'Story',
    value: 'STORY',
  },
};
