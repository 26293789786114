<template>
  <div :ref="productCardRef" class="product-card" :data-id="product.id">
    <div :class="['product-overlay', { selected: isSelected }]">
      <MediaTileCheckbox
        v-if="enableProductSelection"
        :is-selected="isSelected"
        @toggled="toggleMultiSelectClicked"
      />
      <Icon
        v-if="enableEditingProduct"
        data-cy="EditProductIcon"
        class="edit-product-icon"
        name="pencil"
        medium
        :hover-color="editIconHoverColor"
        @click.prevent.stop="openEditProductPopup"
      />
    </div>
    <div :class="['select-box', { selected: isSelected }]" />
    <Badge v-if="product.archived" class="archived-badge" text="Archived" color="grey" />
    <div v-if="product.imageUrl" class="image-wrapper">
      <Picture :url="product.imageUrl" :width="150" :height="200" fit="cover" />
    </div>
    <div v-else class="image-wrapper no-image">
      <Icon xlarge name="fileLandscapeImage" />
    </div>
    <section class="product">
      <div class="product-info">
        <div class="clicks">{{ product.clicks.toLocaleString() }} Clicks</div>
        <p v-if="product.title" v-tooltip="product.title" v-line-clamp="2" class="title">
          {{ product.title }}
        </p>
        <p
          v-if="product.sourceId"
          :ref="productSourceIdRef"
          v-tooltip="productSourceIdTooltip"
          class="optional-info"
        >
          {{ product.sourceId }}
        </p>
        <p v-if="product.price" class="optional-info price">
          {{ product.price }}
        </p>
        <AvailabilityLabel
          v-if="product.availability"
          class="availability"
          :availability="product.availability"
        />
      </div>
      <div v-tooltip="productSourceTooltip" class="product-source">
        <Icon
          v-tooltip="productSourceIconTooltip"
          class="source-type"
          :name="importSourceIcon"
          medium
        />
        <DropdownChip
          v-if="productOverrideItems.length"
          :text="overrideChipText"
          :dropdown-items="productOverrideItems"
        />
        <span
          v-else
          :ref="productSourceRef"
          v-tooltip="productSourceTooltip"
          class="product-source-text"
          data-cy="ProductCardSourceText"
        >
          {{ product.url }}
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import AvailabilityLabel from '@/components/core/products/AvailabilityLabel.vue';
import Badge from '@/components/Badge.vue';
import Icon from '@/components/foundation/Icon.vue';
import Picture from '@/components/foundation/Picture.vue';
import { productImportSourceEnum, productCatalogSourceEnum } from '@/app/library/constants';
import { useMediaSelectStore } from '@/stores/media-select';
import DropdownChip from '@/components/foundation/DropdownChip.vue';
import MediaTileCheckbox from '@/components/MediaTileCheckbox.vue';
import { getCountryName } from '@/utils/countries';
import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ProductCard',
  components: {
    AvailabilityLabel,
    Badge,
    Icon,
    MediaTileCheckbox,
    Picture,
    DropdownChip,
  },
  props: {
    product: { type: Object, default: null },
    enableProductSelection: { type: Boolean, default: false },
    enableEditingProduct: { type: Boolean, default: false },
  },
  emits: ['selectProduct', 'openEditProductPopup'],
  data() {
    return {
      productSourceIdOverflows: false,
      overflowObserver: new ResizeObserver(this.checkTextOverflowing),
      editIconHoverColor: colours.ACTION.ACTION_500,
      showEditProductPopup: false,
      productSourceOverflows: false,
    };
  },
  computed: {
    ...mapStores(useMediaSelectStore, useTrackingStore),
    productSourceIdTooltip() {
      if (!this.productSourceIdOverflows) {
        return '';
      }
      return {
        content: this.product.sourceId,
        theme: 'dh-tooltip-medium',
        delay: { show: 600, hide: 0 },
      };
    },
    productSourceTooltipData() {
      return {
        content: this.product.url,
        theme: 'dh-tooltip-medium',
        delay: { show: 600, hide: 0 },
        popperTriggers: ['hover'],
      };
    },
    productSourceTooltip() {
      if (!this.productOverrideItems.length && this.productSourceOverflows) {
        return this.productSourceTooltipData;
      }
      return '';
    },
    productSourceIconTooltip() {
      if (this.productOverrideItems.length) {
        return this.productSourceTooltipData;
      }
      return '';
    },
    productCardRef() {
      return `product-card-${this.product.id}`;
    },
    productSourceRef() {
      return `${this.product.id}source`;
    },
    productSourceIdRef() {
      return `${this.product.id}sourceId`;
    },
    importSourceIcon() {
      switch (this.importSource) {
        case productImportSourceEnum.SINGLE_PRODUCT_LINK:
          return 'link';
        case productImportSourceEnum.FACEBOOK_PRODUCT_CATALOG:
          return 'facebook';
        case productImportSourceEnum.OTHER_PRODUCT_FEED:
          return 'synchronize';
        default:
          return '';
      }
    },
    isSelected() {
      if (this.product == null) {
        return null;
      }
      return this.mediaSelectStore.multiSelectSelectedItems.some(
        (item) => item.id === this.product.id,
      );
    },
    importSource() {
      if (!this.product.productFeed?.id) {
        return productImportSourceEnum.SINGLE_PRODUCT_LINK;
      }
      if (!this.product.productFeed.productCatalog) {
        return productImportSourceEnum.OTHER_PRODUCT_FEED;
      }
      if (this.product.productFeed.productCatalog.source === productCatalogSourceEnum.FACEBOOK) {
        return productImportSourceEnum.FACEBOOK_PRODUCT_CATALOG;
      }
      return null;
    },
    productOverrideItems() {
      if (!this.product.productOverrides?.length) return [];
      return this.product.productOverrides
        .map((item) => {
          return {
            text: getCountryName(item.override),
            iconName: item.url ? 'expand' : null,
            iconPosition: 'right',
            action: item.url ? () => this.handleDropdownChipItemClicked(item.url) : null,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    overrideChipText() {
      const length = this.productOverrideItems.length;
      const appendedString = length > 1 ? 'locales' : 'locale';
      return `${length} ${appendedString}`;
    },
  },
  mounted() {
    nextTick(() => {
      if (this.$refs[this.productCardRef]) {
        this.checkTextOverflowing();
        this.overflowObserver.observe(this.$refs[this.productCardRef]);
      }
    });
  },
  beforeUnmount() {
    if (!this.$refs[this.productCardRef]) return;
    this.overflowObserver.unobserve(this.$refs[this.productCardRef]);
  },
  methods: {
    toggleMultiSelectClicked() {
      if (!this.isSelected) {
        this.mediaSelectStore.addItemToMultiSelect({ item: this.product });
      } else {
        this.mediaSelectStore.removeItemFromMultiSelect({ item: this.product });
      }
    },
    checkTextOverflowing() {
      const productSourceIdEl = this.$refs[this.productSourceIdRef];
      const productSourceEl = this.$refs[this.productSourceRef];
      if (productSourceIdEl) {
        this.productSourceIdOverflows =
          productSourceIdEl.scrollWidth > productSourceIdEl.clientWidth;
      }

      if (productSourceEl) {
        this.productSourceOverflows = productSourceEl.scrollWidth > productSourceEl.clientWidth;
      }
    },
    openEditProductPopup() {
      this.$emit('openEditProductPopup', this.product);
    },
    handleDropdownChipItemClicked(url) {
      window.open(url, '_blank').focus();
      this.trackingStore.track('Product URL Clicked', {
        overrideClicked: true,
      });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.product-card {
  position: relative;
  background: var(--background-0);
  height: var(--height-192);
  min-width: var(--width-300);
  max-width: var(--width-420);
  box-shadow: var(--shadow-1);
  border-radius: var(--round-corner);
  font-size: var(--x14);
  font-weight: var(--font-normal);
  color: var(--text-secondary);
  transition: var(--transition-all);
  display: flex;
  text-align: left;

  .product-overlay {
    width: 100%;
    border: var(--space-4) solid transparent;
    border-top-left-radius: var(--round-corner);
    border-top-right-radius: var(--round-corner);
    opacity: 0;
    position: absolute;
    transition: var(--transition-all);
    visibility: hidden;
    z-index: var(--z-index-sticky);

    &.selected {
      opacity: 1;
      visibility: visible;
    }

    :deep(.circular-checkbox input#termCheck) {
      pointer-events: none;
    }

    .edit-product-icon {
      visibility: hidden;
      position: absolute;
      top: var(--space-4);
      right: var(--space-8);
    }
  }

  &:hover {
    box-shadow: var(--shadow-2);
    cursor: pointer;
    transform: var(--hover-move);

    .product-overlay {
      opacity: 1;
      visibility: visible;

      .edit-product-icon {
        visibility: visible;
      }
    }
  }

  .select-box {
    border: var(--space-4) solid var(--action-500);
    border-radius: var(--space-6);
    height: 100%;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    width: 100%;
    z-index: var(--z-index-sticky);

    &.selected {
      visibility: visible;
    }
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: var(--round-corner) 0 0 var(--round-corner);
    overflow: hidden;
    width: var(--width-124);
    flex-shrink: 0;
  }

  .image-wrapper::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .image-wrapper picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    :deep(img) {
      height: 100%;
      object-fit: cover;
    }
  }
}

.product {
  display: block;
  padding: var(--space-16);
  position: relative;
  width: calc(100% - var(--width-124));

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .title {
      font-size: var(--x16);
      color: var(--text-primary);
      overflow-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: var(--space-20);
      padding-bottom: var(--space-2);
    }

    .optional-info {
      color: var(--text-secondary);
      font-size: var(--x12);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .clicks {
      font-size: var(--x12);
      line-height: var(--space-13);
      margin-bottom: var(--space-8);
    }
  }

  .product-source {
    display: flex;
    position: absolute;
    bottom: 0;
    margin-bottom: var(--space-16);
    width: 100%;
    align-items: center;

    .product-source-text {
      font-size: var(--x14);
      color: var(--text-primary);
      margin: 0 var(--space-32) 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .source-type {
      flex-shrink: 0;
      margin-right: var(--space-8);
    }
  }
}

.product-card .no-image {
  background: var(--background-300);
  border-radius: var(--round-corner-small);

  svg {
    fill: var(--border);
  }
}

.archived-badge {
  position: absolute;
  right: var(--space-16);
  top: calc(-1 * var(--space-8));
  width: var(--width-68);
  z-index: var(--z-index-sticky);
}
</style>
