import { defineStore } from 'pinia';
import isArray from 'lodash/isArray';
import sum from 'lodash/sum';
import snakeCase from 'lodash/snakeCase';
import dayjs from 'dayjs';
import enumTypes from '@/app/library/constants';
import * as CampaignsAPI from '@/apis/campaigns';
import * as CreatorsAPI from '@/apis/creators';
import { usePdfStore } from '@/stores/pdf';
import { useAuthStore } from '@/stores/auth';
import { DashboardAPI } from '@/apis';
import { originForReport, constants, mediaSourceType } from '@/config';
import { orderedReportChannelList } from '@/app/landingPage/constants';
import { stringifyQuery } from '@/utils/query';
import {
  browserStorageGetItem,
  browserStorageRemoveItem,
  browserStorageSetItem,
} from '@/utils/browserStorage';
import { timeScaleTypes } from '@/app/campaigns/constants';
import { useTrackingStore } from '@/stores/tracking';
import { getSourceType, getCreatorsDateRange } from '@/app/campaigns/utils';

// Amount of time in milliseconds google analytics campaigns should be considered valid for in browser storage
const GOOGLE_ANALYTICS_CAMPAIGNS_CACHE_LIFESPAN = 300000; // 5 minutes

function groupCampaignStatsByChannel(campaignStatsResponse) {
  const summaryCampaignStatsByChannel = {};
  Object.entries(campaignStatsResponse).forEach(([date, channelStats]) => {
    Object.keys(channelStats).forEach((channel) => {
      if (channel in summaryCampaignStatsByChannel) {
        summaryCampaignStatsByChannel[channel] = {
          ...summaryCampaignStatsByChannel[channel],
          ...{ [date]: channelStats[channel] },
        };
      } else {
        summaryCampaignStatsByChannel[channel] = { [date]: channelStats[channel] };
      }
    });
  });
  return summaryCampaignStatsByChannel;
}

export function getPlatformFromMediaSource(source) {
  switch (source.toUpperCase()) {
    case mediaSourceType.FACEBOOK_LINK:
    case mediaSourceType.FACEBOOK_TEXT:
    case mediaSourceType.FACEBOOK_TEXT_LINK:
      return mediaSourceType.FACEBOOK;
    case mediaSourceType.TWITTER_LINK:
    case mediaSourceType.TWITTER_TEXT:
      return mediaSourceType.TWITTER;
    default:
      return source.toUpperCase();
  }
}

function platformsAddedProperties(media, galleries) {
  const platforms = new Set();
  const platformMediaAdded = {};
  media?.forEach((item) => {
    const platform = getPlatformFromMediaSource(item.source || item.postType);
    platforms.add(platform);
    platformMediaAdded[platform] = (platformMediaAdded[platform] ?? 0) + 1;
  });
  galleries?.forEach((item) => {
    platforms.add(item.galleryType);
    platformMediaAdded[item.galleryType] = (platformMediaAdded[item.galleryType] ?? 0) + 1;
  });
  return {
    platformsAdded: Array.from(platforms),
    platformsTotalMediaAdded: platformMediaAdded,
  };
}

function getHashtagsAdded(oldCampaign, newCampaign) {
  const oldCampaignHashtags = oldCampaign?.hashtags?.map((hashtag) => hashtag.name) ?? [];
  const newCampaignHashtags = newCampaign?.hashtags?.map((hashtag) => hashtag.name) ?? [];
  return newCampaignHashtags.filter((hashtag) => !oldCampaignHashtags.includes(hashtag));
}

function getHashtagsRemoved(oldCampaign, newCampaign) {
  const oldCampaignHashtags = oldCampaign?.hashtags?.map((hashtag) => hashtag.name) ?? [];
  const newCampaignHashtags = newCampaign?.hashtags?.map((hashtag) => hashtag.name) ?? [];
  return oldCampaignHashtags.filter((hashtag) => !newCampaignHashtags.includes(hashtag));
}

function getUpdateActionName(oldCampaign, newCampaign) {
  if (oldCampaign.name !== newCampaign.name) {
    if (
      getHashtagsAdded(oldCampaign, newCampaign).length > 0 ||
      getHashtagsRemoved(oldCampaign, newCampaign).length > 0
    ) {
      return 'Name and hashtag change';
    }
    return 'Name change';
  }
  return 'Hashtag change';
}

function isCompetitiveType(mediaItem) {
  const isFormattedSourceType = enumTypes.COMPETITIVE_LIST.includes(
    getSourceType(mediaItem)?.split(':')[1],
  );
  const isSourceType = enumTypes.COMPETITIVE_LIST.includes(getSourceType(mediaItem));
  const isType = enumTypes.COMPETITIVE_LIST.includes(mediaItem?.type);
  return isFormattedSourceType || isSourceType || isType;
}

function isUgcType(mediaItem, isOwnedMedia = true) {
  const isFormattedSourceType = enumTypes.UGC_LIST.includes(
    getSourceType(mediaItem)?.split(':')[1],
  );
  const isSourceType = enumTypes.UGC_LIST.includes(getSourceType(mediaItem));
  const isType = enumTypes.UGC_LIST.includes(mediaItem?.type);
  const isCompetitive = isCompetitiveType(mediaItem);
  if (isOwnedMedia) {
    return !isFormattedSourceType && !isSourceType && !isType && !isCompetitive;
  }
  return isFormattedSourceType || isSourceType || isType;
}

function filterMedia(media, limitSource, page) {
  let ugcMedia = [];
  let relationshipMedia = [];
  const ownedMedia = media?.filter((item) => isUgcType(item, true));
  if (enumTypes.UGC_LIST.includes(limitSource)) {
    ugcMedia = media?.filter((item) => isUgcType(item, false));
  } else {
    const isRelationships = (page ?? '').startsWith('relationships.');
    ugcMedia = media?.filter((item) => !isRelationships && isUgcType(item, false));
    relationshipMedia = media?.filter((item) => isRelationships && isUgcType(item, false)) ?? [];
    // add relationshipId (int or null) for each object
    relationshipMedia.reduce((results, mediaItem) => {
      mediaItem.relationshipId = mediaItem.relationship?.id ?? null;
      if (mediaItem.relationshipId === null) {
        mediaItem.relationshipId = mediaItem.instagram?.relationship?.id ?? null;
      }
      results.push(mediaItem);
      return results;
    }, []);
  }

  return {
    ownedMedia,
    ugcMedia,
    relationshipMedia,
  };
}

export const useCampaignsStore = defineStore('campaigns', {
  resetOnBrandChange: true,
  state: () => ({
    campaign: {},
    campaignLowestPerformingVideos: [],
    campaignTopPerformingVideos: [],
    campaigns: [],
    createCampaign: null,
    summaryCampaigns: [],
    campaignStats: {},
    summaryCampaignStatsByChannel: {},
    campaignTotalStats: {},
    campaignsNextUrl: null,
    campaignChannelMedia: {},
    campaignChannelMediaNextUrl: null,
    topPosts: [],
    lowestPerformingPosts: [],
    campaignRelationships: [],
    averageEngagementRate: {},
    netNewFollowers: {},
    subscribersGained: {},
    subscribersLost: {},
    graphStats: {},
    graphReportStats: {},
    graphReportStatsLoading: false,
    monitoredHashtags: null,
    hashtagsStatsTotal: null,
    hashtagStatsExtendedMetrics: null,
    googleAnalyticsCampaigns: null,
    dateRange: null,
    campaignChannelMediaCount: 0,
    scale: timeScaleTypes.MONTHLY,
    sort: 'DATE',
    googleAnalyticsCampaignsStats: [],
    googleAnalyticsCampaignsStatTotals: {},
    googleAnalyticsCampaignsCurrencyCode: '',
    topKeywords: null,
    topKeywordCloudTypeFilters: [
      { label: 'Keywords', value: 'words', checked: true, disabled: false },
      { label: 'Hashtags', value: 'hashtags', checked: true, disabled: false },
    ],
    campaignSentimentOverview: {},
    campaignListLowToHigh: false,
    campaignListSearchTerm: '',
    campaignListSort: 'CREATED',
    campaignListSortOptions: [
      {
        label: 'Recently Added',
        value: 'CREATED',
      },
      {
        label: 'Campaign Name: A - Z',
        value: 'NAME',
      },
    ],
    campaignCreators: [],
    campaignCreatorsPostsMedia: [],
    campaignCreatorsStoriesMedia: [],
    creatorSummaryCampaigns: [],
    campaignDeliverables: [],
    campaignDeliverableStats: {},
    pending: {
      createCampaign: false,
      addMediaToCampaign: false,
      summaryCampaigns: false,
      campaign: false,
      campaigns: false,
      getCampaignChannelMedia: false,
      getCampaignUgcMedia: false,
      removeMediaFromCampaign: false,
      campaignStatsTopline: false,
      campaignStatsForOverview: false,
      averageEngagementRate: false,
      monitoredHashtags: false,
      downloadSpreadsheet: false,
      googleAnalyticsCampaigns: false,
      googleAnalyticsCampaignsStats: false,
      campaignSentimentOverview: false,
      topKeywords: false,
      campaignCreators: false,
      campaignCreatorsPostsMedia: false,
      campaignCreatorsStoriesMedia: false,
      creatorSummaryCampaigns: false,
      campaignDeliverables: false,
    },
    error: {
      campaign: null,
      googleAnalyticsCampaigns: null,
      addMediaToCampaign: null,
      removeMediaFromCampaign: null,
      campaignSentimentOverview: null,
      topKeywords: false,
      campaignDeliverables: false,
    },
  }),
  getters: {
    pdfReady: (state) => {
      return (
        !state.pending.campaign &&
        !state.pending.getCampaignChannelMedia &&
        !state.pending.getCampaignUgcMedia &&
        !state.pending.googleAnalyticsCampaignsStats &&
        !state.pending.averageEngagementRate &&
        !state.campaignTopLineStatsLoading &&
        !state.graphReportStatsLoading
      );
    },
    campaignTopLineStatsLoading: (state) => {
      return state.pending.campaignStatsTopline || state.pending.campaignStatsForOverview;
    },
    graphDates: (state) => {
      if (state.campaign.latest_media_published_date === null && state.dateRange?.length < 2) {
        return [];
      }
      const startDate = state.dateRange
        ? state.dateRange[0]
        : state.campaign.earliest_media_published_date?.split('T')[0];
      const endDate = state.dateRange
        ? state.dateRange[1]
        : state.campaign.latest_media_published_date?.split('T')[0];
      return [startDate, endDate];
    },
    campaignCreatorsDateRange: (state) => {
      const sourceCreatedAt = state.campaign.value?.creator_filters?.source_created_at;
      return getCreatorsDateRange(sourceCreatedAt);
    },
  },
  actions: {
    addCampaignToList(incomingCampaign) {
      this.campaigns = [...this.campaigns, incomingCampaign];
    },
    addCampaignsToList(incomingCampaigns) {
      this.campaigns = [...this.campaigns, ...incomingCampaigns];
    },
    updateCampaignFromList(incomingCampaign) {
      const previousCampaign = this.campaign;
      this.campaign = incomingCampaign;

      if (previousCampaign?.id !== incomingCampaign.id) {
        const earliestDate = incomingCampaign.earliest_media_published_date
          ? dayjs(incomingCampaign.earliest_media_published_date).format('YYYY-MM-DD')
          : dayjs().subtract(4, 'weeks').format('YYYY-MM-DD');
        const latestDate = incomingCampaign.latest_media_published_date
          ? dayjs(incomingCampaign.latest_media_published_date).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD');
        const allTImeDateRange = [earliestDate, latestDate];

        this.dateRange = previousCampaign.id
          ? allTImeDateRange
          : this.dateRange ?? allTImeDateRange;
      }

      const index = this.campaigns.findIndex((campaign) => campaign.id === incomingCampaign.id);
      this.campaigns = [
        ...this.campaigns.filter((campaign) => campaign.id !== incomingCampaign.id),
      ];
      this.campaigns[index] = incomingCampaign;
    },
    deleteCampaignFromList(deletedCampaign) {
      this.campaigns = this.campaigns.filter(
        (campaign) => campaign.id !== deletedCampaign.campaignId,
      );
      this.campaign = {};
    },
    updateSummaryCampaigns(summaryCampaigns) {
      this.summaryCampaigns = summaryCampaigns;
    },
    clearSummaryCampaigns() {
      this.summaryCampaigns = [];
    },
    updateCreatorSummaryCampaigns(creatorSummaryCampaigns) {
      this.creatorSummaryCampaigns = creatorSummaryCampaigns;
    },
    clearCreatorSummaryCampaigns() {
      this.creatorSummaryCampaigns = [];
    },
    addNextCampaignsUrl(nextUrl) {
      this.campaignsNextUrl = nextUrl;
    },
    addCampaignRelationshipsToList(relationships) {
      this.campaignRelationships = [...this.campaignRelationships, ...relationships];
    },
    clearCampaignChannelMedia() {
      this.campaignChannelMedia = {};
      this.campaignChannelMediaNextUrl = null;
    },
    addCampaignChannelMedia(source, media) {
      this.campaignChannelMedia = {
        ...this.campaignChannelMedia,
        [source]: [...media],
      };
    },
    updateCampaignChannelMedia(source, media) {
      this.campaignChannelMedia = {
        ...this.campaignChannelMedia,
        [source]: [...this.campaignChannelMedia[source], ...media],
      };
    },
    addNextChannelMediaUrl(nextUrl) {
      this.campaignChannelMediaNextUrl = nextUrl;
    },
    addCampaignStats(incomingCampaignStats) {
      this.campaignStats = { ...this.campaignStats, ...incomingCampaignStats };
    },
    clearCampaignStats() {
      this.campaignStats = {};
      this.summaryCampaignStatsByChannel = {};
      this.campaignTotalStats = {};
      this.campaignSentimentOverview = {};
    },
    updateSummaryCampaignStatsByChannel(summaryCampaignStatsByChannel) {
      this.summaryCampaignStatsByChannel = summaryCampaignStatsByChannel;
    },
    updateCampaignTotalStats(campaignTotalStats) {
      this.campaignTotalStats = campaignTotalStats;
    },
    clearCampaignsList() {
      this.campaigns = [];
    },
    clearCampaignRelationships() {
      this.campaignRelationships = [];
    },
    updateGraphReportStatsLoading(loading) {
      this.graphReportStatsLoading = loading;
    },
    updateGraphReportStats(data) {
      this.graphReportStats = {
        ...this.graphReportStats,
        [data.metric]: { ...data.data, type: 'GRAPH', meta: data.meta },
      };
    },
    clearMonitoredHashtags() {
      this.monitoredHashtags = null;
    },
    resetHashtagsStats() {
      this.hashtagsStatsTotal = null;
    },
    addHashtagStats(newHashtagTotals) {
      this.hashtagsStatsTotal = newHashtagTotals.length ? sum(newHashtagTotals) : 0;
    },
    addToHashTagStatsExtendedMetrics(newHashtagExtendedMetrics) {
      this.hashtagStatsExtendedMetrics = newHashtagExtendedMetrics;
    },
    addToSummaryList(createdCampaign) {
      this.summaryCampaigns = [createdCampaign, ...this.summaryCampaigns];
    },
    async createCampaign(campaign) {
      this.pending.createCampaign = true;
      try {
        const authStore = useAuthStore();
        const brandId = authStore.currentBrand?.id;

        const response = await CampaignsAPI.createCampaign({
          brandId,
          name: campaign.name,
          hashtagIds: campaign.hashtags?.map((hashtag) => hashtag.hashtagId) ?? [],
          startDate: campaign.startDate,
          endDate: campaign.endDate,
        });

        const createdCampaign = response?.data;
        this.addCampaignToList(createdCampaign);
        this.addToSummaryList(createdCampaign);

        const trackingStore = useTrackingStore();
        trackingStore.track('Campaigns Create Campaign', {
          createdCampaigns: this.campaigns.length + 1,
          campaignId: createdCampaign.id,
          campaignName: createdCampaign.name,
          hashtags: campaign.hashtags?.map((hashtag) => hashtag.name) ?? [],
        });

        return createdCampaign;
      } finally {
        this.pending.createCampaign = false;
      }
    },
    async updateCampaign({ originalCampaign, editedCampaign }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand?.id;

      const { campaignId } = editedCampaign;
      const response = await CampaignsAPI.updateCampaign({
        brandId,
        campaignId,
        name: editedCampaign.name,
        hashtagIds:
          editedCampaign.hashtags?.map((hashtag) => hashtag?.hashtagId || hashtag?.hashtag_id) ??
          [],
        googleAnalyticsSelectedCampaigns: originalCampaign.google_analytics_selected_campaigns,
        creatorIds: editedCampaign.creatorIds,
        creatorFilters: editedCampaign.creatorFilters,
      });
      const updatedCampaign = response?.data;
      this.updateCampaignFromList(updatedCampaign);

      const trackingStore = useTrackingStore();
      trackingStore.track('Campaigns Edit Campaign', {
        createdCampaigns: this.campaigns.length,
        campaignId: response.data.id,
        campaignName: this.campaign.name,
        action: getUpdateActionName(originalCampaign, editedCampaign),
        campaignsNewName: response.data.name,
        hashtags: editedCampaign.hashtags?.map((hashtag) => hashtag.name) ?? [],
        hashtagsAdded: getHashtagsAdded(originalCampaign, editedCampaign),
        hashtagsRemoved: getHashtagsRemoved(originalCampaign, editedCampaign),
      });
      return updatedCampaign;
    },
    async getCampaign({ campaignId }) {
      this.pending.campaign = true;
      try {
        const authStore = useAuthStore();
        const brandId = authStore.currentBrand.id;
        const response = await CampaignsAPI.getCampaign({
          brandId,
          campaignId,
        });
        this.updateCampaignFromList(response?.data);
      } catch (err) {
        this.error.campaign = err;
        throw err;
      } finally {
        this.pending.campaign = false;
      }
    },
    async deleteCampaign(campaign) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand?.id;

      const { campaignId } = campaign;

      const response = await CampaignsAPI.deleteCampaign({ brandId, campaignId });
      const deletedCampaign = response?.data;

      this.deleteCampaignFromList(campaign);

      const trackingStore = useTrackingStore();
      trackingStore.track('Campaigns Delete Campaign', {
        createdCampaigns: this.campaigns.length - 1,
        campaignId: campaign.id,
        campaignName: campaign.name,
      });
      return deletedCampaign;
    },
    async getVideos({
      campaignId,
      limit = 7,
      sort,
      sourceCreatedOnOrAfter,
      sourceCreatedOnOrBefore,
    }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;
      const response = await CampaignsAPI.getVideos({
        brandId,
        campaignId,
        limit,
        sort,
        sourceCreatedOnOrAfter,
        sourceCreatedOnOrBefore,
      });
      if (sort.startsWith('-')) {
        this.campaignTopPerformingVideos = response?.data?.data;
      } else {
        this.campaignLowestPerformingVideos = response?.data?.data;
      }
    },
    async addMediaToCampaign({ campaign, media, galleries, page, limitSource }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;

      const campaignId = campaign.id;
      const campaignName = campaign.name;

      const sortedMedia = filterMedia(media, limitSource, page);

      const typeOfMediaAdded = [];
      const typeOfMediaAddedTotal = {};

      let handle;

      this.pending.addMediaToCampaign = true;
      try {
        if (sortedMedia.ownedMedia?.length || galleries?.length) {
          const mediaIds = sortedMedia.ownedMedia?.map((item) => item.id);
          const galleryIds = galleries?.map((gallery) => gallery.id);

          await CampaignsAPI.addOwnedMediaToCampaign({
            brandId,
            campaignId,
            mediaIds,
            galleryIds,
          });

          typeOfMediaAdded.push('Owned');
          typeOfMediaAddedTotal.owned = mediaIds?.length;
        }

        if (sortedMedia.ugcMedia?.length || galleries?.length) {
          const mediaIds = sortedMedia.ugcMedia?.map((item) => item.id);
          const galleryIds = galleries?.map((gallery) => gallery.id);

          await CampaignsAPI.addUgcMediaToCampaign({
            brandId,
            campaignId,
            mediaIds,
            galleryIds,
          });

          typeOfMediaAdded.push('ugc');
          typeOfMediaAddedTotal.UGC = mediaIds?.length;
        }

        // assign first int relationship
        const itemRelationship = [...sortedMedia.relationshipMedia].find(
          (item) => item.relationshipId !== null,
        );

        const relationshipId = itemRelationship?.relationshipId ?? null;

        if (sortedMedia.relationshipMedia?.length && relationshipId) {
          const mediaIds = sortedMedia.relationshipMedia?.map((item) => item.id);

          await CampaignsAPI.addMediaToCampaignRelationship({
            brandId,
            campaignId,
            relationshipId,
            mediaIds,
          });

          typeOfMediaAdded.push('Relationship');
          typeOfMediaAddedTotal.relationship = mediaIds?.length;

          handle =
            sortedMedia.relationshipMedia[0]?.instagram?.relationship.user.handle ||
            sortedMedia.relationshipMedia[0].relationship.user.handle;
        }

        const mixpanelPayload = {
          type: media ? 'Media' : 'Board',
          campaignId,
          campaignName,
          totalMediaAdded: media
            ? media.length
            : sum(galleries.map((gallery) => gallery.gallerySize)),
          ...platformsAddedProperties(media, galleries),
          boardNamesAdded: galleries?.map((gallery) => gallery.name),
          boardIDsAdded: galleries?.map((gallery) => gallery.id),
          mediaIDs: media?.map((item) => item.id),
          page,
          typeOfMediaAdded,
          typeOfMediaAddedTotal,
          handle,
        };
        const trackingStore = useTrackingStore();
        trackingStore.track('Campaigns Add To Campaign', mixpanelPayload);
      } catch (err) {
        this.error.addMediaToCampaign = err;
        throw err;
      } finally {
        this.pending.addMediaToCampaign = false;
      }
    },
    async getSummaryCampaigns() {
      this.pending.summaryCampaigns = true;
      try {
        const authStore = useAuthStore();
        const brandId = authStore.currentBrand?.id;
        const response = await CampaignsAPI.getSummaryCampaigns({ brandId });
        const summaryCampaigns = response?.data;
        this.updateSummaryCampaigns(summaryCampaigns);
      } catch (err) {
        if (err?.response?.status === 404) {
          this.clearSummaryCampaigns();
        } else {
          throw err;
        }
      } finally {
        this.pending.summaryCampaigns = false;
      }
    },
    async getCreatorSummaryCampaigns({ creatorId }) {
      this.pending.creatorSummaryCampaigns = true;
      try {
        const authStore = useAuthStore();
        const brandId = authStore.currentBrand?.id;
        const response = await CampaignsAPI.getSummaryCampaigns({ brandId, creatorId });
        const creatorSummaryCampaigns = response?.data;
        this.updateCreatorSummaryCampaigns(creatorSummaryCampaigns);
      } catch (err) {
        if (err?.response?.status === 404) {
          this.clearCreatorSummaryCampaigns();
        } else {
          throw err;
        }
      } finally {
        this.pending.creatorSummaryCampaigns = false;
      }
    },
    async getAllCampaigns() {
      this.pending.campaigns = true;
      try {
        const authStore = useAuthStore();
        const brandId = authStore.currentBrand?.id;
        const response = await CampaignsAPI.getAllCampaigns({ brandId });
        const campaigns = response?.data?.data;
        this.addCampaignsToList(campaigns);
        this.addNextCampaignsUrl(undefined);
      } finally {
        this.pending.campaigns = false;
      }
    },
    async getCampaigns({ url, limit = 48, search = null, sort = '-CREATED', ids } = {}) {
      this.pending.campaigns = true;
      try {
        const authStore = useAuthStore();
        const brandId = authStore.currentBrand?.id;
        const response = await CampaignsAPI.getCampaigns({
          url,
          brandId,
          limit,
          search,
          sort,
          ids,
        });
        const campaigns = ids?.length > 0 ? response?.data : response?.data.data;
        const paging = response?.data?.paging;
        this.addCampaignsToList(campaigns);
        this.addNextCampaignsUrl(paging?.next);
      } catch (err) {
        if (err?.response?.status === 404) {
          this.addCampaignsToList([]);
        } else {
          throw err;
        }
      } finally {
        this.pending.campaigns = false;
      }
    },
    async getCampaignRelationships({ startDate, endDate }) {
      try {
        const authStore = useAuthStore();
        const brandId = authStore.currentBrand.id;
        const campaignId = this.campaign.id;
        const response = await CampaignsAPI.getCampaignRelationships({
          brandId,
          campaignId,
          startDate,
          endDate,
        });
        const campaignRelationships = response?.data?.data;
        this.addCampaignRelationshipsToList(campaignRelationships);
      } catch (err) {
        if (err?.response?.status === 404) {
          this.addCampaignRelationshipsToList([]);
        } else {
          throw err;
        }
      }
    },
    async getCampaignChannelMedia({
      url,
      sort,
      source,
      sourceCreatedOnOrAfter,
      sourceCreatedOnOrBefore,
      limit = 24,
    } = {}) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;
      const campaignId = this.campaign.id;

      const request = source === 'UGC' ? 'getCampaignUgcMedia' : 'getCampaignChannelMedia';

      if (!url) {
        this.clearCampaignChannelMedia();
      }

      this.pending[request] = true;

      try {
        const response = await CampaignsAPI[request]({
          brandId,
          campaignId,
          sort,
          source,
          sourceCreatedOnOrAfter,
          sourceCreatedOnOrBefore,
          limit,
          url,
        });

        const media = response?.data?.data;
        const paging = response?.data?.paging;
        this.campaignChannelMediaCount = paging.count;

        if (url) {
          this.updateCampaignChannelMedia(source, media);
        } else {
          this.addCampaignChannelMedia(source, media);
        }

        this.addNextChannelMediaUrl(paging?.next);
      } finally {
        this.pending[request] = false;
      }
    },
    async getPosts({
      campaignId,
      sort,
      sourceCreatedOnOrAfter,
      sourceCreatedOnOrBefore,
      limit = 6,
    }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;

      const response = await CampaignsAPI.getPosts({
        brandId,
        campaignId,
        sort,
        sourceCreatedOnOrAfter,
        sourceCreatedOnOrBefore,
        limit,
      });

      if (sort.startsWith('-')) {
        this.topPosts = response?.data?.data;
      } else {
        this.lowestPerformingPosts = response?.data?.data;
      }
    },
    async getCampaignStatsForOverview({ startDate, endDate }) {
      this.pending.campaignStatsForOverview = true;
      try {
        const authStore = useAuthStore();
        const brandId = authStore.currentBrand.id;
        const campaignId = this.campaign.id;

        const payload = await CampaignsAPI.getCampaignStatsForOverview({
          brandId,
          campaignId,
          startDate,
          endDate,
        });

        const { data } = payload.data;

        if (data) {
          this.updateCampaignTotalStats(data);
        }
      } finally {
        this.pending.campaignStatsForOverview = false;
      }
    },
    async getCampaignStats({ startDate, endDate, sources, scale }) {
      this.pending.campaignStatsTopline = true;

      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;
      const campaignId = this.campaign.id;

      try {
        const response = await CampaignsAPI.getCampaignStats({
          brandId,
          campaignId,
          startDate,
          endDate,
          sources,
          scale,
        });
        const campaignStats = response.data.data;
        if (campaignStats) this.addCampaignStats(campaignStats);
      } catch (error) {
        if (error?.response?.status === 404) {
          this.clearCampaignStats();
        } else {
          throw error;
        }
      } finally {
        this.updateSummaryCampaignStatsByChannel(groupCampaignStatsByChannel(this.campaignStats));
        this.pending.campaignStatsTopline = false;
      }
    },
    async removeMediaFromCampaign({ campaign, media }) {
      this.pending.removeMediaFromCampaign = true;
      try {
        const authStore = useAuthStore();
        const brandId = authStore.currentBrand.id;
        const campaignId = campaign.id;
        const campaignName = campaign.name;

        const ugcMedia = media?.filter(
          (item) => getSourceType(item)?.includes('UGC') && !item?.instagram?.relationship,
        );
        const relationshipMedia = media?.filter(
          (item) => item?.instagram?.relationship && getSourceType(item)?.includes('UGC'),
        );
        const ownedMedia = media?.filter((item) => !getSourceType(item)?.includes('UGC'));
        const ugcPayload = ugcMedia?.map((item) => item.id);
        const ownedPayload = ownedMedia?.map((item) => item.id);
        const typeOfMediaRemoved = [];
        const typeOfMediaRemovedTotal = {};

        if (ownedMedia?.length) {
          await CampaignsAPI.removeOwnedMediaFromCampaign({
            brandId,
            campaignId,
            mediaIds: ownedPayload,
          });
          typeOfMediaRemoved.push('Owned');
          typeOfMediaRemovedTotal.owned = ownedMedia.length;
        }
        if (relationshipMedia?.length) {
          const relationshipIdsMap = {};
          relationshipMedia.forEach((mediaItem) => {
            const relationshipId = mediaItem?.instagram?.relationship?.id;
            if (!(relationshipId in relationshipIdsMap)) {
              relationshipIdsMap[relationshipId] = [];
            }
            relationshipIdsMap[relationshipId].push(mediaItem.id);
          });

          Object.entries(relationshipIdsMap).forEach(async ([relationshipId, mediaIds]) => {
            await CampaignsAPI.removeRelationshipMediaFromCampaign({
              brandId,
              campaignId,
              relationshipId,
              mediaIds,
            });
          });
          typeOfMediaRemoved.push('Relationship');
          typeOfMediaRemovedTotal.relationship = relationshipMedia.length;
        }

        if (ugcMedia?.length) {
          await CampaignsAPI.removeUgcMediaFromCampaign({
            brandId,
            campaignId,
            mediaIds: ugcPayload,
          });
          typeOfMediaRemoved.push('UGC');
          typeOfMediaRemovedTotal.ugc = ugcMedia.length;
        }

        const trackingStore = useTrackingStore();
        trackingStore.track('Campaigns Remove From Campaign', {
          campaignId,
          campaignName,
          totalMediaRemoved: media?.length,
          platformsRemoved: [...new Set(media.map((item) => item?.source || item?.postType))],
          mediaIDs: media.map((item) => item.id),
          typeOfMediaRemoved,
          typeOfMediaRemovedTotal,
        });
      } catch (err) {
        this.error.removeMediaFromCampaign = err;
        throw err;
      } finally {
        this.pending.removeMediaFromCampaign = false;
      }
    },
    async getChannelMetrics({
      channels,
      metrics,
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
    }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;
      const payload = await DashboardAPI.getTopLineStatsForChannel({
        brandIds: brandId,
        startDate,
        endDate,
        contextStartDate: contextStartDate ?? startDate,
        contextEndDate: contextEndDate ?? endDate,
        channels: isArray(channels) ? channels.join(',') : channels,
        metrics: isArray(metrics) ? metrics.join(',') : metrics,
        requirePosts: true,
      });
      return payload?.data?.data?.[brandId]?.metrics;
    },
    async getAllChannelsAverageEngagementRate({ startDate, endDate }) {
      this.pending.averageEngagementRate = true;
      try {
        const metrics = await this.getChannelMetrics({
          channels: [
            constants.TIKTOK,
            constants.INSTAGRAM,
            constants.FACEBOOK,
            constants.PINTEREST,
            constants.TWITTER,
          ],
          metrics: 'AVG_ENGAGEMENT_RATE',
          startDate,
          endDate,
        });
        this.averageEngagementRate = metrics.AVG_ENGAGEMENT_RATE;
      } finally {
        this.pending.averageEngagementRate = false;
      }
    },
    async getAllChannelsNetNewFollowers({ startDate, endDate }) {
      const metrics = await this.getChannelMetrics({
        channels: [
          constants.TIKTOK,
          constants.INSTAGRAM,
          constants.FACEBOOK,
          constants.PINTEREST,
          constants.TWITTER,
        ],
        metrics: 'NET_NEW_FOLLOWERS',
        startDate,
        endDate,
      });
      this.netNewFollowers = metrics.NET_NEW_FOLLOWERS;
    },
    async getYouTubeSubscriberMetrics({ startDate, endDate }) {
      const metrics = await this.getChannelMetrics({
        channels: constants.YOUTUBE,
        metrics: ['SUBSCRIBERS_GAINED', 'SUBSCRIBERS_LOST'],
        startDate,
        endDate,
      });
      this.subscribersGained = metrics.SUBSCRIBERS_GAINED;
      this.subscribersLost = metrics.SUBSCRIBERS_LOST;
    },
    async updateGraphStats(brandId, startDate, endDate, metric, channels, scale) {
      const statsKey = metric;
      const timeScale = scale;
      try {
        const { data } = await DashboardAPI.getGraphStats({
          brandIds: brandId,
          startDate,
          metrics: metric,
          endDate,
          channels: channels.join(),
          timeScale,
        });

        this.graphStats = {
          ...this.graphStats,
          [statsKey]: data,
        };
      } catch (error) {
        this.graphStats = { ...this.graphStats, [statsKey]: {} };
      }
    },
    async getGraphStats({ startDate, endDate, metric, channels, scale } = {}) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;

      const params = { startDate, endDate };
      const channelsFiltered = channels;
      this.updateGraphReportStatsLoading(true);
      if (channelsFiltered.length > 0) {
        await this.updateGraphStats(
          brandId,
          params.startDate,
          params.endDate,
          metric,
          channelsFiltered,
          scale,
        );
      }
      const meta = {
        brand_ids: [brandId],
        channels: orderedReportChannelList,
        metric,
      };
      this.updateGraphReportStats({ metric, meta, data: this.graphStats[metric] });
      this.updateGraphReportStatsLoading(false);
    },
    async getMonitoredHashtags() {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;

      this.pending.monitoredHashtags = true;
      try {
        const response = await CampaignsAPI.getMonitoredHashtags({ brandId });
        const hashtags = response?.data;
        this.monitoredHashtags = hashtags;
      } finally {
        this.pending.monitoredHashtags = false;
      }
    },
    async getHashtagStatsTotal({ hashtags, startDate, endDate, scale }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;

      this.resetHashtagsStats();
      const hashtagsTotals = [];
      const hashtagExtendedMetrics = [];
      this.addToHashTagStatsExtendedMetrics(hashtagExtendedMetrics);
      await Promise.all(
        hashtags.map(async (hashtag) => {
          try {
            const payload = await CampaignsAPI.getHashtagStatsTotal({
              brandId,
              // TODO: 🛠️ find root of snake_case ids, replace with camelCase
              hashtagId: hashtag.hashtagId ?? hashtag.hashtag_id,
              startDate,
              endDate,
              scale,
              limit: 1,
            });
            const response = payload?.data;
            const count = response?.paging?.count;

            if (count >= 0) {
              hashtagsTotals.push(count);
              hashtagExtendedMetrics.push({
                label: `#${hashtag.name}`,
                value: count,
                percent: false,
              });
            }
          } catch (error) {
            if (error?.response?.status === 404) {
              hashtagsTotals.push(0);
            } else {
              throw error;
            }
          }
        }),
      );
      this.addToHashTagStatsExtendedMetrics(hashtagExtendedMetrics);
      this.addHashtagStats(hashtagsTotals);
    },
    setDownloadingSpreadsheet(downloading) {
      this.pending.downloadSpreadsheet = downloading;
    },
    async downloadSpreadsheet({ socketId, startDate, endDate }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;
      const campaignId = this.campaign.id;

      const response = await CampaignsAPI.downloadSpreadsheet({
        brandId,
        campaignId,
        socketId,
        startDate,
        endDate,
      });

      if (response.status === 202) {
        this.setDownloadingSpreadsheet(true);
      }

      const trackingStore = useTrackingStore();
      trackingStore.track('Campaigns Report Download Selected', {
        downloadOptionSelected: 'all tabs xlsx',
      });
    },
    async downloadPDF({ content, query, socketId }) {
      const authStore = useAuthStore();
      const brand = authStore.currentBrand.label;
      const campaign = this.campaign;
      const campaignName = snakeCase(campaign.name);
      const filename = `campaign-${campaignName}-${content}-${dayjs().format('YYYY-MM-DD')}`;
      const url = `${originForReport()}/${brand}/campaigns/${
        campaign.id
      }/${content}/report?${stringifyQuery({
        ...query,
        brand,
      })}`;

      const pdfStore = usePdfStore();
      pdfStore.getPdfV2({
        filename,
        url,
        notificationSocketId: socketId,
        notificationMeta: JSON.stringify({
          type: 'campaign',
        }),
        idleMaxInflightRequest: 2,
        idleTimeout: 20,
        useWaitFor: true,
        width: '1440px',
        height: '1920px',
        margin: 10,
      });

      const trackingStore = useTrackingStore();
      trackingStore.track('Campaigns Report Download Selected', {
        downloadOptionSelected: content === 'all' ? 'all tabs pdf' : `${content} pdf`,
      });
    },
    async getGoogleAnalyticsCampaigns({ dimensionFilter, ignoreCache } = {}) {
      this.error.googleAnalyticsCampaigns = false;
      const authStore = useAuthStore();
      const cachedGoogleAnalyticsCampaigns = JSON.parse(
        browserStorageGetItem('googleAnalyticsCampaigns'),
      );
      const cachedGoogleAnalyticsCampaignsTimestamp = parseInt(
        browserStorageGetItem('googleAnalyticsCampaignsTimestamp'),
        10,
      );
      const now = dayjs().valueOf();
      const invalidCache =
        now - cachedGoogleAnalyticsCampaignsTimestamp > GOOGLE_ANALYTICS_CAMPAIGNS_CACHE_LIFESPAN;
      if (!ignoreCache && cachedGoogleAnalyticsCampaigns && !invalidCache) {
        this.googleAnalyticsCampaigns = cachedGoogleAnalyticsCampaigns;
      } else {
        try {
          this.pending.googleAnalyticsCampaigns = true;
          const response = await CampaignsAPI.getGoogleAnalyticsReport({
            brandId: authStore.currentBrand.id,
            startDate: this.dateRange[0],
            endDate: this.dateRange[1],
            dimensions: ['campaign'],
            metrics: ['sessions'], // Must specify at least one metric
            dimensionFilter,
          });
          this.googleAnalyticsCampaigns = response.data.rows
            .map((row) => row.dimensions[0])
            .filter((campaign) => campaign !== '(not set)');
          // Make sure the selected campaigns are in the list of avaiable campaigns
          if (this.campaign.google_analytics_selected_campaigns) {
            this.campaign.google_analytics_selected_campaigns.forEach((campaign) => {
              if (!this.googleAnalyticsCampaigns.includes(campaign)) {
                this.googleAnalyticsCampaigns.push(campaign);
              }
            });
          }
          if (!ignoreCache) {
            browserStorageSetItem(
              'googleAnalyticsCampaigns',
              JSON.stringify(this.googleAnalyticsCampaigns),
            );
            browserStorageSetItem('googleAnalyticsCampaignsTimestamp', now);
          }
        } catch {
          this.error.googleAnalyticsCampaigns = true;
          this.googleAnalyticsCampaigns = this.campaign.google_analytics_selected_campaigns ?? [];
        } finally {
          this.pending.googleAnalyticsCampaigns = false;
        }
      }
    },
    clearGoogleAnalyticsCampaigns() {
      browserStorageRemoveItem('googleAnalyticsCampaigns');
      browserStorageRemoveItem('googleAnalyticsCampaignsTimestamp');
    },
    async setSelectedGoogleAnalyticsCampaign(campaign) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand?.id;

      const response = await CampaignsAPI.updateCampaign({
        brandId,
        campaignId: this.campaign.id,
        name: this.campaign.name,
        hashtagIds: this.campaign.hashtags?.map((hashtag) => hashtag.hashtagId) ?? [],
        googleAnalyticsSelectedCampaigns: campaign ? [campaign] : null,
      });
      const updatedCampaign = response.data;

      this.updateCampaignFromList(updatedCampaign);
    },
    clearGoogleAnalyticsCampaignsStats() {
      this.googleAnalyticsCampaignsStats = [];
      this.googleAnalyticsCampaignsStatTotals = {};
      this.googleAnalyticsCampaignsCurrencyCode = '';
    },
    async getGoogleAnalyticsCampaignsStats() {
      const authStore = useAuthStore();

      const metrics = [
        'totalUsers',
        'sessions',
        'transactions',
        'ecommerceConversionRate',
        'revenue',
      ];
      const dimensionFilter = {
        operator: 'AND',
        filters: [
          {
            operator: 'IN_LIST',
            dimensionName: 'campaign',
            value: this.campaign.google_analytics_selected_campaigns,
          },
        ],
      };

      try {
        this.pending.googleAnalyticsCampaignsStats = true;
        const rowsResponse = await CampaignsAPI.getGoogleAnalyticsReport({
          brandId: authStore.currentBrand.id,
          startDate: this.dateRange[0],
          endDate: this.dateRange[1],
          metrics,
          dimensions: ['sourceMedium'],
          dimensionFilter,
        });
        this.googleAnalyticsCampaignsStats = rowsResponse.data.rows.map((row) => {
          const statsObj = {
            sourceMedium: row.dimensions[0],
          };
          metrics.forEach((metric, index) => {
            statsObj[metric] = parseFloat(row.metrics[index]);
          });
          return statsObj;
        });

        // The totals google analytics returns in the rowsResponse are a sum of the values in the
        // rows, but a single user may appear in multiple different rows leading to counting them
        // twice. We need to request the total users for the campaign without breaking it down by
        // source/medium for the total to match what's in Google Analytics.
        const totalsResponse = await CampaignsAPI.getGoogleAnalyticsReport({
          brandId: authStore.currentBrand.id,
          startDate: this.dateRange[0],
          endDate: this.dateRange[1],
          metrics,
          dimensions: ['campaign'],
          dimensionFilter,
        });
        const totalsObj = {
          sourceMedium: '',
        };
        metrics.forEach((metric, index) => {
          totalsObj[metric] = parseFloat(totalsResponse.data.totals[index]);
        });
        this.googleAnalyticsCampaignsStatTotals = totalsObj;
        this.googleAnalyticsCampaignsCurrencyCode = rowsResponse.data.metadata.currency_code;
      } finally {
        this.pending.googleAnalyticsCampaignsStats = false;
      }
    },
    async getTopKeywords({
      campaignId,
      sourceCreatedOnOrAfter,
      sourceCreatedOnOrBefore,
      cloudTypes,
    }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;

      this.topKeywords = null;
      this.pending.topKeywords = true;
      this.error.topKeywords = false;

      const queryCloudTypes = cloudTypes.join(',');
      try {
        const response = await CampaignsAPI.getTopKeywords({
          brandId,
          campaignId,
          sourceCreatedOnOrAfter,
          sourceCreatedOnOrBefore,
          cloudTypes: queryCloudTypes,
        });
        this.topKeywords = response.data.data;
      } catch (error) {
        this.error.topKeywords = true;
      } finally {
        this.pending.topKeywords = false;
      }
    },
    resetTopKeywordFilters() {
      this.topKeywordCloudTypeFilters = [
        { label: 'Keywords', value: 'words', checked: true, disabled: false },
        { label: 'Hashtags', value: 'hashtags', checked: true, disabled: false },
      ];
    },
    async getCampaignSentimentOverview(brandId, campaignId, startDate, endDate) {
      this.error.campaignSentimentChannelDistribution = null;
      this.pending.campaignSentimentChannelDistribution = true;

      try {
        const response = await CampaignsAPI.getCampaignSentiment({
          brandId,
          campaignId,
          startDate,
          endDate,
        });
        this.campaignSentimentOverview = response.data.data;

        // Adding the sum of each platform to the object
        Object.entries(this.campaignSentimentOverview).forEach(([key, value]) => {
          this.campaignSentimentOverview[key].sum =
            value.positive_sentiment + value.neutral_sentiment + value.negative_sentiment;
        });
      } catch (error) {
        this.campaignSentimentOverview = null;
        this.error.campaignSentimentOverview = error;
      } finally {
        this.pending.campaignSentimentOverview = false;
      }
    },
    async fetchCampaignCreators() {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;
      const creatorIds = this.campaign.creator_ids;

      try {
        this.pending.campaignCreators = true;

        let nextUrl;
        const data = [];

        const fetchCampaignCreatorsRecursive = async () => {
          const response = await CreatorsAPI.fetchBrandCreators({
            url: nextUrl,
            brandId,
            creatorIds,
          });
          data.push(...response.data.data);
          nextUrl = response.data.paging.next;

          if (nextUrl) {
            await fetchCampaignCreatorsRecursive();
          }
        };

        await fetchCampaignCreatorsRecursive();

        this.campaignCreators = data;
      } finally {
        this.pending.campaignCreators = false;
      }
    },

    async fetchCampaignCreatorMedia({ sort = '-DATE', sourceIsForPosts = true, limit = 24 } = {}) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand.id;
      const campaignId = this.campaign.id;
      let tempSourceCreatedOnOrAfter = null;
      let tempSourceCreatedOnOrBefore = null;
      const source = sourceIsForPosts
        ? enumTypes.CREATOR_INSTAGRAM
        : enumTypes.CREATOR_INSTAGRAM_STORIES;

      if (this.dateRange?.length === 2) {
        tempSourceCreatedOnOrAfter = this.dateRange[0];
        tempSourceCreatedOnOrBefore = this.dateRange[1];
      }
      const request =
        source === enumTypes.CREATOR_INSTAGRAM
          ? 'campaignCreatorsPostsMedia'
          : 'campaignCreatorsStoriesMedia';
      try {
        this.pending[request] = true;
        let nextUrl;
        const data = [];
        const fetchCampaignCreatorMediaRecursive = async () => {
          const response = await CampaignsAPI.getCampaignChannelMedia({
            url: nextUrl,
            brandId,
            campaignId,
            ...(nextUrl
              ? {}
              : {
                  sort,
                  source,
                  sourceCreatedOnOrAfter: tempSourceCreatedOnOrAfter,
                  sourceCreatedOnOrBefore: tempSourceCreatedOnOrBefore,
                  limit,
                }),
          });
          // will remove recursive call once pagination and GDI designs are ready
          if (response.data.data) {
            data.push(...response.data.data);
            nextUrl = response.data.paging.next;
            if (nextUrl) {
              await fetchCampaignCreatorMediaRecursive();
            }
          }
        };
        await fetchCampaignCreatorMediaRecursive();
        this[request] = data;
      } finally {
        this.pending[request] = false;
      }
    },
  },
});
