import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import sum from 'lodash/sum';
import isEmpty from 'lodash/isEmpty';
import { useAuthStore } from '@/stores/auth';
import * as InstagramAPI from '@/apis/instagram';
import constants from '@/app/instagram/constants';
import { useSocketStore } from '@/stores/socket';
import { refreshAbortController, handleCancelError } from '@/apis/axios.utils';

export const useInstagramAccountStore = defineStore(
  'instagramAccount',
  () => {
    const authStore = useAuthStore();
    const socketStore = useSocketStore();

    const tooltips = constants.accountOverviewTooltips;

    const pending = ref({
      accountOverview: false,
      igUser: false,
      addAccountStatus: false,
      brandInstagramAccount: false,
    });

    const initialized = ref({
      brandInstagramAccount: false,
    });

    const error = ref({
      addAccountStatus: null,
      getIgUser: null,
    });

    const abortControllers = {
      getIgUser: ref(null),
    };

    const brandInstagramAccount = ref(null);
    const addedAccount = ref(null);
    const accountOverview = ref(null);
    const igUser = ref({});
    const igUserPosts = ref([]);
    const igUserNextToken = ref(null);

    const profileStats = computed(() => {
      if (!accountOverview.value) {
        return [];
      }
      return [
        {
          label: 'Content Reach',
          total: accountOverview.value.reach.values ? '' : '-',
          tooltip: tooltips.contentReach,
          chartData: accountOverview.value.reach.values
            ? accountOverview.value.reach
            : { labels: accountOverview.value.impressions.labels, values: [], disabled: true },
          metric: 'Reach',
        },
        {
          label: 'Content Impressions',
          total: accountOverview.value.impressions.values
            ? sum(accountOverview.value.impressions.values)
            : '-',
          tooltip: tooltips.contentImpression,
          chartData: accountOverview.value.impressions,
          metric: 'Impressions',
        },
        {
          label: 'Profile Views',
          total: accountOverview.value.views.values ? sum(accountOverview.value.views.values) : '-',
          tooltip: tooltips.profileViews,
          chartData: accountOverview.value.views,
        },
      ];
    });

    const interactionStats = computed(() => {
      if (!accountOverview.value) {
        return [];
      }
      const { websiteClicks, emailContactClicks, textMessageClicks, getDirectionClicks } =
        accountOverview.value;
      return [
        {
          label: 'Website Clicks',
          total: websiteClicks.values ? sum(websiteClicks.values) : '-',
          tooltip: tooltips.websiteClicks,
          chartData: websiteClicks,
        },
        {
          label: 'Email Contact Clicks',
          total: emailContactClicks.values ? sum(emailContactClicks.values) : '-',
          tooltip: tooltips.emailContactClicks,
          chartData: emailContactClicks,
        },
        {
          label: 'Text Message Clicks',
          total: textMessageClicks.values ? sum(textMessageClicks.values) : '-',
          tooltip: tooltips.textMessageClicks,
          chartData: textMessageClicks,
        },
        {
          label: 'Get Direction Clicks',
          total: getDirectionClicks.values ? sum(getDirectionClicks.values) : '-',
          tooltip: tooltips.getDirectionClicks,
          chartData: getDirectionClicks,
        },
      ];
    });

    async function getIgUser({ handle, after, limit }) {
      pending.value.igUser = true;
      try {
        const signal = refreshAbortController(abortControllers.getIgUser);

        // If the handle were searching doesn't match the existing posts, clear them.
        if (igUserPosts.value[0]?.handle !== handle) {
          igUserPosts.value = [];
        }

        const brandId = authStore.currentBrand?.id;
        const response = await InstagramAPI.getInstagramUsers(
          { brandId, handle, after, limit },
          { signal },
        );
        const payload = response?.data;

        if (isEmpty(igUser.value)) {
          igUser.value = payload?.user;
        }

        if (payload?.posts) {
          const posts = payload.posts.map((item) => ({ handle: igUser.value?.handle, ...item }));
          igUserPosts.value = [...igUserPosts.value, ...posts];
          igUserNextToken.value = payload.after;
        }

        return payload;
      } catch (e) {
        error.value.getIgUser = e.response;
        if (e.response?.status === 422) {
          igUserPosts.value = [];
          return [];
        }
        return handleCancelError(e);
      } finally {
        pending.value.igUser = false;
      }
    }

    async function addAccount({ handle }) {
      pending.value.addAccountStatus = true;
      error.value.addAccountStatus = null;
      try {
        const response = await InstagramAPI.addAccount({ handle });
        const payload = response?.data;
        addedAccount.value = payload;
        return payload;
      } catch (e) {
        error.value.addAccountStatus = e;
        throw e;
      } finally {
        pending.value.addAccountStatus = false;
      }
    }

    async function getBrandInstagramAccount() {
      const brandId = authStore.currentBrand?.id;
      pending.value.brandInstagramAccount = true;
      try {
        const response = await InstagramAPI.getBrandInstagramAccount({ brandId });
        const payload = response?.data;
        brandInstagramAccount.value = payload;
        initialized.value.brandInstagramAccount = true;
        return payload;
      } catch (e) {
        if (e?.response?.status === 404) {
          brandInstagramAccount.value = null;
        } else {
          throw e;
        }
      } finally {
        pending.value.brandInstagramAccount = false;
      }
      return undefined;
    }

    async function getAccountOverview({ scale, startDate, endDate }) {
      pending.value.accountOverview = true;
      try {
        const brandId = authStore.currentBrand?.id;
        const response = await InstagramAPI.getBrandUserInsights({
          brandId,
          scale,
          startDate,
          endDate,
        });
        const payload = response?.data;
        accountOverview.value = payload;
        return payload;
      } finally {
        pending.value.accountOverview = false;
      }
    }

    async function getAccountOverviewCSV({ scale, startDate, endDate }) {
      const brandId = authStore.currentBrand?.id;
      const socketId = socketStore.id;
      const response = await InstagramAPI.getBrandUserInsightsCsv({
        brandId,
        scale,
        startDate,
        endDate,
        socketId,
      });
      const payload = response?.data;
      return payload;
    }

    function clearBrandInstagramAccount() {
      brandInstagramAccount.value = null;
    }

    function clearIgUser() {
      igUser.value = null;
      igUserPosts.value = [];
      error.value.getIgUser = null;
      igUserNextToken.value = null;
    }

    return {
      pending,
      initialized,
      error,
      igUser,
      igUserPosts,
      igUserNextToken,
      accountOverview,
      profileStats,
      interactionStats,
      addedAccount,
      brandInstagramAccount,
      getAccountOverview,
      getAccountOverviewCSV,
      clearBrandInstagramAccount,
      getIgUser,
      clearIgUser,
      addAccount,
      getBrandInstagramAccount,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
