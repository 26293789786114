import { colours } from '@/ux/colours';

export const noUsersMessage = 'No Users Found';

export const resendInviteDisabledTooltip = 'User has accepted and is setting up their account.';

export const apiDocumentationLink = 'https://developer.dashhudson.com/';

export const userPreferences = [
  'send_facebook_connection_email',
  'send_instagram_connection_email',
  'send_tiktok_connection_email',
  'send_youtube_connection_email',
  'send_instagram_daily_report_email',
  'send_instagram_weekly_report_email',
  'send_instagram_monthly_report_email',
  'send_pinterest_daily_report_email',
  'send_pinterest_weekly_report_email',
  'send_twitter_weekly_report_email',
  'send_pinterest_monthly_report_email',
  'send_mobile_scheduler_notification',
];

export const userPermissions = {
  ads: [
    'can_access_ad_boards',
    'can_access_ad_predictions',
    'can_access_ads',
    'can_access_tiktok_ads',
    'can_disconnect_fb_ads',
    'can_access_fb_ad_accounts_manager',
    'can_manage_tiktok_ads',
  ],
  facebook: ['can_access_fb_insights'],
  general: ['can_access_csv'],
  instagram: [
    'can_access_account_overview',
    'can_access_boards',
    'can_access_community_ugc',
    'can_access_emv',
    'can_access_instagram',
    'can_access_likeshop',
    'can_access_likeshop_email_capture',
    'can_access_rights_requests',
    'can_access_riq_new_followers',
    'can_access_search',
    'can_access_stories',
    'can_access_stories_swipeup',
    'can_access_ugc_more',
    'can_access_your_followers',
    'can_access_your_posts',
  ],
  campaigns: ['can_access_campaigns_google_analytics'],
  competitive: ['can_access_competitors'],
  community: ['can_access_community', 'can_access_instagram_direct'],
  library: [
    'can_access_product_widget',
    'can_access_shoppable_galleries',
    'can_edit_galleries',
    'can_edit_publish_dates',
  ],
  pinterest: ['can_access_pint', 'can_access_pint_boards', 'can_disconnect_pint'],
  relationships: ['can_manage_tiktok_creator_marketplace'],
  scheduler: [
    'can_access_scheduler',
    'can_access_scheduler_tiktok',
    'can_access_scheduler_fb',
    'can_access_scheduler_ig',
    'can_access_scheduler_pint',
    'can_access_scheduler_twit',
    'can_auto_publish_tiktok',
    'can_auto_publish_facebook',
    'can_auto_publish_instagram',
    'can_auto_publish_pinterest',
    'can_auto_publish_twit',
  ],
  social_listening: ['can_access_social_listening'],
  twitter: ['can_access_twitter_insights', 'can_disconnect_twitter'],
  vision: ['can_access_viq', 'can_access_vision', 'can_access_vision_ai'],
  youtube: ['can_access_youtube_insights'],
};

export const brandPermissions = {
  ads: ['can_access_ad_boards', 'can_access_ads'],
  campaigns: ['can_access_campaigns_google_analytics'],
  competitive: ['can_access_competitors'],
  community: ['can_access_community_collaboration', 'can_access_inbox_automation'],
  facebook: ['can_access_fb_insights'],
  general: ['can_access_csv'],
  instagram: [
    'boards_limit',
    'can_access_account_overview',
    'can_access_boosted_posts',
    'can_access_community_ugc',
    'can_access_emv',
    'can_access_instagram',
    'can_access_likeshop_email_capture',
    'can_access_non_business',
    'can_access_rights_requests',
    'can_access_riq_new_followers',
    'can_access_stories_swipeup',
    'can_access_ugc_more',
    'can_access_viq_trends',
    'can_access_your_followers',
    'can_access_your_posts',
    'hashtags_limit',
    'locations_limit',
    'story_boards_limit',
    'viq_accounts_per_trend_limit',
    'viq_trend_group_limit',
  ],
  library: ['can_access_product_widget', 'can_access_shoppable_galleries'],
  pinterest: ['can_access_pint'],
  relationships: ['can_access_instagram_relationships'],
  scheduler: [
    'can_auto_publish_tiktok',
    'can_auto_publish_facebook',
    'can_auto_publish_instagram',
    'can_auto_publish_twit',
  ],
  settings: ['can_access_teams_and_permissions', 'can_access_api_keys'],
  social_listening: ['can_access_social_listening', 'can_access_vision_ai_social_listening'],
  tiktok: ['can_access_tiktok_likeshop'],
  twitter: ['can_access_twitter_insights'],
  vision: [
    'can_access_viq',
    'can_access_vision',
    'can_access_vision_ai_community',
    'can_access_vision_ai_dashboards',
  ],
  youtube: ['can_access_youtube_insights'],
  tags: ['can_access_brand_tags', 'can_access_competitor_tags', 'can_access_content_tags'],
};

export const COMPETITOR_PLATFORM_ICON_NAMES = {
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
};

export const COMPETITOR_PLATFORM_OPTIONS = {
  INSTAGRAM: {
    title: 'Instagram',
    iconName: COMPETITOR_PLATFORM_ICON_NAMES.INSTAGRAM,
    value: 'INSTAGRAM',
    limitReachedMessage:
      "You've reached the maximum number of competitor accounts. Purchase the Premium Analytics add-on for unlimited competitor monitoring.",
  },
  TWITTER: {
    title: 'X',
    iconName: COMPETITOR_PLATFORM_ICON_NAMES.TWITTER,
    value: 'TWITTER',
    limitReachedMessage:
      "You've reached the maximum number of competitor accounts. Purchase the Premium Analytics add-on for unlimited competitor monitoring.",
  },
  YOUTUBE: {
    title: 'YouTube',
    iconName: COMPETITOR_PLATFORM_ICON_NAMES.YOUTUBE,
    value: 'YOUTUBE',
    limitReachedMessage:
      "You've reached the maximum number of competitor accounts. Purchase the Premium Analytics add-on for unlimited competitor monitoring.",
  },
  FACEBOOK: {
    title: 'Facebook',
    iconName: COMPETITOR_PLATFORM_ICON_NAMES.FACEBOOK,
    value: 'FACEBOOK',
    limitReachedMessage:
      "You've reached the maximum number of competitor accounts. Purchase the Premium Analytics add-on for unlimited competitor monitoring.",
  },
};

export const COMPETITOR_PLATFORM_DEFAULT_ORDER = Object.values(COMPETITOR_PLATFORM_OPTIONS).map(
  (option) => option.value,
);

const COMPETITOR_PLATFORM_OPTIONS_WITHOUT_FACEBOOK = Object.values(
  COMPETITOR_PLATFORM_OPTIONS,
).filter((platform) => platform.value !== 'FACEBOOK');

export const COMPETITOR_FILTER_DEFAULT_VALUE = [
  {
    label: COMPETITOR_PLATFORM_OPTIONS_WITHOUT_FACEBOOK.map((platform) => platform.title).join(
      ', ',
    ),
    field: 'competitor_platform',
    imgFilter: true,
    emptyOperator: true,
    operator: 'is',
    valueToIconMap: COMPETITOR_PLATFORM_ICON_NAMES,
    value: COMPETITOR_PLATFORM_OPTIONS_WITHOUT_FACEBOOK.map((platform) => platform.value),
  },
];

export const ADD_COMPETITOR_NO_MATCHES = {
  INSTAGRAM:
    'Make sure the account you’re searching for is an Instagram business account and the account handle is entered exactly as it appears on Instagram',
  TWITTER: 'Check the spelling of the handle and try again',
  YOUTUBE: 'Check the spelling of the channel name and try again',
};

export const COMPETITOR_TAG_DELETE_CONFIRMATION = {
  TITLE: 'Delete Tag?',
  MESSAGE:
    'This action will permanently delete it from your list of tags and remove it from all competitors it was associated with. Are you sure you want to proceed?',
  CONFIRM_TEXT: 'Delete',
  SUCCESS_MESSAGE: 'Tags have been removed.',
  ERROR_MESSAGE: 'An error occurred while trying to remove the tags.',
};

export const GOOGLE_ANALYTICS_DEPRECATION_WARNING =
  'This is a Universal Analytics account. Google Analytics 4 accounts are recommended for connection.';

export const GOOGLE_ANALYTICS_4_DEPRECATION_WARNING_LONG =
  'Universal Analytics stopped collecting new data as of July 1st, 2023. We strongly encourage switching to Google Analytics 4 as soon as possible.';

export const CHILI_PIPER_FORMS_MAP = {
  grow: {
    downgrade: {
      name: 'grow_contact_us',
      router: 'grow-contact-us',
    },
  },
  engage: {
    downgrade: {
      name: 'engage_contact_us',
      router: 'engage-contact-us',
    },
    upgrade: {
      name: 'engage_start_free_trial_billing',
      router: 'upgrade-package-start-free-trial',
    },
    subscribe: {
      name: 'engage_buy_now',
      router: 'on-trial-buy-now',
    },
    community: {
      name: 'engage_start_free_trial_community',
      router: 'upgrade-package-start-free-trial',
    },
    library: {
      name: 'engage_start_free_trial_library',
      router: 'upgrade-package-start-free-trial',
    },
  },
  advance: {
    downgrade: {
      name: 'advance_contact_us',
      router: 'advance-contact-us',
    },
    upgrade: {
      name: 'advance_start_free_trial_billing',
      router: 'upgrade-package-start-free-trial',
    },
    subscribe: {
      name: 'advance_buy_now',
      router: 'on-trial-buy-now',
    },
    dashboards: {
      name: 'advance_start_free_trial_dashboards',
      router: 'upgrade-package-start-free-trial',
    },
  },
  enterprise: {
    upgrade: {
      name: 'enterprise_contact_us',
      router: 'enterprise-contact-us',
    },
    subscribe: {
      name: 'enterprise_buy_now',
      router: 'on-trial-buy-now',
    },
  },
  socialListening: {
    contact: {
      name: 'social_listening_get_trial_form',
      router: 'inbound-router-for-social-listening',
    },
    upgrade: {
      name: 'social_listening_upgrade_tier',
      router: 'social-listening-change-tier',
    },
    downgrade: {
      name: 'social_listening_downgrade_tier',
      router: 'social-listening-change-tier',
    },
  },
  premiumAnalytics: {
    contact: {
      name: 'premium_analytics_contact_us',
      router: 'premium-analytics-contact-us',
    },
    trial: {
      name: 'premium_analytics_start_free_trial_billing',
      router: 'upgrade-package-start-free-trial',
    },
    subscribe: {
      name: 'premium_analytics_buy_now',
      router: 'on-trial-buy-now',
    },
    relationships: {
      name: 'premium_analytics_start_free_trial_relationships',
      router: 'upgrade-package-start-free-trial',
    },
    campaigns: {
      name: 'premium_analytics_start_free_trial_campaigns',
      router: 'upgrade-package-start-free-trial',
    },
    competitors: {
      name: 'premium_analytics_start_free_trial_competitors',
      router: 'upgrade-package-start-free-trial',
    },
  },
  button: {
    bookATraining: {
      name: 'book_trial_training',
      router: 'book-trial-training',
    },
    extendTrial: {
      name: 'extend_trial',
      router: 'extend-trial',
    },
  },
  creators: {
    increaseLimit: {
      name: 'increase_creator_limit',
      router: 'increase-creator-limit',
    },
  },
};

export const SOCIAL_LISTENING_FEATURES = [
  'Trend Monitoring and Alerts for Instagram, Twitter, and YouTube',
  'Saved Topics for Public Posts',
  'Brand Health Template and Post Sentiment Reports in Dashboards',
  'Caption Sentiment on UGC Media Popup',
];

export const TRIAL = 'trial';
export const TIER_ONE = 'tier_one';
export const TIER_TWO = 'tier_two';
export const TIER_THREE = 'tier_three';
export const TIER_CUSTOM = 'custom';

export const SOCIAL_LISTENING_TOPICS_OPTIONS_DATA = [
  { value: TIER_ONE, text: '5 Topics' },
  { value: TIER_TWO, text: '10 Topics' },
  { value: TIER_THREE, text: '20 Topics' },
  { value: TIER_CUSTOM, text: '50+ Topics' },
];

export const SUBSCRIPTION_LABELS_MAP = {
  trial: {
    label: 'On Trial',
    value: 'trial',
    colour: colours.ALERT.ALERT_400,
    textColour: colours.TEXT.TEXT_PRIMARY,
  },
  active: {
    label: 'Active',
    value: 'active',
    colour: colours.SUCCESS.SUCCESS_400,
    textColour: colours.BASIC.WHITE,
  },
  inactive: {
    label: 'Inactive',
    value: 'inactive',
    colour: colours.ERROR.ERROR_400,
    textColour: colours.BASIC.WHITE,
  },
};

export const SUBSCRIPTION_PRICE_MAP = {
  social_listening_tier_1: {
    name: 'Tier 1',
    price: 499,
    billingSchedule: 'Monthly',
  },
  social_listening_tier_2: {
    name: 'Tier 2',
    price: 749,
    billingSchedule: 'Monthly',
  },
  social_listening_tier_3: {
    name: 'Tier 3',
    price: 999,
    billingSchedule: 'Monthly',
  },
  grow_monthly: {
    name: 'Grow',
    price: 249,
    billingSchedule: 'Monthly',
  },
  grow_yearly: {
    name: 'Grow',
    price: 2988,
    billingSchedule: 'Yearly',
  },
  grow_yearly_full: {
    name: 'Grow',
    price: 5988,
    billingSchedule: 'Yearly',
  },
};

export const FEATURE_UPGRADE_MAP = {
  dashboards: {
    headline: 'Complex reporting made easy',
    body: 'Analyze and report on multiple channels and brands all within one easy-to-use Dashboard.',
    buttonText: 'Start Free Trial',
    walnutTour: true,
    walnutTourLink:
      'https://app.teamwalnut.com/player/?demoId=97aa85d7-c1c8-473f-81f0-aae06050562a&screenId=db9f9fc3-709f-48cf-99b0-01e93202fd92&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app',
    chiliPiperDetails: { dashboards: CHILI_PIPER_FORMS_MAP.advance.dashboards },
    image: 'dashboards.png',
    secondaryHeadline: 'Learn More About Dashboards',
    blogPosts: [
      {
        title: 'The Dashboard Stella McCartney Uses To Optimize Social Campaigns',
        image: 'blog-post-1.png',
        link: 'https://www.dashhudson.com/case-study/how-stella-mccartney-optimizes-social-media-campaigns',
      },
      {
        title: 'Everything You Need To Know About Creating A Social Media Report',
        image: 'blog-post-2.jpg',
        link: 'https://www.dashhudson.com/blog/social-media-report-social-reporting-business',
      },
      {
        title:
          'Metrics That Influence: How A Digital Marketing Agency Uses Social Data To Hone Its Client Strategy',
        image: 'blog-post-3.png',
        link: 'https://www.dashhudson.com/case-study/taylor-and-pond-client-strategy',
      },
    ],
  },
  campaigns: {
    headline: 'Is proving Social ROI hard?',
    body: 'Get a 360-degree view of your campaign performance across owned, earned, and creator media.',
    walnutTour: true,
    walnutTourLink:
      'https://app.teamwalnut.com/player/?demoId=c1fdcefe-314f-42c9-9735-a223391b45b8&screenId=23cfbd9f-748b-4ce3-89b8-a0a135b6a14d&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app',
    chiliPiperDetails: {
      campaigns: CHILI_PIPER_FORMS_MAP.premiumAnalytics.campaigns,
      contact: CHILI_PIPER_FORMS_MAP.premiumAnalytics.contact,
    },
    image: 'campaigns.png',
    secondaryHeadline: 'Learn More About Campaigns',
    blogPosts: [
      {
        title: 'The Dashboard Stella McCartney Uses To Optimize Social Campaigns',
        image: 'blog-post-1.png',
        link: 'https://www.dashhudson.com/case-study/how-stella-mccartney-optimizes-social-media-campaigns',
      },
      {
        title: 'Google Analytics for Social Media: How to Track Performance',
        image: 'blog-post-4.jpg',
        link: 'https://www.dashhudson.com/blog/google-analytics-for-social-media',
      },
      {
        title: 'How To Run Social Media Campaigns',
        image: 'blog-post-5.jpg',
        link: 'https://www.dashhudson.com/blog/guide-to-social-media-campaigns',
      },
    ],
  },
  relationships: {
    headline: 'Unlock Successful Creator Partnerships',
    body: 'Save hours sifting through creator accounts and gain more time developing quality relationships.',
    walnutTour: false,
    chiliPiperDetails: {
      relationships: CHILI_PIPER_FORMS_MAP.premiumAnalytics.relationships,
      contact: CHILI_PIPER_FORMS_MAP.premiumAnalytics.contact,
    },
    image: 'relationships.png',
    secondaryHeadline: 'Learn More About Relationships',
    blogPosts: [
      {
        title:
          'How Rare Beauty Uses Creators To Drive 98% Of Total Campaign Conversions On TikTok Ads',
        image: 'blog-post-6.png',
        link: 'https://www.dashhudson.com/case-study/rare-beauty-tiktok-marketing-case-study',
      },
      {
        title: 'What Is The Difference Between Content Creators Vs. Influencers?',
        image: 'blog-post-7.jpg',
        link: 'https://www.dashhudson.com/blog/influencer-marketing-content-creator-social-media-strategy-brand-marketing',
      },
      {
        title: 'Creator Marketing: How Influencer Partnerships Are Evolving',
        image: 'blog-post-8.jpg',
        link: 'https://www.dashhudson.com/blog/creator-marketing',
      },
    ],
  },
  community: {
    headline: 'Build community and your bottom line at the same time',
    body: 'Connect with your community and streamline workflows between customer experience and social teams with Community.',
    buttonText: 'Start Free Trial',
    walnutTour: false,
    chiliPiperDetails: {
      community: CHILI_PIPER_FORMS_MAP.engage.community,
    },
    image: 'community.png',
    secondaryHeadline: 'Learn More About Community',
    blogPosts: [
      {
        title: '5 Ways To Build A Social Media Community For Your Brand',
        image: 'blog-post-9.jpg',
        link: 'https://www.dashhudson.com/blog/building-a-social-media-community',
      },
      {
        title: 'How Coterie Drives Community Growth And Engagement Through Social Listening',
        image: 'blog-post-10.png',
        link: 'https://www.dashhudson.com/case-study/coterie-drives-community-growth-with-social-listening',
      },
      {
        title: '6 Social Media Community Management Best Practices',
        image: 'blog-post-11.jpg',
        link: 'https://www.dashhudson.com/blog/social-media-community-management-best-practices-marketing-tips',
      },
    ],
  },
  competitors: {
    headline: 'How do you stack up against your competitors?',
    body: 'Celebrate your wins or uncover opportunities with instant cross-channel competitive and industry benchmarks at your fingertips.',
    walnutTour: true,
    walnutTourLink:
      'https://app.teamwalnut.com/player/?demoId=c47adce3-aca5-443a-ac50-646f557054f2&screenId=38ae4902-2c57-4985-807e-4391721a25db&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app',
    chiliPiperDetails: {
      competitors: CHILI_PIPER_FORMS_MAP.premiumAnalytics.competitors,
      contact: CHILI_PIPER_FORMS_MAP.premiumAnalytics.contact,
    },
    image: 'competitors.png',
    secondaryHeadline: 'Learn More About Competitors',
    blogPosts: [
      {
        title: 'The Competitive Insights BÉIS Uses To Outsmart Social',
        image: 'blog-post-12.png',
        link: 'https://www.dashhudson.com/case-study/beis-uses-competitive-insights-to-succeed-on-social',
      },
      {
        title:
          'How Flamingo Surpassed The Industry Average Retention Rate By 41% With Competitive Insights',
        image: 'blog-post-13.png',
        link: 'https://www.dashhudson.com/case-study/flamingo-surpasses-industry-average-retention-rate-with-competitive-insights',
      },
      {
        title: 'How To Do Instagram Competitive Analysis: Tips For Brands',
        image: 'blog-post-14.jpg',
        link: 'https://www.dashhudson.com/blog/how-to-analyze-competitors-on-instagram',
      },
    ],
  },
  library: {
    headline: 'The Smartest Way to Manage Your Social Media Assets',
    body: 'Surface the perfect owned, earned, or paid content in seconds with our visual search technology.',
    buttonText: 'Start Free Trial',
    walnutTour: false,
    chiliPiperDetails: {
      library: CHILI_PIPER_FORMS_MAP.engage.library,
    },
    image: 'library.png',
    secondaryHeadline: 'Learn More About Library',
    blogPosts: [
      {
        title: 'How Brands Can Leverage Their Social Media Library To Elevate E-Commerce Strategy',
        image: 'blog-post-15.jpg',
        link: 'https://www.dashhudson.com/blog/social-media-library-on-site',
      },
      {
        title: 'Social Media Content Calendar: How To Get It Right',
        image: 'blog-post-16.jpg',
        link: 'https://www.dashhudson.com/blog/social-media-content-calendar',
      },
      {
        title: 'Visual Content Marketing Strategy: 10 Best Practices From Leading Brands',
        image: 'blog-post-17.jpg',
        link: 'https://www.dashhudson.com/blog/visual-content-marketing-strategy',
      },
    ],
  },
  listening: {
    headline: "Want to know who's talking about you?",
    body: 'Experience AI-powered social listening insights for video, visual, and text – without the noise.',
    walnutTour: true,
    walnutTourLink:
      'https://www.dashhudson.com/interactive-demo/social-listening-product-demo?utm_medium=referral&utm_source=dh_platform&utm_campaign=sl_upgrade',
    chiliPiperDetails: { contact: CHILI_PIPER_FORMS_MAP.socialListening.contact },
    image: 'social-listening.png',
    secondaryHeadline: 'Learn More About Social Listening',
    blogPosts: [
      {
        title: 'How Summer Fridays Amplifies Customer Voices With AI-Powered Social Listening',
        image: 'blog-post-18.png',
        link: 'https://www.dashhudson.com/case-study/summer-fridays-leverages-ai-powered-social-listening',
      },
      {
        title: 'What Is Social Listening And Why Is It Important For Brands?',
        image: 'blog-post-19.jpg',
        link: 'https://www.dashhudson.com/blog/what-is-social-listening',
      },
      {
        title: 'How Coterie Drives Community Growth And Engagement Through Social Listening',
        image: 'blog-post-10.png',
        link: 'https://www.dashhudson.com/case-study/coterie-drives-community-growth-with-social-listening',
      },
    ],
  },
};

export const FEATURE_UPGRADE_LINK_TEXT = Object.freeze({
  WALNUT_TOUR: 'Take a Tour',
  PLANS_AND_ADDONS: 'Plans and Add-Ons',
});

export const CONTENT_TAGS_EVENT_NAMES = {
  CONTENT_TAG_CREATED: 'Content Tag Created',
  CONTENT_TAG_EDITED: 'Content Tag Edited',
  CONTENT_TAG_DELETED: 'Content Tag Deleted',
  CONTENT_TAG_MEDIA_ACTION: 'Content Tag Media Action',
  CONTENT_TAG_FILTER_USED: 'Content Tag Filter Used',
};

export const CONTENT_TAG_EDIT_CONTEXTS = {
  BRANDS: 'visibility',
  NAME: 'tag name',
};

export const CONTENT_TAG_MEDIA_ACTIONS = {
  ADD: 'Added',
  REMOVE: 'Removed',
};

export const CONTENT_TAG_ADDED_METHOD = {
  MEDIA_POP_UP: 'global pop-up',
  MEDIA_MULTI_SELECT: 'media multi-select toolbar',
};

export const facebookAdsAccountDisableToggleTooltip =
  'You must have at least one ad account enabled. Disconnecting your Meta Ads account can be done on the Platform Connections page.';

export const CREATE_CONTENT_AUTOMATION_WIZARD_RADIO_OPTIONS = Object.freeze({
  BRANDS: {
    label: 'Brands',
    value: 'Brands',
  },
  BRAND_TAGS: { label: 'Brand Tags', value: 'Brand Tags' },
});
