<template>
  <div class="add-to-dropdown">
    <section v-on-click-outside="hideAddToDropdown">
      <transition :name="dropdownTransition">
        <div
          v-if="showAddToDropdown && openSection.galleryTypeList"
          class="gallery-type-list"
          data-cy="gallery-type-list"
        >
          <ul>
            <li
              v-for="(galleryType, index) in galleryTypeList"
              :key="index"
              v-tooltip="disabledTooltip(galleryType)"
              :class="{ disabled: disabledType(galleryType) }"
              @click="selectGalleryType(galleryType)"
            >
              <Icon :name="galleryTypeDetails[galleryType].icon" class="type" small />
              <span>{{ galleryTypeDetails[galleryType].label }}</span>
              <Icon name="caret" class="caret" xxsmall />
            </li>
          </ul>
        </div>
      </transition>

      <AddToCampaigns
        v-if="openSection.campaignSearchList"
        ref=""
        :media="selectedMedia ? selectedMedia : mediaSelectStore.multiSelectSelectedItems"
        :hide-dropdown="hideAddToDropdown"
        :select-only="selectOnly"
        @handle-back-click="handleBackClick"
        @handle-save-campaigns="handleSaveCampaigns"
      />

      <transition name="fade">
        <div
          v-if="showAddToDropdown && openSection.gallerySearchList"
          class="gallery-search-dropdown"
        >
          <MultiSelectList
            :items="galleryStore.summaryGalleries"
            :search-placeholder="searchPlaceholderText"
            :loading="galleryStore.pending.summaryGalleries > 0"
            class="gallery-search-list"
            @item-remove="(itemId) => softRemove.push(itemId)"
            @item-add="(itemId) => (softRemove = softRemove.filter((it) => it !== itemId))"
          >
            <template #topNav>
              <div class="back" @click="handleBackClick">
                <Icon name="caret" dir="down" xxsmall />
                <span>All</span>
              </div>
            </template>
            <template #emptyListAddOn>
              <div class="empty-gallery-view">
                <div>
                  <p>{{ emptyGalleryText }}</p>
                  <Button class="add-new-button" small primary @click="handleAddNewClicked">
                    Add New
                  </Button>
                </div>
              </div>
            </template>
            <template #footer>
              <footer v-if="galleryStore.summaryGalleries.length > 0">
                <a @click="handleAddNewClicked">Add New</a>
                <a class="blue" data-cy="add-to-dropdown-save" @click="handleSaveClicked">Save</a>
              </footer>
            </template>
          </MultiSelectList>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="showAddToDropdown && openSection.addNewGallery" class="new-gallery-dropdown">
          <input
            ref="newGalleryInput"
            v-model="boardOrGalleryName"
            :placeholder="newInputText"
            autofocus
            maxlength="80"
            class="new-gallery-input"
            @click="inputFocus"
          />

          <footer>
            <a @click="handleCancelClicked">Cancel</a>
            <a
              :class="{ disabled: !boardOrGalleryName }"
              class="blue"
              @click="addNewBoardOrGallery"
            >
              Add
            </a>
          </footer>
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapStores } from 'pinia';
import pullAll from 'lodash/pullAll';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import upperFirst from 'lodash/upperFirst';
import debounce from 'lodash/debounce';
import { vOnClickOutside } from '@vueuse/components';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useSharedStore } from '@/stores/shared';
import { useNotificationStore } from '@/stores/notification';
import { useFlagStore } from '@/stores/flag';
import MultiSelectList from '@/components/MultiSelectList.vue';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import { constants, toolTips } from '@/config';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import enumTypes from '@/app/library/constants';
import { checkTweetMediaIsOriginal } from '@/utils/media';
import AddToCampaigns from '@/app/campaigns/components/AddToCampaigns.vue';
import { useCampaignsStore } from '@/stores/campaigns';
import { usePlatformStore } from '@/stores/platform';
import { getCompetitorAndUGCHandles } from '@/app/library/utils';
import { useMediaSelectStore } from '@/stores/media-select';
import { useGalleryStore } from '@/stores/gallery';
import { trackBoardsAddBoardClicked, trackBoardMediaAdded } from '@/components/Galleries/mixpanel';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'AddToDropdown',
  components: {
    Button,
    Icon,
    MultiSelectList,
    AddToCampaigns,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    // Media that comes from the global popup
    selectedMedia: { type: Object, default: null },
    // Media that comes from the upload popup
    uploadedMedia: { type: Array, default: () => [] },
    selectedGalleries: { type: Array, default: () => [] },
    onGalleriesSelected: { type: Function, default: () => {} },
    selectOnly: { type: Boolean, default: false },
    parentName: { type: String, default: '' },
  },
  emits: ['addFromPopup'],
  data() {
    return {
      softRemove: [],
      isInitialShowDropdown: false,
      showAddToDropdown: false,
      customEmptyGalleryText: {
        LIBRARY: 'No galleries found',
        PINTEREST: 'No DH Boards found',
      },
      customSearchPlaceholderText: {
        LIBRARY: 'Search for galleries',
        PINTEREST: 'Search for DH Boards',
      },
      customNewInputText: {
        LIBRARY: 'Enter gallery name',
        PINTEREST: 'Enter DH Board name',
      },
      galleryTypeDetails: {
        LIBRARY: { label: 'Galleries', icon: 'galleries' },
        INSTAGRAM: { label: 'Instagram Boards', icon: 'instagram' },
        INSTAGRAM_STORY: { label: 'Instagram Story Boards', icon: 'stories' },
        PINTEREST: { label: 'DH Pinterest Boards', icon: 'pinterest' },
        FACEBOOK: { label: 'Facebook Boards', icon: 'facebook' },
        TIKTOK: { label: 'TikTok Boards', icon: 'tiktok' },
        TWITTER: { label: 'X Boards', icon: 'twitter' },
        YOUTUBE: { label: 'YouTube Boards', icon: 'youtube' },
        CAMPAIGNS: { label: 'Campaigns', icon: 'campaigns' },
      },
      selectedGalleryType: null,
      boardOrGalleryName: null,
      openSection: {
        galleryTypeList: false,
        gallerySearchList: false,
        addNewGallery: false,
        campaignSearchList: false,
      },
    };
  },
  computed: {
    ...mapStores(
      useAuthStore,
      useCampaignsStore,
      useNotificationStore,
      useNotificationStore,
      usePlatformStore,
      useMediaSelectStore,
      useSharedStore,
      useGalleryStore,
      useTrackingStore,
      useFlagStore,
    ),
    emptyGalleryText() {
      return this.customEmptyGalleryText[this.selectedGalleryType] || 'No Boards found';
    },
    searchPlaceholderText() {
      return this.customSearchPlaceholderText[this.selectedGalleryType] || 'Search for Boards';
    },
    newInputText() {
      return this.customNewInputText[this.selectedGalleryType] || 'Enter Board name';
    },
    hasTikTokAccess() {
      return (
        this.authStore.brand_can('tiktok', 'can_access_tiktok_insights') &&
        this.authStore.user_can('tiktok', 'can_access_tiktok_insights')
      );
    },
    hasFacebookAccess() {
      return (
        this.authStore.brand_can('facebook', 'can_access_fb_insights') &&
        this.authStore.user_can('facebook', 'can_access_fb_insights')
      );
    },
    hasTwitterAccess() {
      return (
        this.authStore.brand_can('twitter', 'can_access_twitter_insights') &&
        this.authStore.user_can('twitter', 'can_access_twitter_insights')
      );
    },
    hasYouTubeAccess() {
      return (
        this.authStore.guard(BRAND.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS) &&
        this.authStore.guard(USER.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS)
      );
    },
    twitterConnected() {
      const platformConnection = this.platformStore.platformConnectionsMap.twitter;
      if (platformConnection && platformConnection[this.authStore.currentBrand.id]) {
        return platformConnection[this.authStore.currentBrand.id].status === 'connected';
      }
      return null;
    },
    hasInstagramAccess() {
      return this.authStore.user_can('instagram', 'can_access_instagram');
    },
    hasPinterestAccess() {
      return this.authStore.guard(USER.PINTEREST.CAN_ACCESS_PINT);
    },
    hasCampaignsAccess() {
      return (
        this.authStore.brand_can('campaigns', 'can_access_campaigns') &&
        this.authStore.user_can('campaigns', 'can_access_campaigns')
      );
    },
    hasLibraryAccess() {
      return this.authStore.guard(BRAND.LIBRARY.CAN_ACCESS_LIBRARY);
    },
    isTikTokUGC() {
      if (this.selectedMedia) {
        return this.selectedMedia.sourceType === enumTypes.TIKTOK_UGC;
      }
      return this.mediaSelectStore.multiSelectSelectedItems?.every((media) => {
        const mediaSourceType = media?.sourceType?.split(':');
        return mediaSourceType?.pop() === enumTypes.TIKTOK_UGC;
      });
    },
    // Filter gallery type options based on selected media item type(s)
    filteredGalleryTypeList() {
      // access dependency explicitly
      const { hasPinterestAccess } = this;
      const galleryTypes = [];
      if (!this.disableLibraryGalleries) {
        galleryTypes.push(constants.LIBRARY);
      }
      // If items are selected using Multi Select
      if (this.mediaSelectStore.multiSelectSelectedItems.length > 0) {
        const mediaSources = this.mediaSelectStore.multiSelectSelectedItems.map(
          (item) => item.source,
        );
        if (this.authStore.canAccessOrganic) {
          if (mediaSources.every((source) => source === constants.INSTAGRAM)) {
            galleryTypes.push(constants.INSTAGRAM);
          } else if (mediaSources.every((source) => source === constants.INSTAGRAM_STORY)) {
            galleryTypes.push(constants.INSTAGRAM_STORY);
          } else if (
            hasPinterestAccess &&
            mediaSources.every((source) => source === constants.PINTEREST)
          ) {
            galleryTypes.push(constants.PINTEREST);
          }
        }

        const isStoryUgcInRelationshipsRoute =
          this.$route.name?.startsWith('relationships.') &&
          this.mediaSelectStore.multiSelectSelectedItems.every((item) => {
            return item?.sourceType === enumTypes.UGC && item?.source === enumTypes.INSTAGRAM_STORY;
          });

        if (
          this.hasCampaignsAccess &&
          !this.isTikTokUGC &&
          !isStoryUgcInRelationshipsRoute &&
          this.mediaSelectStore.multiSelectSelectedItems.every((item) => {
            // Owned Instagram Stories have "OTHER" source_type which will return true for isCompetitive
            const isStoryOrNotCompetitive =
              item.source === constants.INSTAGRAM_STORY ||
              !this.isCompetitive(item?.source_type || item?.sourceType || item?.type);
            return (
              item.source !== constants.UPLOAD &&
              isStoryOrNotCompetitive &&
              checkTweetMediaIsOriginal(item)
            );
          })
        ) {
          galleryTypes.push(constants.CAMPAIGNS);
        }

        if (
          this.hasTikTokAccess &&
          !this.isTikTokUGC &&
          mediaSources.every((source) => source === constants.TIKTOK)
        ) {
          galleryTypes.push(constants.TIKTOK);
        }
        if (
          this.hasFacebookAccess &&
          mediaSources.every((source) => enumTypes.FACEBOOK_SOURCE_LIST.includes(source))
        ) {
          galleryTypes.push(constants.FACEBOOK);
        }
        if (this.hasTwitterAccess && this.checkMultiSelectedOnlyTweets()) {
          const onlyContainOriginalTweets = this.mediaSelectStore.multiSelectSelectedItems.every(
            (item) => checkTweetMediaIsOriginal(item),
          );
          if (onlyContainOriginalTweets) {
            galleryTypes.push(constants.TWITTER);
          }
        }
        if (this.hasYouTubeAccess && mediaSources.every((source) => source === constants.YOUTUBE)) {
          galleryTypes.push(constants.YOUTUBE);
        }
        // If the item is being added from the global popup
      } else if (this.selectedMedia) {
        this.selectedMedia.galleries.forEach((gallery) => {
          let galleryItem = gallery;
          // sometimes gallery is like ["123", "galleryName"] where the first element is id and second is name.
          if (galleryItem.length && galleryItem[0] && galleryItem[1]) {
            galleryItem = { id: gallery[0], name: gallery[1] };
          }
          // gallery property is so we can keep track of what in the selected list is a gallery vs board
          // TODO will need some refactoring when this ticket is tackled: https://app.clubhouse.io/dashhudson/story/24658
          // prevent adding items marked as soft deleted from being added to shared store again
          if (this.softRemove.some((id) => gallery.id === id)) {
            return;
          }
          galleryItem.gallery = true;
          this.sharedStore.updateSelectedMultiSelectListItems({ type: 'add', item: galleryItem });
        });

        const isStoryUgcInRelationshipsRoute =
          this.selectedMedia.sourceType === enumTypes.INSTAGRAM_STORY_UGC &&
          this.$route.name?.startsWith('relationships.');

        if (
          this.hasCampaignsAccess &&
          !isStoryUgcInRelationshipsRoute &&
          !this.isTikTokUGC &&
          this.selectedMedia.postType !== constants.UPLOAD &&
          !this.isCompetitive(this.selectedMedia?.sourceType) &&
          checkTweetMediaIsOriginal(this.selectedMedia)
        ) {
          galleryTypes.push(constants.CAMPAIGNS);
        }

        if (this.hasInstagramAccess) {
          if (this.selectedMedia.postType === constants.INSTAGRAM) {
            galleryTypes.push(constants.INSTAGRAM);
          } else if (this.selectedMedia.postType === constants.INSTAGRAM_STORY) {
            galleryTypes.push(constants.INSTAGRAM_STORY);
          }
        }
        if (this.hasFacebookAccess && this.selectedMedia.postType === constants.FACEBOOK) {
          galleryTypes.push(constants.FACEBOOK);
        }
        if (
          this.hasTikTokAccess &&
          this.selectedMedia.postType === constants.TIKTOK &&
          !this.isTikTokUGC
        ) {
          galleryTypes.push(constants.TIKTOK);
        }
        if (
          this.hasTwitterAccess &&
          this.checkSelectedMediaIsTweet() &&
          checkTweetMediaIsOriginal(this.selectedMedia)
        ) {
          galleryTypes.push(constants.TWITTER);
        }
        if (hasPinterestAccess && this.selectedMedia.postType === constants.PINTEREST) {
          galleryTypes.push(constants.PINTEREST);
        }
        if (this.hasYouTubeAccess && this.selectedMedia.postType === constants.YOUTUBE) {
          galleryTypes.push(constants.YOUTUBE);
        }
      } else if (this.$route.name.includes('scheduler') && this.hasCampaignsAccess) {
        galleryTypes.push(constants.CAMPAIGNS);
      }
      if (this.selectOnly) {
        galleryTypes.push(constants.PINTEREST);
      }

      if (this.checkSelectedMediaIsLinkedIn() || this.checkMultiSelectedContainsLinkedIn()) {
        galleryTypes.splice(
          0,
          galleryTypes.length,
          ...galleryTypes.filter((type) => type !== constants.CAMPAIGNS),
        );
      }

      return galleryTypes;
    },
    disabledGalleryTypeList() {
      if (!this.authStore.canAccessOrganic) {
        return [];
      }
      return difference(this.galleryTypeList, this.filteredGalleryTypeList);
    },
    galleryTypeList() {
      const { hasPinterestAccess } = this;
      const list = [];
      if (this.hasCampaignsAccess) {
        list.push(constants.CAMPAIGNS);
      }
      if (this.hasLibraryAccess) {
        list.push(constants.LIBRARY);
      }
      if (this.hasTikTokAccess) {
        list.push(constants.TIKTOK);
      }
      list.push(constants.INSTAGRAM, constants.INSTAGRAM_STORY);
      if (this.hasFacebookAccess) {
        list.push(constants.FACEBOOK);
      }
      if (hasPinterestAccess || this.selectOnly) {
        list.push(constants.PINTEREST);
      }
      if (this.hasTwitterAccess) {
        list.push(constants.TWITTER);
      }
      if (this.hasYouTubeAccess) {
        list.push(constants.YOUTUBE);
      }
      return list;
    },
    dropdownTransition() {
      // The dropdown should slide into view, but fade in between select gallery options
      if (this.showAddToDropdown && this.isInitialShowDropdown) {
        return 'slide';
      }
      return 'fade';
    },
    includesTextPosts() {
      return (
        this.selectedMedia?.sourceType?.includes('_TEXT') ||
        this.mediaSelectStore.multiSelectSelectedItems.some((mediaItem) => {
          return mediaItem?.source?.includes('_TEXT');
        })
      );
    },
    disableLibraryGalleries() {
      const sourceType = this.selectedMedia?.sourceType;
      return enumTypes.NO_MEDIA_SOURCE_LIST.includes(sourceType) || this.includesTextPosts;
    },
    allDisabled() {
      return this.filteredGalleryTypeList.length === 0;
    },
    selectedMediaContainsInstagramStoryUgc() {
      if (this.selectedMedia) {
        return this.selectedMedia.sourceType === enumTypes.INSTAGRAM_STORY_UGC;
      }

      return this.mediaSelectStore.multiSelectSelectedItems.some((item) => {
        return item?.sourceType === enumTypes.UGC && item?.source === enumTypes.INSTAGRAM_STORY;
      });
    },
  },
  watch: {
    'galleryStore.pending.createGalleryStatus': function createGalleryStatusWatcher(to) {
      if (!to && this.galleryStore.newGallery) {
        this.refreshGalleries();
        this.openSection.addNewGallery = false;
        this.openSection.gallerySearchList = true;
        this.boardOrGalleryName = null;
      }
    },
    'galleryStore.error.createGalleryStatus': function createGalleryStatusErrorWatcher(to, from) {
      const message = (to ?? from)?.response?.data?.description ?? 'An error has occurred.';
      if (
        this.notificationStore.toasts.length === 0 &&
        !this.galleryStore.pending.createGalleryStatus
      ) {
        this.notificationStore.setToast({ message, type: 'error' });
      }
    },
    'galleryStore.pending.addMediaStatus': function addMediaStatusWatcher(to) {
      if (!to) {
        if (this.notificationStore.toasts.length === 0) {
          if (this.galleryStore.error.addMediaStatus) {
            this.notificationStore.setToast({
              message: 'An error has occurred.',
              type: 'error',
            });
          } else {
            this.notificationStore.setToast({ message: 'Your updates have been saved.' });
          }
        }
        this.showAddToDropdown = false;
        this.openSection.gallerySearchList = false;
        this.refreshGalleries();
        this.sharedStore.clearSelectedMultiSelectListItems();
        this.mediaSelectStore.clearItemsFromMultiSelect();
      }
    },
    'campaignsStore.pending.addMediaToCampaign': function addMediaToCampaignWatcher(to) {
      if (!to && this.notificationStore.toasts.length === 0) {
        if (this.campaignsStore.error.addMediaToCampaign) {
          this.notificationStore.setToast({
            message: 'An error has occurred.',
            type: 'error',
          });
        } else {
          const isPlural = this.sharedStore.selectedMultiSelectListItems?.length > 1;
          this.notificationStore.setToast({
            message: `Media added to campaign${isPlural ? 's' : ''}`,
          });
          this.mediaSelectStore.clearItemsFromMultiSelect();
        }
      }
    },
    'campaignsStore.pending.removeMediaFromCampaign': function removeMediaFromCampaignWatcher(to) {
      if (!to && this.notificationStore.toasts.length === 0) {
        if (this.campaignsStore.error.removeMediaFromCampaign) {
          this.notificationStore.setToast({
            message: 'An error has occurred.',
            type: 'error',
          });
        } else {
          this.notificationStore.setToast({ message: 'Media removed from campaign' });
        }
      }
    },
    selectedGalleries(to) {
      if (to.length === 0) {
        this.sharedStore.clearSelectedMultiSelectListItems();
      }
    },
  },
  created() {
    this.sharedStore.clearSelectedMultiSelectListItems();
    this.selectedGalleries.forEach((gallery) => {
      let galleryItem = gallery;
      // sometimes gallery is like ["123", "galleryName"] where the first element is id and second is name.
      if (galleryItem.length && galleryItem[0] && galleryItem[1]) {
        galleryItem = { id: gallery[0], name: gallery[1] };
      }
      this.sharedStore.updateSelectedMultiSelectListItems({ type: 'add', item: galleryItem });
    });
    if (this.selectedMedia?.campaigns) {
      this.selectedMedia.campaigns.forEach((campaign) => {
        this.sharedStore.updateSelectedMultiSelectListItems({
          type: 'add',
          item: { campaign: true, id: campaign.id, name: campaign.name },
        });
      });
    }
  },
  methods: {
    isCompetitive(type) {
      if (!type || typeof type !== 'string') {
        return false;
      }
      return enumTypes.COMPETITIVE_LIST.some((competitiveType) => type.includes(competitiveType));
    },
    isUGC(type) {
      if (!type || typeof type !== 'string') {
        return false;
      }
      return [enumTypes.INSTAGRAM_STORY_UGC, enumTypes.INSTAGRAM_UGC, ...enumTypes.UGC_LIST].some(
        (ugcType) => type.includes(ugcType),
      );
    },
    isRelationship(media) {
      return media?.instagram?.relationship ?? media?.relationship ?? false;
    },
    mapGalleryTypeToTitle(type) {
      switch (type) {
        case constants.LIBRARY:
          return 'Gallery';
        case constants.INSTAGRAM_STORY:
          return 'Story Board';
        default:
          return 'Board';
      }
    },
    disabledTooltip(type) {
      const platform = upperFirst(type.toLowerCase());
      if (!this.disabledGalleryTypeList.includes(type)) {
        return null;
      }
      if (this.flagStore.ready && this.flagStore.flags.tiktokUgc && this.isTikTokUGC) {
        if (type === constants.CAMPAIGNS) {
          return 'Adding TikTok UGC to Campaigns is not available';
        }
        if (type === constants.TIKTOK) {
          return 'Adding TikTok UGC to Boards is not available';
        }
      }
      if (type === constants.CAMPAIGNS) {
        if (this.checkSelectedMediaIsTweet() || this.checkMultiSelectedContainsTweets()) {
          return 'Reposts, Quote Posts, and Replies cannot be added to a Campaign.';
        }
        if (this.checkSelectedMediaIsLinkedIn() || this.checkMultiSelectedContainsLinkedIn()) {
          return 'Content from LinkedIn cannot be added to your Campaigns';
        }
        if (
          this.$route.name?.startsWith('relationships.') &&
          this.selectedMediaContainsInstagramStoryUgc
        ) {
          return 'This action cannot be performed.';
        }
        return 'Only UGC you have been @mentioned or photo-tagged in can be added to Campaigns.';
      }
      if (type === constants.INSTAGRAM_STORY) {
        return 'You can only add Instagram stories to your Instagram Story Boards.';
      }
      if (type === constants.INSTAGRAM) {
        return 'You can only add feed content and UGC to your Instagram Boards.';
      }
      if (type === constants.PINTEREST) {
        return `You can add to DH Pinterest Boards only when this media is published on ${platform}.`;
      }
      if (type === constants.TIKTOK) {
        return `You can only add TikTok videos to your TikTok Boards.`;
      }
      if (type === constants.YOUTUBE) {
        return 'You can only add YouTube videos to your YouTube Boards.';
      }
      if (type === constants.LIBRARY && this.includesTextPosts) {
        return 'Posts without media cannot be added to a Gallery.';
      }
      if (
        type === constants.TWITTER &&
        (this.checkMultiSelectedOnlyTweets() || this.checkSelectedMediaIsTweet())
      ) {
        return toolTips.twitter.cannotAddNonOriginalTweetToBoard;
      }

      if (type === constants.YOUTUBE) {
        return `You can only add YouTube videos to your YouTube Boards.`;
      }
      if (type === constants.TWITTER) {
        return `You can add to X Boards only when this media is published on X.`;
      }

      return `You can add to ${platform} Boards only when this media is published on ${platform}.`;
    },
    openAddToDropdown() {
      this.showAddToDropdown = true;
      this.isInitialShowDropdown = true;
      this.openSection.galleryTypeList = true;
      this.openSection.gallerySearchList = false;
      this.openSection.campaignSearchList = false;
      this.openSection.addNewGallery = false;
    },
    hideAddToDropdown(e) {
      // Use target class name instead of fixed nested structure (parent.refs)
      // in case HTML structure changes and break the function.
      if (
        this.showAddToDropdown &&
        (!e || (e.target.classList && !e.target.classList.contains('add-to-dropdown-button')))
      ) {
        // Trigger Save when clicking outside the dropdown
        this.saveOnClickOutside();
        this.showAddToDropdown = false;
        this.openSection.galleryTypeList = false;
        this.openSection.gallerySearchList = false;
        this.openSection.campaignSearchList = false;
        this.openSection.addNewGallery = false;
      }
    },
    selectGalleryType(galleryType) {
      if (this.disabledType(galleryType)) {
        return;
      }
      this.isInitialShowDropdown = false;
      this.openSection.galleryTypeList = false;
      this.selectedGalleryType = galleryType;
      if (galleryType === constants.CAMPAIGNS) {
        this.openSection.campaignSearchList = true;
      } else {
        this.openSection.gallerySearchList = true;
        this.refreshGalleries();
      }
    },
    handleBackClick() {
      this.openSection.galleryTypeList = true;
      this.openSection.campaignSearchList = false;
      this.openSection.gallerySearchList = false;
      this.softRemove = [];
      this.galleryStore.clearGalleries();
    },
    handleSaveClicked: debounce(function preventFastClick() {
      if (this.showAddToDropdown) {
        this.showAddToDropdown = false;
        this.handleSaveGalleries();
      }
    }, 50),
    async handleSaveGalleries() {
      if (this.selectOnly) {
        this.onGalleriesSelected(this.sharedStore.selectedMultiSelectListItems);
        this.showAddToDropdown = false;
        return;
      }
      let mediaList = [];
      // If we are saving from media popup
      if (this.selectedMedia) {
        mediaList.push(this.selectedMedia.id);
        this.$emit('addFromPopup', 'Add To');
      } else if (this.mediaSelectStore.multiSelectSelectedItems.length > 0) {
        if (this.$route.meta.schedulerRoute) {
          // multi selected items are post instead of media in scheduler routes
          this.mediaSelectStore.multiSelectSelectedItems.forEach((item) => {
            mediaList = [...mediaList, ...item.media_ids];
          });
        } else if (this.$route.name.includes('ads')) {
          mediaList = this.mediaSelectStore.multiSelectSelectedItems.map((item) => {
            // ad card has media id in item.media.media_id
            // viq media items simply has item.id
            return (item.media && item.media.media_id) || item.id;
          });
        } else {
          mediaList = this.mediaSelectStore.multiSelectSelectedItems.map((item) => item.id);
        }
      } else if (this.uploadedMedia.length > 0) {
        mediaList = this.uploadedMedia.map((media) => media.id);
      }
      const selectedGalleriesIds = this.sharedStore.selectedMultiSelectListItems
        .filter((item) => !item.campaign)
        .map((item) => item.id);

      // add media to selected galleries
      selectedGalleriesIds.forEach((id) => {
        this.galleryStore.addMediaToGallery({ galleryId: id, mediaIds: mediaList });
      });
      if (this.selectedMedia) {
        const mediaGalleryIds = this.selectedMedia.galleries.map((gallery) => {
          // sometimes gallery is like ["123", "galleryName"] where the first element is id and second is name.
          // sometimes gallery is an object like { id: .., name: .. }.
          // TODO: unify stupid gallery data format
          if (gallery.id) {
            // If gallery data comes in as an object
            return gallery.id;
          }
          // If gallery data comes in as an array
          return gallery[0];
        });
        const galleryIdsToRemove = pullAll(mediaGalleryIds, selectedGalleriesIds);
        galleryIdsToRemove.forEach((id) => {
          this.galleryStore.removeMediaFromGallery({
            galleryId: id,
            mediaId: this.selectedMedia.id,
          });
        });
        // This should be fixed if this section is refactored.
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedMedia.galleries = this.sharedStore.selectedMultiSelectListItems.filter(
          (item) => !item.campaign,
        );
      }

      // handle mixpanel
      const params = {};
      const otherMedia = getCompetitorAndUGCHandles(this.mediaSelectStore.multiSelectSelectedItems);
      params.ugcMedia = otherMedia.ugc;
      params.competitorMedia = otherMedia.competitor;

      trackBoardMediaAdded({
        mediaIDs: mediaList,
        galleryType: this.selectedGalleryType,
        galleryList: this.sharedStore.selectedMultiSelectListItems,
        addedFrom: this.$route.name,
        mediaAdded: mediaList.length,
        fromMultiSelectBar: true,
        ownedMedia: mediaList.filter(
          (media) =>
            media?.sourceType === enumTypes.OWNED || media?.source_types === enumTypes.OWNED,
        ).length,
        ...params,
      });
    },
    sendAddNewGalleryMixpanel() {
      const galleryType = this.selectedGalleryType;
      const componentName = this.parentName;
      trackBoardsAddBoardClicked(galleryType, componentName);
    },
    handleAddNewClicked() {
      this.openSection.gallerySearchList = false;
      this.openSection.addNewGallery = true;
      nextTick(() => {
        this.$refs.newGalleryInput?.focus?.();
      });
      this.sendAddNewGalleryMixpanel();
    },
    handleCancelClicked() {
      this.openSection.gallerySearchList = true;
      this.openSection.addNewGallery = false;
      this.boardOrGalleryName = null;
    },
    addNewBoardOrGallery() {
      // return if there is no name
      if (!this.boardOrGalleryName) {
        return;
      }
      this.handleAddNewGallery();
    },
    async handleAddNewGallery() {
      // Wait for new gallery to be created, then add selected media to that gallery.
      await this.galleryStore.createGallery({
        name: this.boardOrGalleryName,
        description: '',
        galleryType: this.selectedGalleryType,
      });
      this.notificationStore.setToast({ message: this.formatAddGalleryMessage() });

      const data = {
        name: this.boardOrGalleryName,
        description: '',
        gallery_type: this.selectedGalleryType,
      };
      this.trackingStore.track(`${this.mapGalleryTypeToTitle(this.selectedGalleryType)} Created`, {
        gallery: data,
        galleryType: this.selectedGalleryType,
        description: data.description,
        name: data.name,
        tags: [],
        tagAmount: 0,
        fromMultiSelectBar: true,
      });

      if (this.galleryStore.newGallery) {
        this.sharedStore.updateSelectedMultiSelectListItems({
          type: 'add',
          item: { id: this.galleryStore.newGallery.id, name: this.boardOrGalleryName },
        });
        const selectedMediaIds = this.mediaSelectStore.multiSelectSelectedItems.map(
          (item) => item.id,
        );
        if (selectedMediaIds.length > 0) {
          this.galleryStore.addMediaToGallery({
            galleryId: this.galleryStore.newGallery.id,
            mediaIds: selectedMediaIds,
          });
        }
        this.galleryStore.clearNewGallery();
        this.hideAddToDropdown();
      }
    },
    inputFocus(e) {
      e.target.focus();
    },
    refreshGalleries() {
      this.galleryStore.clearGalleries();
      this.galleryStore.getSummaryGalleries({ type: this.selectedGalleryType });
    },
    formatAddGalleryMessage() {
      if (this.selectedGalleryType === constants.LIBRARY) {
        return 'Gallery created!';
      }
      return 'Board created!';
    },
    saveOnClickOutside() {
      // The AddTo dropdown in the Global Media popup is pre-populated with galleries the item belongs to. Here, we check to
      // see if the user has made any changes to the gallery selections and if none exist, we do not trigger the save function.
      if (this.selectedMedia) {
        const selectedGalleriesIds = this.sharedStore.selectedMultiSelectListItems.map(
          (item) => item.id,
        );
        const mediaGalleryIds = this.selectedMedia.galleries.map((gallery) => {
          if (gallery.id) {
            // If gallery data comes in as an object
            return gallery.id;
          }
          // If gallery data comes in as an array
          return gallery[0];
        });

        if (!isEqual(selectedGalleriesIds, mediaGalleryIds)) {
          this.handleSaveGalleries();
        }
      } else {
        this.handleSaveGalleries();
      }
    },
    disabledType(galleryType) {
      return this.disabledGalleryTypeList?.includes(galleryType) && !this.selectOnly;
    },
    checkMultiSelectedOnlyTweets() {
      if (this.mediaSelectStore.multiSelectSelectedItems.length > 0) {
        const mediaSources = this.mediaSelectStore.multiSelectSelectedItems.map(
          (item) => item.source,
        );
        return mediaSources.every((source) => enumTypes.TWITTER_SOURCE_LIST.includes(source));
      }
      return false;
    },
    checkMultiSelectedContainsTweets() {
      if (this.mediaSelectStore.multiSelectSelectedItems.length > 0) {
        const mediaSources = this.mediaSelectStore.multiSelectSelectedItems.map(
          (item) => item.source,
        );
        return mediaSources.some((source) => enumTypes.TWITTER_SOURCE_LIST.includes(source));
      }
      return false;
    },
    checkSelectedMediaIsTweet() {
      if (this.selectedMedia) {
        return this.selectedMedia.postType === constants.TWITTER;
      }
      return false;
    },
    checkMultiSelectedContainsLinkedIn() {
      return this?.mediaSelectStore?.multiSelectSelectedItems.some(
        (selected) => selected?.source === constants.LINKEDIN,
      );
    },
    checkSelectedMediaIsLinkedIn() {
      return this?.selectedMedia?._original?.source === constants.LINKEDIN;
    },
    handleSaveCampaigns() {
      if (this.selectOnly) {
        this.onGalleriesSelected(this.sharedStore.selectedMultiSelectListItems);
      }
      this.openSection.campaignSearchList = false;
      this.showAddToDropdown = false;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.disabled {
  opacity: 0.3;
  cursor: default;
}

.add-to-dropdown {
  z-index: var(--z-index-dropdown);
}

.gallery-type-list {
  position: absolute;
  width: auto;
  background: var(--background-0);
  border-radius: var(--round-corner-small);
  box-shadow: var(--shadow-4);
  z-index: var(--z-index-dropdown);
  padding: var(--space-8) 0;

  ul {
    width: 100%;
    padding: 0 var(--space-16);
  }

  li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    text-transform: capitalize;
    font-size: var(--x14);
    font-weight: var(--font-medium);
    color: var(--text-primary);
    padding: var(--space-8) 0;
    cursor: pointer;

    &:hover {
      color: var(--action-500);
    }

    .svg-icon.caret {
      margin: 0.1rem 0 0 var(--space-12);
    }

    .svg-icon.type {
      margin-right: var(--space-8);
      flex: none;
    }

    span {
      width: 100%;
      text-align: left;
    }
  }

  li.disabled:hover {
    cursor: not-allowed;
    color: var(--text-primary);
  }
}

.gallery-search-dropdown,
.new-gallery-dropdown {
  position: absolute;
  z-index: var(--z-index-dropdown);
}

.gallery-search-list {
  padding-top: var(--space-8);
}

.gallery-search-list .back {
  margin-left: var(--space-16);
  height: var(--space-16);
  text-align: left;
  font-size: var(--x14);
  color: var(--text-secondary);
  cursor: pointer;
  transition: var(--transition-all);

  &:hover {
    color: var(--action-500);
  }

  svg {
    margin-right: var(--space-8);
  }
}

.empty-gallery-view div {
  text-align: center;
  padding: var(--space-48) 0 var(--space-72);

  p {
    text-align: center;
    font-size: var(--x14);
    margin-bottom: var(--space-16);
  }

  .add-new-button {
    width: 8.3rem;
    margin: auto;
  }
}

.new-gallery-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 19.3rem;
  width: 16.5rem;
  background: var(--background-0);
  box-shadow: var(--shadow-4);
  border-radius: var(--round-corner-small);

  input {
    width: 13.5rem;
    height: var(--space-32);
    margin: var(--space-24) auto var(--space-8);
    padding: 0 var(--space-24);
    border-radius: var(--space-32);
    font-size: var(--x14);
    background-color: var(--background-0);
    color: var(--text-primary);
    border: 1px solid var(--border);

    &:focus,
    &:active {
      border-color: var(--action-500);
    }
  }
}

footer {
  height: var(--space-48);
  background: var(--background-300);
  font-size: var(--x14);
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-24);
  border-radius: 0 0 var(--round-corner-small) var(--round-corner-small);

  a {
    &:hover {
      color: var(--action-500);
    }
  }

  a.disabled,
  a.disabled:hover {
    cursor: auto;
    opacity: 0.3;
  }

  .blue {
    color: var(--action-500);
  }
}
</style>
