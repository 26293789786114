<template>
  <div class="skeleton-loader-container">
    <component
      v-bind="$attrs"
      :is="skeletonComponent"
      v-if="loading"
      :class="{ 'no-animation': noAnimation }"
    ></component>
    <slot v-else></slot>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import mapValues from 'lodash/mapValues';
import { SKELETON_TYPES as TYPES } from '@/config';

const SKELETON_TYPES = {
  ChartSkeleton: {
    type: TYPES.chart,
    component: defineAsyncComponent(() => import('@/components/core/skeleton/ChartSkeleton.vue')),
  },
  CircleSkeleton: {
    type: TYPES.circle,
    component: defineAsyncComponent(() => import('@/components/core/skeleton/CircleSkeleton.vue')),
  },
  CommentSkeleton: {
    type: TYPES.comment,
    component: defineAsyncComponent(() => import('@/components/core/skeleton/CommentSkeleton.vue')),
  },
  CompetitorOverviewBarChartSkeleton: {
    type: TYPES.competitorOverviewBarChartSkeleton,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/CompetitorOverviewBarChartSkeleton.vue'),
    ),
  },
  TopLineStatsSkeleton: {
    type: TYPES.topLineStats,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/TopLineStatsSkeleton.vue'),
    ),
  },
  TopPostsSkeleton: {
    type: TYPES.topPosts,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/TopPostsSkeleton.vue'),
    ),
  },
  TitleSkeleton: {
    type: TYPES.title,
    component: defineAsyncComponent(() => import('@/components/core/skeleton/TitleSkeleton.vue')),
  },
  LongTitleSkeleton: {
    type: TYPES.longTitle,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/LongTitleSkeleton.vue'),
    ),
  },
  HorizontalCardSkeleton: {
    type: TYPES.horizontalCard,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/HorizontalCardSkeleton.vue'),
    ),
  },
  productCardSkeleton: {
    type: TYPES.productCard,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/ProductCardSkeleton.vue'),
    ),
  },
  MediaCardSkeleton: {
    type: TYPES.mediaCard,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/MediaCardSkeleton.vue'),
    ),
  },
  YoutubeMediaCardSkeleton: {
    type: TYPES.youtubeMediaCard,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/YoutubeMediaCardSkeleton.vue'),
    ),
  },
  BlankMediaCardSkeleton: {
    type: TYPES.blankMediaCard,
    component: defineAsyncComponent(() => import('@/components/core/skeleton/BlankMediaCard.vue')),
  },
  UgcMediaCardSkeleton: {
    type: TYPES.ugcMediaCard,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/UgcMediaCardSkeleton.vue'),
    ),
  },
  NotificationSkeleton: {
    type: TYPES.notification,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/NotificationSkeleton.vue'),
    ),
  },
  GalleryCardSkeleton: {
    type: TYPES.galleryCard,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/GalleryCardSkeleton.vue'),
    ),
  },
  HorizontalDistributionChartSkeleton: {
    type: TYPES.horizontalDistributionChartSkeleton,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/HorizontalDistributionChartSkeleton.vue'),
    ),
  },
  HorizontalDistributionFlexChartSkeleton: {
    type: TYPES.horizontalDistributionFlexChartSkeleton,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/HorizontalDistributionFlexChartSkeleton.vue'),
    ),
  },
  GalleryCardSkeletonSquareMedia: {
    type: TYPES.galleryCardSquareMedia,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/GalleryCardSquareMediaSkeleton.vue'),
    ),
  },
  PictureSkeleton: {
    type: TYPES.picture,
    component: defineAsyncComponent(() => import('@/components/core/skeleton/PictureSkeleton.vue')),
  },
  MediaTileMatrixSkeleton: {
    type: TYPES.mediaTileMatrix,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/MediaTileMatrixSkeleton.vue'),
    ),
  },
  ListSkeleton: {
    type: TYPES.list,
    component: defineAsyncComponent(() => import('@/components/core/skeleton/ListSkeleton.vue')),
  },
  InboxMessageSkeleton: {
    type: TYPES.inboxMessage,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/InboxMessageSkeleton.vue'),
    ),
  },
  InboxMessageDetail: {
    type: TYPES.inboxMessageDetail,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/InboxMessageDetailSkeleton.vue'),
    ),
  },
  DirectMessageSkeleton: {
    type: TYPES.directMessage,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/DirectMessageSkeleton.vue'),
    ),
  },
  InstagramStoryCardSkeleton: {
    type: TYPES.instagramStoryCard,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/InstagramStoryCardSkeleton.vue'),
    ),
  },
  WideMediaTile: {
    type: TYPES.wideMediaTile,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/WideMediaTileSkeleton.vue'),
    ),
  },
  VerticalMediaTile: {
    type: TYPES.verticalMediaTile,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/VerticalMediaTileSkeleton.vue'),
    ),
  },
  PresetReplyPopup: {
    type: TYPES.presetReplyPopup,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/PresetReplyPopupSkeleton.vue'),
    ),
  },
  CampaignCard: {
    type: TYPES.campaignCard,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/CampaignCardSkeleton.vue'),
    ),
  },
  TikTokTrendingSoundsSkeleton: {
    type: TYPES.TikTokTrendingSoundsSkeleton,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/TikTokTrendingSoundsSkeleton.vue'),
    ),
  },
  MonthlyDateContentsSkeleton: {
    type: TYPES.monthlyDateContents,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/MonthlyDateContentsSkeleton.vue'),
    ),
  },
  WeeklyDateContents: {
    type: TYPES.weeklyDateContents,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/WeeklyDateContentsSkeleton.vue'),
    ),
  },
  Card: {
    type: TYPES.card,
    component: defineAsyncComponent(() => import('@/components/core/skeleton/CardSkeleton.vue')),
  },
  AccountSelector: {
    type: TYPES.accountSelector,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/AccountSelectorSkeleton.vue'),
    ),
  },
  TrendGroup: {
    type: TYPES.trendGroup,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/TrendGroupSkeleton.vue'),
    ),
  },
  InfluencerTile: {
    type: TYPES.influencerTile,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/InfluencerTileSkeleton.vue'),
    ),
  },
  UserDropdownSelect: {
    type: TYPES.userDropdownSelect,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/UserDropdownSelectSkeleton.vue'),
    ),
  },
  InteractionCard: {
    type: TYPES.interactionCard,
    component: defineAsyncComponent(
      () => import('@/app/community/components/InteractionCardSkeleton.vue'),
    ),
  },
  TagSkeleton: {
    type: TYPES.tag,
    component: defineAsyncComponent(() => import('@/components/core/skeleton/TagSkeleton.vue')),
  },
  AdsAccountsPopupSkeleton: {
    type: TYPES.adsAccountsPopupSkeleton,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/AdsAccountsPopupSkeleton.vue'),
    ),
  },
  ChatBubbleSkeleton: {
    type: TYPES.chatBubbleSkeleton,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/ChatBubbleSkeleton.vue'),
    ),
  },
  ChatFeedSkeleton: {
    type: TYPES.chatFeedSkeleton,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/ChatFeedSkeleton.vue'),
    ),
  },
  TileMatrixSkeleton: {
    type: TYPES.tileMatrix,
    component: defineAsyncComponent(
      () => import('@/components/core/skeleton/TileMatrixSkeleton.vue'),
    ),
  },
};
const SKELETON_COMPONENTS = mapValues(SKELETON_TYPES, ({ component }) => component);
const SKELETON_COMPONENT_TYPE_MAP = Object.fromEntries(
  Object.entries(SKELETON_TYPES).map(([key, { type }]) => [type, key]),
);

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'SkeletonLoader',
  components: {
    ...SKELETON_COMPONENTS,
  },
  props: {
    loading: { type: Boolean, default: false },
    type: {
      type: String,
      default: TYPES.title,
      validator: (value) => value in SKELETON_COMPONENT_TYPE_MAP,
    },
    noAnimation: { type: Boolean, default: false },
  },
  computed: {
    skeletonComponent() {
      return SKELETON_COMPONENT_TYPE_MAP[this.type];
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.skeleton-loader-container {
  display: contents;

  .no-animation {
    animation: none !important;

    :deep(.skeleton-loading) {
      animation: none !important;
    }
  }
}
</style>
