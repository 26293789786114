import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useKeywordExpressionValidators } from '@/app/socialListening/composables/useKeywordExpressionValidators';
import { KEYWORD_TEXT_EDITOR_ERRORS } from '@/app/socialListening/constants';

export const useBooleanExpressionEditorStore = defineStore('booleanExpressionEditorStore', () => {
  const syntaxErrorMap = ref({
    '(': null,
    ')': null,
    AND: null,
    OR: null,
    NOT: null,
  });

  function resetTokenErrorMap() {
    syntaxErrorMap.value = {
      '(': null,
      ')': null,
      AND: null,
      OR: null,
      NOT: null,
    };
  }

  function errorHighlightForSyntaxError(error, keywordBooleanExpression) {
    const unexpected = error.tokens.unexpected;
    const { column, line } = error.location;
    const expressionByLine = keywordBooleanExpression.split('\n');
    const sliceOffset = Math.max(0, unexpected.length - 1); // some unexpected tokens have multiple characters, e.g. "AND"
    const n = expressionByLine[line - 1]
      .slice(0, column + sliceOffset) // slice up to the column + length of the unexpected token
      .split(/(\b|\s+|\(|\))/)
      .filter((char) => char.trim() === unexpected).length;
    syntaxErrorMap.value[unexpected] = n;
  }

  function handleTextEditorErrorsUsingExpression(expression) {
    const { hasMoreThanOneNotClause } = useKeywordExpressionValidators();

    if (hasMoreThanOneNotClause(expression)) {
      syntaxErrorMap.value.NOT = '*';
      return [
        {
          error: KEYWORD_TEXT_EDITOR_ERRORS.MULTIPLE_NOT_CLAUSES,
        },
      ];
    }
    return [];
  }

  return {
    errorHighlightForSyntaxError,
    resetTokenErrorMap,
    syntaxErrorMap,
    handleTextEditorErrorsUsingExpression,
  };
});
