// Note: all references to environment variables should be getters to avoid issues with patchEnv in jest tests
import { env } from '@/env';
import { colours } from '@/ux/colours';
import {
  facebookCompetitiveMediaCardDefaults,
  facebookGalleryCardDefaults,
  facebookMediaCardDefaults,
  facebookMetricFormats,
} from '@/app/facebook/constants';
import {
  pinterestMediaCardDefaults,
  pinterestGalleryCardDefaults,
  pinterestMetricFormats,
} from '@/app/pinterest/constants';
import {
  twitterMediaCardDefaults,
  twitterGalleryCardDefaults,
  twitterMetricFormats,
} from '@/app/twitter/constants';
import {
  tiktokGalleryCardDefaults,
  tiktokMetricFormats,
  tiktokMediaCardDefaults,
} from '@/app/tiktok/constants';
import {
  youtubeMediaCardDefaults,
  youtubeGalleryCardDefaults,
  youtubeMetricFormats,
} from '@/app/youtube/constants';

const DASHWEB_PREVIEW_URL_FORMATS = [
  '^.*deploy-preview-[0-9]+--dash-web-dev\\.netlify\\.app$',
  '^.*pr-[0-9]+\\.d25om9rdt9u3b9\\.amplifyapp\\.com$',
  '^.*pr-.*\\.dashweb\\.dhpreview\\.link$',
];

export const TOKEN_KEY = 'token';

export function originForReport() {
  const { origin } = window.location;
  if (env.reportOriginOverride) {
    return env.reportOriginOverride;
  }
  const isPreviewUrl = DASHWEB_PREVIEW_URL_FORMATS.some((previewUrlRegex) => {
    return new RegExp(previewUrlRegex).test(origin);
  });
  if (!isPreviewUrl) {
    return env.dashwebUrl;
  }
  return origin;
}

export const isDatadogSyntheticsBrowser = !!window._DATADOG_SYNTHETICS_BROWSER;

export const DEFAULT_FONT_SIZE = 16;

export const platformColors = {
  tiktok: '#F26FA4',
  instagram: '#27C449',
  instagramStories: '#E179DA',
  instagramUgc: '#8943FF',
  facebook: '#4990E2',
  pinterest: '#E36042',
  twitter: '#F5A623',
  youtube: '#39BCE6',
};

export const sentimentColors = {
  negative: colours.ERROR.ERROR_500,
  positive: colours.SUCCESS.SUCCESS_500,
  neutral: colours.ACTION.ACTION_500,
};

export const mediaTypeColors = {
  allMedia: '#4990e2',
  video: '#27C449',
  reel: '#E36042',
  photo: '#F5A623',
  carousel: '#E179DA',
  videoReel: '#8943FF',
  photoCarousel: '#39BCE6',
  link: '#C3E921',
  text: '#F0649A',
};

export const filterSizeOptions = ['Any', '800x600', '1024x768', '1600x1200'];

export const filterColorOptions = [
  { name: 'red', hex: '#D0021B' },
  { name: 'orange', hex: '#F5A623' },
  { name: 'yellow', hex: '#F8E71C' },
  { name: 'green', hex: '#30D321' },
  { name: 'cyan', hex: '#50E3C2' },
  { name: 'blue', hex: '#4A90E2' },
  { name: 'purple', hex: '#9013FE' },
  { name: 'pink', hex: '#FF9DA9' },
  { name: 'brown', hex: '#8B572A' },
  { name: 'black', hex: '#000000' },
  { name: 'gray', hex: '#9B9B9B' },
  { name: 'white', hex: '#FFFFFF' },
];

export const dateRangeOptions = [
  { label: 'All Time', value: null },
  { label: 'Last 24 Hours', value: 1 },
  { label: 'Last 3 Days', value: 3 },
  { label: 'Last 7 Days', value: 7 },
  { label: 'Last 4 Weeks', value: 28 },
];

// The max number of days prior to a brand connecting their Twitter Account that we have data available
export const maxTwitterDataCutOffDays = 90;

export const maxMentionsPerTweet = 10;

export const maxTweetThreads = 25;

const metaInstagramMediaPopup = Object.freeze({
  totalVideoViews:
    'The total number times your organic and promoted videos published during the reporting period were played for at least 1ms. This excludes replays. This may include views from IGTV.',
  amountSpentPaid: "The total amount of money you've spent promoting this post",
  clicksPaid:
    'The total number of clicks this promoted post received. This can include link clicks, clicks to your profile, and more.',
  comments: 'The total number of times people commented on this post',
  costPerThruplayPaid:
    'The average cost per ThruPlay for this video\n' +
    '\n' +
    'Cost Per ThruPlay = Amount Spent / Total ThruPlays',
  cpcPaid:
    'The average cost per click on this promoted post\n' +
    '\n' +
    'CPC = Amount Spent / Total Promoted Clicks',
  cpmPaid:
    'The average cost per 1,000 impressions on this promoted post\n' +
    '\n' +
    'CPM = Amount Spent / Total Promoted Impressions x 1,000',
  ctrPaid:
    'The percentage of times people clicked your promoted post after seeing it\n' +
    '\n' +
    'CTR = Total Clicks / Promoted Impressions',
  totalEngagements:
    'The total number of organic and promoted engagements on this post\n' +
    '\n' +
    'Engagements = (Likes + Saves + Comments + Shares) - (Unlikes + Unsaves + Deleted Comments)\n' +
    '\n' +
    'Note: Promoted unsaves are unavailable',
  engagementRate:
    'The organic engagement rate for this post\n' +
    '\n' +
    'Engagement Rate = Engagements / Followers',
  engagementRateImpressions:
    'The organic engagement rate for this post\n' +
    '\n' +
    'Engagement Rate = Engagements / Impresssions',
  engagementRatePaid:
    'The promoted engagement rate for this post\n' +
    '\n' +
    'Engagement Rate = Promoted Engagements / Promoted Impressions',
  frequencyPaid: 'The average number of times people saw this promoted post',
  impressions: 'The total number of impressions this post received ',
  likes: 'The total number of likes this post received',
  reachTotal: 'The total number of unique accounts that saw this post',
  thruplaysPaid:
    'The total number of times this promoted post was played to completion or for at least 15 seconds',
  videoPlays25Paid: 'The total number of times this promoted video was played to 25% of its length',
  videoPlays50Paid: 'The total number of times this promoted video was played to 50% of its length',
  videoPlays75Paid: 'The total number of times this promoted video was played to 75% of its length',
  videoPlays100Paid: 'The total number of times this promoted video was played to 100% completion',
  videoViewsTotal:
    'The number of times this video was initially played for at least 1ms. This excludes replays.',
});

export const toolTips = {
  // Pinterest
  richPin:
    'Rich pins are only available for people that apply for them, and in that case all of their pins are rich pins. Linking to the validator would be confirmation that it will work on Pinterest.',
  newFollowers: 'Number of new followers of your account.',
  siteTraffic:
    'Traffic driven to your website from your pins and UGC, pulled from Google Analytics.',
  ugcTraffic:
    'Traffic driven to your site via user generated content, pulled from Google Analytics.',
  ugcPins: 'UGC are pins created by a user that direct to your website.',
  topPins:
    'Top pins from the past 7 days based on engagements. (Engagements = Pin Clicks + Outbound Clicks + Saves). These posts are compared to the Avg. Engagements in the last 12 weeks.',
  boardNeedsAccount: 'Connect your Pinterest account to choose a Pinterest Board',
  boardTraffic: 'Traffic to your website from pins, pulled from Google Analytics.',
  boardSaves: 'Number of times a user has saved your pin to their own board.',
  boardClicks: 'Number of clicks to your website from pins on your profile.',
  boardImpressions:
    'Number of times a pin showed up in the home feed, search results, and category feeds.',
  boardEngagementRate: 'Engagement Rate = (Clicks + Saves) / Impressions',

  // Pin metrics
  pinterestInsights: {
    insightsHeader: 'Insights data may be delayed up to 72 hours.',
    engagementRateVideo:
      'Percentage of Pinterest users that engaged with your video pin. (Engagement Rate = (Outbound Clicks + Saves) / Video Views)',
    engagementRateImage:
      'Percentage of Pinterest users that engaged with your photo pin. (Engagement Rate = (Pin Clicks + Outbound Clicks + Saves) / Impressions)',
    outboundClicks:
      'When a Pinterest user clicks and follows the link attached to your pin to the destination URL.',
    overviewLinkClicks:
      'When a Pinterest user clicks and follows the link attached to your pins (and saved pins) to the destination URL.',
    engagementLinkClicks:
      'When a Pinterest user clicks and follows the link attached to your owned pins to the destination URL.',
    saves: 'When a Pinterest user saves your pin.',
    impressions: 'When a Pinterest user has your pin displayed to them on Pinterest.',
    pinClicks: 'When a Pinterest user clicks your pin to see more details.',
    comments: 'When a Pinterest user comments on your pin.',
    videoViews: 'The amount of views for at least 2 seconds with 50% of the video pin in view.',
    averageWatchTime: 'The average time someone spent watching your video pin.',
    engagementRate:
      'Percentage of Pinterest users that engaged with your pin. (Engagement Rate = (Pin Clicks + Outbound Clicks + Saves) / Impressions)',
    engagements:
      'The total number of organic engagements your image, video and product Pins received. Engagements = Outbound Clicks + Saves + Pin Clicks',
    engagementsMedia:
      'The total number of engagements your Pin received. Total Engagements = Outbound Clicks + Saves + Pin Clicks',
  },

  // Tiktok metrics
  tiktokInsights: {
    effectiveness:
      'The percentage of people that saw and engaged with this video. Effectiveness Rate = (Likes + Comments + Shares + Video Views) / Reach',
    engagementRate: 'Engagement Rate = Total Engagements / Reach',
    retentionRate:
      'The average percentage of this video that people watched. Retention Rate = Average Time Watched / Video Duration',
    totalEngagements:
      'The total number of engagements this video has received. Total Engagements = Likes + Comments + Shares',
    ugcTotalEngagements:
      'The total number of engagements this video has received. Engagements = Likes + Comments + Shares',
    completionRate: 'The percentage of viewers that watched your entire video.',
    forYouPageViews: 'The total percentage of video views that came from the For You page.',
    followingPageViews:
      'The total percentage of video views that came from viewers on their Following page.',
    hashtagViews: 'The total percentage of video views that came from hashtags.',
    soundViews: "The total percentage of video views that came from the sound's page.",
    profileViews:
      'The total percentage of video views that came from viewers on your personal profile.',
    searchViews: 'The total percentage of video views that came from users performing a search.',
    entertainmentScore:
      'The entertainment measure of this video on a scale of 0 to 10. The higher the Engagement and Retention Rates of your video, the higher your score will be. Scores will be generated 48 hours after publishing.',
  },

  youtubeInsights: {
    insightsHeader: 'Insights data may be delayed up to 72 hours.',
    avgViewPercentage: 'The average retention rate of this video.',
    totalEngagements:
      'The total number of engagements this video has received. Total Engagements  = Likes + Dislikes + Comments + Shares',
  },

  // LinkedIn metrics
  linkedinInsights: {
    clickThroughRate:
      'The percentage of your audience that clicked on this post after seeing it.\n\nClick Through Rate = Clicks / Impressions',
    clicks: 'The number of times people clicked on this post',
    comments: 'The total number of comments this post received',
    engagementRate: 'Engagement Rate = Total Engagements / Impressions',
    engagements: 'The total number of engagements on this post',
    impressions: 'The number of times this organic post has been viewed',
    reactions: 'The total number of reactions this post received',
    uniqueImpressions: 'The number of times this organic post was shown to unique LinkedIn members',
    videoViews:
      'The number of times this video has been viewed.\nA view is counted when at least 50% of the video is visible and playback is over 2 seconds.',
    watchTime: 'The sum of time that all viewers watched your video for',
  },

  instagramOverview: {
    totalVideoViews:
      'The total number times your organic and promoted videos published during the reporting period were played for at least 1ms. This excludes replays. This may include views from IGTV.',
    netNewFollowers:
      'The net total of followers your account has accumulated during the selected date range',
    totalEngagements:
      'The number of likes, saves, comments and shares on your post minus the number of unlikes, unsaves and deleted comments',
    averageEngagementRate:
      'The average engagement rate for your organic posts published during the selected date range\n' +
      '\n' +
      'Engagement Rate = Engagements / Followers',
    averageEffectiveness:
      'The average effectiveness rate for your organic posts published during the reporting period\n' +
      '\n' +
      'Feed Effectiveness Rate = (Likes + Comments + Saves + Video Views) / Reach\n' +
      '\n' +
      'Reels Effectiveness Rate = (Likes + Comments + Saves + Video Views) / Est. Reach',
    averageReach:
      'The average reach for your organic posts published during the selected date range',
    impressions:
      'The total number of impressions for your organic and promoted posts published during the selected date range',
    followersChart:
      'The number of people who have followed your Instagram account during the selected date range',
    mostEngagingPosts:
      'Highest or lowest performing organic and promoted posts published during the selected date range by engagement rate. These posts are compared to the average engagement rate per post over the last 12 weeks.',
    topUGC: 'Top organic posts that featured you during the selected date range, sorted by reach',
    topLikeShopStats: 'Your LikeShop performance during the selected date range',
    topLikeShopContents:
      'Top performing LikeShop posts published during your selected date range. Posts are sorted by number of clicks.',
    mostEntertaining:
      'Your most entertaining Reels published during the reporting period. These posts are compared to the average value from posts published during the comparison period.',
    metrics: {
      SUM_TOTAL_ENGAGEMENTS:
        'The total number of engagements on your organic and promoted posts published during the selected date range\n' +
        '\n' +
        'Engagements = (Likes + Saves + Comments + Shares) - (Unlikes + Unsaves + Deleted Comments)\n' +
        '\n' +
        'Note: Promoted unsaves are unavailable',
      AVG_REACH: 'The average number of unique accounts that saw your organic posts',
      TOTAL_IMPRESSIONS: 'The number of times your organic and promoted posts have been viewed',
      TOTAL_LIKES: 'The total number of likes your organic and promoted posts have received',
      TOTAL_COMMENTS: 'The total number of comments left on your organic and promoted posts',
      SAVES: 'The number of times your organic posts have been saved',
      TOTAL_VIDEO_VIEWS: 'The number of times your organic and promoted videos have been viewed',
      AVG_ENGAGEMENT_RATE:
        'The average percentage of your audience that engaged with your organic posts published during the selected date range\n' +
        '\n' +
        'Engagement Rate = Engagements / Followers',
      AVG_EFFECTIVENESS:
        'The average percentage of users that saw and engaged with your organic posts published during the selected date range.\n' +
        '\n' +
        'Feed Effectiveness Rate = (Likes + Comments + Saves + Video Views) / Reach\n' +
        '\n' +
        'Reels Effectiveness Rate = (Likes + Comments + Saves + Video Views) / Est. Reach',
    },
  },

  instagramInsights: {
    boosted: 'This post has been promoted',
    effectivenessNonVideo:
      'The percentage of users that saw your organic post and engaged with it\n' +
      '\n' +
      'Effectiveness = (Likes + Comments + Saves) / Reach',
    effectiveness:
      'The percentage of users that saw your organic post and engaged with it\n' +
      '\n' +
      'Effectiveness = (Likes + Comments + Saves + Video Views) / Reach',
    effectivenessUgc:
      'The percentage of people that saw your post and engaged with it\n' +
      '\n' +
      'Effectiveness = Likes + Comments / Est. Reach',
    engagementRate:
      'The organic engagement rate for this post\n' +
      '\n' +
      'Engagement Rate = Engagements / Followers',
    engagementRateImpressions:
      'The organic engagement rate for this post\n' +
      '\n' +
      'Engagement Rate = Engagements / Impressions',
    engagementRateUgc:
      'The percentage of your followers that engaged with your post\n' +
      '\n' +
      'Engagement Rate = Likes + Comments / Followers',
    igOther:
      "As you are not photo tagged or @ mentioned in this piece of content, Instagram's API only allows us to show you a bit of info. Click 'See on Instagram' to get more info on this post!",
    totalEngagements:
      'The number of likes, saves, comments and shares on your post minus the number of unlikes, unsaves and deleted comments',
    totalEngagementsUGC:
      'The number of people who engaged with this post\n\nEngagements = Likes + Comments',
    paidTotalEngagements:
      'The total number of Paid Engagements on this post\n' +
      '\n' +
      'Paid Total Engagements = Paid Likes + Paid Comments',
    organicTotalEngagements:
      'The total number of Organic Engagements on this post\n' +
      '\n' +
      'Organic Total Engagements = Organic Likes + Organic Comments + Saves',
    paidImpressions: 'Total impressions from all paid placements',
    unavaliablePaidMetrics:
      'This boosted data is not available via the API 3 years after publishing',
    reach: 'The number of unique accounts who saw this post',
    estimatedReach: 'The estimated number of unique accounts who saw this post',
    boostedReach: 'The number of unique accounts who saw this post. Measures organic Reach only.',
    likes: 'The number of people who liked this post',
    comments: 'The number of people who commented on this post',
    entertainmentScore:
      'The Entertainment Score of this Reel on a scale of 0 to 10. Your score is based on volume of organic engagements compared to reach.',
    follows: 'The number of accounts that started following you after viewing your post',
    emv: 'The Earned Media Value of this post',
    followersGained: 'The estimated number of followers this post gained for your brand',
    profileVisits: 'The number of times your profile was visited after viewing your post',
    profileClicks:
      'The number of actions people take when they visit your profile after viewing your post. This can include bio link clicks, calls, emails, and more.',
  },

  instagramReelsInsights: {
    videoViewsOrganic:
      'The number of times this video was initially played for at least 1ms. This excludes replays.',
    videoViewsPromoted:
      'The number of times your promoted video was initially played for at least 1ms. This excludes replays.',
    reachOrganic: 'The number of unique accounts who saw this post. Measures organic Reach only.',
    estimatedReach: 'The estimated number of unique accounts who saw this post',
    impressionsPromoted: 'The number of impressions from promoted posts',
    effectivenessOrganic:
      'The percentage of users that saw your organic post and engaged with it\n' +
      '\n' +
      'Effectiveness = (Likes + Comments + Saves + Video Views) / Est. Reach',
    effectivenessUgc:
      'The percentage of people that saw your post and engaged with it\n' +
      '\n' +
      'Effectiveness = Likes + Comments / Est. Reach',
    engagementRateOrganic:
      'The organic engagement rate for this post\n' +
      '\n' +
      'Engagement Rate = Engagements / Followers',
    engagementRateOrganicImpressions:
      'The organic engagement rate for this post\n' +
      '\n' +
      'Engagement Rate = Engagements / Impressions',
    engagementRateUgc:
      'The percentage of your followers that engaged with your post\n' +
      '\n' +
      'Engagement Rate = Likes + Comments / Followers',
    engagementsTotal: 'The total number of organic and promoted engagements on this post',
    engagementsOrganic:
      'The number of likes, saves, comments and shares on your post minus the number of unlikes, unsaves and deleted comments.',
    engagementsPromoted:
      // Outdated tooltip no longer in use
      'The number of engagements on this post from promoted sources\n' +
      '\n' +
      'Engagements - Promoted = Likes - Promoted + Comments - Promoted',
    engagementsUgc:
      'The number of people who engaged with this post\n\nEngagements = Likes + Comments',
    likes: 'The number of people who liked this post',
    comments: 'The number of people who commented on this post',
    replays: 'The number of rewatches for at least 1ms your video received in the same session',
    totalPlays:
      'The number of times this Reel was initially played for at least 1ms. Replays are counted after the initial play in the same Reel session.\n' +
      '\n' +
      'Note: This count may be greater than the sum of Replays and Plays because it includes Plays in the Facebook app through Cross App Recommendation.',
    avgTimeViewedSec: 'The average time your video was played during a single viewing',
    totalTimeViewedSec: 'The sum of the time that all viewers watched your video for',
    emv: 'The Earned Media Value of this post',
    followersGained: 'The estimated number of followers this post gained for your brand',
  },

  metaInstagramMediaPopup,

  metaInstagramReelMediaPopup: {
    ...metaInstagramMediaPopup,
    ...{
      videoPlaysTotalPaid:
        'The total number of times this video was played for at least 1ms. Excludes replays.',
      totalEngagementsPaid:
        'The total number of promoted engagements on this post\n' +
        '\n' +
        `Promoted Engagements = Likes + Comments`,
    },
  },

  // Stories
  instagramStory: {
    swipeUps:
      'Dash Hudson can only track link clicks for frames which were scheduled through the Dash Hudson Scheduler and have a trackable link enabled.',
    ugcStorySwipeUps: 'Due to API limitations, link click data is unavailable for UGC Stories',
    swipeUpsUnavailable:
      'Link click tracking is not included in your current package. Contact your customer success representative to upgrade or learn more.',
    exitRate:
      'The percentage of people who exited out of the story. Exit Rate = Exits / Impressions.',
    completionRate:
      'The percentage of people who viewed the entire story\n' +
      '\n' +
      'Completion Rate = (Impressions - Exits) / Impressions.',
    replies:
      'Due to API restrictions, replies from users in the European Economic Area (EEA) or Japan will no longer be included. For stories created in the EEA or Japan, Replies will now return a value of 0.',
    exits:
      'The number of times a viewer has swiped out of the story or tapped the X in the upper-right corner of the story frame. An exit is counted even if it occurs at the last millisecond.',
    limitMessage:
      "You've reached your board limit. To upgrade your package please contact your Customer Success Representative.",
    trackableLink:
      'Link that Dash Hudson uses to track link clicks on this frame. Frames must have a trackable link in order to display link clicks.',
  },
  storyOverview: {
    yourStories:
      "Individual frames are grouped into a story when there's less than half an hour between when each frame is posted.",
    metrics: {
      storyFramesCount:
        'The total number of story frames published during the selected date range.',
      avgReach: 'The average reach of the story frames published during the selected date range.',
      totalImpressions:
        'The total number of impressions on the story frames published during the selected date range.',
      avgCompletionRate:
        'The average completion rate of the story frames published during the selected date range\n' +
        '\n' +
        'Completion Rate = (Impressions - Exits) / Impressions',
      avgExitRate:
        'The average exit rate of the story frames published during the selected date range.\n' +
        '\n' +
        'Exit Rate = Exits / Impressions',
      totalSwipeUps:
        'Total number of link clicks on the story frames published during the selected date range. Only link click links scheduled through the Dash Hudson scheduler are trackable via the platform.',
      totalReplies:
        'The total number of replies to the story frames published during the selected date range.\n' +
        '\n' +
        'Due to API restrictions, replies from users in the European Economic Area (EEA) or Japan will no longer be included. For stories created in the EEA or Japan, Replies will now return a value of 0.',
    },
  },

  facebookAdsInsights: {
    total: 'Total insights across all ad placements',
    startDate: 'The start date of the ad set',
    endDate: 'The end date of the ad set',
    ctr: 'Link Click-Through Rate',
    cpc: 'Cost per Link Click',
    apiLimitationsMessage:
      'Due to API limitations we can only show all time results for unique channels',
  },

  facebookInsights: {
    comments: 'The total number of comments on this post, including threaded replies',
    reactions:
      'The number of times people clicked on like, care, love, wow, haha, sad, and anger reactions on your post',
    totalEngagements: 'Includes Reactions, Comments, Shares, and Post Clicks',
    engagementRate: 'Engagement Rate = Total Engagements / Impressions',
    effectiveness: 'Effectiveness Rate = (Total Engagements + Video Views) / Reach',
    videoCompleteViews:
      'The number of times your video was played from the beginning to 95%, or more, of its length',
    postClicks:
      'The number of times people clicked anywhere in your post without generating a story',
    photoViewClicks: 'The number of times people clicked on a photo in your post',
    linkClicks:
      'When a Facebook user clicks and follows the link in your post or link preview to the destination URL',
    otherClicks:
      'The number of non-content clicks on your post, such as clicks on your profile name or to "see more"',
  },

  facebookReelsInsights: {
    plays:
      'The number of times this video was initially played for at least 1ms. This excludes replays.',
    replays: 'The number of rewatches for at least 1ms your video received in the same session',
    totalPlays:
      'The total number of views your video received. Includes initial plays and rewatches.',
    uniqueImpressions: 'The unique number of people who saw your post at least once',
    avgTimeWatched: 'The average time your video was played during a single viewing',
    totalTimeWatched: 'The sum of the time that all viewers watched your video for',
    follows: 'The number of viewers who followed your Page after viewing your video',
    percentageViewed: 'The percentage of this video your audience watched',
    effectivenessReels:
      "The rate of this post's views and engagement based on the unique accounts reached\n" +
      '\n' +
      'Effectiveness = (Total Engagements + Total Plays) / Reach',
  },

  facebookCompetitiveInsights: {
    comments: 'The total number of comments on this post, including threaded replies',
    reactions:
      'The number of times people clicked on like, care, love, wow, haha, sad, and anger reactions on your post',
    engagements: 'Includes Reactions, Comments, and Shares',
    engagementRate: 'Engagement Rate = Total Engagements / Page Fans',
  },

  twitterInsights: {
    publicEngagements:
      'The total number of times a user interacted with this Post. Public Engagements = Replies + Likes + Repost + Quote Posts',
    engagementRate: 'Engagement Rate = Total Engagements / Impressions',
    impressions: 'The number of times your Post has been seen in timeline or search results.',
    profileClicks: 'The number of times your profile has been clicked from this Post.',
    totalEngagements:
      'The total number of times a user interacted with your Post, including reposts, replies, likes, and clicks on your Post.',
    userFollows:
      'The number of times your account has been followed from this Post. Only organic data is available.',
    videoViews: 'The number of times a video has been watched for at least two seconds.',
  },

  twitterCompetitiveInsights: {
    publicEngagements:
      'The total number of times a user interacted with this Post. Public Engagements = Replies + Likes + Repost + Quote Posts',
    replies: "The number of replies your competitor's post received",
    totalRetweets: "The total number of times your competitor's post was reposted or quote posted",
    likes: "The number of likes your competitor's post received",
    videoViewsTotal: "The number of video views your competitor's post accumulated",
  },

  facebook: {
    averageEngagementRate:
      'The average engagement rate for your organic posts published during the selected date range\n' +
      '\n' +
      'Engagement Rate = Total Engagements / Impressions',
    avgEngagementRate:
      'The average engagement rate for your organic posts published during the selected date range\n' +
      '\n' +
      'Engagement Rate = Total Engagements / Impressions',
    averageEngagementRatePublic:
      'The Total Engagements / Page Fans of posts published during the Reporting Period. This excludes Post Clicks and Impressions to ensure a fair comparison with competitors based on publicly available data.',
    averageEffectiveness:
      'The average effectiveness rate for your organic posts published during the selected date range\n' +
      '\n' +
      'Effectiveness Rate = (Total Engagements + Video Views) / Reach\n',
    avgEffectiveness:
      'The average effectiveness rate for your organic posts published during the selected date range\n' +
      '\n' +
      'Effectiveness Rate = (Total Engagements + Video Views) / Reach\n',
    linkClicks:
      'The total number of link clicks for your organic posts published during the selected date range',
    pageFansChart: 'The total number of people who have liked your Page',
    facebookYourPosts: 'Only organic facebook data will appear in Your Posts',
    netNewFans:
      'The net total of new fans your page has accumulated during the selected date range',
    newFans: 'The net total of new fans your page has accumulated during the selected date range',
    avgDailyPostReach:
      "The average daily reach for any of your page's organic posts during the selected date range",
    avgDailyReach:
      "The average daily reach for any of your page's organic posts during the selected date range",
    engagements:
      "The number of total engagements on any of your page's organic and paid posts during the selected date range",
    impressionsTooltip: `The total number of impressions for any of your page's organic posts during the selected date range`,
    impressions: `The total number of impressions for any of your page's organic posts during the selected date range`,

    metrics: {
      engagementsTooltip:
        'The number of reactions, comments, shares, and post clicks on your posts.',
      avg_engagement_rate:
        'The average engagement rate of the posts published during the selected date range\n' +
        '\n' +
        'Engagement Rate = Total Engagements / Impressions',
      avg_effectiveness:
        'The average effectiveness of the posts published during the selected date range\n' +
        '\n' +
        'Effectiveness Rate = (Total Engagements + Video Views) / Reach',
      reach: "The number of people who had any of your Page's posts enter their screen",
      link_clicks:
        'The total number of link clicks for your organic posts published during the selected date range',
    },
    topPosts:
      'Top performing or lowest performing posts by total engagements published during the selected date range. These posts are compared to the average number of total engagements per post over the last 12 weeks.',
  },

  // Twitter
  twitter: {
    metricsDateSelectTooltip: `Data is only available up to ${maxTwitterDataCutOffDays} days prior to you connecting your X account to Dash Hudson.`,
    deleteThreadToolTip: `Delete threaded Post`,
    addThreadToolTip: `Add Post to Thread`,
    disabledLocationToolTip: `Due to changes in the X API, this feature is temporarily unavailable.`,
    addThreadAutoPublishDisabled: `Auto Publish must be selected to add threaded Posts`,
    addThreadThreadLimitReached: `You cannot add more than ${maxTweetThreads} Posts when publishing a thread`,
    sendNotificationDisabledForThreads: `Send Notification is unavailable when publishing a threaded Post`,
    followersChart: 'The total number of followers your X account has.',
    metrics: {
      engagements:
        'The total number of times a user interacted with your post, including reposts, replies, likes, and clicks on your post.',
      engagement_rate:
        'The engagement rate of posts that received engagement within the selected date range. Engagement Rate = Total Engagements / Impressions.',
      replies: 'The number of times the post has been replied to.',
      total_retweets: 'The number of times the post has been reposted or quote posted.',
      likes: 'The number of times the post has been liked.',
      impressions: 'The number of times your post has been seen in timeline or search results.',
      url_clicks:
        'The number of times a user clicks the link in your post and is sent to the destination URL.',
      user_profile_clicks: 'The number of times your profile has been clicked from a post.',
    },
    topPosts:
      'Top performing or lowest performing posts by total engagements published during the selected date range. These posts are compared to the average number of total engagements per post over the last 12 weeks.',
    cannotAddNonOriginalTweetToBoard:
      'Reposts, Quote Posts, and Replies cannot be added to a Board.',
    tooManyMentionsPerTweet: `You've exceeded the maximum limit of ${maxMentionsPerTweet} @mentions per Post.`,
    tooManyMentionsPerThread: `You've exceeded the maximum limit of ${maxMentionsPerTweet} @mentions per thread.`,
  },

  // TikTok
  tiktok: {
    followersChart:
      'The number of people who have followed your account during the selected date range.',
    metrics: {
      video_views:
        'The number of times your videos have been viewed during the selected date range.',
      single_post_video_views:
        'The number of times your video has been viewed during the selected date range.',
      engagements:
        'The total number of engagements on your videos during the selected date range. Total Engagements = Likes + Comments + Shares',
    },
    mostEntertaining:
      'Your most entertaining Videos published during the reporting period. These posts are compared to the average value from posts published during the comparison period.',
  },

  // landing page
  landingPage: {
    mostViewedVideos: 'Top performing videos across all channels, sorted by video views.',
    visionPredictedUGCVideo:
      'Vision predicted top UGC that either @mentioned or phototagged you during the reporting period, sorted by video views.',
  },

  // Scheduler
  addLinksTooltip: 'Add media to your post for the ability to add links.',
  featureNotIncludedInPlan: 'This feature isn’t included in your plan.',
  firstCommentOnlyAllowedForAutoPublish: 'First comment is only available for auto publishing.',
  hideProductMedia: 'Hide from Product Page Gallery.',
  igMediaCapacityExceeded: 'You can only select up to 10 photos and/or videos',
  illegalMediaSize: 'Instagram only allows one photo or video to be auto published at a time.',
  illegalTimeStamp: 'You must select a post time in the future.',
  insufficientPermission: "You don't have permission to auto publish posts for this brand.",
  invalidLinkLength: 'Your link URL must be shorter than 2000 characters.',
  invalidLinkURL: 'Your link URL is not valid.',
  invalidLinkURLShortener: 'Your link URL is blocked because it may lead to spam.',
  maxDailyIGAutoPublishPostsReached: (postLimit) =>
    `You’ve reached the API limit of ${postLimit} auto published posts in 24 hr. Please use a push notification.`,
  maxIGCharactersReached: 'Your caption must be 2200 characters or less.',
  maxIGCommentCharactersReached: 'Your first comment must be 2200 characters or less.',
  maxIGHashtagsReached: 'You can only only add up to 30 hashtags.',
  maxLinkedInCharactersReached: 'Your caption must be 3000 characters or less.',
  maxLinkedInMediaLimit: 'Your post cannot contain more than 1 image',
  maxPinDescriptionCharactersReached: 'Your description must be 500 characters or less.',
  maxPinTitleCharactersReached: 'Your title must be 100 characters or less.',
  maxTikTokCharactersReached: 'Your caption must be 2200 characters or less.',
  tikTokDisabledTooltip: 'Images are not supported by TikTok. Please select a video.',
  tikTokNoUserPermission: "You don't have the proper permissions to schedule TikTok posts.",
  reelsDisabledTooltip: 'Images are not supported for Reels. Please select a video.',
  linkedInDisabledTooltip: 'Videos are not supported by LinkedIn. Please select an image.',
  mediaUploading: 'Your media is uploading...',
  noMediaSelected: 'You must select a video or image in order to publish now.',
  noMediaSelectedAutoPublish: 'You must select an image or video in order to auto publish.',
  noMediaSelectedTagProducts: 'You must select an image or a video in order to tag products.',
  noVideoSelectedAutoPublish: 'You must select a video in order to auto publish.',
  noPinterestBoardSelectedAutoPublish:
    'For auto publishing this Pin, please make sure it has been added to a Pinterest Board.',
  noPinterestConnection:
    'To schedule a Pin, please connect your Pinterest account on the Platform Connections page..',
  notAutoPublish: 'Auto Publish must be selected to Publish Now.',
  autoPublishNotSupported: 'Auto Publish is not yet supported.',
  publishNowNotSupported: 'Publish Now is not yet supported.',
  noTwitterConnection:
    'To schedule an X post, please connect your Twitter account on the Platform Connections page.',
  noFacebookConnection:
    'To schedule a Facebook post, please connect your Facebook account on the Platform Connections page.',
  noLinkedinConnection:
    'To schedule a LinkedIn post, please connect your LinkedIn account on the Platform Connections page.',
  pinterestDestinationLink:
    "When possible, link directly to the source. Pinterest doesn't allow certain redirects or URL shorteners (like bit.ly).",
  productLinks:
    'Turns shopability of photos on or off. If photos have links assigned and capability is turned off, your photos will not redirect to pages you have assigned.',
  productMedia: 'Media that has been assigned the URL associated with this product.',
  showProductMedia: 'Show in Product Page Gallery.',
  trackableLink:
    'We will convert this link to a tiny URL for tracking purposes. Use this URL when creating your story so we can display link click performance in DH.',
  trackableLinkPopup:
    'A trackable link will only be created for the first story frame. To track link clicks on each frame schedule frames separately.',
  updateLink: "We notice you've changed your media, please add a new link!",
  videoAutoPublishNotSupported: 'Video auto publishing is not supported',
  locationOnlyAllowedInInsAutoPublish: 'Adding a location is only available when auto publishing.',
  locationOnlyAllowedInFBAutoPublish: 'Check in is only available when auto publishing.',
  audienceOnlyAllowedInFBAutoPublish: 'Audience is only available when auto publishing.',
  errorLoadingAudienceCountries:
    'There was an issue loading the list of available Audience countries. Please try refreshing the page.',
  unableToSaveEmptyPost: 'This post is empty. Please add content.',
  invalidConnection: 'To schedule a post, you need to connect your account.',
  invalidTikTokConnection: 'To auto publish a post, you need to connect your TikTok account.',
  invalidTikTokMediaSelection:
    'You can only publish one video at a time on TikTok. Remove additional media to save your post.',
  invalidReelMediaSelection:
    'You can only publish one Reel at a time. Remove additional media to save your post.',
  invalidIGStoryMediaSelection: 'Only one image or video can be auto published at a time.',
  tikTokAllowTogglesOnlyAllowedInAutoPublish:
    'Select Auto Publish to customize Comment, Duet, and Stitch settings.',
  tagProductsOnlyAllowedInInsAutoPublish:
    'Tagging products is only available when auto publishing.',
  tagPeopleOnlyAllowedInInsAutoPublish: 'Tagging people is only available when auto publishing.',
  tagPeopleOnlyAllowedInInsWhenMediaSelected:
    'Tagging people is only available when media is selected.',
  tagPeopleOnlyAllowedInInsWhenAVideoIsSelected:
    'Tagging people is only available when a video is selected',
  instagramLink:
    'Dash Hudson Product Links are universal. Links added to this media will appear in any live Shoppable Gallery or Product Page Gallery where this media exists. If applicable, adding Links will also publish this media to your LikeShop.',
  tiktokLink:
    'Dash Hudson product links are universal. Links added to this media will appear in any live Shoppable Gallery or Product Page Gallery where this media exists.',
  shoppingTagsLoading: 'Your Shopping Tags are loading...',
  mediaConversionError:
    'There was an issue uploading this media to your Library. Please re-upload it to try again.',
  enableVision: 'To use Vision suggested cover images, you must enable auto publish.',
  promoteVision: 'Upgrade your plan to access Vision suggested thumbnails.',
  visionRemoveCoverImage:
    'To use Vision suggested cover images, you must remove your custom cover image.',
  crossBrandDisabledMedia:
    'Duplicating posts across brands is only available for uploaded pieces of media.',
  crossBrandDisabledTweetThread: 'Cross brand duplication is not supported for Post threads.',
  crossBrandDisabledPostStatus: 'Cross brand duplication is only supported for successful posts.',
  facebookGeogatingTooltip:
    'Select location(s) where your post will be visible. Only Facebook admins can view this post outside of the chosen locations.',
  limitLocationForFacebookReels: 'Limit by Location is not available for Facebook Reels',
  // Library
  shoppableSettings: {
    mobileColumn: 'Choose how many columns to display when viewed on mobile devices.',
    mobileGap: 'Choose how much space to put between columns when viewed on mobile devices.',
    customLinks:
      'Turns shoppability on or off for this gallery. If photos have links assigned and shoppability is turned off, your photos will not redirect to pages you have assigned.',
  },
  onlyAddPostsWithMediatoGallery: 'You can only add posts with media to Galleries and Boards.',
  noAddPostsForReels: 'You cannot add posts to Reels.',
  customReorderDisabled: 'Sort by customized order to reorder your media.',
  noSchedulePermission: 'You do not have the proper permissions to use this feature.',
  onDifferentBrand:
    'To action this content, please ensure you are logged into the associated brand.',
  noScheduleForReels: 'Reels cannot be scheduled.',
  noBrandPermissionsForWidget:
    'Product Page Galleries are not included in your plan. Please contact your Customer Success Representative to add them.',
  noUserPermissionsForWidget:
    "You don't have access to this feature. Please contact your account administrator to enable access.",
  productTagIssue:
    'There was an issue with this product tag. Please remove the tag to save your post.',
  invalidaPlatformConnection:
    'Your account is disconnected. Your post may not publish as expected.',
  mediaPopupPublishDatesNoPermissionTooltip:
    'Contact your brand admin for permission to edit approved publishing dates',
  accountCannotGetBulkUpload: 'Brand must be in the Enterprise plan to use bulk uploading',
  tagCannotGetBulkUpload:
    'At least one brand in the tag must be in the Enterprise plan to use bulk uploading',
  brandCannotSelectBulkUpload:
    'Multi-brand upload is only available to brands on the Enterprise plan',
  instagramInviteCollaborators:
    'Invite Instagram users to collaborate and co-author this post to reach a broader audience and boost engagement',
  inviteCollaboratorsDisabled: 'Inviting collaborators is only available when auto publishing.',

  // Instagram
  VIQ: {
    engagement: 'The percentage of your total owned engagement that comes from this segment.',
    photos: 'The percentage of your owned photos that live in this segment.',
    processingTrendAccount: 'Processing data for this account',
  },
  instagramEngagementRate:
    'The Average Engagement of a board will only include engagement from posts created by business accounts.',

  // Add to board limit
  addToBoardSourceLimit: {
    instagram: 'Only Instagram posts can be added to Instagram Boards.',
    instagramStory: 'Only Instagram stories can be added to Story Boards.',
    pinterest: 'Only Pinterest pins can be added to Pinterest Boards',
    uploaded: 'Only uploaded media can be selected.',
  },

  // Wrong type in library
  addToBoardWrongType: {
    expectImage: 'Only images can be published to this channel.',
    expectVideo: 'Only videos can be published to this channel.',
  },

  mustClear: {
    noBrandTagsAccess: 'You must clear the selected brands to choose tags.',
    noBrandAccess: 'You must clear the selected tags to choose brands.',
    noCompetitorTagsAccess: 'You must clear the selected tags to choose competitors.',
    noCompetitorAccess: 'You must clear the selected competitors to choose tags.',
    noBrandTagsAccessSettings: 'Brand must be in the Enterprise plan to use brand tags',
  },

  // Dashboards
  instagramDashboard: {
    followerGrowthRate:
      'The percentage your followers increased by during the Reporting Period. Follower Growth Rate = (New Followers - Old Followers) / Old Followers x 100%',
    followersLost:
      'The total numbers of users who unfollowed your account during the Reporting Period.\n' +
      '\n' +
      '*Lost Followers data is received once per day in the Pacific Timezone.',
    profileViews: 'The amount of times your profile has been viewed during the Reporting Period.',
    websiteClicks:
      'The amount of clicks on the website link in your profile during the Reporting Period.',
    emailContactClicks:
      'The amount of clicks on the email link in your profile during the Reporting Period.',
    textMessageClicks:
      'The amount of clicks on the text message link in your profile during the Reporting Period.',
    getDirectionClicks:
      'The amount of clicks on the directions link in your profile during the Reporting Period.',
    totalEngagements:
      'The total number of organic engagements from all posts published during the Reporting Period.',
    sumTotalEngagements:
      'The total number of engagements from all posts published during the Reporting Period.',
    avgEngagementRate:
      'The average percentage of your audience that engaged with your posts published during the Reporting Period. Engagement Rate = Organic Engagements / Followers',
    avgReach:
      'The average number of unique accounts that saw your posts published during the Reporting Period.',
    avgEffectiveness:
      'The average percentage of users that saw and engaged with your posts published  during the Reporting Period. Effectiveness Rate = (Likes + Comments + Saves + Video Views) / Reach',
    avgEffectivenessNew:
      'The average effectiveness rate for your organic posts published during the reporting period.\n' +
      '\n' +
      'Feed Effectiveness Rate = (Likes + Comments + Saves + Video Views) / Reach\n' +
      '\n' +
      'Reels Effectiveness Rate = (Likes + Comments + Saves + Video Views) / Est. Reach',
    avgEntertainmentScore:
      'The average Entertainment Score of your Reels published during the reporting period. The score is on a scale of 0 to 10 and is based on volume of organic engagements compared to reach.',
    avgEstimatedReach:
      'The average number of unique accounts that saw your posts published during the Reporting Period.\n' +
      "The average estimated number of unique accounts that saw competitor's posts based on their number of followers during the Reporting Period.",
  },
  instagramUGCDashboard: {
    numberOfPosts: 'The total number of posts that mentioned or tagged you.',
    estimatedOrganicReach:
      'The estimated number of unique accounts who saw posts that mentioned or tagged you.',
  },
  instagramStoriesDashboard: {
    avgReach:
      'The average number of unique accounts that saw your stories published during the Reporting Period.',
    exits:
      'The total number of times that a user has swiped out of the story or tapped the X in the upper-right corner of the story frame. An exit is counted even if it occured in the last millisecond.',
    avgExitRate:
      'The percentage of people who exited out of the story.\n' +
      '\n' +
      'Exit Rate = Exits / Impressions.',
    avgCompletionRate:
      'The average percentage of people who viewed the entire story.\n' +
      '\n' +
      'Completion Rate = (Exits - Impressions) / Impressions.',
    swipeUps:
      'Total number of link clicks on the story frames published during the selected date range. Only link click links scheduled through the Dash Hudson scheduler are trackable via the platform.',
  },
  benchmarksDashboard: {
    industryBenchmarks:
      'Industry benchmarks are an average—calculated from a curated set of brands within a specific industry',
    disabledStateMaxBenchmarks: 'You have selected the maximum number of benchmarks and/or brands',
    disabledStateSelectBenchmark: 'You must select a benchmark to add another',
  },
  facebookDashboard: {
    pageFanGrowthRate:
      "The percentage your Page's Fans increased by during the Reporting Period. Page Fans Growth Rate = (New Page Fans - Old Page Fans) / Old Page Fans x 100%",
    totalEngagements:
      'The total number of reactions, comments, shares, and post clicks on all posts published during the Reporting Period.',
    reactions:
      'The number of times people clicked on like, care, love, wow, haha, sad, and anger reactions on posts published during the Reporting Period.',
    postClicks:
      'The number of times a user clicked anywhere on your posts published during the Reporting Period.',
    photoViewClicks:
      'The number of times a user clicked on a photo for posts published during the Reporting Period.',
    linkClicks:
      'The number of times people clicked on the link in a post or link preview for posts published during the Reporting Period.',
    otherClicks:
      'The number of times people clicked on your posts published during the reporting period, such as clicks on your profile name or to "see more".',
    avgEngagementRate:
      'The average engagement rate of the posts published during the Reporting Period. Engagement Rate = (Total Engagements / Impressions)',
    avgEffectiveness:
      'The average effectiveness rate of the posts published during the Reporting Period. Effectiveness Rate = (Total Engagements + Video Views) / Reach',
    avgDailyPostReach:
      'The average number of unique accounts that saw your posts published during the Reporting Period.',
    videoCompleteViews:
      'The number of times your videos published during the reporting period were played from the beginning to 95%, or more, of its length.',
    comments: 'The number of comments any of your posts received during the Reporting Period.',
  },
  pinterestDashboard: {
    followerGrowthRate:
      'The percentage your followers increased by during the Reporting Period. Follower Growth Rate = (New Followers - Old Followers) / Old Followers x 100%',
    avgEngagementRate:
      'The average percentage of your audience that engaged with your Pins during the Reporting Period. Engagement Rate = (Pin Clicks + Outbound Clicks + Saves) / Impressions',
    totalAvgEngagementRate:
      'The average percentage of your audience that engaged with your Pins published during the Reporting Period. Engagement Rate = (Pin Clicks + Outbound Clicks + Saves) / Impressions',
    linkClicks:
      'The number of clicks on the links in your Pins published during the Reporting Period.',
    linkClicksAllPosts:
      'The number of clicks on the links on your Pins during the Reporting Period.',
    numberOfPosts: 'The number of Pins published during the Reporting Period.',
    closeups:
      'The number of times your Pins were clicked to view more details during the Reporting Period.',
    saves: 'The number of times users saved your Pins during the Reporting Period.',
  },
  twitterDashboard: {
    followerGrowthRate:
      'The percentage your followers increased by during the Reporting Period. Follower Growth Rate = (New Followers - Old Followers) / Old Followers x 100%',
    totalEngagementsAllPosts:
      'The total number of times a user interacted with your posts during the Reporting Period, including Reposts, replies, likes, hashtags, or link clicks.',
    avgEngagementRate:
      'The average engagement rate of your posts during the Reporting Period. Engagement Rate = Total Engagements / Impressions',
    totalAvgEngagementRate:
      'The average engagement rate of your posts published during the Reporting Period. Engagement Rate = Total Engagements / Impressions',
    totalRetweetsAllPosts:
      'The total number of times your Posts were Reposted during the Reporting Period. Total Reposts = Reposts + Quote Posts',
    profileClicksAllPosts:
      'The number of times your profile has been clicked from Posts during the Reporting Period.',
    userFollowsAllPosts:
      'The number of times your account has been followed from Posts during the Reporting Period.',
    likesAllPosts: 'The number of likes your Posts received during the Reporting Period.',
    linkClicksAllPosts:
      'The number of times links on your Posts were clicked during the Reporting Period.',
    QuotePostsAllPosts:
      'The number of times your Posts were Quote Posted during the Reporting Period.',
    repliesAllPosts: 'The number of replies your Posts received during the Reporting Period.',
    retweetsAllPosts: 'The number of times your Posts were Reposted during the Reporting Period.',
    videoViewsAllPosts:
      'The number of times your organic videos were viewed during the Reporting Period.',
  },
  tiktokDashboard: {
    netNewFollowers:
      'The net new number of people who have followed your account during the Reporting Period.',
    profileViews: 'The number of times your profile was viewed during the Reporting Period.',
    allPostVideoViews:
      'The number of times any of your organic and promoted videos were viewed during the Reporting Period.',
    allPostLikes:
      'The number of likes any of your organic and promoted videos received during the Reporting Period.',
    allPostComments:
      'The number of comments any of your organic and promoted videos received during the Reporting Period.',
    allPostShares:
      'The number of times any of your organic and promoted videos were shared during the Reporting Period.',
    engagements:
      'The total number of engagements any of your organic and promoted videos received during the Reporting Period.\n' +
      '\n' +
      'Total Engagements = Likes + Comments + Shares',
    followerGrowthRate:
      'The percentage your total followers increased by during the Reporting Period.\n' +
      '\n' +
      'Follower Growth Rate = (Current Period - Previous Period) / Previous Period x 100%',
    netGrowthRate:
      'The percentage your net new followers increased by during the Reporting Period.\n' +
      '\n' +
      'Growth Rate = (Current Period - Previous Period) / Previous Period x 100%',
    averageReach:
      'The average Reach of your organic and promoted videos published during the Reporting Period.',
    averageEngagementRate:
      'The average Engagement Rate of your organic and promoted videos published during the Reporting Period.\n' +
      '\n' +
      'Engagement Rate = Total Engagements / Reach',
    averageRetentionRate:
      'The average Retention Rate of the organic and promoted videos published during the Reporting Period.\n' +
      '\n' +
      'Retention Rate = Average Time Watched / Video Duration',
    averageFYPViews:
      'The average percentage of views that came from the For You page for organic and promoted videos published during the Reporting Period.',
    videoViewsTotal:
      'The total number of times your organic and promoted videos published during the Reporting Period were viewed.',
    likesTotal:
      'The total number of likes your organic and promoted videos published during the Reporting Period received.',
    commentsTotal:
      'The total number of comments your organic and promoted videos published during the Reporting Period received.',
    sharesTotal:
      'The total number of shares your organic and promoted videos published during the Reporting Period received.',
    totalEngagementsTotal:
      'The total number of engagements your organic and promoted videos published during the Reporting Period received.\n' +
      '\n' +
      'Total Engagements = Likes + Comments + Shares',
    avgTimeWatched:
      'The average amount of time viewers spent watching your organic and promoted videos published during the Reporting Period.',
    avgCompletionRate:
      'The average percentage of viewers that watched each organic and promoted video published during the Reporting Period to completion.',
    avgVideoViews:
      'The average number of times your organic and promoted videos published during the Reporting Period were viewed.',
    avgLikesTotal:
      'The average number of likes your organic and promoted videos published during the Reporting Period received.',
    avgCommentsTotal:
      'The average number of comments your organic and promoted videos published during the Reporting Period received.',
    avgSharesTotal:
      'The average number of shares your organic and promoted videos published during the Reporting Period received.',
    avgEngagementsTotal:
      'The average number of engagements your organic and promoted videos published during the Reporting Period received. Total Engagements = Likes + Comments + Shares',
    avgEntertainmentScore:
      'The average Entertainment Score of your organic and promoted videos published during the reporting period. The score is on a scale of 0 to 10 and takes the engagement and retention rates of each video into account. Scores are generated 48 hours after publishing.',
  },
  youTubeDashboard: {
    totalEngagements:
      'The total number of engagements your organic and promoted videos published during the Reporting Period received.\n' +
      '\n' +
      'Total Engagements = Likes + Dislikes + Comments + Shares',
    subscribersTotal:
      'The number of users who have subscribed to your channels by the last day of the Reporting Period.',
    subscriberGrowthRate:
      'The percentage your subscribers have increased by during the Reporting Period.\n' +
      '\n' +
      'Subscriber Growth Rate = (Current Period - Previous Period) / Previous Period x 100%',
    subscribersNetNew:
      'The net new number of users who have subscribed to your channel during the Reporting Period.',
    engagements:
      'The total number of engagements any of your organic and promoted videos received during the Reporting Period.\n' +
      '\n' +
      'Total Engagements = Likes + Dislikes + Comments + Shares',
    estimatedSecondsWatched:
      'The total amount of time that viewers watched any organic and promoted videos on your channel during the Reporting Period.',
    totalEstimatedSecondsWatched:
      'The total amount of time that viewers watched organic and promoted videos on your channel that were published during the Reporting Period.',
    views:
      'The number of times your organic and promoted videos were viewed during the Reporting Period.',
    dislikes:
      'The number of dislikes any of your organic and promoted videos received during the Reporting Period.',
    likes:
      'The number of likes any of your organic and promoted videos received during the Reporting Period.',
    comments:
      'The number of comments any of your organic and promoted videos received during the Reporting Period.',
    avgViewDuration:
      'The average amount of time viewers spent watching any of your organic and promoted videos during the Reporting Period.',
    dislikeTotal:
      'The total number of dislikes your organic and promoted videos published during the Reporting Period received.',
    addedToPlaylistTotal:
      'The total number of times your organic and promoted videos published during the Reporting Period were added to a playlist.',
    avgViewDurationTotal:
      'The average amount of time viewers spent watching your organic and promoted videos published during the Reporting Period',
    videoViewsTotal:
      'The total number of times your organic and promoted videos published during the Reporting Period were viewed.',
    likesTotal:
      'The total number of likes your organic and promoted videos published during the Reporting Period received.',
    commentsTotal:
      'The total number of comments your organic and promoted videos published during the Reporting Period received.',
    sharesTotal:
      'The total number of shares your organic and promoted videos published during the Reporting Period received.',
    avgPercentViewedTotal:
      'The average retention rate of your organic and promoted videos published during the Reporting Period.',
    avgViewPercentage:
      'The average retention rate of your organic and promoted videos during the Reporting Period.',
    shares:
      'The number of shares any of your organic and promoted videos received during the Reporting Period.',
    cardClickRate:
      'The average cards click-through-rate on all your organic and promoted videos during the Reporting Period.\n' +
      '\n' +
      'Card Click Rate = Card Clicks / Card Impressions',
    avgCardClickRate:
      'The average cards click-through-rate on your organic and promoted videos published during the Reporting Period.\n' +
      '\n' +
      'Card Click Rate = Card Clicks / Card Impressions',
    cardClicks:
      'The number of times the cards were clicked on all your organic and promoted videos during the Reporting Period.',
    totalCardClicks:
      'The number of times the cards were clicked on your organic and promoted videos published during the Reporting Period.',
    cardImpressions:
      'The number of times cards were displayed on all your organic and promoted videos during the Reporting Period.',
    totalCardImpressions:
      'The number of times cards were displayed on your organic and promoted videos published during the Reporting Period.',
    cardTeaserClickRate:
      'The average card teasers click-through-rate on all your organic and promoted videos during the Reporting Period.\n' +
      '\n' +
      'Card Teaser Click Rate = Card Teaser Clicks / Card Teaser Impressions',
    avgCardTeaserClickRate:
      'The average card teasers click-through-rate on your organic and promoted videos published during the Reporting Period.\n' +
      '\n' +
      'Card Teaser Click Rate = Card Teaser Clicks / Card Teaser Impressions',
    cardTeaserClicks:
      'The number of times the card teasers were clicked on all your organic and promoted videos during the Reporting Period.',
    totalCardTeaserClicks:
      'The number of times the card teasers were clicked on your organic and promoted videos published during the Reporting Period.',
    cardTeaserImpressions:
      'The number of times card teasers were displayed on all your organic and promoted videos during the Reporting Period.',
    totalCardTeaserImpressions:
      'The number of times card teasers were displayed on your organic and promoted videos published during the Reporting Period.',
    estimatedPremiumSecondsWatched:
      'The total amount of time your organic and promoted videos were viewed during the Reporting Period by YouTube Premium members.',
    totalEstPremiumSecondsWatched:
      'The total amount of time your organic and promoted videos published during the Reporting Period were viewed by YouTube Premium members.',
    premiumViews:
      'The number of times your organic and promoted videos were viewed during the Reporting Period by YouTube Premium members.',
    totalPremiumViews:
      'The number of times your organic and promoted videos published during the Reporting Period were viewed by YouTube Premium members.',
    videosAddedToPlaylist:
      'The number of times any of your organic and promoted videos were added to any YouTube playlists during the Reporting Period.',
  },
  sentimentDashboard: {
    commentSentimentPositiveRate:
      'The percentage of posts published during the reporting period with an overall positive comment sentiment',
    commentSentimentNeutralRate:
      'The percentage of posts published during the reporting period with an overall neutral comment sentiment.',
    commentSentimentNegativeRate:
      'The percentage of posts published during the reporting period with an overall negative comment sentiment.',
  },
  multiChannelDashboard: {
    totalEngagements:
      'The total number of engagements from all posts published during the Reporting Period.',
    followerGrowthRate:
      'The percentage your followers/Page Fans increased by during the Reporting Period.',
    avgEngagementRate:
      'The average percentage of your audience that engaged with your posts published during the Reporting Period.',
    avgReach:
      'The average number of unique accounts that saw your posts published during the Reporting Period.',
    avgEffectiveness:
      'The average percentage of users that saw and engaged with your posts published during the Reporting Period.',
    linkClicks:
      'The number of times people clicked on the link(s) in your posts during the Reporting Period.',
    netNewFollowers:
      'The net new number of people who have followed your account during the Reporting Period.',
    profileViews: 'The number of times your profile was viewed during the Reporting Period.',
    totalLikes:
      'The total number of likes your organic and promoted videos published during the Reporting Period received.',
    totalComments:
      'The total number of comments your organic and promoted videos published during the Reporting Period received.',
    totalVideoViews:
      'The total number of times your organic and promoted videos published during the Reporting Period were viewed.',
    totalShares:
      'The total number of shares your organic and promoted videos published during the Reporting Period received.',
    totalAvgViewDuration:
      'The average amount of time viewers spent watching your organic and promoted videos published during the Reporting Period',
    totalAvgPercentViewed:
      'The average retention rate of your organic and promoted videos published during the Reporting Period.',
  },
  accountDisconnection: {
    instagram:
      'Your Instagram account has been disconnected. Please reconnect to see applicable data.',
    instagram_stories:
      'Your Instagram account has been disconnected. Please reconnect to see applicable data.',
    instagram_story:
      'Your Instagram account has been disconnected. Please reconnect to see applicable data.',
    instagram_ugc:
      'Your Instagram account has been disconnected. Please reconnect to see applicable data.',
    instagram_competitive:
      'Your Instagram account has been disconnected. Please reconnect to see applicable data.',
    instagram_benchmarks:
      'Your Instagram account has been disconnected. Please reconnect to see applicable data.',
    facebook:
      'Your Facebook account has been disconnected. Please reconnect to see applicable data.',
    pinterest:
      'Your Pinterest account has been disconnected. Please reconnect to see applicable data.',
    twitter: 'Your X account has been disconnected. Please reconnect to see applicable data.',
    tiktok: 'Your TikTok account has been disconnected. Please reconnect to see applicable data.',
    youtube: 'Your Youtube account has been disconnected. Please reconnect to see applicable data.',
    all: `You haven't connected any accounts. Use the connection buttons below to connect each account.`,
  },

  // 360 Reports
  engagementFormula: {
    instagram: 'Sum of likes + comments + saves + video views on content published this month.',
    facebook: 'Sum of reactions + comments on content published this month.',
    twitter: 'Sum of reposts + likes on content published this month.',
    pinterest: 'Sum of pin clicks + Outbound clicks + saves this month.',
    engagementRate: 'Engagement Rate = (Likes + Comments) / Followers',
    effectivenessRate: 'Effectiveness Rate = (Likes + Comments + Video Views + Saves) / Reach',
  },

  citations: {
    facebookFans: 'Page Fans: The total number of people who have liked your Page.',
  },

  // Twitter Reports
  weeklyReport: {
    percentage: 'The green / red percentages are for the last week.',
    average: 'The averages are for the last 12 weeks.',
  },

  // Ads
  auto: 'This board will automatically pull in ads associated with your chosen filters.',
  manual: 'Add content manually to a board via Your Ads.',

  // Relationships
  instagramRelationship: {
    avgTotalEng:
      'Average sum of the likes, comments and saves on the influencer’s posts over the last 12 weeks.',
    avgReach: "The average number of unique people who have viewed this influencer's posts.",
    avgEstimatedReach:
      "The average number of estimated unique people who have viewed this influencer's posts.",
    disabledMentionsFilterText:
      'Connect your Instagram account to filter by users who mentioned you.',
    avgEngRate:
      'The average engagement rate for your posts published during the selected date range that feature this creator. This engagement rate uses public data only for comparison purposes.\n' +
      '\nEngagement Rate - Public = (Likes + Comments) / Followers',
  },

  // Top Posts Charts
  mostEntertaining: {
    entertainmentScore: 'Entertainment Score',
    videoViews: 'Video Views',
  },

  // Social Listening
  socialListening: {
    topPostsPage:
      'The most engaging posts within this Trend published during the reporting period.',
    topPostsPreview:
      'The posts in Trends published during the selected date range, sorted by most engagements.',
    topPostsQuickSearch:
      'The most engaging posts matching your search criteria during the reporting period.',
    visualTrends:
      'The most visually similar content segments, based on posts published during the selected date range.',
    visualTrendsPopup:
      'Public posts from within Social Trends that have been grouped together based on their visual similarity.',
    disabledTooltipText: {
      trendsPage: 'Remove keyword to use other filters.',
      topPostsPage: 'To change this filter, go back to Trends page.',
      webResultsPage: 'Web source cannot be edited after Topic creation.',
      topicFilterOff:
        'Create an email alert for when this Topic receives a higher volume of social mentions than usual.',
      topicFilterOn:
        'This Topic is being monitored for a change in volume based on your search criteria.',
    },
  },

  // Sentiment
  sentiment: {
    captionDescription:
      'The sentiment analysis for the caption of this post. Sentiment labels can be modified on a per-post basis for UGC posts.',
    commentsDescription: 'The overall sentiment analysis of the comments left on this post.',
    repliesDescription: 'The overall sentiment analysis of the replies left on this post.',
    publicDescription: 'The sentiment analysis for the caption of this post.',
    noComments: "This post doesn't have any comments on it yet.",
    noReplies: "This post doesn't have any replies on it yet.",
    cannotCalculateSentiment: 'Overall sentiment can not be calculated for this post.',
    overallSentimentProcessing:
      'Overall sentiment is currently processing. Check back a few minutes.',
  },

  // Vision AI
  visionAi: {
    disclaimerMessage: 'AI can make mistakes. Always check the information provided.',
    disabledSourceButton: "You're currently viewing the source for this summary",
    activeSourceButton: 'View summary Source',
    downloadButton: 'Download as .png',
  },

  // Approvals
  requiresApproval: 'This post needs approval before publishing',

  // Content Automation
  brandCannotAccessContentAutomation: 'Brand must have access to content automations',
  tagBrandsCannotAccessContentAutomation:
    'At least one brand in the tag must have access to content automations',
  cannotCreateContentAutomationWithBrandTags:
    'You don’t have access to create an automation with Brand Tags',

  // Custom Metrics
  customMetrics: {
    brandCannotAccessCustomMetrics: 'Brand must have access to custom metrics',
    tagCannotAccessCustomMetrics:
      'At least one brand in the tag must have access to custom metrics',
    usageLimitReached: 'Custom Metric limit reached. A brand can only have 25 custom metrics',
  },
};

export const DEFAULT_VALIDATION_TEMPLATES = {
  required: 'This field is required',
  email: 'Please enter a valid email address.',
  minLength: 'This field must be at least {min} characters.',
  maxLength: 'This field cannot be more than {max} characters.',
};

export const formValidationMessages = {
  required: 'This field is required',
  strongPassword:
    'Please choose a password that contains at least one letter and one number, or, at least one lowercase and one uppercase letter.',
  wrongPassword: 'The old password you submitted is incorrect.',
  passwordMismatch: 'Passwords do not match.',
  minLength: 'Password must be at least eight characters.',
  email: 'Please enter a valid email address.',
  uniqueBrandEmail: 'This email address already belongs to a user on this brand.',
  name: 'Name is required.',
};

export const SERVER_ERROR = 'An error has occurred.';

export const dashboardFormFailureMessages = {
  serverError: 'An error occurred while attempting to save your dashboard.',
};

export const reportFormFailureMessages = {
  serverError: 'An error occurred while attempting to save your report.',
};

export const createBoardFailedMessages = {
  duplicate:
    "Could not save board.. Try a different board name (you've already got one like this).",
  deleted:
    'Psst! You recently deleted a board with the same name.. If you want to restore it, reach out to our support team.',
};

export const linkValidationMessages = {
  empty: 'Link is required',
  invalidLink: 'Your link URL is not valid',
  linkUpdateFailure: 'Failed to save because one or more of your links is invalid',
};

export const productUrlValidationMessages = {
  empty: 'URL is required',
  invalidUrl: 'Your product URL is not valid',
};

export const commentPanelToggleTooltip =
  'Leave a comment or tag a team member here to collaborate.';
export const discardConfirmTitle = 'Discard Changes?';
export const discardCommentConfirmTitle = 'Discard Comment?';
export const discardUploadConfirmTitle = 'Exit Upload Process?';
export const discardConfirmMessage = 'If you exit now, your unsaved changes will be discarded.';
export const discardCommentConfirmMessage =
  'If you exit now, your unsaved comment will be discarded.';
export const discardUploadConfirmMessage =
  "If you leave now, your media won't be uploaded. Are you sure you want to proceed?";
export const discardConfirmButton = 'Discard';
export const saveApplyConfirmTitle = 'Save & Apply Changes?';
export const saveApplyConfirmButton = 'Save & Apply';
export const dataUnavailableMessage = 'Data not available';

export const mediaSourceType = {
  INSTAGRAM: 'INSTAGRAM',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  PINTEREST: 'PINTEREST',
  FACEBOOK: 'FACEBOOK',
  FACEBOOK_ADS: 'FACEBOOK_ADS',
  FACEBOOK_LINK: 'FACEBOOK_LINK',
  FACEBOOK_TEXT: 'FACEBOOK_TEXT',
  FACEBOOK_TEXT_LINK: 'FACEBOOK_TEXT_LINK',
  TIKTOK: 'TIKTOK',
  TWITTER: 'TWITTER',
  TWITTER_LINK: 'TWITTER_LINK',
  TWITTER_TEXT: 'TWITTER_TEXT',
  YOUTUBE: 'YOUTUBE',
  LINKEDIN: 'LINKEDIN',
};

export const constants = {
  UPLOADED: 'UPLOADED',
  LIKESHOP: 'LIKESHOP',
  // Types of galleries
  CAMPAIGNS: 'CAMPAIGNS',
  LIBRARY: 'LIBRARY',
  INSTAGRAM: 'INSTAGRAM',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  INSTAGRAM_UGC: 'INSTAGRAM_UGC',
  INSTAGRAM_STORY_NARRATIVE: 'INSTAGRAM_STORY_NARRATIVE',
  INSTAGRAM_STORY_FRAME: 'INSTAGRAM_STORY_FRAME',
  INSTAGRAM_LIKESHOP: 'INSTAGRAM_LIKESHOP',
  INSTAGRAM_OTHER: 'INSTAGRAM_OTHER',
  TIKTOK_LIKESHOP: 'TIKTOK_LIKESHOP',
  PINTEREST: 'PINTEREST',
  FACEBOOK: 'FACEBOOK',
  FACEBOOK_COMPETITIVE: 'FACEBOOK_COMPETITIVE',
  YOUTUBE: 'YOUTUBE',
  COMPETITIVE_INSTAGRAM: 'COMPETITIVE_INSTAGRAM',
  COMPETITIVE_TWITTER: 'COMPETITIVE_TWITTER',
  COMPETITIVE_YOUTUBE: 'COMPETITIVE_YOUTUBE',
  // Images
  THUMBNAIL_SIZE: 640,
  // Types of content
  TIKTOK: 'TIKTOK',
  TWITTER: 'TWITTER',
  TWITTER_LINK: 'TWITTER_LINK',
  TWITTER_TEXT: 'TWITTER_TEXT',
  FACEBOOK_TEXT: 'FACEBOOK_TEXT',
  FACEBOOK_LINK: 'FACEBOOK_LINK',
  FACEBOOK_TEXT_LINK: 'FACEBOOK_TEXT_LINK',
  UPLOAD: 'UPLOAD',
  LINKEDIN: 'LINKEDIN',
};

export const socialPlatformConnectionProviders = [
  'instagram',
  'twitter',
  'facebook',
  'facebook_ads',
  'pinterest',
  'tiktok',
];

export const galleryTypes = constants;

export const richPinValidatorUrl = 'https://developers.pinterest.com/tools/url-debugger/';

export const termsOfServiceUrl = 'https://dashhudson.com/terms';

export const privacyPolicyUrl = 'https://dashhudson.com/privacy';

export const influencerFaqUrl = 'https://dashhudson.com/influencer-faq';

// Limit in chars as _raison d'etre_ of Twitter
export const maxTweetLength = 280;

// Buffer of 8 chars for numbering in format `(xx/xx) `
export const maxTweetLengthWithAutoNumbering = 272;

// Limit from https://developers.facebook.com/docs/instagram-api/reference/ig-user/media
export const maxInstagramCaptionLength = 2200;

// Limit set to match `maxInstagramCaptionLength`; seems to be real IG limit
export const maxInstagramCommentLength = 2200;

// Limit from https://bytedance.us.feishu.cn/docs/doccnq3SeHrZkncd0AdxL69MCEe#DMh0RO
// (TikTok Business Accounts API)
export const maxTiktokAutoPublishCaptionLength = 2200;

// Was able to publish caption of 500 chars manually on TikTok
export const maxTiktokSendNotificationCaptionLength = 2200;

// Limit from https://developers.facebook.com/docs/instagram-api/reference/ig-user/media
export const maxInstagramHashtags = 30;

// Limit from https://learn.microsoft.com/en-us/linkedin/compliance/integrations/shares/ugc-post-api?tabs=http
export const maxLinkedInCaptionLength = 3000;

export const debounceInputDelay = 1000;

export const debounceInputDelayShort = 500;

export const autoCompleteDebounceDelay = 300;

export const hashtagValidationDebounceDelay = 200;

export const urlPrefix = 'https://';

export const pinterestUrl = 'https://pinterest.com';

// extra time in minutes to wait before showing the overdue label
export const overdueDelay = 20;

export const overdueDraftDelay = 1;

// Interval for tracking Mixpanel sessions
export const sessionInterval = 30 * 60000;

export const trackSessionKey = 'dh.lastsessiontime';

export const imageCropperPresets = {
  square: {
    name: 'Square',
    ratio: '1:1',
    ratioNumericValue: 1,
  },
  instagramPortrait: {
    name: 'Instagram Portrait',
    ratio: '4:5',
    ratioNumericValue: 4 / 5,
  },
  instagramLandscape: {
    name: 'Instagram Landscape',
    ratio: '1.91:1',
    ratioNumericValue: 1.91,
  },
  instagramStory: {
    name: 'Instagram Story',
    ratio: '9:16',
    ratioNumericValue: 9 / 16,
  },
  pinterestPin: {
    name: 'Pinterest Pin',
    ratio: '2:3',
    ratioNumericValue: 2 / 3,
  },
  tweet: {
    name: 'X Post',
    ratio: '1.77:1',
    ratioNumericValue: 1.77,
  },
  facebookPost: {
    name: 'Facebook Post',
    ratio: '1.91:1',
    ratioNumericValue: 1.91,
  },
  likeshopBanner: {
    name: 'LikeShop Banner',
    ratio: '2:1',
    ratioNumericValue: 2,
  },
};

export const videoCropperPresets = {
  square: {
    name: 'Square',
    ratio: '1:1',
    ratioNumericValue: 1,
  },
  instagramPortrait: {
    name: 'Instagram Portrait',
    ratio: '4:5',
    ratioNumericValue: 4 / 5,
  },
  instagramLandscape: {
    name: 'Instagram Landscape',
    ratio: '16:9',
    ratioNumericValue: 16 / 9,
  },
  instagramStory: {
    name: 'Instagram Story',
    ratio: '9:16',
    ratioNumericValue: 9 / 16,
  },
  pinterestPin: {
    name: 'Pinterest Pin',
    ratio: '2:3',
    ratioNumericValue: 2 / 3,
  },
  tweet: {
    name: 'Post',
    ratio: '1.77:1',
    ratioNumericValue: 1.77,
  },
  facebookPortrait: {
    name: 'Facebook Portrait',
    ratio: '9:16',
    ratioNumericValue: 9 / 16,
  },
  facebookLandscape: {
    name: 'Facebook Landscape',
    ratio: '16:9',
    ratioNumericValue: 16 / 9,
  },
  tiktokVideo: {
    name: 'TikTok Video',
    ratio: '9:16',
    ratioNumericValue: 9 / 16,
  },
  tiktokLikeShop: {
    name: 'LikeShop Pinned Content',
    ratio: '3:4',
    ratioNumericValue: 3 / 4,
  },
};

const instagramFeedPostCropperBase = {
  instagramPortrait: {
    name: 'Portrait',
    ratio: '4:5',
  },
  square: {
    name: 'Square',
    ratio: '1:1',
  },
};

export const instagramFeedPostCropperPresetsV2 = {
  image: {
    ...instagramFeedPostCropperBase,
    instagramLandscape: {
      name: 'Landscape',
      ratio: '1.91:1',
    },
  },
  video: {
    ...instagramFeedPostCropperBase,
    instagramLandscape: {
      name: 'Landscape',
      ratio: '16:9',
    },
  },
};

export const instagramStoryCropperPresetsV2 = {
  instagramStory: {
    name: 'Story / Full Screen Video',
    ratio: '9:16',
  },
};

export const instagramStoryReelCropperPresets = {
  instagramStory: {
    name: 'Story / Reel',
    ratio: '9:16',
  },
};

export const facebookReelCropperPresets = {
  instagramStory: {
    name: 'Reel',
    ratio: '9:16',
  },
};

export const instagramReelCoverImageCropperPresets = {
  instagramStory: {
    name: 'Story / Reel',
    ratio: '9:16',
  },
  square: {
    name: 'Profile Grid',
    ratio: '1:1',
  },
};

export const facebookReelCoverImageCropperPresets = {
  instagramStory: {
    name: 'Reel',
    ratio: '9:16',
  },
};

export const tikTokCropperPresets = {
  tiktokVideo: {
    name: 'TikTok Video',
    ratio: '9:16',
  },
};

export const sourceEnum = {
  EDITOR: 'EDITOR',
  FACEBOOK: 'FACEBOOK',
  FACEBOOK_ADS: 'FACEBOOK_ADS',
  FACEBOOK_LINK: 'FACEBOOK_LINK',
  FACEBOOK_TEXT: 'FACEBOOK_TEXT',
  FACEBOOK_TEXT_LINK: 'FACEBOOK_TEXT_LINK',
  INSTAGRAM: 'INSTAGRAM',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  INSTAGRAM_STORY_UGC: 'INSTAGRAM_STORY_UGC',
  LINKEDIN: 'LINKEDIN',
  PINTEREST: 'PINTEREST',
  TIKTOK: 'TIKTOK',
  TWITTER: 'TWITTER',
  TWITTER_LINK: 'TWITTER_LINK',
  TWITTER_TEXT: 'TWITTER_TEXT',
  UPLOAD: 'UPLOAD',
  YOUTUBE: 'YOUTUBE',
};
export const typeEnum = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  CAROUSEL: 'CAROUSEL',
  REEL: 'REEL',
  MULTI_IMAGE: 'MULTI_IMAGE',
};

export const adsUserPlatforms = ['ads', 'library'];

export const regexExpressions = {
  s3_domain: /^https:\/\/dashhudson-(static|dev)\.s3(-accelerate)?\.amazonaws\.com/,
  instagram_handle: /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/,
  // eslint-disable-next-line no-useless-escape
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const FILTERS_OPERATORS = {
  IS: 'is',
  CONTAIN: 'contain',
  NOT_CONTAIN: 'not_contain',
  GREATER_THAN: 'gt',
  LESS_THAN: 'lt',
  BETWEEN: 'between',
  NOT_BETWEEN: 'not_between',
  IN: 'in',
};

export const LOGICAL_OPERATORS = {
  AND: 'AND',
  OR: 'OR',
};

export const FILTERS_OPERATORS_LABEL = {
  is: 'is',
  contain: 'contains',
  not_contain: 'does not contain',
  gt: 'is greater than',
  lt: 'is less than',
  between: 'is between',
  not_between: 'is not between',
  in: 'in',
};

export const PREDICTION_MODEL = {
  ENGAGEMENT: {
    label: 'engagement',
    field: 'eng',
    v2field: 'engagement',
    icon: 'pieGraph',
  },
};

export const UPLOAD_STATUS = {
  SUCCESS: 'success',
  PROCESSING: 'processing',
  PENDING: 'pending',
  FAILED: 'failed',
  INIT: 'init',
  UPLOADING: 'uploading',
};

export const PREDICTION_RATINGS = {
  bad: {
    color: '#B1C1D2',
    tooltip: 'This content is likely to perform lower than average',
  },
  good: {
    color: colours.SUCCESS.SUCCESS_500,
    tooltip: 'This content is likely to perform better than average',
  },
  excellent: {
    color: '#F5A623',
    tooltip: 'This content is likely a top performer',
  },
};

export const SKELETON_TYPES = {
  chart: 'chart',
  title: 'title',
  topLineStats: 'topLineStats',
  topPosts: 'topPosts',
  circle: 'circle',
  comment: 'comment',
  competitorOverviewBarChartSkeleton: 'competitorOverviewBarChartSkeleton',
  longTitle: 'longTitle',
  horizontalCard: 'horizontalCard',
  productCard: 'productCard',
  mediaCard: 'mediaCard',
  blankMediaCard: 'blankMediaCard',
  ugcMediaCard: 'ugcMediaCard',
  notification: 'notification',
  galleryCard: 'galleryCard',
  galleryCardSquareMedia: 'galleryCardSquareMedia',
  picture: 'picture',
  mediaTileMatrix: 'mediaTileMatrix',
  list: 'list',
  inboxMessage: 'inboxMessage',
  inboxMessageDetail: 'inboxMessageDetail',
  directMessage: 'directMessage',
  instagramStoryCard: 'instagramStoryCard',
  wideMediaTile: 'wideMediaTile',
  verticalMediaTile: 'verticalMediaTile',
  presetReplyPopup: 'presetReplyPopup',
  campaignCard: 'campaignCard',
  youtubeMediaCard: 'youtubeMediaCard',
  TikTokTrendingSoundsSkeleton: 'TikTokTrendingSoundsSkeleton',
  trendGroup: 'trendGroup',
  monthlyDateContents: 'monthlyDateContents',
  weeklyDateContents: 'weeklyDateContents',
  card: 'card',
  accountSelector: 'accountSelector',
  horizontalDistributionChartSkeleton: 'horizontalDistributionChartSkeleton',
  horizontalDistributionFlexChartSkeleton: 'horizontalDistributionFlexChartSkeleton',
  influencerTile: 'influencerTile',
  userDropdownSelect: 'userDropdownSelect',
  interactionCard: 'interactionCard',
  tag: 'tag',
  adsAccountsPopupSkeleton: 'adsAccountsPopupSkeleton',
  chatBubbleSkeleton: 'chatBubbleSkeleton',
  chatFeedSkeleton: 'chatFeedSkeleton',
  tileMatrix: 'tileMatrix',
};

export const VISION_AI_CHAT_BUBBLE_SKELETON_TYPES = {
  USER_BUBBLE: 'USER_BUBBLE',
  SYSTEM_BUBBLE: 'SYSTEM_BUBBLE',
};

export const PLATFORM_POST_TYPES = {
  FACEBOOK: 'Post',
  TWITTER: 'Post',
  PINTEREST: 'Pin',
  INSTAGRAM: 'Post',
};

export const DOWNLOAD_TYPES = {
  PDF: 'PDF',
  CSV: 'CSV',
};

// Issue logged here: https://developers.facebook.com/support/bugs/426770815432371/
export const SKEWED_IG_STORY_REACH_MESSAGE =
  "Due to an ongoing issue with Facebook's API, some stories may show higher reach than impressions.";

export const REPORT_MEDIA_LIMIT = 100;

export const SOURCE_TO_METRIC_PROP = {
  FACEBOOK_LINK: constants.FACEBOOK.toLowerCase(),
  FACEBOOK_TEXT: constants.FACEBOOK.toLowerCase(),
  FACEBOOK_TEXT_LINK: constants.FACEBOOK.toLowerCase(),
  FACEBOOK_OTHER: constants.FACEBOOK_COMPETITIVE.toLowerCase(),
  FACEBOOK_OTHER_TEXT: constants.FACEBOOK_COMPETITIVE.toLowerCase(),
  FACEBOOK_OTHER_LINK: constants.FACEBOOK_COMPETITIVE.toLowerCase(),
  FACEBOOK_OTHER_TL: constants.FACEBOOK_COMPETITIVE.toLowerCase(),
  TWITTER_LINK: constants.TWITTER.toLowerCase(),
  TWITTER_TEXT: constants.TWITTER.toLowerCase(),
  INSTAGRAM_STORY: 'instagram_story_frame',
};

const instagramMediaCardDefaults = {
  labels: {
    comments_count: 'Comments',
    engagement: 'Engagement Rate',
    like_count: 'Likes',
    saved: 'Saves',
    totalEngagement: 'Engagements',
    reach: 'Estimated Reach',
    ugcEffectiveness: 'Effectiveness',
  },
  metrics: ['engagement', 'effectiveness', 'totalEngagement', 'reach'],
};

const instagramStoryMediaCardDefaults = {
  labels: {
    igStoryAvgReach: 'Avg. Reach',
    igStoryAvgImpressions: 'Avg. Impressions',
    igStoryAvgCompletionRate: 'Avg. Completion Rate',
    igStoryAvgExitRate: 'Avg. Exit Rate',
    igStorySwipeUps: 'Stories Link Clicks',
  },
  metrics: [
    'igStoryAvgReach',
    'igStoryAvgImpressions',
    'igStoryAvgCompletionRate',
    'igStoryAvgExitRate',
  ],
};

const instagramCompetitiveMediaCardDefaults = {
  labels: {
    comments_count: 'Comments',
    engagement: 'Engagement Rate',
    like_count: 'Likes',
    reach: 'Estimated Reach',
  },
  metrics: ['comments_count', 'engagement', 'reach', 'like_count'],
};

const instagramStoryFrameUgcMediaCardDefaults = {
  labels: {
    views: 'Impressions',
    reach: 'Reach',
    completion_rate: 'Completion Rate',
    exit_rate: 'Exit Rate',
    exits: 'Exits',
  },
};

export const instagramGalleryCardDefaults = {
  metrics: [
    'instagramAvgReach',
    'instagramTotalLikes',
    'instagramTotalComments',
    'instagramAvgEngagement',
    'instagramAvgEffectiveness',
  ],
  labels: {
    instagramAvgReach: 'Avg. Reach - Organic',
    instagramTotalLikes: 'Likes',
    instagramTotalComments: 'Comments',
    instagramAvgEngagement: 'Avg. Engagement Rate - Organic (F)', // on FF remove, change to (F) https://app.shortcut.com/dashhudson/story/130789/update-instagram-boards-remove-ff
    instagramAvgEffectiveness: 'Avg. Effectiveness - Organic',
  },
};

export const competitiveGalleryCardDefaults = { ...instagramGalleryCardDefaults };

export const instagramMetricFormats = {
  instagramAvgReach: 'number_with_commas',
  instagramTotalLikes: 'number_with_commas',
  instagramTotalComments: 'number_with_commas',
  instagramAvgEngagement: 'percent',
  instagramAvgEffectiveness: 'percent',
};

export const DEFAULT_METRICS = {
  instagram: instagramMediaCardDefaults.metrics,
  instagram_stories: instagramStoryMediaCardDefaults.metrics,
  instagram_competitive: instagramCompetitiveMediaCardDefaults.metrics,
  facebook: facebookMediaCardDefaults.metrics,
  facebook_competitive: facebookCompetitiveMediaCardDefaults.metrics,
  pinterest: pinterestMediaCardDefaults.metrics,
  twitter: twitterMediaCardDefaults.metrics,
  tiktok: tiktokMediaCardDefaults.metrics,
  youtube: youtubeMediaCardDefaults.metrics,
};

export const DEFAULT_METRIC_LABELS = {
  instagram: instagramMediaCardDefaults.labels,
  instagram_stories: instagramStoryMediaCardDefaults.labels,
  instagram_competitive: instagramCompetitiveMediaCardDefaults.labels,
  instagram_story_frame: instagramStoryFrameUgcMediaCardDefaults.labels,
  facebook_competitive: facebookCompetitiveMediaCardDefaults.labels,
  pinterest: pinterestMediaCardDefaults.labels,
  twitter: twitterMediaCardDefaults.labels,
  youtube: youtubeMediaCardDefaults.labels,
};

export const DEFAULT_BOARD_METRICS = {
  TIKTOK: tiktokGalleryCardDefaults.metrics,
  FACEBOOK: facebookGalleryCardDefaults.metrics,
  TWITTER: twitterGalleryCardDefaults.metrics,
  YOUTUBE: youtubeGalleryCardDefaults.metrics,
  PINTEREST: pinterestGalleryCardDefaults.metrics,
  INSTAGRAM: instagramGalleryCardDefaults.metrics,
  COMPETITIVE: competitiveGalleryCardDefaults.metrics,
};

export const DEFAULT_BOARD_METRIC_LABELS = {
  TIKTOK: tiktokGalleryCardDefaults.labels,
  FACEBOOK: facebookGalleryCardDefaults.labels,
  TWITTER: twitterGalleryCardDefaults.labels,
  YOUTUBE: youtubeGalleryCardDefaults.labels,
  PINTEREST: pinterestGalleryCardDefaults.labels,
  INSTAGRAM: instagramGalleryCardDefaults.labels,
};

export const BOARD_METRIC_LABEL_FORMATS = {
  TIKTOK: tiktokMetricFormats,
  FACEBOOK: facebookMetricFormats,
  TWITTER: twitterMetricFormats,
  PINTEREST: pinterestMetricFormats,
  YOUTUBE: youtubeMetricFormats,
  INSTAGRAM: instagramMetricFormats,
};

export const BOARD_CONTENT_NAMES = {
  TIKTOK: 'Video',
  TWITTER: 'Post',
  YOUTUBE: 'Video',
};

export const BOARD_ROUTES = {
  TIKTOK: 'tiktok.galleries',
  FACEBOOK: 'facebook.galleries',
  TWITTER: 'twitter.galleries',
  INSTAGRAM_STORY: 'instagram.yourStories.storyBoards',
  PINTEREST: 'pinterest.galleries',
  YOUTUBE: 'youtube.galleries',
  COMPETITIVE_INSTAGRAM: 'competitors.instagram.galleries',
  COMPETITIVE_TWITTER: 'competitors.twitter.galleries',
  COMPETITIVE_YOUTUBE: 'competitors.youtube.galleries',
};

export const BOARD_DETAIL_ROUTES = {
  TIKTOK: 'tiktok.gallery.detail',
  FACEBOOK: 'facebook.gallery.detail',
  TWITTER: 'twitter.gallery.detail',
  INSTAGRAM: 'instagram.galleries.board',
  INSTAGRAM_STORY: 'instagram.stories.galleries.gallery',
  PINTEREST: 'pinterest.galleries.detail',
  YOUTUBE: 'youtube.gallery.detail',
  COMPETITIVE_INSTAGRAM: 'competitors.instagram.galleries.board',
  COMPETITIVE_TWITTER: 'competitors.twitter.galleries.board',
  COMPETITIVE_YOUTUBE: 'competitors.youtube.galleries.board',
};

export const GALLERY_CARD_DISPLAY_FORMAT = {
  DEFAULT_THUMBNAIL: 'defaultThumbnail',
  HORIZONTAL_THUMBNAIL: 'horizontalThumbnail',
  VERTICAL_THUMBNAIL: 'verticalThumbnail',
};

export const commentAndNotificationTypes = {
  COMMENT: 'COMMENT',
  COMMENT_REPLY: 'COMMENT_REPLY',
  COMMENT_MENTION: 'COMMENT_MENTION',
};

export const notificationDescriptionsByType = {
  COMMENT_MENTION: 'mentioned you in a comment',
  COMMENT_REPLY: 'replied to your comment',
  COMMENT: 'commented',
};

export const COMPOSER_TYPES = {
  comment: {
    label: 'comment',
    actionText: 'Comment',
    placeHolderText: 'Add a comment or @mention a teammate',
  },
  reply: {
    label: 'reply',
    actionText: 'Reply',
    placeHolderText: 'Reply',
  },
  edit: {
    label: 'edit',
    actionText: 'Save',
    placeHolderText: 'Edit',
  },
};

export const COMMENT_RESOURCE_TYPES = {
  SCHEDULER_INSTAGRAM_POST: 'SCHEDULER_INSTAGRAM_POST',
  SCHEDULER_PINTEREST_POST: 'SCHEDULER_PINTEREST_POST',
  SCHEDULER_FACEBOOK_POST: 'SCHEDULER_FACEBOOK_POST',
  SCHEDULER_TWITTER_POST: 'SCHEDULER_TWITTER_POST',
  LIBRARY_BRAND_MEDIA: 'LIBRARY_BRAND_MEDIA',
  COMMUNITY_INTERNAL_NOTE: 'COMMUNITY_INTERNAL_NOTE',
  COMMUNITY_ASSIGNMENT: 'COMMUNITY_ASSIGNMENT',
  COMMUNITY_RULE_EXECUTED: 'COMMUNITY_RULE_EXECUTED',
};

export const INSTAGRAM_STORY_CARD_CONSTANTS = {
  MIN_FRAMES_CONTAINER_WIDTH: 200,
  DEFAULT_NUMBER_OF_FRAMES: 3,
  SMALL_NUMBER_OF_FRAMES: 2,
};

export const ENTERTAINING_AVG_LINE_METRICS = {
  AVG_ORGANIC_VIDEO_VIEWS: 'AVG_ORGANIC_VIDEO_VIEWS',
  AVG_VIDEO_VIEWS: 'AVG_VIDEO_VIEWS',
  AVG_ENTERTAINMENT_SCORE_REEL: 'AVG_ENTERTAINMENT_SCORE_REEL',
  AVG_ENTERTAINMENT_SCORE: 'AVG_ENTERTAINMENT_SCORE',
};

export const OVERVIEW_ENTERTAINING_AVG_LINE_METRIC = {
  '-INSTAGRAM_ENTERTAINMENT_SCORE': {
    metric: ENTERTAINING_AVG_LINE_METRICS.AVG_ENTERTAINMENT_SCORE_REEL,
    channel: 'INSTAGRAM',
    post_types: ['reel'],
    attribute: {
      value: 'entertainmentScore',
      isDecimal: true,
    },
    icon: 'leisurePartyPopper',
    tooltips: toolTips.mostEntertaining.entertainmentScore,
  },
  '-INSTAGRAM_VIDEO_VIEWS': {
    metric: ENTERTAINING_AVG_LINE_METRICS.AVG_ORGANIC_VIDEO_VIEWS,
    channel: 'INSTAGRAM',
    post_types: ['video', 'reel'],
    attribute: {
      value: 'videoViews',
      isDecimal: false,
    },
    icon: 'controlPlay',
    tooltips: toolTips.mostEntertaining.videoViews,
  },
  '-TIKTOK_ENTERTAINMENT_SCORE': {
    metric: ENTERTAINING_AVG_LINE_METRICS.AVG_ENTERTAINMENT_SCORE,
    channel: 'TIKTOK',
    attribute: {
      value: 'entertainmentScore',
      isDecimal: true,
    },
    icon: 'leisurePartyPopper',
    tooltips: toolTips.mostEntertaining.entertainmentScore,
  },
  '-TIKTOK_VIEWS': {
    metric: ENTERTAINING_AVG_LINE_METRICS.AVG_VIDEO_VIEWS,
    channel: 'TIKTOK',
    attribute: {
      value: 'views',
      isDecimal: false,
    },
    icon: 'controlPlay',
    tooltips: toolTips.mostEntertaining.videoViews,
  },
};

export const mostEntertainingVideoLimit = 7;
export const mostEntertainingMinAbbrNumber = 1000000;

export const SEARCH_BAR_PLACEHOLER = 'Search Media';
export const COMPETITIVE_SEARCH_BAR_PLACEHOLER = 'Filter by keywords(s)';

export const maxTopPinsStartDateDays = 91;

export const MOST_ENTERTAINING_MIXPANEL_FILTER_EVENT_NAME = {
  INSTAGRAM: 'Instagram Overview Content Insights',
  TIKTOK: 'TikTok Content Selection',
};

export const CHART_COLORS = [
  '#7ED321',
  '#7597F0',
  '#C9E321',
  '#39BCE6',
  '#EB4D4D',
  '#69DCDD',
  '#F6A623',
  '#8943FF',
  '#F0649A',
  '#51CC92',
  '#C5994C',
  '#4BA1D0',
  '#E179DA',
  '#C26C47',
  '#ED7171',
  '#64DB5B',
  '#F6D423',
  '#4356FF',
  '#BD7C9B',
  '#84AE63',
];

export const timeScaleTypes = {
  DAILY: {
    value: 'DAILY',
    label: 'By Day',
    unit: 'day',
    dateFormat: 'YYYY-MM-DD',
  },
  MONTHLY: {
    value: 'MONTHLY',
    label: 'By Month',
    unit: 'month',
    dateFormat: 'YYYY-MM',
  },
};

export const sortOrderOptions = {
  ASC: {
    value: 'ASC',
    label: 'Lowest to Highest',
  },
  DESC: {
    value: 'DESC',
    label: 'Highest to Lowest',
  },
};

export const mixpanelFilterOptions = {
  SCALE: 'Scale',
  GRAPH_METRIC: 'Graph Metric',
  DATE: 'Date',
  SORT_BY: 'Sort By',
  SORT_ORDER: 'Sort Order',
};

export const DROPDOWN_LIST_ITEMS = {
  YOUR_POSTS_CSV: {
    text: 'Your Posts CSV',
    action: 'downloadYourPostsCSV',
  },
};

export const SALES_TERRITORY_TO_INDUSTRY = {
  Beauty: 'Beauty',
  Fashion: 'Fashion',
  Publishing: 'Publishing',
  Retail: 'Retail',
  Media: 'Media',
  Automotive: 'Automotive',
  'Consumer Electronics': 'Consumer Electronics',
  'Consumer Goods': 'Consumer Packaged Goods',
  'Financial Services': 'Financial Services',
  'Food & Bev': 'Food & Beverage',
  'Home Decor / Home Goods': 'Home',
  'Luxury Goods & Jewelry': 'Luxury',
  Healthcare: 'Wellness',
  Travel: 'Travel',
  Technology: 'All Industries',
  Agency: 'All Industries',
  Other: 'All Industries',
  'Non Profit': 'All Industries',
  null: 'All Industries',
  undefined: 'All Industries',
};

export const VISION_AI_SENDER = {
  USER: 'USER',
  ASSISTANT: 'ASSISTANT',
};
